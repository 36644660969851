import { css } from '@emotion/react';
import { LayoutModifier } from './LayoutModifier';

export const Grid = ({
  width,
  height,
  padding,
  innerPadding,
  verticalPadding,
  horizontalPadding,
  background,
  verticalGravity,
  children,
}: LayoutModifier) => {
  const style = css({
    width,
    height,
    padding:
      padding ??
      (verticalPadding && horizontalPadding
        ? `${verticalPadding} ${horizontalPadding}`
        : undefined) ??
      (verticalPadding ? `${verticalPadding} 0` : undefined) ??
      (horizontalPadding ? `0 ${horizontalPadding}` : undefined),
    background,
    gridGap: innerPadding,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: verticalGravity,
    boxSizing: 'border-box',
  });

  return <div css={style}>{children}</div>;
};
