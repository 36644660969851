import Text from '@dotpict-lib/foundation/typography/Text';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';

export type MakingTextProps = {
  text: string;
};

const MakingText = ({ text }: MakingTextProps) => (
  <Text text={text} textStyle={TextStyle.REGULAR16} colorStyle={ColorStyle.TEXT_PRIMARY} />
);

export default MakingText;
