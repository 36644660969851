import { emptyNote, NoteType } from '@dotpict-sns-lib/model/Note/NoteType';

export type ChildNotesState = {
  parentNote: NoteType;
  notes: NoteType[];
};

export const emptyChildNotesState: ChildNotesState = {
  parentNote: emptyNote,
  notes: [],
};
