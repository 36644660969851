import { useState } from 'react';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import TranslationKeys from '@/app/translation/TranslationKeys';
import Text from '@dotpict-lib/foundation/typography/Text';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import useTranslator from '@/app/hook/useTranslator';
import { CropArea, Rect } from '@/app/component/page/work/upload/types';
import CroppedThumbnail from '@/app/component/partial/work/upload/croppedthumbnail/CroppedThumbnail';
import CropDialog, { Media } from '@/app/component/page/work/upload/CropDialog';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';
import Button from '@dotpict-lib/component/dotpict/Button';
import styled from '@emotion/styled';
import OverlayFileSelectButton from '@/app/component/partial/work/upload/overlayfileselectbutton/OverlayFileSelectButton';
import Column from '@dotpict-lib/component/core/layout/Column';
import { HorizontalGravity } from '@dotpict-lib/component/core/layout/HorizontalGravity';
import { Row } from '@dotpict-lib/component/core/layout/Row';

type Props = {
  defaultMedia: Media;
  defaultCropArea: CropArea | undefined;
  handleFileChange: (file: File | undefined) => void;
  handleDecideCrop: (croppedRect: Rect | undefined) => void;
};

const ThumbnailWithRemoveContainer = styled.div`
  position: relative;
  width: 400px;
  height: 400px;
`;

const ThumbnailContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 384px;
  height: 384px;
  clip: rect(0px, 384px, 384px, 0px);
`;

const FileSelectButtonContainer = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  width: 120px;
  height: 32px;
`;

const EditWorkUploadBoxComponent = ({
  defaultMedia,
  defaultCropArea,
  handleFileChange,
  handleDecideCrop,
}: Props) => {
  const [openCropDialog, setOpenCropDialog] = useState<boolean>(false);
  const [croppedArea, setCroppedArea] = useState<CropArea | undefined>(defaultCropArea);
  const [media, setMedia] = useState<Media>(defaultMedia);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const translator = useTranslator();

  const reader = new FileReader();
  reader.onload = (v) => {
    setErrorMessage('');
    const result = v.target?.result;
    if (result == null) {
      setErrorMessage(translator(TranslationKeys.UnknownError));
      return;
    }
    const img = new Image();
    img.src = result as string;
    img.onload = () => {
      setMedia({
        url: result as string,
        width: img.width,
        height: img.height,
      });
    };
  };

  const handleClickCropPosition = () => {
    setOpenCropDialog(true);
  };

  const handleClickResetCropPosition = () => {
    setCroppedArea(undefined);
    handleDecideCrop(undefined);
  };

  const handleClickCropCancel = () => {
    setOpenCropDialog(false);
  };

  const handleClickDecideCropInner = (croppedArea: CropArea, croppedRect: Rect) => {
    handleDecideCrop(croppedRect);
    setCroppedArea(croppedArea);
    setOpenCropDialog(false);
  };

  const handleFileChangeInner = (file: File | undefined) => {
    if (!file) return;
    reader.readAsDataURL(file);
    handleFileChange(file);
  };

  return (
    <>
      <Column horizontalGravity={HorizontalGravity.center}>
        <ThumbnailWithRemoveContainer>
          <ThumbnailContainer>
            <CroppedThumbnail imageUrl={media.url} croppedArea={croppedArea} />
            <FileSelectButtonContainer>
              <OverlayFileSelectButton handleFileSelect={handleFileChangeInner} />
            </FileSelectButtonContainer>
          </ThumbnailContainer>
        </ThumbnailWithRemoveContainer>
        <Spacer height={16} />
        <Row innerPadding={24}>
          <Button
            width={240}
            height={48}
            text={translator(TranslationKeys.CropPosition)}
            isFilled={false}
            isBorder
            handleClick={handleClickCropPosition}
          />
          <Button
            width={240}
            height={48}
            text={translator(TranslationKeys.ResetCropPosition)}
            isFilled={false}
            isBorder
            handleClick={handleClickResetCropPosition}
          />
        </Row>
        {errorMessage.length !== 0 && (
          <>
            <Spacer height={4} />
            <Text
              text={errorMessage}
              textStyle={TextStyle.REGULAR12}
              colorStyle={ColorStyle.ERROR}
            />
          </>
        )}
      </Column>
      <CropDialog
        media={media}
        open={openCropDialog}
        handleDecide={handleClickDecideCropInner}
        handleClose={handleClickCropCancel}
      />
    </>
  );
};

export default EditWorkUploadBoxComponent;
