import { Skeleton } from '@material-ui/lab';
import { Row } from '@dotpict-lib/component/core/layout/Row';
import { VerticalGravity } from '@dotpict-lib/component/core/layout/VerticalGravity';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';

const profileImageSize = 64;
const betweenImageAndNameMargin = 16;

export const PlaceholderUser = () => (
  <Row verticalGravity={VerticalGravity.center}>
    <Skeleton variant="rect" width={profileImageSize} height={profileImageSize} />
    <Spacer width={betweenImageAndNameMargin} />
    <Skeleton variant="rect" width={128} height={14} />
  </Row>
);
