const shouldFetchNext = (nextUrl: string | null, threshold?: number) => {
  const bottomY = window.scrollY + window.innerHeight;
  return (
    // 10くらい見ておかないと端数処理でロードされないことがある
    bottomY >= document.documentElement.scrollHeight - ((threshold ?? 0) + 10) &&
    nextUrl !== null &&
    nextUrl !== ''
  );
};

export default shouldFetchNext;
