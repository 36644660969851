import { createContext, useContext, useEffect, useLayoutEffect } from 'react';
import { GlobalContext } from '@/Routing';
import shouldFetchNext from '@/app/common/common';
import { useNavigate } from 'react-router-dom';
import { useCreateCanvasData } from '@/app/component/page/draw/hooks/storage';
import useAnalytics from '@/app/hook/useAnalytics';
import { GetWorksService } from '@/app/service/GetWorks/GetWorksService';
import dummyGetWorksService from '@/app/service/GetWorks/dummyGetWorksService';
import { GetOdaiService } from '@/app/service/GetOdai/GetOdaiService';
import dummyGetOdaiService from '@/app/service/GetOdai/dummyGetOdaiService';
import usePagingBuilder from '@/app/apiclient/PagingBuilder';
import { OdaiDetailProps } from '@/app/component/page/event/odai/Odai';
import { defaultOdaiDetailState, OdaiDetailStateType } from '@/app/state/OdaiDetailStateType';
import { defaultPaletteColors } from '@/app/common/constant';

export type Dependencies = {
  getOdaiService: GetOdaiService;
  getOdaiWorksService: GetWorksService;
};

export const OdaiContext = createContext<Dependencies>({
  getOdaiService: dummyGetOdaiService,
  getOdaiWorksService: dummyGetWorksService,
});

type Props = {
  odaiTag: string;
};

const useOdai = ({ odaiTag }: Props): OdaiDetailProps => {
  const { getOdaiService, getOdaiWorksService } = useContext(OdaiContext);
  const pagingBuilder = usePagingBuilder();
  const { createCanvasData } = useCreateCanvasData();
  const { globalState, dispatch } = useContext(GlobalContext);
  const navigator = useNavigate();
  const analytics = useAnalytics();

  const fetchOdaiWorks = async (currentState: OdaiDetailStateType) => {
    dispatch({
      type: 'UPDATE_ODAI_DETAIL_STATE',
      payload: {
        odaiDetailState: {
          ...currentState,
          isLoadingWorks: true,
        },
      },
    });
    const response = await getOdaiWorksService({ nextUrl: currentState.nextUrl });
    const newWorks = currentState.works.concat(response.works);
    const newNextUrl = response.nextUrl;
    const newState: OdaiDetailStateType = {
      ...currentState,
      isLoadingWorks: false,
      isVisibleEnd: newNextUrl === '',
      works: newWorks,
      nextUrl: newNextUrl,
    };
    dispatch({
      type: 'UPDATE_ODAI_DETAIL_STATE',
      payload: {
        odaiDetailState: newState,
      },
    });
    if (shouldFetchNext(newNextUrl)) {
      fetchOdaiWorks(newState);
    }
  };

  const refreshOdai = async (odaiTag: string) => {
    dispatch({
      type: 'UPDATE_ODAI_DETAIL_STATE',
      payload: {
        odaiDetailState: {
          ...defaultOdaiDetailState,
          isLoadingWorks: false,
        },
      },
    });
    const odai = await getOdaiService(odaiTag);
    const newState: OdaiDetailStateType = {
      odai,
      isLoadingDetail: false,
      isLoadingWorks: true,
      isVisibleEnd: false,
      works: [],
      nextUrl: pagingBuilder(`/odais/${odai.id}/works`),
    };
    dispatch({
      type: 'UPDATE_ODAI_DETAIL_STATE',
      payload: { odaiDetailState: newState },
    });
    await fetchOdaiWorks(newState);
  };

  const handleDraw = () => {
    const { odai } = globalState.odaiDetailState;
    const canvas = document.createElement('canvas');
    canvas.width = 32;
    canvas.height = 32;
    const newPixels = Array.from({ length: canvas.height }, () => {
      return Array.from({ length: canvas.width }, () => 'FFFFFF');
    });
    const newCanvasId = createCanvasData(
      { width: canvas.width, height: canvas.height },
      defaultPaletteColors,
      newPixels,
      undefined,
      undefined,
      odai,
    );
    analytics('draw_odai', { odai_id: `${odai.id}` });
    navigator(`/draw?current=${newCanvasId}`);
  };

  const handlePost = () => {
    navigator(`/upload?odaiTag=${globalState.odaiDetailState.odai.tag}`);
  };

  const handleScroll = () => {
    if (
      globalState.odaiDetailState.isLoadingWorks ||
      !shouldFetchNext(globalState.odaiDetailState.nextUrl) ||
      globalState.odaiDetailState.works.length === 0
    ) {
      return;
    }
    fetchOdaiWorks({
      ...globalState.odaiDetailState,
      isLoadingWorks: true,
    });
  };

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  useEffect(() => {
    if (!odaiTag || odaiTag === globalState.odaiDetailState.odai.tag) return;
    refreshOdai(odaiTag);
  }, []);

  return {
    ...globalState.odaiDetailState,
    handlePost,
    handleDraw,
  };
};

export default useOdai;
