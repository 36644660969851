import { Dialog } from '@material-ui/core';
import { SizeContext, SizeType } from '@/app/component/page/draw/Context/SizeContext';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';
import Text from '@dotpict-lib/foundation/typography/Text';
import TranslationKeys from '@/app/translation/TranslationKeys';
import useTranslator from '@/app/hook/useTranslator';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import CanvasSizeSelector from '@/app/component/page/draw/Header/CanvasSizeSelector';
import useNewCanvas from '@/app/component/page/draw/canvases/newcanvasdialog/useNewCanvas';
import { PaletteType } from '@/app/model/PaletteType';
import Palette from '@/app/component/partial/palette/Palette';
import styled from '@emotion/styled';
import Button from '@dotpict-lib/component/dotpict/Button';
import Loading from '@dotpict-lib/component/core/Loading';
import Column from '@dotpict-lib/component/core/layout/Column';
import { HorizontalGravity } from '@dotpict-lib/component/core/layout/HorizontalGravity';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

const PalettesContainer = styled.div`
  overflow-y: scroll;
  height: 375px;
`;

const PaletteContainer = styled.div`
  position: relative;
  width: 375px;
  height: 96px;
`;

const PaletteItem = styled.div`
  position: absolute;
  width: 375px;
  height: 96px;
`;

const NewCanvasDialog = ({ open, setOpen }: Props) => {
  const translator = useTranslator();
  const {
    isLoadingPalette,
    palettes,
    selectedPaletteIndex,
    size,
    handleSelectCanvasSize,
    handleClickPalette,
    handleClickCreate,
  } = useNewCanvas(open);

  const renderPalettes = () => {
    if (isLoadingPalette && palettes.length === 0) {
      return <Loading />;
    }
    return (
      <PalettesContainer>
        <Column width={375} innerPadding={4} horizontalGravity={HorizontalGravity.center}>
          {palettes.map((palette: PaletteType, index: number) => (
            <PaletteContainer key={palette.id} onClick={() => handleClickPalette(index)}>
              <PaletteItem>
                <Palette palette={palette} />
              </PaletteItem>
              <PaletteItem>
                <Button
                  width={375}
                  height={96}
                  text=""
                  isFilled={false}
                  isBorder={selectedPaletteIndex === index}
                />
              </PaletteItem>
            </PaletteContainer>
          ))}
        </Column>
      </PalettesContainer>
    );
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={() => setOpen(false)}>
      <Column background={ColorStyle.WHITE} horizontalGravity={HorizontalGravity.center}>
        <Spacer height={20} />
        <Text
          text={translator(TranslationKeys.NewCanvas)}
          textStyle={TextStyle.BOLD20}
          colorStyle={ColorStyle.TEXT_PRIMARY}
        />
        <Spacer height={36} />
        <Text
          text={translator(TranslationKeys.SelectSize)}
          textStyle={TextStyle.REGULAR16}
          colorStyle={ColorStyle.TEXT_PRIMARY}
        />
        <Spacer height={16} />
        <SizeContext.Provider value={size}>
          <CanvasSizeSelector selectSize={(size: SizeType) => () => handleSelectCanvasSize(size)} />
        </SizeContext.Provider>
        <Spacer height={16} />
        <Text
          text={translator(TranslationKeys.SelectPalette)}
          textStyle={TextStyle.REGULAR16}
          colorStyle={ColorStyle.TEXT_PRIMARY}
        />
        <Spacer height={16} />
        <Text
          text={translator(TranslationKeys.SelectPaletteDescription1)}
          textStyle={TextStyle.REGULAR12}
          colorStyle={ColorStyle.TEXT_PRIMARY}
        />
        <Text
          text={translator(TranslationKeys.SelectPaletteDescription2)}
          textStyle={TextStyle.REGULAR12}
          colorStyle={ColorStyle.TEXT_PRIMARY}
        />
        <Spacer height={16} />
        {renderPalettes()}
        <Spacer height={24} />
        <Button
          width={240}
          height={48}
          text={translator(TranslationKeys.Create)}
          isFilled
          handleClick={handleClickCreate}
        />
        <Spacer height={24} />
      </Column>
    </Dialog>
  );
};

export default NewCanvasDialog;
