import { useEffect, useState } from 'react';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import TranslationKeys from '@/app/translation/TranslationKeys';
import Text from '@dotpict-lib/foundation/typography/Text';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import useTranslator from '@/app/hook/useTranslator';
import { CropArea, Rect } from '@/app/component/page/work/upload/types';
import CroppedThumbnail from '@/app/component/partial/work/upload/croppedthumbnail/CroppedThumbnail';
import CropDialog, { Media } from '@/app/component/page/work/upload/CropDialog';
import FileSelector from '@/app/component/partial/work/upload/fileselector/FileSelector';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';
import styled from '@emotion/styled';
import Button from '@dotpict-lib/component/dotpict/Button';
import OverlayFileSelectButton from '@/app/component/partial/work/upload/overlayfileselectbutton/OverlayFileSelectButton';
import Column from '@dotpict-lib/component/core/layout/Column';
import { HorizontalGravity } from '@dotpict-lib/component/core/layout/HorizontalGravity';
import { Row } from '@dotpict-lib/component/core/layout/Row';

type Props = {
  imageFile: File | undefined;
  handleFileChange: (file: File | undefined) => void;
  handleDecideCrop: (croppedRect: Rect | undefined) => void;
};

const ThumbnailWithRemoveContainer = styled.div`
  position: relative;
  width: 384px;
  height: 384px;
`;

const ThumbnailContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 384px;
  height: 384px;
  clip: rect(0px, 384px, 384px, 0px);
`;

const FileSelectButtonContainer = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  width: 120px;
  height: 32px;
`;

const WorkUploadBoxComponent = ({ imageFile, handleFileChange, handleDecideCrop }: Props) => {
  const [openCropDialog, setOpenCropDialog] = useState<boolean>(false);
  const [croppedArea, setCroppedArea] = useState<CropArea | undefined>();
  const [media, setMedia] = useState<Media | undefined>({ url: undefined, width: 0, height: 0 });
  const [errorMessage, setErrorMessage] = useState<string>('');
  const translator = useTranslator();

  const reader = new FileReader();
  reader.onload = (v) => {
    setErrorMessage('');
    const result = v.target?.result;
    if (result == null) {
      setErrorMessage(translator(TranslationKeys.UnknownError));
      return;
    }
    const img = new Image();
    img.src = result as string;
    img.onload = () => {
      if (img.width > 1024 || img.height > 1024) {
        setErrorMessage(translator(TranslationKeys.ErrorImageSizeTooLarge));
        setMedia(undefined);
        setCroppedArea(undefined);
        handleFileChange(undefined);
        return;
      }
      setMedia({
        url: result as string,
        width: img.width,
        height: img.height,
      });
    };
  };

  const handleClickCropPosition = () => {
    setOpenCropDialog(true);
  };

  const handleClickResetCropPosition = () => {
    setCroppedArea(undefined);
    handleDecideCrop(undefined);
  };

  const handleClickCropCancel = () => {
    setOpenCropDialog(false);
  };

  const handleClickDecideCropInner = (croppedArea: CropArea, croppedRect: Rect) => {
    handleDecideCrop(croppedRect);
    setCroppedArea(croppedArea);
    setOpenCropDialog(false);
  };

  useEffect(() => {
    if (!imageFile) return;
    reader.readAsDataURL(imageFile);
  }, [imageFile]);

  return (
    <>
      <Column horizontalGravity={HorizontalGravity.center}>
        <Spacer height={24} />
        {media?.url ? (
          <ThumbnailWithRemoveContainer>
            <ThumbnailContainer>
              <CroppedThumbnail imageUrl={media.url} croppedArea={croppedArea} />
            </ThumbnailContainer>
            <FileSelectButtonContainer>
              <OverlayFileSelectButton handleFileSelect={handleFileChange} />
            </FileSelectButtonContainer>
          </ThumbnailWithRemoveContainer>
        ) : (
          <FileSelector handleFileSelect={handleFileChange} />
        )}
        {errorMessage.length !== 0 && (
          <>
            <Spacer height={4} />
            <Text
              text={errorMessage}
              textStyle={TextStyle.REGULAR12}
              colorStyle={ColorStyle.ERROR}
            />
          </>
        )}
        <Spacer height={24} />
        {imageFile && (
          <>
            <Spacer height={16} />
            <Row innerPadding={24}>
              <Button
                width={240}
                height={48}
                text={translator(TranslationKeys.CropPosition)}
                isFilled={false}
                isBorder
                handleClick={handleClickCropPosition}
              />
              <Button
                width={240}
                height={48}
                text={translator(TranslationKeys.ResetCropPosition)}
                isFilled={false}
                isBorder
                handleClick={handleClickResetCropPosition}
              />
            </Row>
          </>
        )}
      </Column>
      {media && (
        <CropDialog
          media={media}
          open={openCropDialog}
          handleDecide={handleClickDecideCropInner}
          handleClose={handleClickCropCancel}
        />
      )}
    </>
  );
};

export default WorkUploadBoxComponent;
