import { forwardRef } from 'react';
import styled from '@emotion/styled';
import Canvas from '@/app/component/page/draw/Canvas';
import { CanvasHistoryType, PixelsType } from './hooks/types';

const Container = styled.div`
  // キャンバスは画面中央に表示する
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;

  // 全体移動ツール(48px + 9pxマージン=57px)が画面上下左右にいい感じに収まるサイズを確保する
  // 上：88+4+57（ヘッダー）
  // 左右：178+57（サイドバー）
  // 下：57（なし）
  padding: 149px 235px 57px;
`;

type Props = {
  pushHistory(history: CanvasHistoryType): void;
  setPixels: (pixels: PixelsType, ignoreHistory?: boolean) => void;
};

const Body = forwardRef<HTMLCanvasElement, Props>(({ pushHistory, setPixels }, ref) => {
  return (
    <Container>
      <Canvas pushHistory={pushHistory} setPixels={setPixels} ref={ref} />
    </Container>
  );
});

export default Body;
