import { css } from '@emotion/react';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import { Skeleton } from '@material-ui/lab';
import Column from '@dotpict-lib/component/core/layout/Column';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';
import { PlaceholderUser } from '@/app/component/partial/user/item/PlaceholderUser';

const imageSize = 128;

const gridStyle = css({
  display: 'grid',
  justifyContent: 'center',
  gridGap: '4px',
  gridTemplateColumns: `repeat(auto-fill, ${imageSize}px)`,
});

export const PlaceholderUserSummary = () => (
  <Column width="100%" padding="16" background={ColorStyle.WHITE}>
    <PlaceholderUser />
    <Spacer height={24} />
    <div css={gridStyle}>
      {[...new Array(6)].map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Skeleton key={index} variant="rect" width={imageSize} height={imageSize} />
      ))}
    </div>
  </Column>
);
