import { MouseEventHandler } from 'react';
import Text from '@dotpict-lib/foundation/typography/Text';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import Pixelart from '@dotpict-lib/component/core/Pixelart';
import { Row } from '@dotpict-lib/component/core/layout/Row';
import { VerticalGravity } from '@dotpict-lib/component/core/layout/VerticalGravity';
import { HorizontalGravity } from '@dotpict-lib/component/core/layout/HorizontalGravity';
import { CircularProgress } from '@material-ui/core';
import Clickable from '@dotpict-lib/component/core/Clickable';

export type PrimaryButtonSize = {
  type: 'S' | 'M' | 'L';
  textSize: TextStyle;
  height: number;
};

export const PrimaryButtonSizeS: PrimaryButtonSize = {
  type: 'S',
  textSize: TextStyle.BOLD14,
  height: 32,
};

export const PrimaryButtonSizeM: PrimaryButtonSize = {
  type: 'M',
  textSize: TextStyle.BOLD16,
  height: 48,
};

export const PrimaryButtonSizeL: PrimaryButtonSize = {
  type: 'L',
  textSize: TextStyle.BOLD16,
  height: 56,
};

export type PrimaryButtonProps = {
  width: number;
  text: string;
  size: PrimaryButtonSize;
  isLoading?: boolean;
  icon?: string;
  iconHeight?: number;
  iconWidth?: number;
  backgroundColor?: ColorStyle;
  onClick?: MouseEventHandler;
};

export const PrimaryButton = ({
  width,
  text,
  size,
  isLoading,
  icon,
  iconHeight,
  iconWidth,
  backgroundColor,
  onClick,
}: PrimaryButtonProps) => {
  return (
    <Clickable onClick={onClick}>
      <Row verticalGravity={VerticalGravity.center}>
        <Row
          width={2}
          height={size.height - 4}
          background={backgroundColor ?? ColorStyle.TEXT_PRIMARY}
        />
        <Row
          width={width - 4}
          height={size.height}
          innerPadding={4}
          background={backgroundColor ?? ColorStyle.TEXT_PRIMARY}
          verticalGravity={VerticalGravity.center}
          horizontalGravity={HorizontalGravity.center}
        >
          {isLoading ? (
            <CircularProgress size={16} />
          ) : (
            icon && (
              <Pixelart alt="icon" src={icon} width={iconWidth ?? 16} height={iconHeight ?? 16} />
            )
          )}
          <Text text={text} textStyle={size.textSize} colorStyle={ColorStyle.WHITE} />
        </Row>
        <Row
          width={2}
          height={size.height - 4}
          background={backgroundColor ?? ColorStyle.TEXT_PRIMARY}
        />
      </Row>
    </Clickable>
  );
};
