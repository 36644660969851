import { useContext } from 'react';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';
import Pixelart from '@dotpict-lib/component/core/Pixelart';
import { LayoutContext } from '@/Routing';
import Works from '@/app/component/Works';
import { defaultDummyItemCount } from '@/app/common/constant';
import { css } from '@emotion/react';
import useTranslator from '@/app/hook/useTranslator';
import { HorizontalGravity } from '@dotpict-lib/component/core/layout/HorizontalGravity';
import Column from '@dotpict-lib/component/core/layout/Column';
import Loading from '@dotpict-lib/component/core/Loading';
import { OdaiDetailStateType } from '@/app/state/OdaiDetailStateType';
import useOdai from '@/app/component/page/event/odai/useOdai';
import { Row } from '@dotpict-lib/component/core/layout/Row';
import Button from '@dotpict-lib/component/dotpict/Button';
import TranslationKeys from '@/app/translation/TranslationKeys';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import OdaiBigBanner from '@/app/component/partial/event/odaibanner/big/OdaiBigBanner';

export type OdaiDetailProps = OdaiDetailStateType & {
  handlePost: () => void;
  handleDraw: () => void;
};

type Props = {
  odaiTag: string;
};

const Odai = ({ odaiTag }: Props) => {
  const translator = useTranslator();
  const { odai, isLoadingDetail, isLoadingWorks, isVisibleEnd, works, handlePost, handleDraw } =
    useOdai({
      odaiTag,
    });
  const { layoutParams } = useContext(LayoutContext);

  const imageSize = layoutParams.fullWidth < 192 * 2 + 8 ? (layoutParams.fullWidth - 8) / 2 : 192;

  const gridStyle = css({
    display: 'grid',
    justifyContent: 'center',
    gridGap: '8px',
    gridTemplateColumns: `repeat(auto-fill, ${imageSize}px)`,
  });

  if (isLoadingDetail) return <Loading />;

  return (
    <Column width={layoutParams.contentWidth}>
      <Spacer height={40} />
      <Column horizontalGravity={HorizontalGravity.center}>
        <Column width={layoutParams.contentWidth}>
          <OdaiBigBanner odaiTitle={odai.title} />
        </Column>
      </Column>
      <Spacer height={28} />
      <Row width="100%" horizontalGravity={HorizontalGravity.center}>
        <Button
          width={240}
          height={48}
          text={translator(TranslationKeys.Post)}
          textStyle={TextStyle.BOLD16}
          isFilled
          handleClick={handlePost}
        />
        <Spacer width={28} />
        <Button
          width={240}
          height={48}
          text={translator(TranslationKeys.DrawPixelart)}
          textStyle={TextStyle.BOLD16}
          isFilled={false}
          isBorder
          handleClick={handleDraw}
        />
      </Row>
      <Spacer height={52} />
      <Column width="100%" horizontalGravity={HorizontalGravity.center}>
        <Pixelart
          src="https://storage.googleapis.com/dotpict-images/web/everyone_pixelart.png"
          alt="title"
          width={277}
          height={30}
        />
      </Column>
      <Spacer height={28} />
      <Works
        works={works}
        isLoading={isLoadingWorks}
        isVisibleEnd={isVisibleEnd}
        isVisibleAds
        isVisibleLike
        gridStyle={gridStyle}
        imageSize={imageSize}
        dummyItemCount={defaultDummyItemCount}
      />
      <Spacer height={128} />
    </Column>
  );
};

export default Odai;
