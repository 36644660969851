import React, { useCallback, useRef, useState } from 'react';
import { useCanvasActions } from '@/app/component/page/draw/hooks/storage';
import Pixelart from '@dotpict-lib/component/core/Pixelart';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import Text from '@dotpict-lib/foundation/typography/Text';
import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import useTranslator from '@/app/hook/useTranslator';
import TranslationKeys from '@/app/translation/TranslationKeys';
import useAnalytics from '@/app/hook/useAnalytics';

const MenuIcon = styled.div`
  cursor: pointer;
`;

type Props = {
  id: number;
  onClickDownload(): void;
};

const PopupMenu = ({ id, onClickDownload }: Props) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [_postData, deleteData] = useCanvasActions();
  const menuAnchorRef = useRef(null);
  const navigator = useNavigate();
  const translator = useTranslator();
  const analytics = useAnalytics();

  const handleClickMenuIcon = useCallback(() => {
    setOpenMenu((prevOpen) => !prevOpen);
  }, []);

  const handleClosePopper = useCallback(() => {
    setOpenMenu(false);
  }, []);

  const handleClickDelete = useCallback(() => {
    // eslint-disable-next-line no-restricted-globals,no-alert
    if (confirm(translator(TranslationKeys.ConfirmDeleteCanvas))) {
      deleteData(id);
      window.location.reload();
    }
  }, [deleteData]);

  const handleListKeyDown = useCallback((event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenMenu(false);
    }
  }, []);

  const onClickPostWork = () => {
    analytics('draw_post_clicked');
    navigator(`/upload?canvasId=${id}`);
    handleClosePopper();
  };

  return (
    <>
      <Popper open={openMenu} anchorEl={menuAnchorRef.current} role={undefined} transition>
        {({ TransitionProps, placement }) => (
          <Grow
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClosePopper}>
                <MenuList
                  autoFocusItem={openMenu}
                  id="menu-list-grow"
                  onKeyDown={(e) => handleListKeyDown(e)}
                >
                  {/* TODO */}
                  <MenuItem onClick={onClickPostWork}>
                    <Text
                      text={translator(TranslationKeys.PostCanvas)}
                      textStyle={TextStyle.BOLD14}
                      colorStyle={ColorStyle.TEXT_PRIMARY}
                    />
                  </MenuItem>
                  <MenuItem onClick={onClickDownload}>
                    <Text
                      text={translator(TranslationKeys.SaveCanvas)}
                      textStyle={TextStyle.BOLD14}
                      colorStyle={ColorStyle.TEXT_PRIMARY}
                    />
                  </MenuItem>
                  <MenuItem onClick={handleClickDelete}>
                    <Text
                      text={translator(TranslationKeys.DeleteCanvas)}
                      textStyle={TextStyle.BOLD14}
                      colorStyle={ColorStyle.ERROR}
                    />
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      <MenuIcon
        ref={menuAnchorRef}
        aria-controls={openMenu ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleClickMenuIcon}
      >
        <Pixelart
          alt="menu"
          src="https://storage.googleapis.com/dotpict-images/web/ic_menu.svg"
          width={24}
          height={24}
        />
      </MenuIcon>
    </>
  );
};

export default PopupMenu;
