import Column from './layout/Column';
import { HorizontalGravity } from './layout/HorizontalGravity';
import Pixelart from './Pixelart';

type Props = {
  verticalPadding?: number;
};

const End = ({ verticalPadding }: Props) => (
  <Column
    width="100%"
    horizontalGravity={HorizontalGravity.center}
    verticalPadding={verticalPadding}
  >
    <Pixelart
      alt="end"
      width={26}
      height={30}
      src="https://storage.googleapis.com/dotpict-images/web/end.svg"
    />
  </Column>
);

export default End;
