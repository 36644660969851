import { Row } from '@dotpict-lib/component/core/layout/Row';
import { VerticalGravity } from '@dotpict-lib/component/core/layout/VerticalGravity';
import Text from '@dotpict-lib/foundation/typography/Text';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import FontStyle from '@dotpict-lib/foundation/typography/FontStyle';
import TranslationKeys from '@/app/translation/TranslationKeys';
import useTranslator from '@/app/hook/useTranslator';

export type TeamPointProps = {
  teamPoint: number;
  teamColor: string;
};

const TeamPoint = ({ teamPoint, teamColor }: TeamPointProps) => {
  const translator = useTranslator();

  return (
    <Row innerPadding={12} verticalGravity={VerticalGravity.center}>
      <Text
        text={`${teamPoint}`}
        textStyle={TextStyle.BOLD36}
        fontStyle={FontStyle.BOLD_PIXELMPLUS12}
        colorStyle={teamColor}
      />
      <Text
        text={`${translator(TranslationKeys.Point)}`}
        textStyle={TextStyle.BOLD16}
        fontStyle={FontStyle.BOLD_PIXELMPLUS12}
        colorStyle={teamColor}
      />
    </Row>
  );
};

export default TeamPoint;
