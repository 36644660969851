import { useCallback } from 'react';
import styled from '@emotion/styled';
import presetColorList from '@/app/component/page/draw/SideMenu/ToolSelector/EditColor/PresetColorList';

const PresetColorList = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(16, 20px);
  grid-gap: 2px;
  padding: 6px;
  width: 373px;
  height: 268px;
  border: 2px solid #eee;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
`;

const PaletteColor = styled.div<{ color: string }>`
  background: ${({ color }) => `#${color}`};
  cursor: pointer;
`;

type Props = {
  colorIndex: number;
  editPalette(index: number, color: string): void;
};

const PresetTabBody = ({ colorIndex, editPalette }: Props) => {
  const handleEditPalette = useCallback(
    (color: string) => {
      return () => {
        editPalette(colorIndex, color);
      };
    },
    [colorIndex, editPalette],
  );

  return (
    <PresetColorList>
      {presetColorList.map((color) => (
        <PaletteColor color={color} onClick={handleEditPalette(color)} key={color} />
      ))}
    </PresetColorList>
  );
};

export default PresetTabBody;
