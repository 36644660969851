import { UserSummaryType } from '@/app/model/UserSummaryType';

export interface ISearchUsersState {
  query: string;
  userSummaries: UserSummaryType[];
  nextUrl: string | null;
  requestedUserId: number;
}

export const defaultSearchUsersState: ISearchUsersState = {
  query: '',
  userSummaries: [],
  nextUrl: null,
  requestedUserId: 0,
};
