import { PagingType } from '@dotpict-lib/model/PagingType';
import TestUserSraim from '@/app/test/user/TestUserSraim';
import { GetUsersService } from '@/app/service/GetUsers/GetUsersService';
import TestUserChocomelon from '@/app/test/user/TestUserChocomelon';
import TestWorkJuneBride from '@/app/test/work/TestWorkJuneBride';
import TestWorkPiplup from '@/app/test/work/TestWorkPiplup';
import TestWorkDogVsCat from '@/app/test/work/TestWorkDogVsCat';

export const dummyGetUsersService: GetUsersService = (_: PagingType) => {
  return Promise.resolve({
    userSummaries: [
      {
        user: TestUserSraim,
        works: [TestWorkJuneBride, TestWorkPiplup, TestWorkDogVsCat],
      },
      {
        user: TestUserChocomelon,
        works: [],
      },
    ],
    nextUrl: '',
  });
};
