import Text from '@dotpict-lib/foundation/typography/Text';
import useTranslator from '@/app/hook/useTranslator';
import TranslationKeys from '@/app/translation/TranslationKeys';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';
import GooglePlay from '@/app/component/partial/app/googleplay/GooglePlay';
import AppStore from '@/app/component/partial/app/appstore/AppStore';
import Pixelart from '@dotpict-lib/component/core/Pixelart';
import Column from '@dotpict-lib/component/core/layout/Column';
import { HorizontalGravity } from '@dotpict-lib/component/core/layout/HorizontalGravity';
import { Row } from '@dotpict-lib/component/core/layout/Row';

const AboutDotpict = () => {
  const translator = useTranslator();
  return (
    <Column
      padding="12px 16px"
      background={ColorStyle.WHITE}
      horizontalGravity={HorizontalGravity.center}
    >
      <Pixelart
        src="https://storage.googleapis.com/dotpict-images/web/logo.svg"
        alt="logo"
        width={106}
        height={19}
      />
      <Spacer height={12} />
      <Text
        text={translator(TranslationKeys.AboutDotpictText1)}
        textStyle={TextStyle.REGULAR11}
        colorStyle={ColorStyle.TEXT_PRIMARY}
      />
      <Spacer height={16} />
      <Text
        text={translator(TranslationKeys.AboutDotpictText2)}
        textStyle={TextStyle.BOLD12}
        colorStyle={ColorStyle.PRIMARY}
      />
      <Spacer height={4} />
      <Row>
        <GooglePlay />
        <Spacer width={4} />
        <AppStore />
      </Row>
      <Spacer height={4} />
      <Text
        text={translator(TranslationKeys.AboutDotpictText3)}
        textStyle={TextStyle.REGULAR11}
        colorStyle={ColorStyle.TEXT_PRIMARY}
      />
    </Column>
  );
};

export default AboutDotpict;
