import { CropArea } from '@/app/component/page/work/upload/types';
import Pixelart from '@dotpict-lib/component/core/Pixelart';
import { css } from '@emotion/react';

export type CroppedThumbnailProps = {
  imageUrl: string | undefined;
  croppedArea: CropArea | undefined;
};

const CroppedThumbnail = ({ imageUrl, croppedArea }: CroppedThumbnailProps) => {
  if (!croppedArea) {
    return <Pixelart src={imageUrl} alt="upload image" width={384} height={384} />;
  }

  const scale = 100 / croppedArea.width;
  const transform = {
    x: `${-croppedArea.x * scale}%`,
    y: `${-croppedArea.y * scale}%`,
    scale,
    width: 'calc(100% + 0.5px)',
    height: 'auto',
  };

  const imageStyle = css({
    transformOrigin: 'top left',
    transform: `translate3d(${transform.x}, ${transform.y}, 0) scale3d(${transform.scale},${transform.scale},1)`,
    width: transform.width,
    height: transform.height,
  });

  return (
    <Pixelart src={imageUrl} className={imageStyle} alt="upload image" width={384} height={384} />
  );
};

export default CroppedThumbnail;
