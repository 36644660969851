import { createContext, useContext } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@dotpict-lib/component/dotpict/Button';
import TextFieldComponent from '@/app/component/TextFieldComponent';
import WorkUploadBoxComponent from '@/app/component/WorkUploadBoxComponent';
import TranslationKeys from '@/app/translation/TranslationKeys';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';
import Text from '@dotpict-lib/foundation/typography/Text';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import TutorialItems from '@/app/component/partial/work/upload/tutorialitems/TutorialItems';
import Loading from '@dotpict-lib/component/core/Loading';
import TagSection from '@/app/component/partial/work/upload/tagsection/TagSection';
import GuideSection from '@/app/component/partial/work/upload/guidesection/GuideSection';
import useTranslator from '@/app/hook/useTranslator';
import EventSection from '@/app/component/partial/work/upload/eventsection/EventSection';
import { Rect } from '@/app/component/page/work/upload/types';
import Column from '@dotpict-lib/component/core/layout/Column';
import { HorizontalGravity } from '@dotpict-lib/component/core/layout/HorizontalGravity';
import { Row } from '@dotpict-lib/component/core/layout/Row';
import AlertDialog from '@dotpict-lib/component/dialog/alert/AlertDialog';

const MultiSectionWrapper = styled.div`
  padding: 32px;
  background: #ffffff;
`;

const styledLabel = css`
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 12px;
`;

const ModalInnerContainer = styled.div`
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  padding: 32px 0 16px 0;

  /* モバイルは幅いっぱいまで */
  @media screen and (max-width: 680px) {
    padding: 20px;
  }
`;

export type UploadWorkState = {
  imageFile: File | undefined;
  modalOpen: boolean;
  title: string;
  caption: string;
  fixedTag: string;
  tags: string[];
  allowComment: boolean;
  loading: boolean;
  isVisibleJoiningEvent: boolean;
  joiningEventTitle: string;
  isVisibleError: boolean;
  isVisiblePleaseLogin: boolean;
  errorMessage: string;
};

export type UploadWorkProps = UploadWorkState & {
  handleFileChanged: (file: File | undefined) => void;
  handleTitleChanged: (newTitle: string) => void;
  handleTagsChanged: (newTags: string[]) => void;
  handleCaptionChanged: (newCaption: string) => void;
  handleAllowCommentChanged: (newAllowComment: boolean) => void;
  handleClickPost: () => void;
  handleCloseTutorial: () => void;
  handleEndTutorial: () => void;
  handleCloseError: () => void;
  handleLogin: () => void;
  handleDecideCrop: (croppedRect: Rect | undefined) => void;
};

export const UploadWorkContext = createContext<UploadWorkProps>({} as UploadWorkProps);

const UploadWork = () => {
  const fullScreen = useMediaQuery('(max-width: 680px)');
  const translator = useTranslator();

  const {
    imageFile,
    modalOpen,
    title,
    caption,
    fixedTag,
    tags,
    allowComment,
    loading,
    isVisibleJoiningEvent,
    joiningEventTitle,
    isVisibleError,
    isVisiblePleaseLogin,
    errorMessage,
    handleFileChanged,
    handleTitleChanged,
    handleTagsChanged,
    handleCaptionChanged,
    handleAllowCommentChanged,
    handleClickPost,
    handleCloseTutorial,
    handleEndTutorial,
    handleCloseError,
    handleLogin,
    handleDecideCrop,
  } = useContext(UploadWorkContext);

  if (loading) {
    return <Loading />;
  }

  return (
    <Column>
      <Spacer height={32} />
      <MultiSectionWrapper>
        <Text
          text={translator(TranslationKeys.PostSectionTitle)}
          textStyle={TextStyle.BOLD20}
          colorStyle={ColorStyle.TEXT_PRIMARY}
        />
        <Spacer height={24} />
        <WorkUploadBoxComponent
          imageFile={imageFile}
          handleFileChange={handleFileChanged}
          handleDecideCrop={handleDecideCrop}
        />
        <Spacer height={24} />
        <GuideSection />
      </MultiSectionWrapper>
      <Spacer height={24} />
      <MultiSectionWrapper>
        <TextFieldComponent
          id="title"
          label={translator(TranslationKeys.Title)}
          placeholder={translator(TranslationKeys.Title)}
          value={title}
          onChangeValue={handleTitleChanged}
          showMaxLength
          maxLength={32}
        />
        <Spacer height={40} />
        <TagSection fixedTag={fixedTag} tags={tags} handleTagsChanged={handleTagsChanged} />
        {isVisibleJoiningEvent && (
          <>
            <Spacer height={40} />
            <EventSection eventTitle={joiningEventTitle} />
          </>
        )}
        <Spacer height={40} />
        <TextFieldComponent
          id="caption"
          label={translator(TranslationKeys.Caption)}
          placeholder={translator(TranslationKeys.CaptionHint)}
          value={caption}
          onChangeValue={handleCaptionChanged}
          multiline
          showMaxLength
          maxLength={500}
          rows={4}
        />
        <Spacer height={40} />
        <div css={styledLabel}>{translator(TranslationKeys.OtherSettings)}</div>
        <Row>
          <FormControlLabel
            value="allowComment"
            control={<Switch />}
            label={translator(TranslationKeys.AllowComments)}
            checked={allowComment}
            onChange={(_, checked) => handleAllowCommentChanged(checked)}
            labelPlacement="start"
          />
        </Row>
        <Spacer height={32} />
        <Column horizontalGravity={HorizontalGravity.center}>
          <Button
            width={240}
            height={52}
            text={translator(TranslationKeys.Post)}
            isFilled
            handleClick={() => handleClickPost()}
          />
        </Column>
      </MultiSectionWrapper>
      <AlertDialog
        title={translator(TranslationKeys.FailedToUpload)}
        message={errorMessage}
        open={isVisibleError}
        handleClose={handleCloseError}
      />
      <AlertDialog
        title={translator(TranslationKeys.PleaseLogin)}
        message={translator(TranslationKeys.MustLoginToPost)}
        open={isVisiblePleaseLogin}
        handleClose={handleLogin}
      />
      <Dialog
        maxWidth="sm"
        fullWidth
        fullScreen={fullScreen}
        open={modalOpen}
        onClose={handleCloseTutorial}
      >
        <ModalInnerContainer>
          <TutorialItems handleEnd={handleEndTutorial} />
        </ModalInnerContainer>
      </Dialog>
      <Spacer height={120} />
    </Column>
  );
};

export default UploadWork;
