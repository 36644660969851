import React, { useContext, useEffect, useState } from 'react';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import Text from '@dotpict-lib/foundation/typography/Text';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import { useIntl } from 'react-intl';
import { LayoutContext } from '@/Routing';
import { IOldArticle } from '@/app/article/IOldArticle';
import ArticleContentType from '@/app/article/ArticleContentType';
import {
  articleContentBottomSpace,
  articleContentTopSpace,
  articleTitleBottomSpace,
} from '@/app/common/constant';
import { useLocation } from 'react-router-dom';
import ImageComponent from '@/app/component/ImageComponent';
import { IArticleImageContent } from '@/app/article/IArticleImageContent';
import { IOldArticleContent } from '@/app/article/IOldArticleContent';
import Column from '@dotpict-lib/component/core/layout/Column';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';
import { HorizontalGravity } from '@dotpict-lib/component/core/layout/HorizontalGravity';
import { VerticalGravity } from '@dotpict-lib/component/core/layout/VerticalGravity';
import { Row } from '@dotpict-lib/component/core/layout/Row';

interface IProps {
  article: IOldArticle;
}

const ArticleComponent = (props: IProps) => {
  const { article } = props;
  const intl = useIntl();
  const { layoutParams } = useContext(LayoutContext);
  const [renderTitle, setRenderTitle] = useState(true);
  const [isNightMode, setNightMode] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setRenderTitle(params.get('noTitle') !== 'true');
    setNightMode(params.get('isNightMode') === 'true');
  }, [location]);

  const renderContent = (content: IOldArticleContent) => {
    switch (content.type) {
      case ArticleContentType.HEADLINE:
        return (
          <Text
            text={intl.formatMessage({ id: content.content })}
            textStyle={TextStyle.BOLD16}
            colorStyle={isNightMode ? ColorStyle.WHITE : ColorStyle.TEXT_PRIMARY}
          />
        );
      case ArticleContentType.TEXT:
        return (
          <Text
            text={intl.formatMessage({ id: content.content })}
            textStyle={TextStyle.REGULAR14}
            colorStyle={isNightMode ? ColorStyle.WHITE : ColorStyle.TEXT_PRIMARY}
          />
        );
      case ArticleContentType.BOLD_TEXT:
        return (
          <Text
            text={intl.formatMessage({ id: content.content })}
            textStyle={TextStyle.BOLD14}
            colorStyle={isNightMode ? ColorStyle.WHITE : ColorStyle.TEXT_PRIMARY}
          />
        );
      case ArticleContentType.SPACE:
        return <Spacer height={Number(content.content)} />;
      case ArticleContentType.IMAGE:
        return (
          <Row
            verticalGravity={VerticalGravity.center}
            horizontalGravity={HorizontalGravity.center}
          >
            <ImageComponent
              alt="article_image"
              width={(content as IArticleImageContent).width}
              height={(content as IArticleImageContent).height}
              src={content.content}
            />
          </Row>
        );
      default:
        return '';
    }
  };

  return (
    <Column>
      {renderTitle && <Spacer height={layoutParams.contentTopSpace} />}
      <Column
        background={isNightMode ? ColorStyle.NIGHT_BACKGROUND : ColorStyle.WHITE}
        padding="0 32"
        width={layoutParams.contentWidth}
      >
        <Spacer height={articleContentTopSpace} />
        {renderTitle && (
          <>
            <Text
              text={intl.formatMessage({ id: article.titleTranslationKey })}
              textStyle={TextStyle.BOLD20}
              colorStyle={ColorStyle.TEXT_PRIMARY}
            />
            <Spacer height={articleTitleBottomSpace} />
          </>
        )}
        {article.contents.map((content: IOldArticleContent, index: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={index}>{renderContent(content)}</React.Fragment>
        ))}
        <Spacer height={articleContentBottomSpace} />
      </Column>
      {renderTitle && <Spacer height={layoutParams.contentBottomSpace} />}
    </Column>
  );
};

export default ArticleComponent;
