import { UserType } from '@dotpict-lib/model/User/UserType';

const TestUserChocomelon: UserType = {
  id: 5,
  name: 'chocomelon',
  account: 'chocomelon',
  profileImageUrl:
    'https://img.dotpicko.net/935e5a56b803b45e3d0f9f6b506f8b74ac3f5b460ffa4079b8738cbcc94c9e1a.png',
  headerImageUrl:
    'https://img.dotpicko.net/header_0f95da6431ae3ac03dc61f8db99b1430b5751c021d23fde9a8537a20b936d37a.png',
  url: '',
  shareUrl: 'https://dotpict.net/users/@chocomelon',
  text: 'Long Long Long Long Long Long Long Long Long Long Long Long Long Long Long Long Long Long Long Long Long Text',
  followedCount: 45,
  followerCount: 3474,
  isFollowed: false,
  isFollower: false,
  isOpenedRequestBox: false,
  requestBoxText:
    '描いて欲しい作品の説明をなるべく詳しくお願いします。マイペースで作るのでリクエストに応えられない場合があります',
  registerAt: '2018/03/05',
  birthDate: '1989/02/14',
  isPremium: false,
};

export default TestUserChocomelon;
