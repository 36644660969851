import { ColorCodeType } from '@dotpict-lib/model/ColorCode/ColorCodeType';

export type OdaiType = {
  id: number;
  title: string;
  tag: string;
  colorCodes: ColorCodeType[];
  heldDate: string;
};

export const defaultOdai = {
  id: 0,
  title: '',
  tag: '',
  colorCodes: [],
  heldDate: '',
};
