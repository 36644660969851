import { Helmet } from 'react-helmet';

export type OGPProps = {
  title: string;
  description: string;
  imageUrl: string;
};

const OGP = ({ title, description, imageUrl }: OGPProps) => (
  <Helmet>
    <meta property="twitter:title" content={title} />
    <meta property="twitter:description" content={description} />
    <meta property="twitter:image" content={imageUrl} />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={imageUrl} />
  </Helmet>
);

export default OGP;
