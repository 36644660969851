import Text from '@dotpict-lib/foundation/typography/Text';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import { PaletteType } from '@/app/model/PaletteType';
import Pixelart from '@dotpict-lib/component/core/Pixelart';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';
import { Row } from '@dotpict-lib/component/core/layout/Row';
import Column from '@dotpict-lib/component/core/layout/Column';
import { VerticalGravity } from '@dotpict-lib/component/core/layout/VerticalGravity';
import Colors from '@dotpict-lib/component/core/Colors';

export type PaletteProps = {
  palette: PaletteType;
};

const Palette = ({ palette }: PaletteProps) => (
  <Row width="100%" background={ColorStyle.WHITE}>
    <Pixelart lazyLoad={false} alt={palette.title} width={96} height={96} src={palette.imageUrl} />
    <Column padding={8} verticalGravity={VerticalGravity.spaceBetween}>
      <Column>
        <Text
          text={palette.title}
          textStyle={TextStyle.REGULAR11}
          colorStyle={ColorStyle.TEXT_PRIMARY}
        />
        <Spacer height={6} />
        <Text
          text={palette.text}
          textStyle={TextStyle.REGULAR11}
          colorStyle={ColorStyle.TEXT_PRIMARY}
        />
      </Column>
      <Colors colors={palette.colorCodes} colorWidth={10} colorHeight={10} />
    </Column>
  </Row>
);

export default Palette;
