import {
  defaultOfficialEvent,
  OfficialEventType,
} from '@dotpict-sns-lib/model/OfficialEvent/OfficialEventType';
import { WorkType } from '@/app/model/WorkType';

export type OfficialEventResultStateType = {
  fromApp: boolean;
  officialEvent: OfficialEventType;
  teamOneName: string;
  teamOneColor: string;
  teamOnePoint: number;
  teamOneWorks: WorkType[];
  teamTwoName: string;
  teamTwoColor: string;
  teamTwoPoint: number;
  teamTwoWorks: WorkType[];
  description: string;
  closingTexts: string[];
  shareTitle: string;
  twitterShareUrl: string;
  facebookShareUrl: string;
  ogpImageUrl: string;
  bannerImageUrl: string;
  works: WorkType[];
  nextUrl: string;
  isLoadingWorks: boolean;
  isVisibleEnd: boolean;
  requestedUserId: number;
};

export const defaultOfficialEventResultState: OfficialEventResultStateType = {
  fromApp: false,
  officialEvent: defaultOfficialEvent,
  teamOneName: '',
  teamOneColor: '',
  teamOnePoint: 0,
  teamOneWorks: [],
  teamTwoName: '',
  teamTwoColor: '',
  teamTwoPoint: 0,
  teamTwoWorks: [],
  description: '',
  closingTexts: [],
  shareTitle: '',
  twitterShareUrl: '',
  facebookShareUrl: '',
  ogpImageUrl: '',
  bannerImageUrl: '',
  works: [],
  nextUrl: '',
  isLoadingWorks: true,
  isVisibleEnd: false,
  requestedUserId: 0,
};
