export const defaultDummyItemCount = 100;
export const defaultThumbnailImageGridGap = 8;
export const defaultSmallThumbnailImageGridGap = 4;

export const makingHeadlineTopSpace = '56';
export const makingHeadlineBottomSpace = '16';
export const makingLineSpace = '12';
export const makingImageSpace = '32';
export const makingContentBottomSpace = 56;

export const articleContentTopSpace = 32;
export const articleTitleBottomSpace = 16;
export const articleLineSpace = '12';
export const articleContentBottomSpace = 32;

export const shareBaseUrl = 'https://dotpict.net';
export const serviceNameTwitter = 'Twitter';

export const defaultPaletteColors = [
  '14131a',
  '474d5d',
  '8f9aa1',
  'a72751',
  'ff3d3d',
  'ffb72a',
  'f6ed03',
  '4bde3c',
  '33766c',
  '25bfe2',
  '317de9',
  '4a3c95',
  'a55b99',
  'fe827d',
  'fed0b1',
  'ffffff',
];
