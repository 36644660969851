import Spacer from '@dotpict-lib/component/core/layout/Spacer';
import Pixelart from '@dotpict-lib/component/core/Pixelart';
import { NotificationType } from '@/app/model/NotificationType';
import Text from '@dotpict-lib/foundation/typography/Text';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import Column from '@dotpict-lib/component/core/layout/Column';
import { Row } from '@dotpict-lib/component/core/layout/Row';

export type NotificationProps = {
  notification: NotificationType;
  background?: ColorStyle;
};

const Notification = ({ notification, background }: NotificationProps) => (
  <Column width="100%" background={background}>
    <Spacer height={12} />
    <Row>
      <Spacer width={20} />
      <div style={{ width: 48, height: 48 }}>
        <Pixelart alt="profile image" src={notification.imageUrl} width={48} height={48} />
      </div>
      <Spacer width={12} />
      <Column>
        <Text
          text={notification.title}
          textStyle={TextStyle.REGULAR12}
          colorStyle={ColorStyle.TEXT_PRIMARY}
        />
        <Spacer height={12} />
        <Text
          text={notification.createdAt}
          textStyle={TextStyle.REGULAR12}
          colorStyle={ColorStyle.TEXT_SECONDARY}
        />
      </Column>
      <Spacer width={20} />
    </Row>
    <Spacer height={12} />
  </Column>
);

export default Notification;
