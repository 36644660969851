import Pixelart from '@dotpict-lib/component/core/Pixelart';
import Text from '@dotpict-lib/foundation/typography/Text';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import WorkLike from '@/app/component/partial/work/item/WorkLike';
import { Row } from '@dotpict-lib/component/core/layout/Row';
import { VerticalGravity } from '@dotpict-lib/component/core/layout/VerticalGravity';
import { Repict } from '@/app/component/Repict';
import DotpictLink from '@/app/component/DotpictLinkComponent';
import styled from '@emotion/styled';

export type WorkFooterProps = {
  workId: number;
  isLiked: boolean;
  isRepicted: boolean;
  likeCount: number;
  repictCount: number;
  threadCount: number;
  allowThread: boolean;
};

const actionButtonWidth = 32;
const actionCountLeftPadding = 0;
const actionCountWidth = 8 + 32;
const actionWidth = actionButtonWidth + actionCountLeftPadding + actionCountWidth;
const actionMargin = 2;

const Container = styled.div`
  position: relative;
`;

const ThreadContainer = styled.div`
  position: absolute;
  left: 0;
`;

const DisabledThreadContainer = styled.div`
  opacity: 0.5;
`;

const RepictButtonContainer = styled.div`
  position: absolute;
  left: ${actionWidth + actionMargin};
`;

const RepictCountContainer = styled.div`
  position: absolute;
  left: ${actionWidth + actionMargin + actionButtonWidth + actionCountLeftPadding};
`;

const LikeButtonContainer = styled.div`
  position: absolute;
  left: ${(actionWidth + actionMargin) * 2};
`;

const LikeCountContainer = styled.div`
  position: absolute;
  left: ${(actionWidth + actionMargin) * 2 + actionButtonWidth + actionCountLeftPadding};
`;

export const WorkFooter = ({
  workId,
  isLiked,
  isRepicted,
  likeCount,
  repictCount,
  threadCount,
  allowThread,
}: WorkFooterProps) => {
  const renderThread = () => (
    <Row verticalGravity={VerticalGravity.center} innerPadding={8}>
      <Pixelart
        alt="comment"
        src="https://storage.googleapis.com/dotpict-images/web/ic_comment.svg"
        width={32}
        height={32}
      />
      <Row width={32} height={30} verticalGravity={VerticalGravity.center}>
        <Text
          text={`${threadCount > 0 && allowThread ? threadCount : ''}`}
          textStyle={TextStyle.REGULAR10}
          colorStyle={ColorStyle.TEXT_SECONDARY}
        />
      </Row>
    </Row>
  );

  return (
    <Row height={32}>
      <Container>
        <RepictButtonContainer>
          <Repict workId={workId} isRepicted={isRepicted} size={32} />
        </RepictButtonContainer>
        <LikeButtonContainer>
          <WorkLike workId={workId} isLiked={isLiked} size={32} />
        </LikeButtonContainer>
        <ThreadContainer>
          {allowThread ? (
            <DotpictLink to={`/works/${workId}/threads`}>{renderThread()}</DotpictLink>
          ) : (
            <DisabledThreadContainer>{renderThread()}</DisabledThreadContainer>
          )}
        </ThreadContainer>
        <RepictCountContainer>
          <DotpictLink to={`/works/${workId}/repictUsers`}>
            <Row padding="0 0 0 8" width={32} height={30} verticalGravity={VerticalGravity.center}>
              <Text
                text={`${repictCount > 0 ? repictCount : ''}`}
                textStyle={TextStyle.REGULAR10}
                colorStyle={ColorStyle.TEXT_SECONDARY}
              />
            </Row>
          </DotpictLink>
        </RepictCountContainer>
        <LikeCountContainer>
          <DotpictLink to={`/works/${workId}/likeUsers`}>
            <Row padding="0 0 0 8" width={32} height={30} verticalGravity={VerticalGravity.center}>
              <Text
                text={`${likeCount > 0 ? likeCount : ''}`}
                textStyle={TextStyle.REGULAR10}
                colorStyle={ColorStyle.TEXT_SECONDARY}
              />
            </Row>
          </DotpictLink>
        </LikeCountContainer>
      </Container>
    </Row>
  );
};
