import { createContext, useContext, useEffect, useLayoutEffect, useState } from 'react';
import { GlobalContext, LayoutContext } from '@/Routing';
import { UserDetailBindModel, UserDetailOgp } from '@/app/component/page/user/detail/UserDetail';
import { defaultUserDetailState, UserDetailStateType } from '@/app/state/UserDetailStateType';
import { DotpictAction } from '@/app/common/reducer';
import shouldFetchNext from '@/app/common/common';
import { UserType } from '@dotpict-lib/model/User/UserType';
import { WorkType } from '@/app/model/WorkType';
import { GetUserDetailService } from '@/app/service/GetUserDetail/GetUserDetailService';
import dummyGetUserDetailService from '@/app/service/GetUserDetail/dummyGetUserDetailService';
import { GetUserWorksService } from '@/app/service/GetUserWorks/GetUserWorksService';
import dummyGetUserWorksService from '@/app/service/GetUserWorks/dummyGetUserWorksService';
import usePagingBuilder from '@/app/apiclient/PagingBuilder';

type OutProps = {
  bindModel: UserDetailBindModel;
  ogp: UserDetailOgp;
  user: UserType; // ProfileHeaderのuser依存で仕方なく渡している
  userWorks: WorkType[];
};

export type Dependencies = {
  getUserDetailService: GetUserDetailService;
  getUserWorksService: GetUserWorksService;
};

export const UserDetailContext = createContext<Dependencies>({
  getUserDetailService: dummyGetUserDetailService,
  getUserWorksService: dummyGetUserWorksService,
});

type Props = {
  userId: number;
  account: string;
};

const useUserDetail = ({ userId, account }: Props): OutProps => {
  const { globalState, dispatch } = useContext(GlobalContext);
  const { layoutParams } = useContext(LayoutContext);
  const [isLoading, setLoading] = useState(false);
  const pagingBuilder = usePagingBuilder();
  const { getUserDetailService, getUserWorksService } = useContext(UserDetailContext);

  const fetchData = async (currentState: UserDetailStateType) => {
    setLoading(true);
    const userWorksResponse = await getUserWorksService({ nextUrl: currentState.nextUrl });
    const action: DotpictAction = {
      type: 'UPDATE_USER_DETAIL_STATE',
      payload: {
        userDetailState: {
          user: userWorksResponse.user,
          works: currentState.works.concat(userWorksResponse.works),
          nextUrl: userWorksResponse.nextUrl,
        },
      },
    };
    dispatch(action);
    setLoading(false);
  };

  const fetchDataByAccount = async (account: string) => {
    setLoading(true);
    const user = await getUserDetailService(account);
    const userWorksResponse = await getUserWorksService({
      nextUrl: pagingBuilder(`/users/${user.id}/works`),
    });
    const action: DotpictAction = {
      type: 'UPDATE_USER_DETAIL_STATE',
      payload: {
        userDetailState: {
          user: userWorksResponse.user,
          works: userWorksResponse.works,
          nextUrl: userWorksResponse.nextUrl,
        },
      },
    };
    dispatch(action);
    setLoading(false);
  };

  const handleScroll = () => {
    if (!isLoading && shouldFetchNext(globalState.userDetailState.nextUrl)) {
      fetchData(globalState.userDetailState);
    }
  };

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  useEffect(() => {
    if (userId <= 0 || userId === globalState.userDetailState.user.id) {
      return;
    }
    window.scrollTo(0, 0);
    const userDetailState: UserDetailStateType = defaultUserDetailState;
    userDetailState.nextUrl = pagingBuilder(`/users/${userId}/works`);
    const action: DotpictAction = {
      type: 'UPDATE_USER_DETAIL_STATE',
      payload: { userDetailState },
    };
    dispatch(action);
    fetchData(userDetailState);
  }, [userId]);

  useEffect(() => {
    if (account === '' || account === globalState.userDetailState.user.account) {
      return;
    }
    window.scrollTo(0, 0);
    fetchDataByAccount(account);
  }, [account]);

  return {
    bindModel: {
      userId: globalState.userDetailState.user.id,
      isFollowed: globalState.userDetailState.user.isFollowed,
      isLoading,
      isVisibleEnd: globalState.userDetailState.nextUrl === '',
      thumbnailImageGridGap: layoutParams.thumbnailImageGridGap,
      contentWidth: layoutParams.contentWidth,
      thumbnailImageSize: layoutParams.thumbnailImageSize,
    },
    ogp: {
      title: globalState.userDetailState.user.name,
      description: globalState.userDetailState.user.text,
      image: globalState.userDetailState.user.profileImageUrl,
    },
    user: globalState.userDetailState.user,
    userWorks: globalState.userDetailState.works,
  };
};

export default useUserDetail;
