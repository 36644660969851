import { WorkType } from '../model/WorkType';

export interface ITemplateResultState {
  requestedUserId: number;
  imageUrl: string;
  ogpImageUrl: string;
  eventName: string;
  title: string;
  description: string;
  works: WorkType[];
  nextUrl: string;
}

export const defaultTemplateResultState: ITemplateResultState = {
  requestedUserId: 0,
  imageUrl: '',
  ogpImageUrl: '',
  eventName: '',
  title: '',
  description: '',
  works: [],
  nextUrl: '',
};
