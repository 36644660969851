import { Button } from '@material-ui/core';
import Text from '@dotpict-lib/foundation/typography/Text';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import { Row } from '@dotpict-lib/component/core/layout/Row';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';

export type TabLayoutProps = {
  items: string[];
  selectedIndex: number;
  onClick: (index: number) => void;
};

export const TabLayout = ({ items, selectedIndex, onClick }: TabLayoutProps) => {
  return (
    <Row>
      {items.map((item: string, index: number) => (
        <Row key={item}>
          <Button onClick={() => onClick(index)}>
            <Text
              text={item}
              textStyle={TextStyle.BOLD16}
              colorStyle={index === selectedIndex ? ColorStyle.PRIMARY : ColorStyle.TEXT_PRIMARY}
            />
          </Button>
          {index < items.length - 1 && <Spacer width={24} />}
        </Row>
      ))}
    </Row>
  );
};
