import emptyUser, { UserType } from '@dotpict-lib/model/User/UserType';

export interface IMeState {
  isLoggedIn: boolean;
  token: string;
  user: UserType;
}

export const defaultMeState: IMeState = {
  isLoggedIn: false,
  token: '',
  user: emptyUser,
};
