import { Button } from '@material-ui/core';
import styled from '@emotion/styled';
import Text from '@dotpict-lib/foundation/typography/Text';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';

interface TabLayoutProps {
  items: string[];
  selectedIndex: number;
  onClick: (index: number) => void;
}

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
`;

const NoPaddingButton = styled(Button)<{ selected: boolean }>`
  display: inline-block;
  padding: 0;
  minheight: 0;
  minwidth: 0;
  border-radius: 0;
  border-bottom: 4px solid transparent;

  ${({ selected }) =>
    selected &&
    `
      border-color: ${ColorStyle.PRIMARY};
    `};
`;

const TabLayout = ({ items, selectedIndex, onClick }: TabLayoutProps) => {
  return (
    <Container>
      {items.map((item: string, index: number) => (
        <NoPaddingButton
          onClick={() => onClick(index)}
          key={item}
          selected={index === selectedIndex}
        >
          <Text text={item} textStyle={TextStyle.BOLD16} colorStyle={ColorStyle.PRIMARY} />
        </NoPaddingButton>
      ))}
    </Container>
  );
};

export default TabLayout;
