import { RefObject } from 'react';
import styled from '@emotion/styled';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import Text from '@dotpict-lib/foundation/typography/Text';
import { Dialog } from '@material-ui/core';
import { PixelsType } from '@/app/component/page/draw/hooks/types';
import ModalBody from '@/app/component/page/draw/DownloadModal/ModalBody';
import useTranslator from '@/app/hook/useTranslator';
import TranslationKeys from '@/app/translation/TranslationKeys';
import { SizeType } from '@/app/component/page/draw/Context/SizeContext';

const ModalContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 680px;
  padding: 12px 24px 24px;
  background: ${ColorStyle.WHITE};
`;

const Header = styled.div`
  margin-bottom: 13px;
`;

type Props = {
  open: boolean;
  canvasRef: RefObject<HTMLCanvasElement>;
  canvasSize: SizeType;
  title: string | undefined;
  pixels: PixelsType | undefined;
  onCloseModal(): void;
};

const DownloadModal = ({ open, canvasRef, canvasSize, title, pixels, onCloseModal }: Props) => {
  const translator = useTranslator();

  return (
    <Dialog maxWidth="xl" open={open} onClose={onCloseModal} scroll="body">
      <ModalContainer>
        {open && (
          <>
            <Header>
              <Text
                text={translator(TranslationKeys.SaveCanvas)}
                textStyle={TextStyle.BOLD20}
                colorStyle={ColorStyle.TEXT_PRIMARY}
              />
            </Header>

            <ModalBody
              canvasRef={canvasRef}
              canvasSize={canvasSize}
              title={title}
              pixels={pixels}
              onCloseModal={onCloseModal}
            />
          </>
        )}
      </ModalContainer>
    </Dialog>
  );
};

export default DownloadModal;
