import { useContext } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import Pixelart from '@dotpict-lib/component/core/Pixelart';
import { Link, useNavigate } from 'react-router-dom';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import Text from '@dotpict-lib/foundation/typography/Text';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import { MakingContentType, MakingImageContentType } from '@/app/making/MakingContentType';
import TranslationKeys from '@/app/translation/TranslationKeys';
import { LayoutContext } from '@/Routing';
import ShareComponent from '@/app/component/ShareComponent';
import { makingContentBottomSpace, shareBaseUrl } from '@/app/common/constant';
import MakingLikeComponent from '@/app/component/MakingLikeComponent';
import DefaultGridStyle from '@/app/style/DefaultGridStyle';
import MakingThumbnailComponent from '@/app/component/MakingThumbnailComponent';
import { Helmet } from 'react-helmet';
import { MakingLinkType } from '@/app/making/MakingLinkType';
import ExternalLinkComponent from '@/app/component/ExternalLinkComponent';
import Ads from '@/app/component/partial/ads/Ads';
import DotpictAdsType from '@/app/ads/DotpictAdsType';
import useTranslator from '@/app/hook/useTranslator';
import MakingImage from '@/app/component/page/making/detail/image/MakingImage';
import MakingHeadline from '@/app/component/page/making/detail/headline/MakingHeadline';
import MakingText from '@/app/component/page/making/detail/text/MakingText';
import MakingPixelart from '@/app/component/page/making/detail/pixelart/MakingPixelart';
import { MakingType } from '@/app/making/MakingType';
import MakingContentEnum from '@/app/making/MakingContentEnum';
import useMakingDetail from '@/app/component/page/making/detail/useMakingDetail';
import Column from '@dotpict-lib/component/core/layout/Column';
import { Row } from '@dotpict-lib/component/core/layout/Row';
import { VerticalGravity } from '@dotpict-lib/component/core/layout/VerticalGravity';
import { HorizontalGravity } from '@dotpict-lib/component/core/layout/HorizontalGravity';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';
import Button from '@dotpict-lib/component/dotpict/Button';
import Badge from '@/app/component/partial/common/badge/Badge';

const profileImageSize = 128;
const anotherMakingGap = 16;

export type MakingDetailState = {
  making: MakingType;
  isLiked: boolean;
  fromApp: boolean;
  mainImageSize: number;
  rootContainerStyle: SerializedStyles;
  anotherMakings: MakingType[];
};

export type MakingDetailAction = {
  handleClickLike: () => void;
};

const MakingDetail = () => {
  const translator = useTranslator();
  const { layoutParams } = useContext(LayoutContext);
  const {
    making,
    isLiked,
    fromApp,
    mainImageSize,
    rootContainerStyle,
    anotherMakings,
    handleClickLike,
  } = useMakingDetail();

  const anotherMakingColumnCount = layoutParams.isMobile ? 1 : 2;
  const anotherMakingItemWidth = layoutParams.isMobile
    ? layoutParams.contentWidth
    : (layoutParams.contentWidth - anotherMakingGap) / 2;
  const anotherMakingItemHeight = anotherMakingItemWidth / 2;

  const anotherMakingGridStyle = css(DefaultGridStyle, {
    gridGap: anotherMakingGap,
    gridTemplateColumns: `repeat(${anotherMakingColumnCount}, ${anotherMakingItemWidth}px)`,
  });
  const navigator = useNavigate();

  const renderOgp = () => {
    const title = translator(TranslationKeys.MakingOgpTitle, {
      authorName: translator(making.authorName),
    });
    const description = translator(making.theme);
    const ogpImageUrl = making.ogpImage;
    return (
      <Helmet>
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={description} />
        <meta property="twitter:image" content={ogpImageUrl} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={ogpImageUrl} />
      </Helmet>
    );
  };

  const instansOfIMakingImageContent = (arg: any) =>
    arg !== null &&
    typeof arg === 'object' &&
    typeof arg.width === 'number' &&
    typeof arg.height === 'number';

  const renderContent = (content: MakingContentType) => {
    switch (content.type) {
      case MakingContentEnum.HEADLINE:
        return <MakingHeadline text={content.content} />;
      case MakingContentEnum.TEXT:
        return <MakingText text={content.content} />;
      case MakingContentEnum.PIXELART:
        return instansOfIMakingImageContent(content) ? (
          <MakingPixelart
            imageUrl={content.content}
            width={(content as MakingImageContentType).width}
            height={(content as MakingImageContentType).height}
          />
        ) : (
          <MakingPixelart imageUrl={content.content} width={mainImageSize} height={mainImageSize} />
        );
      case MakingContentEnum.IMAGE:
        return (
          <MakingImage
            imageUrl={content.content}
            width={(content as MakingImageContentType).width}
            height={(content as MakingImageContentType).height}
          />
        );
      case MakingContentEnum.SPACE:
        return <Spacer height={Number(content.content)} />;
      default:
        return '';
    }
  };

  const renderShare = () => (
    <ShareComponent
      url={`${shareBaseUrl}/making/${making.key}`}
      title={translator(TranslationKeys.MakingShareText, {
        authorName: translator(making.authorName),
        theme: translator(making.theme),
      })}
    />
  );

  return (
    <>
      {renderOgp()}
      <div css={rootContainerStyle}>
        <Column
          verticalGravity={VerticalGravity.center}
          horizontalGravity={HorizontalGravity.center}
        >
          <Spacer height={layoutParams.contentTopSpace} />
          <Pixelart
            alt="main_image"
            src={making.mainImage}
            width={mainImageSize}
            height={mainImageSize}
          />
          <Spacer height={40} />
          <Column
            background={ColorStyle.WHITE}
            padding="0 32"
            width={layoutParams.contentWidth - 32 * 2}
          >
            <Spacer height={32} />
            <Badge
              text={translator(TranslationKeys.Making)}
              color={ColorStyle.BLUE}
              width={200}
              height={36}
              textStyle={TextStyle.BOLD16}
            />
            <Spacer height={32} />
            <Row>
              <Text
                text={translator(TranslationKeys.MakingTitle, {
                  authorName: translator(making.authorName),
                })}
                textStyle={TextStyle.BOLD20}
                colorStyle={ColorStyle.TEXT_PRIMARY}
              />
            </Row>
            <Spacer height={16} />
            {renderShare()}
            <Spacer height={32} />
            <Text
              text={translator(TranslationKeys.MakingDescription, {
                authorName: translator(making.authorName),
              })}
              textStyle={TextStyle.REGULAR16}
              colorStyle={ColorStyle.TEXT_PRIMARY}
            />
            <Spacer height={24} />
            <Column background={ColorStyle.WHITE_GRAY} width="100%">
              <Spacer height={24} />
              <Row>
                <Spacer width={24} />
                <Text
                  text={translator(TranslationKeys.TableOfContents)}
                  textStyle={TextStyle.BOLD16}
                  colorStyle={ColorStyle.TEXT_PRIMARY}
                />
              </Row>
              <Spacer height={12} />
              <ul>
                {making.tableOfContents.map((textId) => (
                  <li>
                    <Text
                      text={translator(textId)}
                      textStyle={TextStyle.REGULAR16}
                      colorStyle={ColorStyle.TEXT_PRIMARY}
                    />
                  </li>
                ))}
              </ul>
              <Spacer height={12} />
            </Column>
          </Column>
          <Column
            background={ColorStyle.WHITE}
            padding="0 32"
            width={layoutParams.contentWidth - 32 * 2}
          >
            {making.contents.map((content) => (
              <>{renderContent(content)}</>
            ))}
            <Column
              verticalGravity={VerticalGravity.center}
              horizontalGravity={HorizontalGravity.center}
            >
              <Text
                text={translator(TranslationKeys.MakingShare)}
                textStyle={TextStyle.BOLD16}
                colorStyle={ColorStyle.TEXT_PRIMARY}
              />
              <Spacer height={16} />
              {renderShare()}
              <Spacer height={makingContentBottomSpace} />
            </Column>
          </Column>
          <Column
            background={ColorStyle.WHITE}
            padding="0 32"
            width={layoutParams.contentWidth - 32 * 2}
          >
            <Column background={ColorStyle.WHITE_GRAY} width="100%">
              <Spacer height={24} />
              <Column
                verticalGravity={VerticalGravity.center}
                horizontalGravity={HorizontalGravity.center}
              >
                <Pixelart
                  alt="profile_image"
                  src={making.profileImage}
                  width={profileImageSize}
                  height={profileImageSize}
                />
                <Spacer height={24} />
                <Text
                  text={translator(making.authorName)}
                  textStyle={TextStyle.BOLD20}
                  colorStyle={ColorStyle.TEXT_PRIMARY}
                />
              </Column>
              <Spacer height={16} />
              <Row>
                <Spacer width={32} />
                <Column>
                  {making.profileTexts.map((profileText: string) => (
                    <Text
                      text={translator(profileText)}
                      textStyle={TextStyle.REGULAR16}
                      colorStyle={ColorStyle.TEXT_PRIMARY}
                    />
                  ))}
                  {making.links.map((link: MakingLinkType) => (
                    <Row>
                      <Text
                        text={`${link.serviceName}:`}
                        textStyle={TextStyle.REGULAR16}
                        colorStyle={ColorStyle.TEXT_PRIMARY}
                      />
                      <Spacer width={8} />
                      <ExternalLinkComponent
                        text={link.url}
                        url={link.url}
                        textStyle={TextStyle.REGULAR16}
                        colorStyle={ColorStyle.BLUE}
                      />
                    </Row>
                  ))}
                </Column>
                <Spacer width={32} />
              </Row>
              {fromApp ? (
                <Spacer height={24} />
              ) : (
                <>
                  <Spacer height={32} />
                  <Column
                    verticalGravity={VerticalGravity.center}
                    horizontalGravity={HorizontalGravity.center}
                  >
                    <Button
                      width={200}
                      height={36}
                      text={translator(TranslationKeys.ViewWorks)}
                      isFilled
                      handleClick={() => navigator(`/users/${making.userId}`)}
                    />
                  </Column>
                  <Spacer height={40} />
                </>
              )}
            </Column>
            <Spacer height={56} />
            <Column
              verticalGravity={VerticalGravity.center}
              horizontalGravity={HorizontalGravity.center}
            >
              <Text
                text={translator(
                  isLiked ? TranslationKeys.MakingLikedTitle : TranslationKeys.MakingLikeTitle,
                )}
                textStyle={TextStyle.BOLD16}
                colorStyle={ColorStyle.TEXT_PRIMARY}
              />
              <Spacer height={16} />
              <MakingLikeComponent isLiked={isLiked} handleClick={handleClickLike} />
            </Column>
            <Spacer height={40} />
          </Column>
        </Column>
        {anotherMakings.length !== 0 && !fromApp && (
          <>
            <Spacer height={32} />
            <Column
              verticalGravity={VerticalGravity.center}
              horizontalGravity={HorizontalGravity.center}
            >
              <Ads adsType={DotpictAdsType.RECTANGLE_LARGE} />
            </Column>
            <Spacer height={32} />
            <Text
              text={translator(TranslationKeys.AnotherMakings)}
              textStyle={TextStyle.BOLD20}
              colorStyle={ColorStyle.TEXT_PRIMARY}
            />
            <Spacer height={16} />
            <div css={anotherMakingGridStyle}>
              {anotherMakings
                .filter((value: MakingType) => value.key !== making.key)
                .map((value: MakingType) => (
                  <Link key={value.key} to={`/making/${value.key}`}>
                    <MakingThumbnailComponent
                      making={value}
                      width={anotherMakingItemWidth}
                      height={anotherMakingItemHeight}
                    />
                  </Link>
                ))}
            </div>
          </>
        )}
        {!fromApp && (
          <>
            <Spacer height={40} />
            <Column
              verticalGravity={VerticalGravity.center}
              horizontalGravity={HorizontalGravity.center}
            >
              <Ads adsType={DotpictAdsType.RECTANGLE_LARGE} />
            </Column>
          </>
        )}
        <Spacer height={40} />
      </div>
    </>
  );
};

export default MakingDetail;
