import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import Text from '@dotpict-lib/foundation/typography/Text';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import styled from '@emotion/styled';

const Wrapper = styled.div<{ width: number; height: number; backgroundColor: ColorStyle }>`
  display: flex;
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  justify-content: center;
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

export type BadgeProps = {
  text: string;
  color: ColorStyle;
  width: number;
  height: number;
  textStyle: TextStyle;
};

const Badge = (props: BadgeProps) => {
  const { text, color, width, height, textStyle } = props;

  return (
    <Wrapper width={width} height={height} backgroundColor={color}>
      <Text text={text} textStyle={textStyle} colorStyle={ColorStyle.WHITE} />
    </Wrapper>
  );
};

export default Badge;
