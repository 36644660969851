import { css } from '@emotion/react';

export type ColorProps = {
  hexColor: string;
  width: number;
  height: number;
};

const Color = ({ hexColor, width, height }: ColorProps) => {
  const style = css({
    background: `#${hexColor}`,
    width,
    height,
  });

  return <div css={style} />;
};

export default Color;
