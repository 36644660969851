import { css } from '@emotion/react';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import { VerticalGravity } from '@dotpict-lib/component/core/layout/VerticalGravity';
import { Row } from '@dotpict-lib/component/core/layout/Row';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';

interface IProps {
  width: number;
  height: number;
  placeHolder?: string;
  isHidden?: boolean;
  value?: string;
  onTextChange?: (text: string) => void;
}

const defaultProps = {
  isHidden: false,
  value: undefined,
  placeHolder: '',
  onTextChange: () => {},
};

/**
 * @deprecated InputComponentの代わりにTextFieldを使ってください
 */
const InputComponent = (props: IProps) => {
  const { width, height, placeHolder, isHidden, value, onTextChange = () => {} } = props;
  const sideMargin = 12;
  const inputWidth = width - sideMargin * 2;

  const inputStyle = css({
    border: 'none',
    outline: 'none',
    background: 'transparent',
    fontSize: 14,
    width: inputWidth,
  });

  return (
    <Row
      width={width}
      height={height}
      background={ColorStyle.WHITE_GRAY}
      verticalGravity={VerticalGravity.center}
    >
      <Spacer width={12} />
      <input
        css={inputStyle}
        type={isHidden ? 'password' : 'text'}
        value={value}
        placeholder={placeHolder}
        onChange={(e) => onTextChange(e.target.value)}
      />
    </Row>
  );
};

InputComponent.defaultProps = defaultProps;

export default InputComponent;
