import { LoginService } from '@/app/service/LoginService/LoginService';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { dotpictFirebaseAuth } from '@/app/library/dotpictFirebaseAuth';
import TranslationKeys from '@/app/translation/TranslationKeys';
import DomainError from '@/app/service/DomainError';
import { EmptyResponseType } from '@/app/model/responsetype/EmptyResponseType';

const dotpictLoginService: LoginService = (mailAddress: string, password: string) => {
  return signInWithEmailAndPassword(dotpictFirebaseAuth, mailAddress, password)
    .then<EmptyResponseType>(() => {
      return {};
    })
    .catch((error) => {
      if (error.code === 'auth/wrong-password') {
        throw new DomainError(TranslationKeys.IncorrectPasswordError);
      } else {
        throw new DomainError(TranslationKeys.FailedLoginError);
      }
    });
};

export default dotpictLoginService;
