import { useContext, useState } from 'react';
import { APIContext, GlobalContext, MeContext } from '@/Routing';
import { DotpictAction } from '@/app/common/reducer';
import likeAnimationData from '@/assets/lottie/BtnLikeOn_64x64.json';
import deleteLikeAnimationData from '@/assets/lottie/BtnLikeOff_64x64.json';
import { AnimatableActionButton } from '@/app/component/AnimatableActionButton';

type Props = {
  noteId: number;
  isLiked: boolean;
  size: number;
};

export const NoteLike = ({ noteId, isLiked, size }: Props) => {
  const { dispatch } = useContext(GlobalContext);
  const meState = useContext(MeContext);
  const [isStopped, setStopped] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const { client } = useContext(APIContext);

  const handleClick = async () => {
    if (!meState.isLoggedIn) {
      // eslint-disable-next-line no-alert
      window.alert('ログインが必要です');
      return;
    }
    if (isLoading) {
      return;
    }
    setStopped(false);
    setLoading(true);
    const request = async () => {
      const note = isLiked
        ? await client.deleteLikeNote(meState.token, `${noteId}`)
        : await client.postLikeNote(meState.token, `${noteId}`);
      const action: DotpictAction = {
        type: 'UPDATE_NOTE',
        payload: { note },
      };
      dispatch(action);
    };
    await request();
    setLoading(false);
    setStopped(true);
  };

  return (
    <AnimatableActionButton
      size={size}
      isStopped={isStopped}
      isOn={isLiked}
      offToOnAnimationData={likeAnimationData}
      onToOffAnimationData={deleteLikeAnimationData}
      handleClick={handleClick}
    />
  );
};
