import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import Text from '@dotpict-lib/foundation/typography/Text';
import useTranslator from '@/app/hook/useTranslator';
import TranslationKeys from '@/app/translation/TranslationKeys';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import Loading from '@dotpict-lib/component/core/Loading';
import Column from '@dotpict-lib/component/core/layout/Column';
import { HorizontalGravity } from '@dotpict-lib/component/core/layout/HorizontalGravity';
import { NoteType } from '@dotpict-sns-lib/model/Note/NoteType';
import { useChildNotes } from '@/app/component/page/note/childnotes/useChildNotes';
import { Note } from '@/app/component/page/note/item/Note';
import End from '@dotpict-lib/component/core/End';

export type ChildNotesViewProps = {
  parentNote: NoteType;
  notes: NoteType[];
  isLoading: boolean;
};

export const ChildNotes = () => {
  const translator = useTranslator();
  const { parentNote, notes, isLoading } = useChildNotes();

  return (
    <Column verticalPadding={32} innerPadding={32}>
      <Text
        text={translator(TranslationKeys.Replies)}
        textStyle={TextStyle.BOLD20}
        colorStyle={ColorStyle.TEXT_PRIMARY}
      />
      <Note isVisibleFooter={false} note={parentNote} />
      <Column width="100%" horizontalGravity={HorizontalGravity.center}>
        <Text
          text={translator(TranslationKeys.NotePostUnavailableFromWeb)}
          textStyle={TextStyle.REGULAR14}
          colorStyle={ColorStyle.TEXT_PRIMARY}
        />
      </Column>
      {notes.length !== 0 && (
        <Column innerPadding={8}>
          {notes.map((note) => (
            <Note key={note.id} isVisibleFooter note={note} />
          ))}
        </Column>
      )}
      {isLoading && <Loading />}
      {!isLoading && notes.length === 0 && (
        <Column width="100%" horizontalGravity={HorizontalGravity.center}>
          <Text
            text={translator(TranslationKeys.NoReplies)}
            textStyle={TextStyle.REGULAR14}
            colorStyle={ColorStyle.TEXT_PRIMARY}
          />
        </Column>
      )}
      {!isLoading && <End />}
    </Column>
  );
};
