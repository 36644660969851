import { useContext, useState } from 'react';
import { APIContext, GlobalContext, MeContext } from '@/Routing';
import { DotpictAction } from '@/app/common/reducer';
import repictAnimationData from '@/assets/lottie/BtnRepictOn_64x64.json';
import deleteRepictAnimationData from '@/assets/lottie/BtnRepictOff_64x64.json';
import { AnimatableActionButton } from '@/app/component/AnimatableActionButton';

type Props = {
  workId: number;
  isRepicted: boolean;
  size: number;
};

export const Repict = ({ workId, isRepicted, size }: Props) => {
  const { dispatch } = useContext(GlobalContext);
  const meState = useContext(MeContext);
  const [isStopped, setStopped] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const { client } = useContext(APIContext);

  const handleClick = async () => {
    if (!meState.isLoggedIn) {
      // eslint-disable-next-line no-alert
      window.alert('ログインが必要です');
      return;
    }
    if (isLoading) {
      return;
    }
    setStopped(false);
    setLoading(true);
    const request = async () => {
      const work = isRepicted
        ? await client.deleteRepict(meState.token, `${workId}`)
        : await client.postRepict(meState.token, `${workId}`);
      const action: DotpictAction = {
        type: 'UPDATE_WORK',
        payload: { work },
      };
      dispatch(action);
    };
    await request();
    setLoading(false);
    setStopped(true);
  };

  return (
    <AnimatableActionButton
      size={size}
      isStopped={isStopped}
      isOn={isRepicted}
      offToOnAnimationData={repictAnimationData}
      onToOffAnimationData={deleteRepictAnimationData}
      handleClick={handleClick}
    />
  );
};
