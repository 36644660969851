import WorkDetail from '@/app/component/page/work/detail/WorkDetail';
import { useContext, useMemo } from 'react';
import { APIContext } from '@/Routing';
import { Dependencies, WorkDetailContext } from '@/app/component/page/work/detail/useWorkDetail';
import { useParams } from 'react-router-dom';

const WorkDetailPage = () => {
  const { client } = useContext(APIContext);
  const { workId } = useParams();

  const dependencies = useMemo((): Dependencies => {
    return {
      getWorkDetailService: (workId: number) => client.fetchWorkDetail(workId),
    };
  }, [client]);

  return (
    <WorkDetailContext.Provider value={dependencies}>
      <WorkDetail workId={Number(workId)} />
    </WorkDetailContext.Provider>
  );
};

export default WorkDetailPage;
