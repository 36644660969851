import User from '@/app/component/partial/user/item/User';
import Works from '@/app/component/Works';
import { css } from '@emotion/react';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import Column from '@dotpict-lib/component/core/layout/Column';
import { WorkType } from '@/app/model/WorkType';

const imageSize = 128;

const gridStyle = css({
  display: 'grid',
  justifyContent: 'center',
  columnGap: 4,
  gridTemplateColumns: `repeat(auto-fill, ${imageSize}px)`,
});

export type UserSummaryProps = {
  userId: number;
  name: string;
  text: string;
  profileImageUrl: string;
  isFollowed: boolean;
  isFollower: boolean;
  works: WorkType[];
  isVisibleFollow: boolean;
  isLoading: boolean;
};

const UserSummary = ({
  userId,
  name,
  text,
  profileImageUrl,
  isFollowed,
  isFollower,
  works,
  isVisibleFollow,
  isLoading,
}: UserSummaryProps) => (
  <Column width="100%" padding={16} innerPadding={24} background={ColorStyle.WHITE}>
    <User
      userId={userId}
      name={name}
      text={text}
      profileImageUrl={profileImageUrl}
      isFollowed={isFollowed}
      isFollower={isFollower}
      isVisibleFollowInfo={isVisibleFollow && !isLoading}
    />
    {(works.length !== 0 || isLoading) && (
      <Works
        works={works}
        isLoading={isLoading}
        isVisibleEnd={false}
        isVisibleAds={false}
        isVisibleLike
        gridStyle={gridStyle}
        imageSize={imageSize}
        dummyItemCount={6}
      />
    )}
  </Column>
);

export default UserSummary;
