enum ArticleContentType {
  HEADLINE,
  TEXT,
  BOLD_TEXT,
  SPACE,
  IMAGE,
  LINK,
}

export default ArticleContentType;
