import { useContext } from 'react';
import Ads from '@/app/component/partial/ads/Ads';
import { LayoutContext } from '@/Routing';
import DotpictAdsType from '@/app/ads/DotpictAdsType';
import Column from '@dotpict-lib/component/core/layout/Column';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';

const SideComponent = () => {
  const { layoutParams } = useContext(LayoutContext);

  return (
    <Column>
      <Spacer height={layoutParams.contentTopSpace} />
      <Ads adsType={DotpictAdsType.RECTANGLE_LARGE} />
    </Column>
  );
};

export default SideComponent;
