import { defaultOdai, OdaiType } from '@dotpict-sns-lib/model/Odai/OdaiType';
import {
  defaultOfficialEvent,
  OfficialEventType,
} from '@dotpict-sns-lib/model/OfficialEvent/OfficialEventType';

export type NetaStateType = {
  officialEvent: OfficialEventType;
  odai: OdaiType;
};

export const defaultNetaState: NetaStateType = {
  officialEvent: defaultOfficialEvent,
  odai: defaultOdai,
};
