import { ListItem } from '@/app/component/partial/listitem/ListItem';
import { ReactNode } from 'react';
import Text from '@dotpict-lib/foundation/typography/Text';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';
import Ads from '@/app/component/partial/ads/Ads';
import DotpictAdsType from '@/app/ads/DotpictAdsType';
import End from '@dotpict-lib/component/core/End';
import { Work } from '@/app/component/partial/work/item/Work';
import { TimelineHeader } from '@/app/component/page/timeline/TimelineItemHeader';
import { Note } from '@/app/component/page/note/item/Note';
import UserSummary from '@/app/component/partial/user/summary/UserSummary';

export const renderListItem = (listItem: ListItem): ReactNode | null => {
  switch (listItem.type) {
    case 'USER':
      return (
        <UserSummary
          key={listItem.userSummary.user.id}
          userId={listItem.userSummary.user.id}
          name={listItem.userSummary.user.name}
          text={listItem.userSummary.user.text}
          profileImageUrl={listItem.userSummary.user.profileImageUrl}
          isFollowed={listItem.userSummary.user.isFollowed}
          isFollower={listItem.userSummary.user.isFollower}
          works={listItem.userSummary.works}
          isVisibleFollow={false}
          isLoading={false}
        />
      );
    case 'WORK':
      return (
        <Work
          key={listItem.work.id}
          workId={listItem.work.id}
          title={listItem.work.title}
          caption={listItem.work.caption}
          imageUrl={listItem.work.imageUrl}
          tags={listItem.work.tags}
          width={listItem.work.width}
          height={listItem.work.height}
          createdAt={listItem.work.createdAt}
          isLiked={listItem.work.isLiked}
          isRepicted={listItem.work.isRepicted}
          userId={listItem.work.user.id}
          userName={listItem.work.user.name}
          officialEventTag={listItem.work.officialEvent?.tag ?? ''}
          officialEventTitle={listItem.work.officialEvent?.title ?? ''}
          userEventId={listItem.work.userEvent?.id ?? 0}
          userEventTitle={listItem.work.userEvent?.title ?? ''}
          odaiTag={listItem.work.odai?.tag ?? ''}
          odaiTitle={listItem.work.odai?.title ?? ''}
          profileImageUrl={listItem.work.user.profileImageUrl}
          likeCount={listItem.work.likeCount}
          repictCount={listItem.work.repictCount}
          threadCount={listItem.work.threadCount}
          allowThread={listItem.work.allowThread}
          isVisibleUser
          maxImageSize={listItem.maxImageSize}
          header={
            listItem.headerInfo === null ? undefined : <TimelineHeader info={listItem.headerInfo} />
          }
        />
      );
    case 'NOTE':
      return <Note key={listItem.note.id} isVisibleFooter note={listItem.note} />;
    case 'MESSAGE':
      return (
        <Text
          key={Math.random().toString()}
          text={listItem.message}
          textStyle={TextStyle.REGULAR14}
          colorStyle={ColorStyle.TEXT_PRIMARY}
        />
      );
    case 'SPACER':
      return <Spacer key={Math.random().toString()} height={listItem.size} />;
    case 'RECTANGLE_ADS':
      return (
        <Ads
          key={Math.random().toString()}
          adsType={DotpictAdsType.RECTANGLE_LARGE}
          verticalPadding={16}
        />
      );
    case 'END':
      return <End key={Math.random().toString()} verticalPadding={16} />;
    default:
      return null;
  }
};
