import { defaultFollowedUsersState, IFollowedUsersState } from '@/app/state/IFollowedUsersState';
import { defaultFollowerUsersState, IFollowerUsersState } from '@/app/state/IFollowerUsersState';
import { defaultLikeUsersState, ILikeUsersState } from '@/app/state/ILikeUsersState';
import { defaultMeState, IMeState } from '@/app/state/IMeState';
import { defaultSearchUsersState, ISearchUsersState } from '@/app/state/ISearchUsersState';
import { defaultSearchWorksState, ISearchWorksState } from '@/app/state/ISearchWorksState';
import { defaultTemplateResultState, ITemplateResultState } from '@/app/state/ITemplateResultState';
import { defaultUserDetailState, UserDetailStateType } from '@/app/state/UserDetailStateType';
import { defaultWorkDetailState, IWorkDetailState } from '@/app/state/IWorkDetailState';
import { defaultWorksState, WorksStateType } from '@/app/state/WorksStateType';
import { defaultMyPageState, MyPageStateType } from '@/app/state/MyPageState';
import { defaultNotificationsState, INotificationsState } from '@/app/state/INotificationsState';
import { defaultWorkThreadsState, WorkThreadsState } from '@/app/state/WorkThreadsState';
import { defaultNetaState, NetaStateType } from '@/app/state/NetaStateType';
import {
  defaultOfficialEventDetailState,
  OfficialEventDetailStateType,
} from '@/app/state/OfficialEventDetailStateType';
import {
  defaultOfficialEventResultState,
  OfficialEventResultStateType,
} from '@/app/state/OfficialEventResultStateType';
import { defaultOdaiDetailState, OdaiDetailStateType } from '@/app/state/OdaiDetailStateType';
import { defaultUsersState, UsersStateType } from '@/app/state/UsersStateType';
import { defaultTimelineState, TimelineStateType } from '@/app/state/TimelineStateType';
import { defaultWorkRepliesState, WorkRepliesState } from '@/app/state/WorkRepliesState';
import { ChildNotesState, emptyChildNotesState } from '@/app/state/ChildNotesState';

export type GlobalStateType = {
  timelineState: TimelineStateType;
  followingUserWorksState: WorksStateType;
  newestWorksState: WorksStateType;
  pickUpWorksState: WorksStateType;
  searchWorksState: ISearchWorksState;
  searchUsersState: ISearchUsersState;
  odaiDetailState: OdaiDetailStateType;
  officialEventDetailState: OfficialEventDetailStateType;
  officialEventResultState: OfficialEventResultStateType;
  likeUsersState: ILikeUsersState;
  followedUsersState: IFollowedUsersState;
  followerUsersState: IFollowerUsersState;
  userDetailState: UserDetailStateType;
  usersStateHash: { [key: string]: UsersStateType };
  meState: IMeState;
  myPageState: MyPageStateType;
  workDetailState: IWorkDetailState;
  templateResultState: ITemplateResultState;
  notificationsState: INotificationsState;
  workThreadsState: WorkThreadsState;
  workRepliesState: WorkRepliesState;
  netaState: NetaStateType;
  childNotesState: ChildNotesState;
  grandNotesState: ChildNotesState;
  message: string;
};

export const defaultGlobalState: GlobalStateType = {
  timelineState: defaultTimelineState,
  followingUserWorksState: defaultWorksState,
  newestWorksState: defaultWorksState,
  pickUpWorksState: defaultWorksState,
  searchWorksState: defaultSearchWorksState,
  searchUsersState: defaultSearchUsersState,
  odaiDetailState: defaultOdaiDetailState,
  officialEventDetailState: defaultOfficialEventDetailState,
  officialEventResultState: defaultOfficialEventResultState,
  likeUsersState: defaultLikeUsersState,
  followedUsersState: defaultFollowedUsersState,
  followerUsersState: defaultFollowerUsersState,
  userDetailState: defaultUserDetailState,
  usersStateHash: {
    LIKED: defaultUsersState,
    FOLLOWED: defaultUsersState,
    FOLLOWER: defaultUsersState,
    SEARCH_RESULT: defaultUsersState,
  },
  meState: defaultMeState,
  myPageState: defaultMyPageState,
  workDetailState: defaultWorkDetailState,
  templateResultState: defaultTemplateResultState,
  notificationsState: defaultNotificationsState,
  workThreadsState: defaultWorkThreadsState,
  workRepliesState: defaultWorkRepliesState,
  netaState: defaultNetaState,
  childNotesState: emptyChildNotesState,
  grandNotesState: emptyChildNotesState,
  message: '',
};

export default defaultGlobalState;
