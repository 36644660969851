import { useNavigate } from 'react-router-dom';
import { TimelineItemHeaderInfoType } from '@/app/state/TimelineItemHeaderInfoType';
import { TimelineItemHeaderState } from '@/app/component/page/timeline/TimelineItemHeader';

type Props = {
  info: TimelineItemHeaderInfoType;
};

export const useTimelineItemHeader = ({ info }: Props): TimelineItemHeaderState => {
  const navigator = useNavigate();

  const onClickHeader = () => {
    switch (info.type) {
      case 'user':
        navigator(`/users/${info.user!.id}`);
        break;
      default:
        break;
    }
  };

  return {
    bindModel: {
      iconImageUrl: info.iconImageUrl,
      title: info.title,
      text: info.text,
    },
    onClickHeader,
  };
};
