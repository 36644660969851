import emptyUser, { UserType } from '@dotpict-lib/model/User/UserType';
import { WorkType } from '../model/WorkType';

export type UserDetailStateType = {
  user: UserType;
  works: WorkType[];
  nextUrl: string;
};

export const defaultUserDetailState: UserDetailStateType = {
  user: emptyUser,
  works: [],
  nextUrl: '',
};
