import styled from '@emotion/styled';
import ColorButton from '@/app/component/page/draw/SideMenu/ColorSelector/ColorButton';
import { PaletteType } from '@/app/component/page/draw/hooks/types';

const SelectorList = styled.div<{ vertical: boolean }>`
  display: grid;
  grid-gap: 2px;
  grid-auto-flow: ${({ vertical }) => (vertical ? 'row' : 'column')};
  z-index: -1;
`;

type Props = {
  palette: PaletteType;
  vertical: boolean;
  selectColor: (selectedIndex: number) => () => void;
  selectedColorIndex: number;
};

const ColorSelector = ({ palette, vertical, selectColor, selectedColorIndex }: Props) => {
  return (
    <SelectorList vertical={vertical}>
      {palette.map((paletteColor, index) => (
        <ColorButton
          // eslint-disable-next-line react/no-array-index-key
          key={`${paletteColor}_${index}`}
          color={paletteColor}
          index={index}
          vertical={vertical}
          selectedIndex={selectedColorIndex}
          selectColor={selectColor}
        />
      ))}
    </SelectorList>
  );
};

export default ColorSelector;
