import Loading from '@dotpict-lib/component/core/Loading';
import Column from '@dotpict-lib/component/core/layout/Column';
import { HorizontalGravity } from '@dotpict-lib/component/core/layout/HorizontalGravity';
import { VerticalGravity } from '@dotpict-lib/component/core/layout/VerticalGravity';
import { PlaceholderUserSummary } from '@/app/component/partial/user/summary/PlaceholderUserSummary';
import { UsersKey } from '@/app/component/page/user/list/UsersKey';
import { useUsers } from '@/app/component/page/user/list/useUsers';
import { ListItem } from '@/app/component/partial/listitem/ListItem';
import { renderListItem } from '@/app/component/partial/listitem/renderListItems';

type Props = {
  usersKey: UsersKey;
};

export type UsersState = {
  listItems: ListItem[];
  isLoading: boolean;
};

const Users = ({ usersKey }: Props) => {
  const { listItems, isLoading } = useUsers({
    usersKey,
  });

  return (
    <Column
      width="100%"
      verticalGravity={VerticalGravity.center}
      horizontalGravity={HorizontalGravity.center}
      innerPadding={8}
    >
      {listItems.length === 0 &&
        [...new Array(20)].map(() => <PlaceholderUserSummary key={Math.random().toString()} />)}
      {listItems.map((listItem) => renderListItem(listItem))}
      {isLoading && <Loading verticalPadding={16} />}
    </Column>
  );
};

export default Users;
