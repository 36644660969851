import React, { createContext, Dispatch, useEffect, useMemo, useReducer, useState } from 'react';
import { BrowserRouter, Route, Routes, Outlet } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import defaultGlobalState, { GlobalStateType } from '@/app/state/GlobalStateType';
import reducer, { DotpictAction } from '@/app/common/reducer';
import HeaderComponent from '@/app/component/HeaderComponent';
import { dotpictFirebaseAuth } from '@/app/library/dotpictFirebaseAuth';
import { MeResponseType } from '@/app/model/responsetype/MeResponseType';
import { DotpictClient, IDotpictClient } from '@/app/apiclient/DotpictApiClient';
import { defaultMeState, IMeState } from '@/app/state/IMeState';
import SideComponent from '@/app/component/SideComponent';
import { defaultLayoutParams, ILayoutParams } from '@/app/constant/ILayoutParams';
import SearchWorkResultComponent from '@/app/component/SearchWorkResultComponent';
import { WorkLikedUsers } from '@/app/component/page/user/list/WorkLikedUsers';
import {
  defaultSmallThumbnailImageGridGap,
  defaultThumbnailImageGridGap,
} from '@/app/common/constant';
import PrivacyComponent from '@/app/component/page/article/PrivacyComponent';
import GuidelineComponent from '@/app/component/page/article/GuidelineComponent';
import TemplateEventComponent from '@/app/component/TemplateEventComponent';
import SignUpComponent from '@/app/component/SignUpComponent';
import SixArticleComponent from '@/app/component/page/article/SixArticleComponent';
import ArticleDetailComponent from '@/app/component/page/article/ArticleDetailComponent';
import DeepLinkTestComponent from '@/app/component/DeepLinkTestComponent';
import { MyPageLikedWorks, MyPagePostedWorks } from '@/app/component/page/me/mypage';
import Notifications from '@/app/component/page/notifications';
import RequestBox from '@/app/component/page/requestbox';
import UserEventDetail from '@/app/component/page/event/user/detail/UserEventDetail';
import LoginPage from '@/app/component/page/me/login';
import UploadWorkPage from '@/app/component/page/work/upload';
import DrawPage from '@/app/component/page/draw';
import CanvasesPage from '@/app/component/page/draw/canvases';
import WorkDetailPage from '@/app/component/page/work/detail';
import NotFound from '@/app/component/NotFound';
import ImageGuideline from '@/app/component/page/imageguideline';
import { User } from '@firebase/auth';
import EditWorkPage from '@/app/component/page/work/edit';
import MakingDetail from '@/app/component/page/making/detail/MakingDetail';
import OfficialEventResultPage from '@/app/component/page/event/official/result/OfficialEventResultPage';
import AppIcon from '@/app/image/app_icon.svg';
import Column from '@dotpict-lib/component/core/layout/Column';
import { VerticalGravity } from '@dotpict-lib/component/core/layout/VerticalGravity';
import { HorizontalGravity } from '@dotpict-lib/component/core/layout/HorizontalGravity';
import Loading from '@dotpict-lib/component/core/Loading';
import { Row } from '@dotpict-lib/component/core/layout/Row';
import UserDetailPage from '@/app/component/page/user/detail';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';
import OfficialEventPage from '@/app/component/page/event/official/normal';
import OdaiPage from '@/app/component/page/event/odai/OdaiPage';
import { FollowedUsers } from '@/app/component/page/user/list/FollowedUsers';
import { FollowerUsers } from '@/app/component/page/user/list/FollowerUsers';
import { SearchResultUsers } from '@/app/component/page/user/list/SearchResultUsers';
import { LanguageType } from '@/app/model/Language';
import useTranslator from '@/app/hook/useTranslator';
import { WorkRepliesPage } from '@/app/component/page/work/thread/replies/WorkRepliesPage';
import { WorkThreadsPage } from '@/app/component/page/work/thread/threads/WorkThreadsPage';
import { ChildNotesPage } from '@/app/component/page/note/childnotes/ChildNotesPage';
import { GrandNotesPage } from '@/app/component/page/note/grandnotes/GrandNotesPage';
import { RepictedUsers } from '@/app/component/page/user/list/RepictedUsers';
import { NoteLikedUsers } from '@/app/component/page/user/list/NoteLikedUsers';
import { WorkThreadLikedUsers } from '@/app/component/page/user/list/WorkThreadLikedUsers';
import { FeedbackPage } from '@/app/component/page/feedback/FeedbackPage';
import { toast, Toaster } from 'react-hot-toast';
import { Global } from '@emotion/react';
import { HomePage } from './app/component/page/home/HomePage';

export type IGlobalContext = {
  globalState: GlobalStateType;
  dispatch: Dispatch<DotpictAction>;
};

export type IAPIContext = {
  client: IDotpictClient;
};

export type ILayoutContext = {
  layoutParams: ILayoutParams;
};

export const GlobalContext = createContext<IGlobalContext>({} as IGlobalContext);
export const APIContext = createContext<IAPIContext>({} as IAPIContext);
export const LayoutContext = createContext<ILayoutContext>({} as ILayoutContext);
export const MeContext = createContext({} as IMeState);

declare global {
  interface Window {
    gtag?: (
      key: string,
      trackingId: string,
      // eslint-disable-next-line camelcase
      config: { page_path: string },
    ) => void;
  }
}

export const createDesktopLayoutParams = (contentWidth: number): ILayoutParams => {
  return {
    contentTopSpace: 40,
    contentBottomSpace: 72,
    rectangleAdsWidth: 336,
    rectangleAdsHeight: 280,
    contentWidth,
    mainContainerWidth: 832, // 792 + 20 * 2
    betweenContentAndSideSpace: 64,
    sideComponentWidth: 336,
    fullWidth: 832 + 64 + 336,
    thumbnailImageSize: 192,
    smallThumbnailImageSize: 128,
    thumbnailImageGridGap: defaultThumbnailImageGridGap,
    smallThumbnailImageGridGap: defaultSmallThumbnailImageGridGap,
    homeSectionInfoSideMargin: 0,
    makingCarouselPadding: Math.floor(contentWidth / 6),
    makingCarouselItemSpace: 16,
    isMobile: false,
  };
};

export const createMobileLLayoutParams = (contentWidth: number): ILayoutParams => {
  const thumbnailImageGridGap = 4;
  const smallThumbnailImageGridGap = 2;
  return {
    contentTopSpace: 24,
    contentBottomSpace: 24,
    rectangleAdsWidth: 336,
    rectangleAdsHeight: 280,
    contentWidth,
    mainContainerWidth: contentWidth,
    betweenContentAndSideSpace: 0,
    sideComponentWidth: 0,
    fullWidth: contentWidth,
    thumbnailImageSize: Math.floor((contentWidth - thumbnailImageGridGap) / 2),
    smallThumbnailImageSize: Math.floor((contentWidth - smallThumbnailImageGridGap * 2) / 3),
    thumbnailImageGridGap,
    smallThumbnailImageGridGap,
    homeSectionInfoSideMargin: 16,
    makingCarouselPadding: 0,
    makingCarouselItemSpace: 0,
    isMobile,
  };
};

const WithHeaderLayout = (
  isFullSize: boolean,
  meState: IMeState,
  layoutParams: ILayoutParams,
  children: React.ReactNode,
) => {
  return (
    <>
      <HeaderComponent />
      <Row
        width={
          layoutParams.mainContainerWidth +
          layoutParams.betweenContentAndSideSpace +
          layoutParams.sideComponentWidth
        }
        horizontalGravity={HorizontalGravity.center}
      >
        <Column width={layoutParams.mainContainerWidth}>
          {/* Routeコンポーネントの中身がここに描画される */}
          {children}
        </Column>
        {isFullSize && !meState.user.isPremium && (
          <>
            <Spacer width={layoutParams.betweenContentAndSideSpace} />
            <SideComponent />
          </>
        )}
      </Row>
    </>
  );
};

export const apiBaseUrl = 'https://api.dotpicko.net';

type Props = {
  language: LanguageType;
};

const Routing = ({ language }: Props) => {
  const translator = useTranslator();

  const [globalState, dispatch] = useReducer(reducer, defaultGlobalState);
  const [isLoadingFirebaseAuth, setLoadingFirebaseAuth] = useState(true);
  const [client, setClient] = useState<DotpictClient>(
    new DotpictClient(apiBaseUrl, language, translator),
  );
  const [layoutParams, setLayoutParams] = useState(defaultLayoutParams);
  const isFullSize = useMediaQuery('(min-width:1232px)');

  const createLayoutParams = (): ILayoutParams => {
    if (isFullSize) {
      return createDesktopLayoutParams(792);
    }
    return createMobileLLayoutParams(document.documentElement.clientWidth);
  };

  // キャンバス一覧とDraw画面があるのでRootで管理
  useEffect(() => {
    // 1 -> 2 odaiJsonを追加
    localStorage.setItem('draw_version', '2');
  }, []);

  useEffect(() => {
    if (globalState.message === '') return;
    toast(globalState.message);
    const consumeMessageAction: DotpictAction = {
      type: 'SHOW_MESSAGE',
      payload: { message: '' },
    };
    dispatch(consumeMessageAction);
  }, [globalState.message]);

  useEffect(() => {
    const refreshData = (user: User | null) => {
      if (user === null) {
        const action: DotpictAction = {
          type: 'UPDATE_ME_STATE',
          payload: {
            meState: {
              ...defaultMeState,
              user: {
                ...defaultMeState.user,
                isPremium: localStorage.getItem('is_premium') === 'true',
              },
            },
          },
        };
        dispatch(action);
      } else {
        const fetchData = async () => {
          const token = await user?.getIdToken(false);
          if (token === undefined) return;
          dispatch({
            type: 'UPDATE_ME_STATE',
            payload: {
              meState: {
                ...defaultMeState,
                isLoggedIn: true,
                token,
                user: {
                  ...defaultMeState.user,
                  isPremium: localStorage.getItem('is_premium') === 'true',
                },
              },
            },
          });
          const response: MeResponseType = await client.fetchMe(token);
          dispatch({
            type: 'UPDATE_ME_STATE',
            payload: { meState: { isLoggedIn: true, token, user: response.user } },
          });
          localStorage.setItem('is_premium', response.user.isPremium ? 'true' : 'false');
        };
        fetchData();
      }
    };
    // tokenが1hourでexpireしたとき用
    const unsubscribeIdTokenChanged = dotpictFirebaseAuth.onIdTokenChanged((user: User | null) => {
      refreshData(user);
    });
    // login済みかどうか
    const unsubscribeAuthStateChanged = dotpictFirebaseAuth.onAuthStateChanged((_) => {
      setLoadingFirebaseAuth(false);
    });
    return function cleanup() {
      unsubscribeIdTokenChanged();
      unsubscribeAuthStateChanged();
    };
  }, [setLoadingFirebaseAuth]);

  useEffect(() => {
    setLayoutParams(createLayoutParams);
  }, [isFullSize]);

  useEffect(() => {
    setClient(new DotpictClient(apiBaseUrl, language, translator));
  }, [language]);

  const globalValue = useMemo(() => {
    return { globalState, dispatch };
  }, [globalState, dispatch]);

  const apiValue = useMemo(() => {
    return { client };
  }, [client]);

  const layoutValue = useMemo(() => {
    return { layoutParams };
  }, [layoutParams]);

  if (isLoadingFirebaseAuth) {
    return (
      <Column
        width="100%"
        height="100%"
        innerPadding={32}
        verticalGravity={VerticalGravity.center}
        horizontalGravity={HorizontalGravity.center}
      >
        <img width={144} height={144} src={AppIcon} alt="app icon" />
        <Loading />
      </Column>
    );
  }

  return (
    <BrowserRouter>
      <Global
        styles={{
          '@font-face': [
            {
              fontFamily: 'PixelMplus12-Bold',
              src: 'url("https://storage.googleapis.com/dotpict-images/web/fonts/PixelMplus12-Bold.ttf") format("truetype")',
            },
          ],
        }}
      />
      <GlobalContext.Provider value={globalValue}>
        <APIContext.Provider value={apiValue}>
          <MeContext.Provider value={globalState.meState}>
            <LayoutContext.Provider value={layoutValue}>
              <Column horizontalGravity={HorizontalGravity.center}>
                <Routes>
                  <Route path="/draw" element={<DrawPage />} />
                  {/* element配下に置くと@がmatchingしないので暫定対応 */}
                  <Route
                    path="/@:accountName"
                    element={WithHeaderLayout(
                      isFullSize,
                      globalState.meState,
                      layoutParams,
                      <UserDetailPage />,
                    )}
                  />
                  <Route
                    element={WithHeaderLayout(
                      isFullSize,
                      globalState.meState,
                      layoutParams,
                      <Outlet />,
                    )}
                  >
                    <Route path="/" element={<HomePage />} />
                    <Route path="/notifications" element={<Notifications />} />
                    <Route path="/works/:workId" element={<WorkDetailPage />} />
                    <Route path="/users/:userId" element={<UserDetailPage />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/signup" element={<SignUpComponent />} />
                    <Route path="/making/:makingId" element={<MakingDetail />} />
                    <Route path="/articles/:articleId" element={<ArticleDetailComponent />} />
                    <Route path="/search/users/:query" element={<SearchResultUsers />} />
                    <Route
                      path="/search/works/:type/:query"
                      element={<SearchWorkResultComponent />}
                    />
                    <Route path="/works/:workId/threads/:threadId" element={<WorkRepliesPage />} />
                    <Route path="/works/:workId/threads" element={<WorkThreadsPage />} />
                    <Route path="/notes/:parentNoteId/childNotes" element={<ChildNotesPage />} />
                    <Route path="/notes/:parentNoteId/grandNotes" element={<GrandNotesPage />} />
                    <Route path="/feedback" element={<FeedbackPage />} />
                    <Route path="/works/:workId/repictUsers" element={<RepictedUsers />} />
                    <Route path="/works/:workId/likeUsers" element={<WorkLikedUsers />} />
                    <Route
                      path="/works/:workId/threads/:threadId/likeUsers"
                      element={<WorkThreadLikedUsers />}
                    />
                    <Route path="/notes/:noteId/likeUsers" element={<NoteLikedUsers />} />
                    <Route path="/users/:userId/followedUsers" element={<FollowedUsers />} />
                    <Route path="/users/:userId/followerUsers" element={<FollowerUsers />} />
                    <Route path="/upload" element={<UploadWorkPage />} />
                    <Route path="/privacy" element={<PrivacyComponent />} />
                    <Route path="/guidelines" element={<GuidelineComponent />} />
                    <Route path="/deep_link_test" element={<DeepLinkTestComponent />} />
                    <Route path="/6th" element={<SixArticleComponent />} />
                    <Route path="/me/requestBox" element={<RequestBox />} />
                    <Route path="/me/postedWorks" element={<MyPagePostedWorks />} />
                    <Route path="/me/likedWorks" element={<MyPageLikedWorks />} />
                    <Route
                      path="/template_events/:eventName"
                      element={<TemplateEventComponent />}
                    />
                    <Route path="/userEvents/:userEventId" element={<UserEventDetail />} />
                    <Route path="/imageGuideline" element={<ImageGuideline />} />
                    <Route path="/odais/:odaiTag" element={<OdaiPage />} />
                    <Route
                      path="/officialEvents/:officialEventTag"
                      element={<OfficialEventPage />}
                    />
                    <Route
                      path="/officialEvents/:officialEventTag/result"
                      element={<OfficialEventResultPage />}
                    />
                    <Route path="/canvases" element={<CanvasesPage />} />
                    <Route path="/works/:workId/edit" element={<EditWorkPage />} />
                    <Route path="*" element={<NotFound />} />
                  </Route>
                </Routes>
                <Toaster position="bottom-right" />
              </Column>
            </LayoutContext.Provider>
          </MeContext.Provider>
        </APIContext.Provider>
      </GlobalContext.Provider>
    </BrowserRouter>
  );
};

export default Routing;
