import { MouseEventHandler, useState } from 'react';
import { css } from '@emotion/react';
import Text from '@dotpict-lib/foundation/typography/Text';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import Pixelart from '@dotpict-lib/component/core/Pixelart';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';
import { Row } from '@dotpict-lib/component/core/layout/Row';
import { VerticalGravity } from '@dotpict-lib/component/core/layout/VerticalGravity';
import { HorizontalGravity } from '@dotpict-lib/component/core/layout/HorizontalGravity';
import { CircularProgress } from '@material-ui/core';

type Props = {
  width: number | string;
  height: number;
  text: string;
  isFilled: boolean;
  isLoading?: boolean;
  color?: string;
  icon?: string;
  isBorder?: boolean;
  handleClick?: MouseEventHandler;
  textStyle?: TextStyle;
};

const Button = ({
  width,
  height,
  text,
  isFilled,
  isLoading,
  color,
  icon,
  isBorder,
  handleClick,
  textStyle,
}: Props) => {
  const [isHover, setHover] = useState(false);
  const unwrappedColor = color ?? ColorStyle.PRIMARY;
  const buttonCss = css({
    height,
    width,
    boxSizing: 'border-box',
    padding: 0,
    borderRadius: 0,
    justifyContent: 'center',
    background: isFilled ? unwrappedColor : 'transparent',
    border:
      isBorder !== undefined && isBorder && !isFilled
        ? `solid 2px ${unwrappedColor}`
        : 'transparent',
  });

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <button
      css={buttonCss}
      type="button"
      onClick={handleClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Row
        height="100%"
        background={`rgba(0, 0, 0, ${isHover ? 0.1 : 0})`}
        verticalGravity={VerticalGravity.center}
        horizontalGravity={HorizontalGravity.center}
      >
        {icon && (
          <>
            <Pixelart alt="icon" src={icon} width={14} height={14} />
            <Spacer width={6} />
          </>
        )}
        <Text
          text={text}
          textStyle={textStyle ?? TextStyle.BOLD14}
          colorStyle={isFilled ? ColorStyle.WHITE : ColorStyle.PRIMARY}
        />
      </Row>
    </button>
  );
};

export default Button;
