import { css } from '@emotion/react';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import Text from '@dotpict-lib/foundation/typography/Text';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import Column from '@dotpict-lib/component/core/layout/Column';
import { HorizontalGravity } from '@dotpict-lib/component/core/layout/HorizontalGravity';
import { VerticalGravity } from '@dotpict-lib/component/core/layout/VerticalGravity';

type Props = {
  id: string;
  width: number;
  height: number;
  text: string;
  onChange: (file: File) => void;
};

const InputStyle = css`
  display: none;
`;

// https://material-ui.com/components/buttons/#upload-button
const FileUploadComponent = ({ id, width, height, text, onChange }: Props) => {
  const styledLabel = css`
    width: ${width};
    height: ${height};
    border: solid 2px ${ColorStyle.PRIMARY};
    background: ${ColorStyle.WHITE};
  `;

  return (
    <label css={styledLabel} htmlFor={id}>
      <Column
        width={width}
        height={height}
        horizontalGravity={HorizontalGravity.center}
        verticalGravity={VerticalGravity.center}
      >
        <Text text={text} textStyle={TextStyle.BOLD14} colorStyle={ColorStyle.PRIMARY} />
      </Column>
      <input
        accept="image/png,image/gif"
        css={InputStyle}
        id={id}
        type="file"
        onChange={(event) => {
          const file = event.target.files?.item(0);
          if (file == null) return;
          onChange(file);
        }}
      />
    </label>
  );
};

export default FileUploadComponent;
