import { imageContainerStyle } from '@/app/component/page/making/detail/styles';
import ImageComponent from '@/app/component/ImageComponent';
import Column from '@dotpict-lib/component/core/layout/Column';
import { HorizontalGravity } from '@dotpict-lib/component/core/layout/HorizontalGravity';

export type ImageProps = {
  imageUrl: string;
  width: number;
  height: number;
};

const MakingImage = ({ imageUrl, width, height }: ImageProps) => (
  <Column horizontalGravity={HorizontalGravity.center}>
    <div css={imageContainerStyle}>
      <ImageComponent alt="making_image" src={imageUrl} width={width} height={height} />
    </div>
  </Column>
);

export default MakingImage;
