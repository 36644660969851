import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Clickable from '@dotpict-lib/component/core/Clickable';
import { DialogContent } from '@material-ui/core';

export type ConfirmationDialogProps = {
  title: string;
  message: string;
  isEnabled: boolean;
  handleClickOk: () => void;
  handleClickCancel?: () => void;
  children: React.ReactNode;
};

const ConfirmationDialog = ({
  title,
  message,
  isEnabled,
  handleClickOk,
  handleClickCancel,
  children,
}: ConfirmationDialogProps) => {
  const [open, setOpen] = useState(false);

  // eslint-disable-next-line no-underscore-dangle
  const _handleClickOk = () => {
    setOpen(false);
    handleClickOk();
  };

  // eslint-disable-next-line no-underscore-dangle
  const _handleClickCancel = () => {
    setOpen(false);
    if (handleClickCancel) {
      handleClickCancel();
    }
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <>
      {isEnabled ? <Clickable onClick={handleClickOpen}>{children}</Clickable> : children}
      <Dialog
        open={open}
        onClose={handleClickClose}
        aria-labelledby="confirmation-dialog-title"
        aria-describedby="confirmation-dialog-description"
      >
        <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirmation-dialog-description">{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={_handleClickCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={_handleClickOk} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmationDialog;
