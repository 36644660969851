import Text from '@dotpict-lib/foundation/typography/Text';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';
import TranslationKeys from '@/app/translation/TranslationKeys';
import Button from '@dotpict-lib/component/dotpict/Button';
import ExternalLinkComponent from '@/app/component/ExternalLinkComponent';
import EventColorCode from '@/app/component/partial/event/colorcode/EventColorCode';
import useTranslator from '@/app/hook/useTranslator';
import { useState } from 'react';
import useDownloadPalette from '@/app/component/page/event/user/detail/useDownloadPalette';
import Column from '@dotpict-lib/component/core/layout/Column';
import { HorizontalGravity } from '@dotpict-lib/component/core/layout/HorizontalGravity';
import { Row } from '@dotpict-lib/component/core/layout/Row';
import { VerticalGravity } from '@dotpict-lib/component/core/layout/VerticalGravity';
import { Grid } from '@dotpict-lib/component/core/layout/Grid';
import Colors from '@dotpict-lib/component/core/Colors';

export type HowToUsePaletteProps = {
  paletteName: string;
  colors: string[];
};

const HowToUsePalette = ({ paletteName, colors }: HowToUsePaletteProps) => {
  const translator = useTranslator();
  const [colorCodeOpen, setColorCodeOpen] = useState(false);

  const downloadPalette = useDownloadPalette({ paletteName, colors });

  const handleClickColorCodeToggle = () => {
    setColorCodeOpen(!colorCodeOpen);
  };

  return (
    <Column width="100%" horizontalGravity={HorizontalGravity.center}>
      <Spacer height={24} />
      <Row
        width="100%"
        horizontalGravity={HorizontalGravity.spaceBetween}
        verticalGravity={VerticalGravity.center}
      >
        <Row>
          <Spacer width={48} />
          <Column width={240}>
            <Text
              text={translator(TranslationKeys.UsePalette)}
              textStyle={TextStyle.BOLD20}
              colorStyle={ColorStyle.TEXT_PRIMARY}
            />
            <Spacer height={8} />
            <Text
              text={translator(TranslationKeys.EnableUseOtherColors)}
              textStyle={TextStyle.REGULAR13}
              colorStyle={ColorStyle.TEXT_PRIMARY}
            />
            <Spacer height={13} />
            <Button
              width={240}
              height={48}
              text={translator(TranslationKeys.Download)}
              textStyle={TextStyle.BOLD16}
              isFilled
              handleClick={() => downloadPalette()}
            />
            <Spacer height={13} />
            <ExternalLinkComponent
              text={translator(TranslationKeys.HowToUsePaletteInAseprite)}
              url="https://dotpict.notion.site/Web-Aseprite-dotpict-ff18d7f2d7314bc9a208b504bca8b771"
              textStyle={TextStyle.REGULAR13}
              colorStyle={ColorStyle.PRIMARY}
            />
            <Spacer height={13} />
          </Column>
        </Row>
        <Row>
          <Column width={256} horizontalGravity={HorizontalGravity.end}>
            <Column
              background={ColorStyle.PALE_LIGHTER}
              width="100%"
              horizontalGravity={HorizontalGravity.center}
            >
              <Spacer height={12} />
              <Colors colors={colors} colorWidth={16} colorHeight={16} />
              <Spacer height={12} />
            </Column>
            <Spacer height={20} />
            <Text
              text={translator(
                colorCodeOpen ? TranslationKeys.HideColorCodes : TranslationKeys.ShowColorCodes,
              )}
              textStyle={TextStyle.BOLD14}
              colorStyle={ColorStyle.PRIMARY}
              handleClick={() => handleClickColorCodeToggle()}
            />
          </Column>
          <Spacer width={48} />
        </Row>
      </Row>
      {colorCodeOpen && (
        <>
          <Spacer height={24} />
          <Grid width={584}>
            {colors.map((color, index) => (
              <Column key={color}>
                <Spacer height={16} />
                <Row>
                  <EventColorCode color={color} />
                  {index !== colors.length - 1 && <Spacer width={20} />}
                </Row>
              </Column>
            ))}
          </Grid>
        </>
      )}
    </Column>
  );
};

export default HowToUsePalette;
