import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useContext } from 'react';
import Pixelart from '@dotpict-lib/component/core/Pixelart';
import Text from '@dotpict-lib/foundation/typography/Text';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import TranslationKeys from '@/app/translation/TranslationKeys';
import { LayoutContext } from '@/Routing';
import Column from '@dotpict-lib/component/core/layout/Column';
import { HorizontalGravity } from '@dotpict-lib/component/core/layout/HorizontalGravity';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';

const NotFound = () => {
  const intl = useIntl();
  const { layoutParams } = useContext(LayoutContext);

  return (
    <Column
      padding={layoutParams.isMobile ? '0 24' : '0'}
      horizontalGravity={HorizontalGravity.center}
    >
      <Spacer height={128} />
      <Text
        text={intl.formatMessage({ id: TranslationKeys.NotFoundTitle })}
        textStyle={TextStyle.BOLD20}
        colorStyle={ColorStyle.TEXT_PRIMARY}
      />
      <Spacer height={90} />
      <Pixelart
        alt="Not Found"
        src="https://storage.googleapis.com/dotpict-images/web/not_found.svg"
        width={116}
        height={148}
      />
      <Spacer height={90} />
      <Text
        text={intl.formatMessage({ id: TranslationKeys.NotFoundMessage1 })}
        textStyle={TextStyle.REGULAR16}
        colorStyle={ColorStyle.GRAY_DEEP}
      />
      <Text
        text={intl.formatMessage({ id: TranslationKeys.NotFoundMessage2 })}
        textStyle={TextStyle.REGULAR16}
        colorStyle={ColorStyle.GRAY_DEEP}
      />
      <Spacer height={48} />
      <Link to="/">
        <Text
          text={intl.formatMessage({ id: TranslationKeys.NotFoundGoToTop })}
          textStyle={TextStyle.BOLD14}
          colorStyle={ColorStyle.PRIMARY}
        />
      </Link>
      <Spacer height={48} />
    </Column>
  );
};

export default NotFound;
