import { Button } from '@material-ui/core';
import GooglePlayImg from '@/app/image/googleplay.png';

const GooglePlay = () => {
  const handleClickGooglePlay = () => {
    window.location.href = '../../../../../../../details?id=net.dotpicko.dotpict';
  };

  return (
    <Button onClick={handleClickGooglePlay}>
      <img alt="google play" src={GooglePlayImg} width={120} />
    </Button>
  );
};

export default GooglePlay;
