import { UserType } from '@dotpict-lib/model/User/UserType';
import { convertUnixTimeToDateString } from '@dotpict-lib/model/Date/convertUnixTimeToDateString';

export const convertArrayToUser = (json: any): UserType => ({
  id: json.id,
  name: json.name,
  account: json.account,
  url: json.url,
  shareUrl: json.share_url,
  text: json.text,
  profileImageUrl: json.profile_image_url,
  headerImageUrl: json.header_image_url,
  followedCount: json.followed_count,
  followerCount: json.follower_count,
  isFollowed: json.is_followed,
  isFollower: json.is_follower,
  isOpenedRequestBox: json.is_opened_request_box,
  requestBoxText: json.request_box_text,
  registerAt: convertUnixTimeToDateString(json.created_at),
  birthDate: json.birth_date,
  isPremium: json.is_premium,
});
