import { defaultOdai, OdaiType } from '@dotpict-sns-lib/model/Odai/OdaiType';
import { WorkType } from '../model/WorkType';

export type OdaiDetailStateType = {
  odai: OdaiType;
  works: WorkType[];
  nextUrl: string;
  isLoadingDetail: boolean;
  isLoadingWorks: boolean;
  isVisibleEnd: boolean;
};

export const defaultOdaiDetailState: OdaiDetailStateType = {
  odai: defaultOdai,
  works: [],
  nextUrl: '',
  isLoadingDetail: true,
  isLoadingWorks: false,
  isVisibleEnd: false,
};
