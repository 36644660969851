import React from 'react';
import { css } from '@emotion/react';
import Text from '@dotpict-lib/foundation/typography/Text';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import TranslationKeys from '@/app/translation/TranslationKeys';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTranslator from '@/app/hook/useTranslator';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';
import TextFieldComponent from '@/app/component/TextFieldComponent';
import Pixelart from '@dotpict-lib/component/core/Pixelart';
import usePage from '@/app/component/page/requestbox/send/usePage';
import { UserType } from '@dotpict-lib/model/User/UserType';
import { Row } from '@dotpict-lib/component/core/layout/Row';
import Column from '@dotpict-lib/component/core/layout/Column';
import { HorizontalGravity } from '@dotpict-lib/component/core/layout/HorizontalGravity';
import Clickable from '@dotpict-lib/component/core/Clickable';
import Button from '@dotpict-lib/component/dotpict/Button';

const ModalInnerStyle = css`
  background-color: white;
  margin-left: auto;
  margin-right: auto;

  padding: 32px 0 16px 0;

  /* モバイルは幅いっぱいまで */
  @media screen and (max-width: 680px) {
    padding: 20px;
  }
`;

export type RequestSendBindModel = {
  isOpenModal: boolean;
  profileImageUrl: string;
  requestBoxText: string;
  text: string;
};

export const InitialRequestSendBindModel: RequestSendBindModel = {
  isOpenModal: false,
  profileImageUrl: '',
  requestBoxText: '',
  text: '',
};

type Props = {
  user: UserType;
  children: React.ReactNode;
};

const RequestSend = ({ user, children }: Props) => {
  const fullScreen = useMediaQuery('(max-width: 680px)');
  const translator = useTranslator();

  const { bindModel, handleClickOpen, handleClickClose, onChangeText, handleClickSave } = usePage({
    user,
  });

  const renderDialogContent = () => (
    <Row>
      <Spacer width={24} />
      <Column width="100%" horizontalGravity={HorizontalGravity.center}>
        <Text
          text={translator(TranslationKeys.RequestBoxSend)}
          textStyle={TextStyle.BOLD20}
          colorStyle={ColorStyle.TEXT_PRIMARY}
        />
        <Spacer height={20} />
        <Row>
          <Pixelart alt="profileImageUrl" src={bindModel.profileImageUrl} width={48} height={48} />
          <Spacer width={12} />
          <Text
            text={bindModel.requestBoxText}
            textStyle={TextStyle.REGULAR12}
            colorStyle={ColorStyle.TEXT_PRIMARY}
            flex={1}
          />
        </Row>
        <Spacer height={26} />
        <Column width="100%">
          <TextFieldComponent
            id="text"
            placeholder={translator(TranslationKeys.RequestBoxSendHint)}
            value={bindModel.text}
            onChangeValue={(text) => onChangeText(text)}
            multiline
            showMaxLength
            maxLength={500}
            rows={4}
          />
        </Column>
        <Spacer height={40} />
        <Button
          width={240}
          height={52}
          text={translator(TranslationKeys.RequestSend)}
          isFilled
          handleClick={() => handleClickSave()}
        />
        <Spacer height={8} />
        <Button
          width={240}
          height={52}
          text={translator(TranslationKeys.Cancel)}
          isFilled={false}
          handleClick={() => handleClickClose()}
        />
      </Column>
      <Spacer width={24} />
    </Row>
  );

  return (
    <>
      <Clickable onClick={() => handleClickOpen()}>{children}</Clickable>
      <Dialog
        maxWidth="sm"
        fullWidth
        fullScreen={fullScreen}
        open={bindModel.isOpenModal}
        onClose={() => handleClickClose()}
        scroll="body"
      >
        <div css={ModalInnerStyle}>{renderDialogContent()}</div>
      </Dialog>
    </>
  );
};

export default RequestSend;
