import { useState, useEffect, createContext } from 'react';
import { useCurrentCanvasData } from '@/app/component/page/draw/hooks/storage';
import { CanvasHistoryType } from '@/app/component/page/draw/hooks/types';

type History = {
  pointer: number;
  canvasHistories: CanvasHistoryType[];
};

const initialState = { pointer: 0, canvasHistories: [] };

const HistoryLimit = 100;

export const HistoryContext = createContext<History>(initialState);

export const useHistory = () => {
  const [canvasData, setStorageData] = useCurrentCanvasData();
  const [canvasHistories, setCanvasHistories] = useState<CanvasHistoryType[]>([]);
  const [pointer, setPointer] = useState<number>(-1);

  const pushHistory = (history: CanvasHistoryType) => {
    const currentHistories = canvasHistories.slice(pointer, HistoryLimit);
    const newCanvasHistories = [history, ...currentHistories].slice(0, HistoryLimit);

    setCanvasHistories(newCanvasHistories);

    if (pointer !== 0) {
      setPointer(0);
    }
  };

  const redo = () => {
    if (pointer > 0) {
      setPointer(pointer - 1);
    }
  };

  const undo = () => {
    if (pointer < HistoryLimit - 1 && pointer < canvasHistories.length - 1) {
      setPointer(pointer + 1);
    }
  };

  // 新たなHistoryが追加されるか、Redo/Undoを行ったときにLocalStorageに上書きする
  useEffect(() => {
    if (canvasHistories[pointer] !== undefined) {
      setStorageData(
        canvasHistories[pointer].canvasSize,
        canvasHistories[pointer].pixels,
        canvasHistories[pointer].palette,
      );
    }
  }, [canvasHistories, pointer]);

  // ページ初期化時にLocalStorageに前に描いたキャンバスのデータがあれば復元する
  useEffect(() => {
    if (canvasData !== null && canvasHistories.length === 0) {
      const { pixels, canvasSize, palette } = canvasData;

      const newHistory = {
        pixels,
        canvasSize,
        palette,
      };

      setCanvasHistories([newHistory]);
      setPointer(0);
    }
  }, [canvasData]);

  const history = { pointer, canvasHistories };

  return [history, pushHistory, redo, undo] as [
    typeof history,
    typeof pushHistory,
    typeof redo,
    typeof undo,
  ];
};
