import { defaultWorkThread, WorkThreadType } from '@/app/model/WorkThreadType';
import emptyWork, { WorkType } from '../model/WorkType';

export type WorkRepliesState = {
  work: WorkType;
  parentThread: WorkThreadType;
  threads: WorkThreadType[];
};

export const defaultWorkRepliesState: WorkRepliesState = {
  work: emptyWork,
  parentThread: defaultWorkThread,
  threads: [],
};
