import { useCallback } from 'react';
import styled from '@emotion/styled';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import Text from '@dotpict-lib/foundation/typography/Text';
import Pixelart from '@dotpict-lib/component/core/Pixelart';
import useTranslator from '@/app/hook/useTranslator';
import TranslationKeys from '@/app/translation/TranslationKeys';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  // ボーダー分引く
  width: ${128 - 4}px;
  height: ${128 - 4}px;
  border: 2px solid ${ColorStyle.PRIMARY};
  background: ${ColorStyle.WHITE};
  cursor: pointer;
`;

const TextContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  grid-gap: 8px;
`;

type Props = {
  setOpenNewCanvas: (open: boolean) => void;
};

const DrawCanvas = ({ setOpenNewCanvas }: Props) => {
  const translator = useTranslator();

  const handleClick = useCallback(() => {
    setOpenNewCanvas(true);
  }, []);

  return (
    <Container onClick={handleClick}>
      <TextContainer>
        <Pixelart
          alt="add"
          src="https://storage.googleapis.com/dotpict-images/web/ic_add_canvas.svg"
          width={12}
          height={12}
        />

        <Text
          text={translator(TranslationKeys.NewCanvas)}
          textStyle={TextStyle.REGULAR14}
          colorStyle={ColorStyle.PRIMARY}
        />
      </TextContainer>
    </Container>
  );
};

export default DrawCanvas;
