import Text from '@dotpict-lib/foundation/typography/Text';
import TranslationKeys from '@/app/translation/TranslationKeys';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';
import PageControl from '@dotpict-lib/component/core/PageControl';
import Button from '@dotpict-lib/component/dotpict/Button';
import useTranslator from '@/app/hook/useTranslator';
import { useState } from 'react';
import TutorialItem, {
  TutorialItemProps,
} from '@/app/component/partial/work/upload/tutorial/TutorialItem';
import Column from '@dotpict-lib/component/core/layout/Column';
import { HorizontalGravity } from '@dotpict-lib/component/core/layout/HorizontalGravity';
import { Row } from '@dotpict-lib/component/core/layout/Row';

export type TutorialItemsProps = {
  handleEnd: () => void;
};

const TutorialItems = ({ handleEnd }: TutorialItemsProps) => {
  const translator = useTranslator();
  const [activeStep, setActiveStep] = useState(0);

  const tutorialItems: TutorialItemProps[] = [
    {
      imageUrl: 'https://storage.googleapis.com/dotpict-images/web/post-tutorial/tutorial01@2x.png',
      text: translator(TranslationKeys.PostTutorial1),
    },
    {
      imageUrl: 'https://storage.googleapis.com/dotpict-images/web/post-tutorial/tutorial02@2x.png',
      text: translator(TranslationKeys.PostTutorial2),
    },
    {
      imageUrl: 'https://storage.googleapis.com/dotpict-images/web/post-tutorial/tutorial03@2x.png',
      text: translator(TranslationKeys.PostTutorial3),
    },
    {
      imageUrl: 'https://storage.googleapis.com/dotpict-images/web/post-tutorial/tutorial04@2x.png',
      text: translator(TranslationKeys.PostTutorial4),
    },
    {
      imageUrl: 'https://storage.googleapis.com/dotpict-images/web/post-tutorial/tutorial05@2x.png',
      text: translator(TranslationKeys.PostTutorial5),
    },
  ];

  const handleNext = () => {
    if (activeStep === tutorialItems.length - 1) {
      handleEnd();
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 0) return;
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Column horizontalGravity={HorizontalGravity.center}>
      <Text
        text={translator(TranslationKeys.PostTutorialTitle)}
        textStyle={TextStyle.BOLD20}
        colorStyle={ColorStyle.TEXT_PRIMARY}
      />
      <Text
        text={translator(TranslationKeys.PostTutorialTitle2)}
        textStyle={TextStyle.BOLD20}
        colorStyle={ColorStyle.TEXT_PRIMARY}
      />
      <Spacer height={24} />
      <Row>
        <Spacer width={60} />
        <TutorialItem
          imageUrl={tutorialItems[activeStep].imageUrl}
          text={tutorialItems[activeStep].text}
        />
        <Spacer width={60} />
      </Row>
      <Spacer height={42} />
      <PageControl selectedIndex={activeStep} numberOfItems={tutorialItems.length} />
      <Spacer height={38} />
      <Button
        width={240}
        height={52}
        text={translator(
          activeStep < tutorialItems.length - 1
            ? TranslationKeys.Next
            : TranslationKeys.AgreeTheGuideline,
        )}
        isFilled={activeStep === tutorialItems.length - 1}
        isBorder
        handleClick={handleNext}
      />
      {activeStep > 0 && (
        <Button
          width={240}
          height={52}
          text={translator(TranslationKeys.Back)}
          isFilled={false}
          handleClick={handleBack}
        />
      )}
    </Column>
  );
};

export default TutorialItems;
