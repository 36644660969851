import { Dependencies, LoginContext } from '@/app/component/page/me/login/useLogin';
import { useContext, useMemo } from 'react';
import { APIContext } from '@/Routing';
import dotpictLoginService from '@/app/service/LoginService/dotpictLoginService';
import Login from '@/app/component/page/me/login/Login';

const LoginPage = () => {
  const { client } = useContext(APIContext);

  const dependencies = useMemo((): Dependencies => {
    return {
      loginService: dotpictLoginService,
    };
  }, [client]);

  return (
    <LoginContext.Provider value={dependencies}>
      <Login />
    </LoginContext.Provider>
  );
};

export default LoginPage;
