import EventColorCode from '@/app/component/partial/event/colorcode/EventColorCode';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';
import Text from '@dotpict-lib/foundation/typography/Text';
import TranslationKeys from '@/app/translation/TranslationKeys';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import useTranslator from '@/app/hook/useTranslator';
import Button from '@dotpict-lib/component/dotpict/Button';
import useDownloadPalette from '@/app/component/page/event/user/detail/useDownloadPalette';
import Column from '@dotpict-lib/component/core/layout/Column';
import { Grid } from '@dotpict-lib/component/core/layout/Grid';
import { Row } from '@dotpict-lib/component/core/layout/Row';

export type EventColorCodesProps = {
  paletteName: string;
  colors: string[];
};

const EventColorCodes = ({ paletteName, colors }: EventColorCodesProps) => {
  const translator = useTranslator();
  const downloadPaletteImage = useDownloadPalette({ paletteName, colors });

  return (
    <Column>
      <Text
        text={translator(TranslationKeys.EventColorCode)}
        textStyle={TextStyle.BOLD16}
        colorStyle={ColorStyle.TEXT_PRIMARY}
      />
      <Spacer height={16} />
      <Grid>
        {colors.map((color, index) => (
          <Column key={color}>
            <Spacer height={16} />
            <Row>
              <EventColorCode color={color} />
              {index !== colors.length - 1 && <Spacer width={20} />}
            </Row>
          </Column>
        ))}
      </Grid>
      <Spacer height={20} />
      <Button
        width={280}
        height={40}
        text={translator(TranslationKeys.EventDownloadColorCodes)}
        isBorder
        isFilled={false}
        handleClick={() => downloadPaletteImage()}
      />
    </Column>
  );
};

export default EventColorCodes;
