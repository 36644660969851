import { OfficialEventType } from '@dotpict-sns-lib/model/OfficialEvent/OfficialEventType';
import { defaultBattleInfo } from '@dotpict-sns-lib/model/OfficialEvent/BattleInfoType';
import { convertArrayToBattleInfo } from '@dotpict-sns-lib/model/OfficialEvent/convertArrayToBattleInfo';
import { ColorCodeType } from '@dotpict-lib/model/ColorCode/ColorCodeType';
import rgbToHex from '@dotpict-lib/util/rgbToHex';
import { convertUnixTimeToDateString } from '@dotpict-lib/model/Date/convertUnixTimeToDateString';

export const convertArrayToOfficialEvent = (json: any): OfficialEventType => ({
  id: json.id,
  imageUrl: json.image_url,
  layerImageUrl0: json.layer_image_url0,
  layerImageUrl1: json.layer_image_url1,
  layerImageUrl2: json.layer_image_url2,
  activeLayerIndex: json.active_layer_index,
  colorCodes: json.color_codes.map((colorCode: ColorCodeType) =>
    rgbToHex(colorCode.red, colorCode.green, colorCode.blue),
  ),
  title: json.title,
  canvasTitle: json.canvas_title,
  tag: json.tag,
  backgroundColorCode: rgbToHex(
    json.background_color_code.red,
    json.background_color_code.green,
    json.background_color_code.blue,
  ),
  startAt: convertUnixTimeToDateString(json.start_at),
  endAt: convertUnixTimeToDateString(json.end_at),
  isBattleEvent: json.battle_info !== null,
  battleInfo:
    json.battle_info === null ? defaultBattleInfo : convertArrayToBattleInfo(json.battle_info),
  createdAt: convertUnixTimeToDateString(json.created_at),
  bannerImageUrl: json.banner_image_url,
  shareText: json.share_text,
  shareUrl: json.share_url,
});
