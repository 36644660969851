import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import Text from '@dotpict-lib/foundation/typography/Text';
import useTranslator from '@/app/hook/useTranslator';
import TranslationKeys from '@/app/translation/TranslationKeys';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import Loading from '@dotpict-lib/component/core/Loading';
import { WorkThreadType } from '@/app/model/WorkThreadType';
import WorkThread from '@/app/component/page/work/thread/item/WorkThread';
import { WorkThreadInput } from '@/app/component/page/work/thread/input/WorkThreadInput';
import { WorkType } from '@/app/model/WorkType';
import Column from '@dotpict-lib/component/core/layout/Column';
import { useWorkReplies } from '@/app/component/page/work/thread/replies/useWorkReplies';
import { HorizontalGravity } from '@dotpict-lib/component/core/layout/HorizontalGravity';
import End from '@dotpict-lib/component/core/End';

export type WorkRepliesViewProps = {
  work: WorkType;
  parentThread: WorkThreadType;
  threads: WorkThreadType[];
  isLoading: boolean;
};

export const WorkReplies = () => {
  const translator = useTranslator();
  const { work, parentThread, threads, isLoading } = useWorkReplies();

  return (
    <Column verticalPadding={32} innerPadding={32}>
      <Text
        text={translator(TranslationKeys.Replies)}
        textStyle={TextStyle.BOLD20}
        colorStyle={ColorStyle.TEXT_PRIMARY}
      />
      <WorkThread isReply={false} isVisibleFooter={false} thread={parentThread} />
      {threads.length !== 0 && (
        <Column innerPadding={8}>
          {threads.map((thread) => (
            <WorkThread key={thread.id} isReply isVisibleFooter thread={thread} />
          ))}
        </Column>
      )}
      {isLoading && <Loading />}
      {!isLoading && threads.length === 0 && (
        <Column width="100%" horizontalGravity={HorizontalGravity.center}>
          <Text
            text={translator(TranslationKeys.NoReplies)}
            textStyle={TextStyle.REGULAR14}
            colorStyle={ColorStyle.TEXT_PRIMARY}
          />
        </Column>
      )}
      <WorkThreadInput isReply workId={work.id} parentThreadId={parentThread.id} />
      {!isLoading && <End />}
    </Column>
  );
};
