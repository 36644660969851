import { NotificationType } from '@/app/model/NotificationType';

export type INotificationsState = {
  notifications: NotificationType[];
  nextUrl: string | null;
  requestedUserId: number;
};

export const defaultNotificationsState: INotificationsState = {
  notifications: [],
  nextUrl: null,
  requestedUserId: 0,
};
