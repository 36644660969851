import { WorkType } from '../model/WorkType';

export interface ISearchWorksState {
  type: string | null;
  query: string;
  works: WorkType[];
  nextUrl: string | null;
  requestedUserId: number;
}

export const defaultSearchWorksState: ISearchWorksState = {
  type: null,
  query: '',
  works: [],
  nextUrl: null,
  requestedUserId: 0,
};
