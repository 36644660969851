import { WorkThreadsResponseType } from '@/app/model/responsetype/WorkThreadsResponseType';
import emptyWork from '@/app/model/WorkType';
import { defaultWorkThread } from '@/app/model/WorkThreadType';

export type GetWorkThreadsService = () => Promise<WorkThreadsResponseType>;

export const dummyGetWorkThreadsService: GetWorkThreadsService =
  (): Promise<WorkThreadsResponseType> => {
    return Promise.resolve({
      work: emptyWork,
      workThreads: [
        {
          ...defaultWorkThread,
          text: 'dummyText',
        },
      ],
    });
  };
