import { OfficialEventType } from '@dotpict-sns-lib/model/OfficialEvent/OfficialEventType';

export const TestOfficialEventDogVsCat: OfficialEventType = {
  id: 62,
  imageUrl: 'https://storage.googleapis.com/dotpict-images/official_events/Dog_vs_Cat_square.png',
  layerImageUrl0:
    'https://storage.googleapis.com/dotpict-images/official_events/transparent_64x64.png',
  layerImageUrl1:
    'https://storage.googleapis.com/dotpict-images/official_events/transparent_64x64.png',
  layerImageUrl2:
    'https://storage.googleapis.com/dotpict-images/official_events/balcony_layer2.png',
  activeLayerIndex: 0,
  backgroundColorCode: 'FFFFFF',
  bannerImageUrl:
    'https://storage.googleapis.com/dotpict-images/official_events/banner/Balcony_ja.png',
  battleInfo: {
    description:
      '「犬」か「猫」あなたはどちらが好き？それぞれのタグで投稿された作品数・いいね数の合計で勝敗を決めるイベントです！好きな方に参加しましょう！もちろん両方に参加してもOKです！',
    teamOne: {
      name: '犬',
      tag: 'Dog',
      point: 32706,
      colorCode: 'EDB175',
    },
    teamTwo: {
      name: '猫',
      tag: '',
      point: 46222,
      colorCode: '8AA5A7',
    },
  },
  canvasTitle: 'バルコニー',
  colorCodes: [
    '402A25',
    '693B11',
    '935300',
    'BE6D17',
    'D18334',
    'EDB175',
    '58A6DD',
    'A4EEFF',
    'FFFA9D',
    '575E5E',
    '717D7E',
    '8AA5A7',
    '9CBEC0',
    'FFA597',
    'FFE8D8',
    'FFFFFF',
  ],
  createdAt: '2022/08/28 00:00:00',
  endAt: '2022/09/11 23:59:59',
  isBattleEvent: true,
  shareText: '犬 or 猫 #dotpict_Dog_vs_Cat #dotpict',
  shareUrl: 'https://dotpict.net/officialEvents/Dog_vs_Cat',
  startAt: '2022/08/29 00:00:00',
  tag: 'Dog_vs_Cat',
  title: '犬 or 猫',
};
