import OfficialEventResult, {
  OfficialEventResultContext,
} from '@/app/component/page/event/official/result/OfficialEventResult';
import useOfficialEventResult from '@/app/component/page/event/official/result/useOfficialEventResult';

const OfficialEventResultPage = () => {
  const props = useOfficialEventResult();

  return (
    <OfficialEventResultContext.Provider value={props}>
      <OfficialEventResult />
    </OfficialEventResultContext.Provider>
  );
};

export default OfficialEventResultPage;
