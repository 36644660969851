import { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import Text from '@dotpict-lib/foundation/typography/Text';
import { useCanvasContext, usePaintCanvas } from '@/app/component/page/draw/hooks';
import { PixelsType } from '@/app/component/page/draw/hooks/types';
import PopupMenu from '@/app/component/page/draw/canvases/canvases/PopupMenu';
import DownloadModal from '@/app/component/page/draw/DownloadModal';
import { SizeType } from '@/app/component/page/draw/Context/SizeContext';
import Column from '@dotpict-lib/component/core/layout/Column';
import { VerticalGravity } from '@dotpict-lib/component/core/layout/VerticalGravity';

const Container = styled.div`
  display: grid;
  grid-template-rows: repeat(3, auto);
  grid-gap: 12px;
  justify-items: center;
`;

const CanvasLink = styled(Link)`
  width: 128px;
  height: 128px;
`;

const StyledCanvas = styled.canvas`
  background: #fff;
  box-sizing: border-box;
`;

const MenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TextContainer = styled.div`
  display: grid;
  grid-gap: 4px;
  grid-template-rows: auto auto;
`;

const Title = styled.div`
  width: 100px;
  text-align: left;

  // Textコンポーネントにスタイルを当てる
  div {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

type Props = {
  id: number;
  title: string;
  canvasSize: SizeType;
  pixels: PixelsType;
};

const CanvasItem = ({ id, title, canvasSize, pixels }: Props) => {
  const [paint] = usePaintCanvas();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [context] = useCanvasContext(canvasRef);
  const location = useLocation();
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (context === null || canvasRef.current === null) {
      return;
    }

    // 一時的なキャンバスに描画し、それを表示上のキャンバスにコピーする
    const temporaryCanvas = document.createElement('canvas');
    const temporaryContext = temporaryCanvas.getContext('2d')!;
    temporaryCanvas.height = canvasSize.height;
    temporaryCanvas.width = canvasSize.width;
    paint(temporaryContext, 1, 1, pixels);

    // ONのままだと拡大時にぼやけてしまうためfalseにする
    context.imageSmoothingEnabled = false;

    // レンダリングされてるcanvasの実際の高さと幅を取得
    const rect = canvasRef.current.getBoundingClientRect();

    // レンダリングされてるcanvasに画像コピー
    context.drawImage(temporaryCanvas, 0, 0, rect.width, rect.height);
  }, [context, canvasRef, location]);

  const handleClickDownload = useCallback(() => {
    setOpenModal((prevOpen) => !prevOpen);
  }, []);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  return (
    <Container>
      <CanvasLink to={`/draw?current=${id}`}>
        <Column width={128} height={128} verticalGravity={VerticalGravity.center}>
          <StyledCanvas
            ref={canvasRef}
            width={
              canvasSize.width > canvasSize.height
                ? 128
                : (128 * canvasSize.width) / canvasSize.height
            }
            height={
              canvasSize.height > canvasSize.width
                ? 128
                : (128 * canvasSize.height) / canvasSize.width
            }
          />
        </Column>
      </CanvasLink>
      <MenuContainer>
        <TextContainer>
          <Title>
            <Text
              text={title}
              textStyle={TextStyle.REGULAR12}
              colorStyle={ColorStyle.TEXT_PRIMARY}
            />
          </Title>
          <Text
            text={`${canvasSize.width}x${canvasSize.height}`}
            textStyle={TextStyle.REGULAR12}
            colorStyle={ColorStyle.TEXT_SECONDARY}
          />
        </TextContainer>
        <PopupMenu id={id} onClickDownload={handleClickDownload} />
      </MenuContainer>
      <DownloadModal
        open={openModal}
        onCloseModal={handleCloseModal}
        canvasRef={canvasRef}
        canvasSize={canvasSize}
        title={title}
        pixels={pixels}
      />
    </Container>
  );
};

export default CanvasItem;
