import { useNavigate } from 'react-router-dom';
import Text from '@dotpict-lib/foundation/typography/Text';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import Pixelart from '@dotpict-lib/component/core/Pixelart';
import { Row } from '@dotpict-lib/component/core/layout/Row';
import { VerticalGravity } from '@dotpict-lib/component/core/layout/VerticalGravity';
import { HorizontalGravity } from '@dotpict-lib/component/core/layout/HorizontalGravity';
import { FollowButton } from '@/app/component/partial/user/followbutton/FollowButton';
import Column from '@dotpict-lib/component/core/layout/Column';
import Clickable from '@dotpict-lib/component/core/Clickable';
import useTranslator from '@/app/hook/useTranslator';
import TranslationKeys from '@/app/translation/TranslationKeys';

export type UserProps = {
  userId: number;
  name: string;
  // eslint-disable-next-line react/no-unused-prop-types
  text: string;
  profileImageUrl: string;
  isFollowed: boolean;
  isFollower: boolean;
  isVisibleFollowInfo: boolean;
};

const User = ({
  userId,
  name,
  profileImageUrl,
  isFollowed,
  isFollower,
  isVisibleFollowInfo,
}: UserProps) => {
  const navigator = useNavigate();
  const translator = useTranslator();

  const onClick = () => {
    navigator(`/users/${userId}`);
  };

  return (
    <Row
      width="100%"
      horizontalGravity={HorizontalGravity.spaceBetween}
      verticalGravity={VerticalGravity.center}
    >
      <Clickable onClick={onClick}>
        <Row verticalGravity={VerticalGravity.center} innerPadding={8}>
          <Pixelart alt={name} src={profileImageUrl} width={64} height={64} />
          <Column horizontalGravity={HorizontalGravity.start} innerPadding={6}>
            <Text text={name} textStyle={TextStyle.BOLD12} colorStyle={ColorStyle.TEXT_PRIMARY} />
            {isVisibleFollowInfo && isFollower && (
              <Row horizontalPadding={6} verticalPadding={2} background={ColorStyle.MONO20}>
                <Text
                  text={translator(TranslationKeys.IsFollower)}
                  textStyle={TextStyle.REGULAR9}
                  colorStyle={ColorStyle.MONO50}
                  maxLines={1}
                />
              </Row>
            )}
          </Column>
        </Row>
      </Clickable>
      {isVisibleFollowInfo && <FollowButton userId={userId} isFollowed={isFollowed} />}
    </Row>
  );
};

export default User;
