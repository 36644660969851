import useTranslator from '@/app/hook/useTranslator';
import TranslationKeys from '@/app/translation/TranslationKeys';
import Column from '@dotpict-lib/component/core/layout/Column';
import { HorizontalGravity } from '@dotpict-lib/component/core/layout/HorizontalGravity';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';
import TextFieldComponent from '@/app/component/TextFieldComponent';
import Button from '@dotpict-lib/component/dotpict/Button';
import Text from '@dotpict-lib/foundation/typography/Text';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import Loading from '@dotpict-lib/component/core/Loading';
import { useFeedback } from '@/app/component/page/feedback/useFeedback';

export type FeedbackViewProps = {
  message: string;
  isLoading: boolean;
  feedbackSucceeded: boolean;
  onChangeMessageChanged: (newMessage: string) => void;
  onClickSend: () => void;
};

export const Feedback = () => {
  const translator = useTranslator();
  const { message, isLoading, feedbackSucceeded, onChangeMessageChanged, onClickSend } =
    useFeedback();

  const renderContent = () => {
    if (feedbackSucceeded) {
      return (
        <Text
          text={translator(TranslationKeys.FeedbackSucceeded)}
          textStyle={TextStyle.REGULAR10}
          colorStyle={ColorStyle.TEXT_SECONDARY}
        />
      );
    }

    return (
      <>
        <TextFieldComponent
          id="message"
          label={translator(TranslationKeys.Feedback)}
          placeholder={translator(TranslationKeys.FeedbackSummaryPlaceholder)}
          value={message}
          onChangeValue={onChangeMessageChanged}
          multiline
          showMaxLength
          maxLength={3000}
          rows={10}
        />
        <Spacer height={8} />
        <Text
          text={translator(TranslationKeys.FeedbackSummary)}
          textStyle={TextStyle.REGULAR10}
          colorStyle={ColorStyle.TEXT_SECONDARY}
        />
        <Spacer height={24} />
        <Column horizontalGravity={HorizontalGravity.center}>
          {isLoading ? (
            <Loading />
          ) : (
            <Button
              width={240}
              height={52}
              text={translator(TranslationKeys.Send)}
              isFilled
              handleClick={onClickSend}
            />
          )}
        </Column>
      </>
    );
  };

  return (
    <Column verticalPadding={32}>
      <Column padding={24} background={ColorStyle.MONO00}>
        {renderContent()}
      </Column>
    </Column>
  );
};
