const ja: {[key: string]: string} = {
  Empty: ' ',
  Comment: 'コメント',
  Send: '送信',
  Ads: '広告',
  Tweet: 'ツイートする',
  Share: 'シェアする',
  CommentHint: 'コメントする...',
  ReplyHint: '返信する...',
  CanNotPostThreads: 'この作品にはコメントができません',
  NoThreads: 'コメントがありません',
  NoReplies: '返信がありません',
  Follow: 'フォロー',
  Followed: 'フォロー中',
  Follower: 'フォロワー',
  PickUpWorks: 'ピックアップ',
  LoadMore: 'もっとみる',
  NewestWorks: '新着',
  PickupWorks: '注目',
  FollowingWorks: 'フォロー',
  Guidelines: 'ガイドライン',
  Title: 'タイトル',
  Tag: 'タグ',
  User: 'ユーザー',
  Email: 'メールアドレス',
  Password: 'パスワード',
  Login: 'ログイン',
  SignUpFromApp: '新規登録はアプリから！',
  Search: '検索',
  Cancel: 'キャンセル',
  MyPage: 'マイページ',
  Logout: 'ログアウト',
  LikeCount: 'いいね',
  PostedWorks: '投稿作品',
  LikedWorks: 'いいね',
  NotFoundTitle: 'お探ししたページが見つかりません',
  NotFoundMessage1: 'お探しのページは一時的にアクセスできない状況か、移動または削除された可能性があります。',
  NotFoundMessage2: 'URL、ファイル名に間違いがないか再度ご確認ください。',
  NotFoundGoToTop: 'トップページ',
  DrawPixelartFromApp: 'アプリでドット絵を描こう',
  Company: '運営会社',
  SignUp: '新規登録',
  SignUpSubmit: '登録する',
  SignUpPassword: 'パスワード',
  AlreadyHaveAccount: 'すでにアカウントを持っている方',
  Edit: '編集',
  EditProfile: 'マイページ編集',
  ChangeImage: '画像を変更',
  UserName: 'ユーザー名',
  UserNamePlaceholder: 'ユーザー名を入力してください',
  Text: '紹介文',
  TextPlaceholder: '紹介文を入力してください',
  WebSite: 'SNS / webサイト',
  WebSitePlaceholder: 'URLを入力してください',
  Save: '保存',
  AccountName: 'アカウント名',
  AccountNamePlaceholder: 'アルファベット、アンダースコア、3~32文字',
  Next: '次へ',
  PostWork: '投稿する',
  ViewGuideline: 'ガイドラインを確認する',
  Caption: 'キャプション',
  CaptionHint: '作品について説明してください',
  AllowComments: 'コメントを許可する',
  SizeLimit: 'PNGまたはGIF\n1024x1024、4MBまで',
  AddFile: 'ドット絵を追加',
  OtherSettings: 'その他設定',
  PostTutorialTitle: 'ガイドラインに同意後',
  PostTutorialTitle2: '投稿できるようになります',
  PostTutorial1: '誰かがつくった作品やゲーム、\nアニメなどのコピーは投稿できません',
  PostTutorial2: '下着、胸、おしり、性器の強調や露出\n性行為を連想させる作品は投稿できません',
  PostTutorial3: '多量出血、切断された人体など\nグロテスクな作品は投稿できません',
  PostTutorial4: '作品投稿目的でない文字のみの投稿\n宣伝、コメント欄への誘導\n誹謗中傷は禁止です',
  PostTutorial5: '作品投稿目的でない文字を含む投稿は禁止です。',
  AgreeTheGuideline: 'ガイドラインに同意する',
  Back: 'もどる',
  Post: '投稿する',
  PostSectionTitle: 'ドット絵を投稿する',
  PostCautionTitle: 'ドット絵以外の投稿は禁止です',
  PostCautionSummary: 'ここはドット絵好きのためのコミュニティです。ドット絵以外を投稿した場合は、アカウントを停止させていただきます。',
  PostCautionSummary2: 'また、卑猥な作品、グロテスクな作品、他人の作品、文字を含む迷惑行為などは見つけ次第削除します。必ずガイドラインを一読ください。',
  PostCautionGuidelines: '詳しいガイドラインについて',
  PostCautionExample: 'どんなドット絵を投稿すればいい？',
  PostCautionExampleDetail: 'マウスオーバーすると各絵について詳細な説明が表示されます。',
  PostCautionExampleDetail2: '※各絵の定義はdotpict内におけるものであり、広義に定められたものではありません。',
  Pixelart: 'ピクセルアート',
  Binaryart: 'バイナリアート',
  Convert: 'ドット絵変換',
  Oekaki: 'お絵かき',
  Picture: '写真',
  PostCautionPixelart: 'dotpictやAsepriteなどのドットペンを使って描いた絵。1~数ピクセル単位で描く作業を繰り返し作成する。かつて色が制限された環境で誕生した表現方法であるため、必要以上に色を使わないことが多い。',
  PostCautionBinaryart: 'dotpictやAsepriteなどのドットペン（2値ペン）を使って描いた絵。お絵かきのように描きつつも、ドット単位での表現を残している作品。',
  PostCautionConvert: '写真やお絵かきをドット絵に変換するツールを使用したものを投稿することはできません。投稿した場合はアカウント停止の対象になります。',
  PostCautionOekaki: 'お絵かきツールなどのブラシやペンを使って作られた絵。投稿した場合はアカウント停止の対象になります。',
  PostCautionPicture: 'ドット絵を撮影したものでも写真はダメです。投稿した場合はアカウント停止の対象になります。',
  MaxTags: 'タグは3つまでです',
  ErrorImageSizeTooLarge: '画像サイズが大きすぎます。1024x1024までです。',
  UnknownError: 'エラーが発生しました',
  Notifications: '通知',
  AboutDotpict: 'dotpictとは',
  AboutDotpictText1: 'ドット絵の投稿や閲覧が楽しめるWebサービスです',
  AboutDotpictText2: '＼ドット絵を描いてみたい方は／',
  AboutDotpictText3: '初心者向けのアプリがあるので、ぜひチャレンジしてみてください！',
  RequestBox: 'リクエストボックス',
  RequestBoxSettings: 'リクエストボックス設定',
  RequestBoxEnabled: 'リクエストを受け付ける',
  RequestBoxSummaryTitle: 'あなたのリクエストボックスの説明文',
  RequestBoxSummaryText: 'あなたのリクエスト投稿フォームに表示される説明文です。',
  RequestBoxSummaryHint: '描きたいときにマイペースに描きたいと思います！',
  RequestBoxSend: 'リクエストを送る',
  RequestBoxView: 'リクエストを見る',
  RequestReceived: '受け取ったリクエスト',
  RequestCompleted: '完了したリクエスト',
  RequestAll: 'リクエスト一覧',
  RequestSucceeded: 'リクエストを送信しました',
  RequestTextZero: 'リクエスト内容を入力してください',
  RequestTextOver: 'リクエスト内容は500文字までです',
  RequestDeleteTitle: 'リクエストを削除',
  RequestDeleteMessage: 'このリクエストを削除しますか？この操作は取り消せません。',
  RequestDeleted: 'リクエストを削除しました。',
  RequestBoxSendHint: 'リクエスト内容を入力',
  RequestNotifications: 'リクエスト通知',
  RequestSend: '送信',
  Replies: '返信一覧',
  Threads: 'コメント一覧',
  ViewReply: '{value}件の返信をみる',
  SearchResult: '「{value}」の検索結果',
  MoveToWork: '作品にもどる',
  UserEventDescription1: 'ユーザー企画の参加方法',
  UserEventDescription2: '1. 企画テンプレートをダウンロード',
  UserEventDescription3: '2. 企画カラーコードの色を使ってドット絵を描く',
  UserEventDescription4: '3. 完成したら「この企画の作品として投稿」してみましょう！',
  EventTemplate: '企画テンプレート',
  EventColorCode: '企画カラーコード',
  EventDownloadTemplate: 'テンプレートをダウンロード',
  EventDownloadColorCodes: 'カラーコードを画像でダウンロード',
  EventPostTitle: 'この企画の作品として投稿する',
  ParticipatingWorks: '参加作品',
  JoiningEvent: '参加中の企画',
  RegistrationDate: '登録日 : ',
  Birthday: '誕生日 : ',
  HoldingPeriod: '開催期間 :',
  BattleEventResult: '＼🎉結果発表🎉／',
  CurrentScoreRate: '＼ 現在の得点率 ／',
  WhichJoin: '＼ どちらのチームで参加する？／',
  BattleEventPointRule1: '作品投稿で+10ポイント！',
  BattleEventPointRule2: 'いいねで+1ポイント！',
  FailedLoginError: 'ログインに失敗しました',
  IncorrectPasswordError: 'パスワードが無効です',
  AddMyPageHeaderImage: '+ ヘッダー画像を追加',
  ChangeMyPageHeaderImage: 'ヘッダー画像を変更',
  MyPageHeaderSettingTitle: 'ヘッダー画像が設定されていません',
  MyPageHeaderNote: '画像の推奨比率は 3:1 です。PNGまたはGIF 1024x1024まで',
  ViewImageGuideline: '画像のガイドラインを確認する',
  Point: 'pt',
  JoinTeam: '{value}チームに参加する',
  EventResult: '＼ 結果発表 ／',
  ThumbnailAlt: '作品のサムネイル画像',
  JoinEvent: '参加する',
  HowToPost: '投稿方法',
  Download: 'ダウンロード',
  SelectImage: '画像を選択する',
  DownloadTemplate: '企画のテンプレートをダウンロードしよう',
  UsePalette: 'カラーパレットを使ってみよう！',
  EnableUseOtherColors: 'もちろんパレット以外の色を自由に使ってOK',
  HowToUsePaletteInAseprite: 'Asepriteでのパレットの設定方法',
  HideColorCodes: 'カラーコードを隠す',
  ShowColorCodes: 'カラーコードを表示',
  PostToDotpict: '作品が完成したらdotpictに投稿！',
  NewCanvas: '新規作成',
  MyCanvases: 'キャンバス（α）',
  DefaultCanvasTitle: 'キャンバス',
  DeleteCanvas: '削除する',
  SaveCanvas: '画像を保存する',
  PostCanvas: '投稿する',
  ConfirmDeleteCanvas: '本当に削除してもよろしいですか？',
  BackToCanvases: 'キャンバス一覧に戻る',
  Pen: 'ペン [P]',
  Eraser: '消しゴム [E]',
  Bucket: 'バケツ [B]',
  Line: '直線 [L]',
  Move: '全体移動 [M]',
  Undo: '戻る [Z]',
  Redo: '進む [Y]',
  ClearAll: '全消し',
  SelectSaveSize: '書き出すサイズを選択してください',
  SaveSize: '{scale}（書き出しサイズ：{size}）',
  SelectSize: 'サイズを選択',
  SelectPalette: 'パレットを選択',
  SelectPaletteDescription1: '色変更機能はただいま実装中です。',
  SelectPaletteDescription2: '選択したパレットはこのキャンバスでは固定になります。',
  Create: '作成する',
  TrialTitle: 'Web版のお絵かき機能はα版です',
  TrialMessage1: 'アプリ版と比べて機能は少なめです。機能が物足りないと思った方はアプリ版をインストールしてください。',
  TrialMessage2: '※キャンバスのデータはブラウザのローカルデータとして保存されています。アカウントには紐付いていないため、続きを描きたい場合は同じブラウザを使うようにしてください。ブラウザのローカルデータは永久に保存されることが保証されていません、またα版のためdotpictの仕様変更等により描いたデータは消える可能性があります。描いたドット絵は投稿したり保存するようにしてください。',
  TrialMessage3: '既知の不具合: マウス以外（ペンタブレットなど）の操作が不安定なことを確認しています。',
  PleaseLogin: 'ログインしてください',
  MustLoginToPost: '投稿するためにはログインが必要です。',
  SendFeedback: 'フィードバックを送る',
  Draw: '描く',
  Notice: 'お知らせ',
  Making: 'メイキング',
  MakingTitle: '{authorName}さんのドット絵メイキング',
  MakingShare: 'この記事をシェアする',
  MakingOgpTitle: '{authorName}さんのドット絵メイキング',
  MakingShareText: '{authorName}さんのドット絵メイキング「{theme}」 #dotpict',
  MakingLikeTitle: 'このメイキングは参考になりましたか？',
  MakingLikedTitle: 'フィードバックありがとうございます！',
  MakingLike: '参考になった',
  MakingTwitter: 'Twitter',
  ViewWorks: '作品を見る',
  MakingDescription: '{authorName}さんが普段どのようにドット絵を作っているのか、その過程をメイキングとしてまとめました！',
  AnotherMakings: '他のクリエイターのメイキング',
  TableOfContents: '目次',
  FailedToUpload: '投稿に失敗しました',
  EditColor: '色を変更',
  DrawPixelart: 'ドット絵を描く',
  ImageReduction: '画像の縮小',
  ImageReductionDetail: '写真やイラストを縮小したものを投稿することはできません。投稿した場合はアカウント停止の対象になります。',
  CropPosition: 'クロップ位置を修正',
  ResetCropPosition: 'クロップ位置をリセット',
  CropDecide: '確定する',
  ZoomRate: '拡大率',
  EditWorkInfo: '作品情報を編集',
  UpdateWorkInfo: '作品情報を更新',
  EditImage: '画像を修正する',
  DailyTheme1: '今日の',
  DailyTheme2: 'ピクセル',
  DailyThemeDescription1: '32ピクセルの中で',
  DailyThemeDescription2: 'お題を描いてね！',
  DailyTheme: '今日の32ピクセル',
  Update: '更新',
  Delete: '削除',
  DeleteWorkConfirmation: 'この作品を削除しますか？この操作は取り消せません。',
  DeletedWork: '作品を削除しました',
  Menu: 'メニュー',
  Language: '言語',
  Others: 'その他',
  Timeline: 'タイムライン',
  Everyone: '全員',
  MutualFollowOnly: '相互フォローのみ',
  FollowersOnly: 'フォロワーのみ',
  AccountRegistrationRequired: 'アカウント登録が必要です',
  NotePostUnavailableFromWeb: 'Web版からは現在ノートの投稿はご利用いただけません。アプリ版をご利用ください。',
  NotFound: 'コンテンツがありません',
  RecommendedWorks: '関連作品',
  IsFollower: 'フォローされています',
  Feedback: 'ご意見・ご要望',
  FeedbackSummary: '頂いたご意見は必ず目を通します！\nご意見への返信はできないことをご了承下さい。',
  FeedbackSummaryPlaceholder: 'ご意見・ご要望を入力して下さい',
  FeedbackSucceeded: '送信が完了しました。ご意見・ご要望ありがとうございました',
  PrivacyTitle: 'プライバシーポリシー',
  PrivacyText1: '1. 基本方針',
  PrivacyText2: 'dotpict（以下、「本サービス」）運営チーム（以下、「本運営」といいます。）は、個人情報の重要性を認識し、個人情報を保護することが社会的責務であると考え、個人情報に関する法令を遵守し、本運営が取扱う個人情報の取得、利用、管理を適正に行います。',
  PrivacyText3: '2. 適用範囲',
  PrivacyText4: '本プライバシーポリシーは、本サービスにおいて、利用者様（以下、敬称略）の個人情報もしくはそれに準ずる情報を取り扱う際に、本運営が遵守する方針を示したものです。',
  PrivacyText5: '3. 個人情報の取得と利用目的',
  PrivacyText6: '本運営は利用者から以下の情報をご提供いただく場合がございます。',
  PrivacyText7: '・本サービスのログインに使用するメールアドレスとパスワード',
  PrivacyText8: '・dotpict内でのユーザーの行動やデバイスデータ（データ分析、ターゲット広告や広告効果測定のため）',
  PrivacyText82: '・広告ID（最適な広告を配信するために利用）',
  PrivacyText9: 'また本運営は、利用者からご提供いただく情報を以下の目的の範囲内において、本サービスで利用します。お客様の同意なく目的外の利用に使用することはありません。',
  PrivacyText10: '・本サービスにログインするため',
  PrivacyText11: '・本サービスに関するご意見、お問い合わせ内容の確認・回答のため',
  PrivacyText12: '・本サービスの改善のため',
  PrivacyText13: '・最適な広告を配信するため',
  PrivacyText14: '4. 個人情報の管理',
  PrivacyText15: '本運営は、利用者から取得した情報の管理について、以下を徹底します。',
  PrivacyText16: '1). 情報の正確性の確保',
  PrivacyText17: '利用者から取得した情報については、常に正確かつ最新の情報となるよう努めます。',
  PrivacyText18: '2). 安全管理措置',
  PrivacyText19: '本運営は、個人情報の管理については、細心の注意を払い厳重に取扱うことを徹底しています。',
  PrivacyText20: '3). 保存期間と廃棄',
  PrivacyText21: '利用者からご提供いただいた情報については、本サービス終了した場合は廃棄します。また、不要となった場合にもすみやかに廃棄します。',
  PrivacyText22: '5. 第三者提供の有無',
  PrivacyText23: '本運営は、利用者の同意を得ることなく、第三者に個人情報を提供することはありません。但し、以下の場合はこの限りではありません。',
  PrivacyText24: '・法令により開示または提供が認められる場合',
  PrivacyText25: '6. 個人情報の開示・訂正・利用停止等',
  PrivacyText26: '開示、訂正、利用停止等のお申し出があった場合には、当社所定の方法に基づき対応致します。具体的な方法については、個別にご案内しますので、下記受付窓口までお問い合わせく ださい。',
  PrivacyText27: 'support@dotpict.zendesk.com ',
  PrivacyText28: '7. 改訂',
  PrivacyText29: '平成 30 年 10 月 6 日 改定',
  PrivacyText30: '平成 30 年 2 月 2 日 策定',
  GuidelineTitle: 'ガイドライン',
  GuidelineText1: 'ガイドラインはdotpictをドット絵好きのためのより良いコミュニティにするためのものです。必ず内容に目を通し、ガイドラインを守って楽しくアプリを使いましょう。',
  GuidelineText2: '🌸安心できるコンテンツを投稿しましょう🌸',
  GuidelineText3: '・dotpictは幅広い年齢層や国の方が利用しています。みんなが安心して見られるコンテンツを投稿するよう心がけてください。',
  GuidelineText4: '❎作品でない文字投稿、コメント欄への誘導❎',
  GuidelineText5: '・作品投稿目的でない文字を含む投稿は禁止です。',
  GuidelineText6: '・例えば「フォローして」「いいねして」「プロフィールを見て」「リクエスト募集」などの文字をドット絵作品に加え投稿することは、その投稿自体は作品投稿を目的としていないため禁止です。',
  GuidelineText7: '・作品に下矢印をつけコメント欄へ誘導することは禁止です。',
  GuidelineText8: '・例えば「暇だからお話しましょう」「お知らせ」「プロフィールを見て」「質問コーナー」なども作品投稿を目的としていない文字を含む投稿のため禁止です。',
  GuidelineText9: '❎複製を投稿❎',
  GuidelineText10: '・dotpictはあなたの作品を披露する場です。あなたの作品でないドット絵を投稿することや、ドット絵に変換するツールでドット絵にしたものを模写して投稿するのは禁止です。',
  GuidelineText11: '・ゲーム画像やアニメ画像を模写して投稿するのは禁止です。',
  GuidelineText12: '・他人が投稿した作品を自分の作品として投稿するのは禁止です。',
  GuidelineText13: '・ドット絵に変換するアプリなどで変換したドット絵を模写して投稿するのは禁止です。',
  GuidelineText14: '・たとえあなたが撮影した写真をドット絵に変換するアプリで変換しドット化したとしても、dotpictとしてはそれをあなたが制作した作品であると認めません。',
  GuidelineText15: '・ただし、ドット絵に変換するアプリを利用せず、あなたが撮影した風景を参考にしながらドット絵にし、そこにキャラクターを追加で描くなどはすばらしいドット絵です。',
  GuidelineText16: '❎ドット絵以外を投稿❎',
  GuidelineText17: '・128x128のキャンバスで棒人間、顔文字だけなどを描いて投稿するのは禁止です。',
  GuidelineText18: '・ここはドット絵好きのためのコミュニティです、ドット絵を投稿しましょう。',
  GuidelineText19: '・ドット絵の定義は幅広く、dotpictでは明確に定義することはしません。ただ明らかに不適切と思える大きいキャンバスでの棒人間や1ドットだけ打った作品などはdotpictのコミュニティにふさわしくないと考え削除します。',
  GuidelineText20: '❎連続投稿❎',
  GuidelineText21: '・類似した作品を連続して投稿するのは禁止です。',
  GuidelineText22: '❎個人情報を公開❎',
  GuidelineText23: '・LINEの連絡先やメールアドレスなどを交換するのは禁止です',
  GuidelineText24: '❎卑猥な作品の投稿❎',
  GuidelineText25: '・dotpictは卑猥な作品に対して非常に厳しく対応します。一度でも投稿すると警告なしでアカウントを停止する場合があります。',
  GuidelineText26: '・下着、胸、おしり、性器の強調や露出された作品を投稿するのは禁止です。',
  GuidelineText27: '・性行為自体、もしくは性行為を連想させる作品を投稿するのは禁止です。',
  GuidelineText28: '・排泄物を含む作品を投稿するのは禁止です。',
  GuidelineText29: '❎グロテスクな作品の投稿❎',
  GuidelineText30: '・人体を切断する行為、人体の欠損、多量の出血などグロテスクな作品を投稿するのは禁止です。',
  GuidelineText31: '🌸思いやりを持って接しましょう🌸',
  GuidelineText32: '・悪口や批判など他人をキズつけることはしないでください。',
  GuidelineText33: '・ガイドラインに違反している人がいたら、注意などのコメントをせず、運営に通報をお願いします。良かれと思ったコメントでも相手を傷つけたり、思わぬトラブルになる可能性があります。',
  GuidelineText34: '※以上に挙げていないコンテンツでも運営が問題があると判断した投稿は削除します。',
  GuidelineText35: 'ガイドラインに違反した作品やコメントなどは削除され、違反したユーザーには警告がされます。警告は他のユーザーの通報によるものではなく、運営が内容を検討した結果送ったものです。警告内容を確認し、繰り返し同じことをしないでください。',
  GuidelineText36: '繰り返し違反をした場合はアカウントを停止します。アカウントが停止されると二度とアカウントは復旧できません。',
  VerifiedAccountTitle: '公認ユーザーとは',
  VerifiedAccountText1: '公認ユーザーとは運営が独自の基準でユーザーに付与するステータスで、プロフィールにバッジが表示されます。',
  VerifiedAccountText2: '公認ユーザーは新機能への早期アクセスや限定機能が利用可能となり、作画画面の広告が非表示となります。（SNS画面の広告は通常のままです）',
  VerifiedAccountText3: '選出条件',
  VerifiedAccountText4: '以下の条件に当てはまると運営が判断したユーザーが対象となります。',
  VerifiedAccountText5: 'ドット絵のクリエイターとして他のユーザーに良い影響を与えている',
  VerifiedAccountText6: '創作性の高い作品を投稿している',
  VerifiedAccountText7: '継続条件',
  VerifiedAccountText8: '月に1回程度の投稿（目安であり必ずしもこの限りではありません）',
  SixTitle: 'dotpictは6周年を迎えました',
  SixText1: '10月27日はdotpictの誕生日です！',
  SixText2: 'dotpictはおかげさまで6周年を迎えることができました。日頃からdotpictをお使い・ご投稿いただいている皆様には感謝でいっぱいです。',
  SixText3: '7年目でdotpictは大きく進化する予定です。',
  SixText4: '今後dotpictに追加される機能の一部を紹介します！',
  SixText5: '※開発中のため一部変更がある可能性があります',
  SixText6: 'レイヤー機能を追加',
  SixText7: 'レイヤーは3枚固定です。',
  SixText8: 'レイヤーとは別に背景色が設定できます。',
  SixText9: 'パレットの色数が最大24色に',
  SixText10: 'パレットの色を2色〜24色まで設定できるようになります。',
  SixText11: '24色追加に伴い、多くの色から目的の色を選択しやすくするためにスポイト機能を追加します。',
  SixText12: 'ペンサイズ、消しゴムサイズを追加',
  SixText13: 'ペンは1px固定でしたが1px~10pxまで設定可能になります。',
  SixText14: 'ペンサイズが追加されたことで大きなドット絵を制作するスピードが格段にアップします。',
  SixText15: '大きなペンでざっくり描いて、削って形を整えるような描き方ができます。',
  SixText16: '自動選択ツールを追加',
  SixText17: 'バケツを塗るように選択することができます。（画像参照）',
  SixText18: '選択した部分の色と連続した色が選択されます。',
  SixText19: '一部分だけ移動したいときにすごく便利な機能です。',
  SixText20: '小さなサイズで作品を確認しやすくなります',
  SixText21: 'ドット絵を制作しながらワンタップで気軽に小さなサイズを確認しやすくなります。',
  SixText22: '今まで一覧画面に戻って小さなサムネイルを確認していた方も多かったと思うのでかなり扱いやすくなったと思います。',
  SixText23: 'ガイドのパターンが増えます',
  SixText24: 'ガイドに9分割が増えます。パースを意識した絵が描きやすくなります。',
  SixText25: 'ガイド色に白が増えます。暗めの絵のときに見づらかったガイドが見やすくなります。',
  SixText26: ' [iPadのみ] ドット絵制作画面が横画面に対応',
  SixText27: 'マイキャンバス一覧が見やすくなります',
  SixText28: 'マイキャンバス一覧がグリッド表示になり、1画面でより多くの作品を確認できるようになります。',
  SixText29: '※作成日、更新日はスペースの都合で作品を開いた先で確認する仕様に変更になります。',
  SixText30: '初心者への配慮',
  SixText31: 'これだけの機能を追加してもdotpictの使い心地は初心者の方にとって今までとほとんど変わりません。',
  SixText32: 'レイヤー、自動選択などの複雑なツールは初心者の方の多くは必要としません。そのため上級者設定というメニューを作り、追加される機能の多くが最初はOFFになっています。',
  SixText33: 'またお絵かき画面内に「お絵かきのヘルプ」を充実させツールについての解説を画像もしくは動画つきで行う予定です。',
  SixText34: '各ツールにはラベルがつき、ツールが多くても用途が何かわかりやすくなります。',
  SixText35: 'この他にもアニメーションなど多くの機能を開発中です！ぜひ今後のdotpictにご期待ください。',
  SixText36: 'dotpict運営',
  RequestboxTitle: 'リクエスト機能リリース！',
  RequestboxText1: 'リクエストを受け付けているユーザーにリクエストが送れる機能、リクエストボックスがリリースされました！',
  RequestboxText2: 'リクエストを受け付けている方のプロフィールページに行くと「リクエストを送る」ボタンが表示されています。',
  RequestboxText3: 'リクエストを受け付けても良いよという方は今すぐリクエストボックスの設定をしましょう。',
  RequestboxText4: '今すぐ設定する',
};

export default ja;
