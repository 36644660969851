import { Row } from '@dotpict-lib/component/core/layout/Row';
import { VerticalGravity } from '@dotpict-lib/component/core/layout/VerticalGravity';
import { css } from '@emotion/react';
import Text from '@dotpict-lib/foundation/typography/Text';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import TeamOutlineLeft from '@/app/image/team_outline_left.svg';
import TeamOutlineMiddle from '@/app/image/team_outline_middle.svg';
import TeamOutlineRight from '@/app/image/team_outline_right.svg';
import Column from '@dotpict-lib/component/core/layout/Column';
import { HorizontalGravity } from '@dotpict-lib/component/core/layout/HorizontalGravity';

export type TeamNameProps = {
  teamName: string;
  teamColor: string;
};

const middleWidth = 142;
const height = 34;

const TeamName = ({ teamName, teamColor }: TeamNameProps) => {
  const leftOutlineStyle = css({
    width: 14,
    height,
    maskImage: `url('${TeamOutlineLeft}')`,
    backgroundColor: teamColor,
  });

  const rightOutlineStyle = css({
    width: 14,
    height,
    maskImage: `url('${TeamOutlineRight}')`,
    backgroundColor: teamColor,
  });

  const middleContainer = css({
    position: 'relative',
    width: middleWidth,
    height,
  });

  const middleOutlineStyle = css({
    position: 'absolute',
    width: middleWidth,
    height,
    maskImage: `url('${TeamOutlineMiddle}')`,
    maskRepeat: 'repeat-x',
    backgroundColor: teamColor,
  });

  const middleTextContainerStyle = css({
    position: 'absolute',
    width: middleWidth,
    height,
  });

  return (
    <Row verticalGravity={VerticalGravity.center}>
      <div css={leftOutlineStyle} />
      <div css={middleContainer}>
        <div css={middleOutlineStyle}>
          <Text text={teamName} textStyle={TextStyle.BOLD16} colorStyle={teamColor} />
        </div>
        <div css={middleTextContainerStyle}>
          <Column
            width={middleWidth}
            height={height}
            verticalGravity={VerticalGravity.center}
            horizontalGravity={HorizontalGravity.center}
          >
            <Text text={teamName} textStyle={TextStyle.BOLD16} colorStyle={teamColor} />
          </Column>
        </div>
      </div>
      <div css={rightOutlineStyle} />
    </Row>
  );
};

export default TeamName;
