import { useContext, useEffect } from 'react';
import { APIContext, GlobalContext, LayoutContext, MeContext } from '@/Routing';
import { useNavigate } from 'react-router-dom';
import { CanvasesProps } from '@/app/component/page/draw/canvases/index';

const useCanvases = (): CanvasesProps => {
  const meState = useContext(MeContext);
  const { globalState, dispatch } = useContext(GlobalContext);
  const navigator = useNavigate();
  const { layoutParams } = useContext(LayoutContext);
  const { client } = useContext(APIContext);

  const reloadNeta = async () => {
    const response = await client.fetchNeta(meState.token);
    dispatch({
      type: 'UPDATE_NETA_STATE',
      payload: {
        netaState: {
          officialEvent: response.officialEvent,
          odai: response.odai,
        },
      },
    });
  };

  useEffect(() => {
    reloadNeta();
  }, []);

  const handleClickOfficialEvent = () => {
    navigator(`/officialEvents/${globalState.netaState.officialEvent.tag}`);
  };

  const handleClickOdai = () => {
    navigator(`/odais/${globalState.netaState.odai.tag}`);
  };

  return {
    isVisibleOfficialEvent: globalState.netaState.officialEvent.id > 0,
    bannerWidth: (layoutParams.contentWidth * 2) / 3,
    officialEventBannerImageUrl: globalState.netaState.officialEvent.bannerImageUrl,
    officialEventBannerHeight: ((layoutParams.contentWidth * 2) / 3) * (592 / 1024),
    odaiTitle: globalState.netaState.odai.title,
    handleClickOfficialEvent,
    handleClickOdai,
  };
};

export default useCanvases;
