import Pixelart from '@dotpict-lib/component/core/Pixelart';
import Text from '@dotpict-lib/foundation/typography/Text';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import TranslationKeys from '@/app/translation/TranslationKeys';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';
import useTranslator from '@/app/hook/useTranslator';
import DotpictLink from '@/app/component/DotpictLinkComponent';
import EditProfile from '@/app/component/page/me/editprofile';
import useMyPageHeader from '@/app/component/page/me/mypage/useMyPageHeader';
import { UserType } from '@dotpict-lib/model/User/UserType';
import Column from '@dotpict-lib/component/core/layout/Column';
import { Row } from '@dotpict-lib/component/core/layout/Row';
import { HorizontalGravity } from '@dotpict-lib/component/core/layout/HorizontalGravity';
import { VerticalGravity } from '@dotpict-lib/component/core/layout/VerticalGravity';

export type MyPageHeaderProps = {
  user: UserType;
};

export type MyPageHeaderHeaderBindModel = {
  id: number;
  name: string;
  account: string;
  headerImageUrl: string;
  profileImageUrl: string;
  url: string;
  text: string;
  followedCount: number;
  followerCount: number;
  registerAt: string;
  birthDate: string;
  contentWidth: number;
};

const MyPageHeader = ({ user }: MyPageHeaderProps) => {
  const { bindModel } = useMyPageHeader({ user });
  const translator = useTranslator();

  return (
    <Column background={ColorStyle.WHITE} width={bindModel.contentWidth}>
      {bindModel.headerImageUrl !== '' && (
        <>
          <Pixelart
            alt="header"
            src={bindModel.headerImageUrl}
            width={bindModel.contentWidth}
            height={bindModel.contentWidth / 3}
            objectFit="cover"
          />
          <Spacer height={24} />
        </>
      )}
      <Spacer height={24} />
      <Column width="100%">
        <Row width="100%">
          <Spacer width={24} />
          <Pixelart alt={bindModel.name} src={bindModel.profileImageUrl} width={96} height={96} />
          <Spacer width={16} />
          <Column width={bindModel.contentWidth - 24 * 2 - 96 - 16}>
            <Row width="100%" horizontalGravity={HorizontalGravity.spaceBetween}>
              <Column>
                <Text
                  text={bindModel.name}
                  textStyle={TextStyle.BOLD16}
                  colorStyle={ColorStyle.TEXT_PRIMARY}
                />
                {bindModel.account.length !== 0 && (
                  <Text
                    text={`@${bindModel.account}`}
                    textStyle={TextStyle.REGULAR13}
                    colorStyle={ColorStyle.TEXT_SECONDARY}
                  />
                )}
              </Column>
              <EditProfile />
            </Row>
            <Spacer height={8} />
            <Row>
              <DotpictLink to={`/users/${bindModel.id}/followedUsers`}>
                <Row verticalGravity={VerticalGravity.center}>
                  <Text
                    text={`${bindModel.followedCount}`}
                    textStyle={TextStyle.BOLD14}
                    colorStyle={ColorStyle.TEXT_PRIMARY}
                  />
                  &nbsp;
                  <Text
                    text={`${translator(TranslationKeys.Followed)}`}
                    textStyle={TextStyle.REGULAR12}
                    colorStyle={ColorStyle.TEXT_PRIMARY}
                  />
                </Row>
              </DotpictLink>
              <Spacer width={24} />
              <DotpictLink to={`/users/${bindModel.id}/followerUsers`}>
                <Row verticalGravity={VerticalGravity.center}>
                  <Text
                    text={`${bindModel.followerCount}`}
                    textStyle={TextStyle.BOLD14}
                    colorStyle={ColorStyle.TEXT_PRIMARY}
                  />
                  &nbsp;
                  <Text
                    text={translator(TranslationKeys.Follower)}
                    textStyle={TextStyle.REGULAR12}
                    colorStyle={ColorStyle.TEXT_PRIMARY}
                  />
                </Row>
              </DotpictLink>
            </Row>
          </Column>
        </Row>
        <Spacer height={16} />
        <Row>
          <Spacer width={24} />
          <a href={bindModel.url}>
            <Text
              text={bindModel.url}
              textStyle={TextStyle.BOLD12}
              colorStyle={ColorStyle.PRIMARY}
            />
          </a>
        </Row>
        <Spacer height={8} />
        <Row verticalGravity={VerticalGravity.center}>
          <Spacer width={24} />
          <Text
            text={bindModel.registerAt}
            textStyle={TextStyle.REGULAR11}
            colorStyle={ColorStyle.TEXT_SECONDARY}
          />
        </Row>
        <Spacer height={8} />
        <Row>
          <Spacer width={24} />
          <Text
            text={bindModel.text}
            textStyle={TextStyle.REGULAR14}
            colorStyle={ColorStyle.TEXT_PRIMARY}
            flex={1}
          />
          <Spacer width={24} />
        </Row>
        <Spacer height={24} />
      </Column>
    </Column>
  );
};

export default MyPageHeader;
