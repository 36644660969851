import { createContext, useContext, useEffect, useState } from 'react';
import { GlobalContext } from '@/Routing';
import { WorkThreadsProps } from '@/app/component/page/work/thread/threads/WorkThreads';
import {
  dummyGetWorkThreadsService,
  GetWorkThreadsService,
} from '@/app/service/GetWorkThreads/GetWorkThreadsService';
import { defaultWorkThreadsState } from '@/app/state/WorkThreadsState';

export type WorkThreadsDependencies = {
  workId: number;
  getWorkThreadsService: GetWorkThreadsService;
};

export const WorkThreadsContext = createContext<WorkThreadsDependencies>({
  workId: 0,
  getWorkThreadsService: dummyGetWorkThreadsService,
});

export const useWorkThreads = (): WorkThreadsProps => {
  const { dispatch, globalState } = useContext(GlobalContext);
  const { workId, getWorkThreadsService } = useContext(WorkThreadsContext);
  const [isLoading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    dispatch({
      type: 'UPDATE_WORK_THREADS_STATE',
      payload: {
        workThreadsState: defaultWorkThreadsState,
      },
    });
    const response = await getWorkThreadsService();
    dispatch({
      type: 'UPDATE_WORK_THREADS_STATE',
      payload: {
        workThreadsState: {
          work: response.work,
          threads: response.workThreads,
        },
      },
    });
    setLoading(false);
  };

  useEffect(() => {
    if (workId === 0 || globalState.workThreadsState.work.id === workId) return;
    fetchData();
  }, [workId]);

  return {
    work: globalState.workThreadsState.work,
    threads: globalState.workThreadsState.threads,
    workTitle: globalState.workThreadsState.work.title,
    workThumbnailImageUrl: globalState.workThreadsState.work.thumbnailImageUrl,
    isLoading,
  };
};
