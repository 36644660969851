import { createContext, useContext, useEffect, useState } from 'react';
import { GlobalContext } from '@/Routing';
import { DotpictAction } from '@/app/common/reducer';
import {
  dummyGetChildNotesService,
  GetChildNotesService,
} from '@/app/service/GetChildNotes/GetChildNotesService';
import { ChildNotesViewProps } from '@/app/component/page/note/childnotes/ChildNotes';
import { emptyChildNotesState } from '@/app/state/ChildNotesState';

export type GrandNotesDependencies = {
  parentNoteId: number;
  getChildNotesService: GetChildNotesService;
};

export const GrandNotesContext = createContext<GrandNotesDependencies>({
  parentNoteId: 0,
  getChildNotesService: dummyGetChildNotesService,
});

export const useGrandNotes = (): ChildNotesViewProps => {
  const { dispatch, globalState } = useContext(GlobalContext);
  const { parentNoteId, getChildNotesService } = useContext(GrandNotesContext);
  const [isLoading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    dispatch({
      type: 'UPDATE_GRAND_NOTES_STATE',
      payload: {
        grandNotesState: emptyChildNotesState,
      },
    });
    const response = await getChildNotesService();
    const action: DotpictAction = {
      type: 'UPDATE_GRAND_NOTES_STATE',
      payload: {
        grandNotesState: {
          parentNote: response.parentNote,
          notes: response.notes,
        },
      },
    };
    dispatch(action);
    setLoading(false);
  };

  useEffect(() => {
    if (parentNoteId === 0 || globalState.grandNotesState.parentNote.id === parentNoteId) return;
    fetchData();
  }, [parentNoteId]);

  return {
    parentNote: globalState.grandNotesState.parentNote,
    notes: globalState.grandNotesState.notes,
    isLoading,
  };
};
