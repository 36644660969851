import { css } from '@emotion/react';
import { Helmet } from 'react-helmet';
import Works from '@/app/component/Works';
import DefaultGridStyle from '@/app/style/DefaultGridStyle';
import { defaultDummyItemCount } from '@/app/common/constant';
import UserDetailHeader from '@/app/component/page/user/detail/UserDetailHeader';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';
import useUserDetail from '@/app/component/page/user/detail/useUserDetail';
import Column from '@dotpict-lib/component/core/layout/Column';
import { HorizontalGravity } from '@dotpict-lib/component/core/layout/HorizontalGravity';

export type UserDetailBindModel = {
  userId: number;
  isFollowed: boolean;
  isLoading: boolean;
  isVisibleEnd: boolean;
  thumbnailImageGridGap: number;
  contentWidth: number;
  thumbnailImageSize: number;
};

export type UserDetailOgp = {
  title: string;
  description: string;
  image: string;
};

type Props = {
  userId: number;
  account: string;
};

const UserDetail = ({ userId, account }: Props) => {
  const { bindModel, ogp, user, userWorks } = useUserDetail({ userId, account });

  const GridStyle = css(DefaultGridStyle, {
    gridGap: bindModel.thumbnailImageGridGap,
    gridTemplateColumns: `repeat(${Math.floor(
      bindModel.contentWidth / bindModel.thumbnailImageSize,
    )}, ${bindModel.thumbnailImageSize}px)`,
  });

  const renderOgp = () => (
    <Helmet>
      <meta property="twitter:title" content={ogp.title} />
      <meta property="twitter:description" content={ogp.description} />
      <meta property="twitter:image" content={ogp.image} />
      <meta property="og:title" content={ogp.title} />
      <meta property="og:description" content={ogp.description} />
      <meta property="og:image" content={ogp.image} />
    </Helmet>
  );

  return (
    <>
      {renderOgp()}
      <Column horizontalGravity={HorizontalGravity.center}>
        <UserDetailHeader user={user} />
        <Spacer height={32} />
      </Column>
      <Works
        works={userWorks}
        isLoading={bindModel.isLoading}
        isVisibleEnd={bindModel.isVisibleEnd}
        isVisibleAds={false}
        isVisibleLike={false}
        gridStyle={GridStyle}
        imageSize={bindModel.thumbnailImageSize}
        dummyItemCount={defaultDummyItemCount}
      />
    </>
  );
};

export default UserDetail;
