import Pixelart from '@dotpict-lib/component/core/Pixelart';
import Text from '@dotpict-lib/foundation/typography/Text';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import { Row } from '@dotpict-lib/component/core/layout/Row';
import { VerticalGravity } from '@dotpict-lib/component/core/layout/VerticalGravity';
import Column from '@dotpict-lib/component/core/layout/Column';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';
import { TimelineItemHeaderInfoType } from '@/app/state/TimelineItemHeaderInfoType';
import { useTimelineItemHeader } from '@/app/component/page/timeline/useTimelineItemHeader';
import Clickable from '@dotpict-lib/component/core/Clickable';

type TimelineHeaderProps = {
  info: TimelineItemHeaderInfoType;
};

type TimelineItemHeaderBindModel = {
  iconImageUrl: string;
  title: string;
  text: string;
};

export type TimelineItemHeaderState = {
  bindModel: TimelineItemHeaderBindModel;
  onClickHeader: () => void;
};

export const TimelineHeader = ({ info }: TimelineHeaderProps) => {
  const { bindModel, onClickHeader } = useTimelineItemHeader({ info });

  return (
    <Column>
      <Clickable onClick={onClickHeader}>
        <Row innerPadding={8} verticalGravity={VerticalGravity.center}>
          {bindModel.iconImageUrl !== '' && (
            <Pixelart alt="icon" src={bindModel.iconImageUrl} width={24} height={24} />
          )}
          <Column>
            {bindModel.title !== '' && (
              <Text
                text={bindModel.title}
                textStyle={TextStyle.BOLD16}
                colorStyle={ColorStyle.TEXT_PRIMARY}
              />
            )}
            {bindModel.text !== '' && (
              <Text
                text={bindModel.text}
                textStyle={TextStyle.REGULAR12}
                colorStyle={ColorStyle.TEXT_SECONDARY}
              />
            )}
          </Column>
        </Row>
      </Clickable>
      <Spacer height={16} />
    </Column>
  );
};
