import { useState } from 'react';
import { Button } from '@material-ui/core';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import TextFieldComponent from '@/app/component/TextFieldComponent';
import TranslationKeys from '@/app/translation/TranslationKeys';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';
import Pixelart from '@dotpict-lib/component/core/Pixelart';
import Text from '@dotpict-lib/foundation/typography/Text';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import Tag from '@/app/component/partial/work/upload/tag/Tag';
import useTranslator from '@/app/hook/useTranslator';
import Column from '@dotpict-lib/component/core/layout/Column';
import { Row } from '@dotpict-lib/component/core/layout/Row';

const Note = styled.div`
  padding: 24px;
  background: #fafafa;
`;

const AddButtonStyle = css`
  height: 48px;
`;

type Props = {
  fixedTag: string;
  tags: string[];
  handleTagsChanged: (newTags: string[]) => void;
};

const TagSection = ({ fixedTag, tags, handleTagsChanged }: Props) => {
  const [tag, setTag] = useState<string>('');
  const translator = useTranslator();

  const addTag = () => {
    if (tag.length === 0 || tag.length > 32 || tags.includes(tag)) return;
    handleTagsChanged(tags.concat([tag]));
    setTag('');
  };

  const handleClickAddTag = () => {
    addTag();
  };

  const handleClickDeleteTag = (tagName: string) => {
    handleTagsChanged(tags.filter((t) => t !== tagName));
  };

  const handleTagKeyPress = (event: any) => {
    // 13 => enterkeyのwhich
    if (event.which !== 13) return;
    addTag();
  };

  return (
    <Column>
      {tags.length < 3 ? (
        <Row>
          <TextFieldComponent
            id="tag"
            label={translator(TranslationKeys.Tag)}
            placeholder={translator(TranslationKeys.Tag)}
            value={tag}
            onChangeValue={setTag}
            onKeyPress={handleTagKeyPress}
            showMaxLength
            maxLength={32}
          />
          <Column>
            <Spacer height={32} />
            <Button css={AddButtonStyle} onClick={handleClickAddTag}>
              <Pixelart
                alt="add"
                src="https://storage.googleapis.com/dotpict-images/web/ic_add.svg"
                width={16}
                height={16}
              />
            </Button>
          </Column>
        </Row>
      ) : (
        <Note>
          <Text
            text={translator(TranslationKeys.MaxTags)}
            textStyle={TextStyle.REGULAR14}
            colorStyle={ColorStyle.GRAY_DEEP}
          />
        </Note>
      )}
      <Spacer height={12} />
      <Row innerPadding={12}>
        {tags.map((t) => (
          <Tag key={t} fixed={t === fixedTag} tagName={t} onClick={() => handleClickDeleteTag(t)} />
        ))}
      </Row>
    </Column>
  );
};

export default TagSection;
