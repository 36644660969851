import {
  defaultOfficialEvent,
  OfficialEventType,
} from '@dotpict-sns-lib/model/OfficialEvent/OfficialEventType';
import { WorkType } from '../model/WorkType';

export type OfficialEventDetailStateType = {
  officialEvent: OfficialEventType;
  mainImageUrl: string;
  tag: string;
  templateImageUrl: string;
  colors: string[];
  shareTitle: string;
  twitterShareUrl: string;
  facebookShareUrl: string;
  bannerImageUrl: string;
  isLoadingDetail: boolean;
  isLoadingWorks: boolean;
  isVisibleEnd: boolean;
  works: WorkType[];
  nextUrl: string;
  isBattleEvent: boolean;
  description: string;
  teamOneText: string;
  teamOneTag: string;
  teamOneScore: number;
  teamOneColor: string;
  teamTwoText: string;
  teamTwoTag: string;
  teamTwoScore: number;
  teamTwoColor: string;
};

export const defaultOfficialEventDetailState: OfficialEventDetailStateType = {
  officialEvent: defaultOfficialEvent,
  mainImageUrl: '',
  tag: '',
  templateImageUrl: '',
  colors: [],
  shareTitle: '',
  twitterShareUrl: '',
  facebookShareUrl: '',
  bannerImageUrl: '',
  isLoadingDetail: true,
  isLoadingWorks: false,
  isVisibleEnd: false,
  works: [],
  nextUrl: '',
  isBattleEvent: false,
  description: '',
  teamOneText: '',
  teamOneTag: '',
  teamOneScore: 0,
  teamOneColor: '',
  teamTwoTag: '',
  teamTwoText: '',
  teamTwoScore: 0,
  teamTwoColor: '',
};
