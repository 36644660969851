import { useContext, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { APIContext, GlobalContext, LayoutContext } from '@/Routing';
import Users from '@/app/component/page/user/list/Users';
import Column from '@dotpict-lib/component/core/layout/Column';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';
import { PagingType } from '@dotpict-lib/model/PagingType';
import { Dependencies, UsersContext } from '@/app/component/page/user/list/useUsers';
import { UsersKey } from '@/app/component/page/user/list/UsersKey';

const usersKey: UsersKey = 'REPICTED';

export const RepictedUsers = () => {
  const { workId } = useParams();
  const { client } = useContext(APIContext);
  const { globalState, dispatch } = useContext(GlobalContext);
  const { layoutParams } = useContext(LayoutContext);

  const dependencies = useMemo((): Dependencies => {
    return {
      getUsersService: (paging: PagingType) => client.fetchUsers(paging.nextUrl),
    };
  }, [client]);

  useEffect(() => {
    if (!workId || globalState.usersStateHash[usersKey]?.key === workId) return;
    dispatch({
      type: `UPDATE_USERS_STATE`,
      payload: {
        usersKey,
        usersState: {
          key: workId,
          userSummaries: [],
          nextUrl: `${client.getBaseUrl}/works/${workId}/repict_users?work_count=6`,
        },
      },
    });
  }, [workId]);

  return (
    <UsersContext.Provider value={dependencies}>
      <Column>
        <Spacer height={layoutParams.contentTopSpace} />
        <Users usersKey={usersKey} />
        <Spacer height={layoutParams.contentBottomSpace} />
      </Column>
    </UsersContext.Provider>
  );
};
