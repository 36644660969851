import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import TranslationKeys from '@/app/translation/TranslationKeys';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';
import Pixelart from '@dotpict-lib/component/core/Pixelart';
import useTranslator from '@/app/hook/useTranslator';
import FileSelectButton from '@/app/component/partial/work/upload/fileselectbutton/FileSelectButton';
import Column from '@dotpict-lib/component/core/layout/Column';
import { HorizontalGravity } from '@dotpict-lib/component/core/layout/HorizontalGravity';

export type FileSelectorProps = {
  handleFileSelect: (file: File | undefined) => void;
};

const Border = styled.div`
  width: 100%;
  border: 2px dashed ${ColorStyle.GRAY_DEEP};
`;

const description = css`
  white-space: pre-line;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
`;

const FileSelector = ({ handleFileSelect }: FileSelectorProps) => {
  const translator = useTranslator();

  return (
    <Border>
      <Column horizontalGravity={HorizontalGravity.center}>
        <Spacer height={40} />
        <Pixelart
          src="https://storage.googleapis.com/dotpict-images/web/ic_file.svg"
          alt="file"
          width={36}
          height={36}
        />
        <Spacer height={16} />
        <FileSelectButton handleFileSelect={handleFileSelect} />
        <p css={description}>{translator(TranslationKeys.SizeLimit)}</p>
        <Spacer height={24} />
      </Column>
    </Border>
  );
};

export default FileSelector;
