import React, { createContext, Dispatch, useMemo } from 'react';
import Routing from '@/Routing';
import useLocalStorage from '@/app/hook/useLocalStorage';
import { LanguageType } from '@/app/model/Language';
import ja from '@/app/translation/ja';
import en from '@/app/translation/en';
import { IntlProvider } from 'react-intl';

type LanguageDependencies = {
  language: LanguageType;
  setLanguage: Dispatch<React.SetStateAction<LanguageType>>;
};

export const LanguageContext = createContext<LanguageDependencies>({} as LanguageDependencies);

export const Language = () => {
  // FYI: https://qiita.com/shogo82148/items/548a6c9904eb19269f8c TODO: IE対応のやつの変数がなかったからIEで動くか調べる
  const browserLanguage =
    (window.navigator.languages && window.navigator.languages[0]) || window.navigator.language;
  const [language, setLanguage] = useLocalStorage<LanguageType>(
    'lang',
    browserLanguage.includes('ja') ? 'ja' : 'en',
  );

  const languageValue = useMemo(() => {
    return { language, setLanguage };
  }, [language, setLanguage]);

  return (
    <IntlProvider locale={language} messages={language === 'ja' ? ja : en}>
      <LanguageContext.Provider value={languageValue}>
        <Routing language={language} />
      </LanguageContext.Provider>
    </IntlProvider>
  );
};
