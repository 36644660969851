import React, { useEffect } from 'react';
import { TabLayout } from '@dotpict-lib/component/core/TabLayout';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';
import { useLocation } from 'react-router-dom';
import useTranslator from '@/app/hook/useTranslator';
import TranslationKeys from '@/app/translation/TranslationKeys';
import useLocalStorage from '@/app/hook/useLocalStorage';
import CompletedRequests from '@/app/component/page/requestbox/CompletedRequests';
import ReceivedRequests from '@/app/component/page/requestbox/ReceivedRequests';
import Text from '@dotpict-lib/foundation/typography/Text';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import Button from '@dotpict-lib/component/dotpict/Button';
import RequestBoxSettings from '@/app/component/page/requestbox/settings';
import Column from '@dotpict-lib/component/core/layout/Column';
import { HorizontalGravity } from '@dotpict-lib/component/core/layout/HorizontalGravity';
import { Row } from '@dotpict-lib/component/core/layout/Row';

const types = ['received', 'completed'];

const RequestBox: React.FC = () => {
  const location = useLocation();
  const translator = useTranslator();
  const [requestType, setRequestType] = useLocalStorage('requestType', 'received');

  const tabItems = [
    translator(TranslationKeys.RequestReceived),
    translator(TranslationKeys.RequestCompleted),
  ];

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const type = params.get('type');
    if (type !== null && types.includes(type)) {
      setRequestType(type);
    }
  }, [location]);

  const handleClickTagItem = (index: number) => {
    if (tabItems[index] === translator(TranslationKeys.RequestCompleted)) {
      setRequestType('completed');
    } else {
      setRequestType('received');
    }
  };

  const renderContent = (type: string) => {
    switch (type) {
      case 'completed':
        return <CompletedRequests />;
      default:
        return <ReceivedRequests />;
    }
  };

  const convTypeToIndex = (type: string) => {
    if (type === 'completed') {
      return 1;
    }
    return 0;
  };

  return (
    <Column>
      <Spacer height={24} />
      <Row horizontalGravity={HorizontalGravity.spaceBetween}>
        <Text
          text={translator(TranslationKeys.RequestBox)}
          textStyle={TextStyle.BOLD20}
          colorStyle={ColorStyle.TEXT_PRIMARY}
        />
        <RequestBoxSettings>
          <Button
            width={240}
            height={32}
            text={translator(TranslationKeys.RequestBoxSettings)}
            isFilled={false}
            isBorder
          />
        </RequestBoxSettings>
      </Row>
      <Spacer height={24} />
      <TabLayout
        items={tabItems}
        selectedIndex={convTypeToIndex(requestType)}
        onClick={handleClickTagItem}
      />
      <Spacer height={8} />
      {renderContent(requestType)}
    </Column>
  );
};

export default RequestBox;
