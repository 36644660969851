import { OfficialEventType } from '@dotpict-sns-lib/model/OfficialEvent/OfficialEventType';
import { defaultBattleInfo } from '@dotpict-sns-lib/model/OfficialEvent/BattleInfoType';

export const TestOfficialEventJuneBride: OfficialEventType = {
  id: 58,
  imageUrl: 'https://storage.googleapis.com/dotpict-images/official_events/june_bride.png',
  layerImageUrl0:
    'https://storage.googleapis.com/dotpict-images/official_events/transparent_64x64.png',
  layerImageUrl1:
    'https://storage.googleapis.com/dotpict-images/official_events/transparent_64x64.png',
  layerImageUrl2:
    'https://storage.googleapis.com/dotpict-images/official_events/transparent_64x64.png',
  activeLayerIndex: 0,
  backgroundColorCode: 'FFFFFF',
  bannerImageUrl:
    'https://storage.googleapis.com/dotpict-images/official_events/banner/JuneBride_ja.png',
  battleInfo: defaultBattleInfo,
  canvasTitle: 'ジューンブライド',
  colorCodes: [
    '555483',
    '5A719C',
    '74A9C8',
    '98DCDD',
    'C6EAEA',
    '9E7DC4',
    'C097D9',
    'E7A2DE',
    'B7BFFF',
    'BDE1FF',
    'B06890',
    'E68BB5',
    'FEBFCA',
    'FFE8D8',
    'F3EEF9',
    'FFFFFF',
  ],
  createdAt: '2022/05/30 22:54:13',
  endAt: '2022/06/15 23:59:59',
  isBattleEvent: false,
  shareText: 'ジューンブライドのドット絵を描こう #dotpict_JuneBride #dotpict',
  shareUrl: 'https://dotpict.net/officialEvents/JuneBride',
  startAt: '2022/06/01 00:00:00',
  tag: 'JuneBride',
  title: 'ジューンブライドのドット絵を描こう',
};
