import { css } from '@emotion/react';

const imageSize = 192;

const DefaultGridStyle = css({
  display: 'grid',
  justifyContent: 'center',
  gridGap: '8px',
  gridTemplateColumns: `repeat(auto-fill, ${imageSize}px)`,
});

export default DefaultGridStyle;
