import React, { useState } from 'react';
import Cropper, { Area } from 'react-easy-crop';
import styled from '@emotion/styled';
import { Media } from '@/app/component/page/work/upload/CropDialog';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';
import { HorizontalGravity } from '@dotpict-lib/component/core/layout/HorizontalGravity';
import Column from '@dotpict-lib/component/core/layout/Column';

type Props = {
  media: Media;
  onCropComplete: (croppedArea: Area, croppedAreaPixels: Area, zoomRate: number) => void;
};

const Container = styled.div`
  position: relative;
  width: 480px;
  height: 480px;
`;

const CONTAINER_SIZE = 480;

const CropperWrapper = ({ media, onCropComplete }: Props) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const containerStyle: React.CSSProperties = {
    width: 480,
    height: 480,
  };

  const cropAreaStyle: React.CSSProperties = {
    width: 480,
    height: 480,
    border: `dashed 4px ${ColorStyle.PRIMARY}`,
  };

  const mediaStyle: React.CSSProperties = {
    imageRendering: 'pixelated',
    objectFit: 'contain',
    width:
      media.width > media.height ? CONTAINER_SIZE : CONTAINER_SIZE * (media.width / media.height),
    height:
      media.height > media.width ? CONTAINER_SIZE : CONTAINER_SIZE * (media.height / media.width),
  };

  return (
    <Column horizontalGravity={HorizontalGravity.center} height="100%">
      <Container>
        <Cropper
          image={media.url}
          style={{ containerStyle, mediaStyle, cropAreaStyle }}
          crop={crop}
          zoom={zoom}
          aspect={1}
          showGrid={false}
          onCropChange={setCrop}
          onCropComplete={(croppedArea: Area, croppedAreaPixels: Area) => {
            onCropComplete(croppedArea, croppedAreaPixels, zoom);
          }}
        />
      </Container>
      <Spacer height={24} />
      <input
        type="range"
        value={zoom}
        min={1}
        max={4}
        step={0.1}
        aria-labelledby="Zoom"
        onChange={(e) => {
          setZoom(Number(e.target.value));
        }}
        className="zoom-range"
      />
    </Column>
  );
};

export default CropperWrapper;
