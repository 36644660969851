import { useMemo, useRef } from 'react';
import styled from '@emotion/styled';
import Header from '@/app/component/page/draw/Header';
import SideMenu from '@/app/component/page/draw/SideMenu';
import Body from '@/app/component/page/draw/Body';
import { SizeContext, useSize } from '@/app/component/page/draw/Context/SizeContext';
import { ToolContext, useTool } from '@/app/component/page/draw/Context/ToolContext';
import { TitleContext, useTitle } from '@/app/component/page/draw/Context/TitleContext';
import { ColorContext, useColors } from '@/app/component/page/draw/Context/ColorContext';
import { HistoryContext, useHistory } from '@/app/component/page/draw/Context/HistoryContext';
import { CanvasHistoryType } from '@/app/component/page/draw/hooks/types';

const Layout = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  grid-gap: 4px;
  width: 100%;
  height: 100vh;
`;

const SideMenuLayout = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
`;

type Props = {
  pushHistory(history: CanvasHistoryType): void;
  redo(): void;
  undo(): void;
};

const DrawPage = ({ pushHistory, redo, undo }: Props) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [size, selectSize] = useSize();
  const [title, editTitle] = useTitle();
  const [
    pixels,
    setPixels,
    initializePixels,
    color,
    colorIndex,
    palette,
    selectColor,
    editPalette,
    background,
  ] = useColors(pushHistory, size);
  const [tool, selectTool] = useTool(redo, undo, initializePixels);

  const pixelsValue = useMemo(() => {
    return { color, background, palette, colorIndex, pixels };
  }, [color, background, palette, colorIndex, pixels]);

  return (
    <SizeContext.Provider value={size}>
      <TitleContext.Provider value={title}>
        <ToolContext.Provider value={tool}>
          <ColorContext.Provider value={pixelsValue}>
            <Layout>
              <Body pushHistory={pushHistory} setPixels={setPixels} ref={canvasRef} />
              <Header selectSize={selectSize} editTitle={editTitle} canvasRef={canvasRef} />
              <SideMenuLayout>
                <SideMenu
                  selectTool={selectTool}
                  selectColor={selectColor}
                  editPalette={editPalette}
                />
              </SideMenuLayout>
            </Layout>
          </ColorContext.Provider>
        </ToolContext.Provider>
      </TitleContext.Provider>
    </SizeContext.Provider>
  );
};

// HistoryContextはキャンバスの状態を管理するため、他のContextをラップする必要がある
const DrawPageRoot = () => {
  const [history, pushHistory, redo, undo] = useHistory();

  return (
    <HistoryContext.Provider value={history}>
      <DrawPage pushHistory={pushHistory} redo={redo} undo={undo} />
    </HistoryContext.Provider>
  );
};
export default DrawPageRoot;
