import { useState, useEffect, createContext, useContext } from 'react';
import { HistoryContext } from '@/app/component/page/draw/Context/HistoryContext';

export type SizeType = {
  width: number;
  height: number;
};
export const defaultCanvasSize: SizeType = {
  width: 32,
  height: 32,
};
export type SizeContextType = SizeType & { manual: boolean };
const initialState = { ...defaultCanvasSize, manual: false };
export const SizeContext = createContext<SizeContextType>(initialState);

export const useSize = () => {
  const [size, setSize] = useState<SizeContextType>(initialState);
  const history = useContext(HistoryContext);

  // 画面上でキャンバスサイズを変更したとき
  const selectSize = (selected: SizeType) => {
    return () => {
      setSize({ ...selected, manual: true });
    };
  };

  // Redo/UndoによりHistoryのpointerが変化して、canvasSizeに変更がある場合は変更する
  useEffect(() => {
    const newHistory = history.canvasHistories[history.pointer];
    if (newHistory !== undefined && newHistory.canvasSize !== undefined) {
      setSize({ ...newHistory.canvasSize, manual: false });
    }
  }, [history.pointer]);

  return [size, selectSize] as [typeof size, typeof selectSize];
};
