import emptyUser, { UserType } from '@dotpict-lib/model/User/UserType';

export type WorkThreadType = {
  id: number;
  parentId: number;
  workId: number;
  user: UserType;
  text: string;
  threadCount: number;
  isLiked: boolean;
  likeCount: number;
  likedProfileImageUrl: string;
  isLikedByAuthor: boolean;
  createdAt: string;
};

export const defaultWorkThread: WorkThreadType = {
  id: 0,
  parentId: 0,
  workId: 0,
  user: emptyUser,
  text: '',
  threadCount: 0,
  isLiked: false,
  likeCount: 0,
  likedProfileImageUrl: '',
  isLikedByAuthor: false,
  createdAt: '',
};
