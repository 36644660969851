import { useCallback } from 'react';
import { analytics } from '@/app/library/dotpictFirebaseAuth';
import { logEvent } from 'firebase/analytics';

const useAnalytics = () => {
  return useCallback(
    (eventName: string, eventParams?: { [key: string]: any }) => {
      return logEvent(analytics, eventName, eventParams);
    },
    [analytics],
  );
};

export default useAnalytics;
