import { NoteType } from '@dotpict-sns-lib/model/Note/NoteType';
import { convertArrayToNoteImage } from '@dotpict-sns-lib/model/Note/convertArrayToNoteImage';
import { convertArrayToUser } from '@dotpict-lib/model/User/convertArrayToUser';
import { convertUnixTimeToDateTimeString } from '@dotpict-lib/model/Date/convertUnixTimeToDateTimeString';

export const convertArrayToNote = (json: any): NoteType => ({
  id: json.id,
  parentId: json.parent_id,
  user: convertArrayToUser(json.user),
  text: json.text,
  childCount: json.child_count,
  scope: json.scope,
  type: json.type,
  image: json.image === null ? null : convertArrayToNoteImage(json.image),
  isLiked: json.is_liked,
  likeCount: json.like_count,
  likedProfileImageUrl: json.liked_profile_image_url,
  isEnabledDelete: json.is_enabled_delete,
  createdAt: convertUnixTimeToDateTimeString(json.created_at),
});
