import { useContext, useMemo } from 'react';
import { APIContext } from '@/Routing';
import { useParams } from 'react-router-dom';
import {
  ChildNotesContext,
  ChildNotesDependencies,
} from '@/app/component/page/note/childnotes/useChildNotes';
import { ChildNotes } from '@/app/component/page/note/childnotes/ChildNotes';

export const ChildNotesPage = () => {
  const { client } = useContext(APIContext);
  const { parentNoteId } = useParams();

  const dependencies = useMemo((): ChildNotesDependencies => {
    return {
      parentNoteId: Number(parentNoteId),
      getChildNotesService: () =>
        parentNoteId ? client.fetchChildNotes(parentNoteId) : Promise.reject(),
    };
  }, [client]);

  return (
    <ChildNotesContext.Provider value={dependencies}>
      <ChildNotes />
    </ChildNotesContext.Provider>
  );
};
