import React, { KeyboardEventHandler } from 'react';
import { css } from '@emotion/react';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';

interface Props {
  id: string;
  label?: string;
  placeholder?: string;
  value: string;
  multiline?: boolean;
  disabled?: boolean;
  error?: string;
  rows?: number;
  cols?: number;
  maxLength?: number;
  showMaxLength?: boolean;
  autoComplete?: string;
  onKeyPress?: KeyboardEventHandler;
  onChangeValue(value: string): void;
}

const container = css`
  display: grid;
  width: 100%;
`;

const styledLabel = css`
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 12px;
`;

const styledInput = css`
  height: 48px;
  width: 100%;
  border: none;
  outline: none;
  background: ${ColorStyle.WHITE_GRAY};
  font-size: 14px;
  line-height: 22px;
  padding: 13px 14px 13px 14px;
  transition: background-color 0.2s, box-shadow 0.2s;
  box-sizing: border-box;

  &::placeholder {
    color: ${ColorStyle.GRAY_DEEP};
  }

  :focus {
    box-shadow: 0 0 0 2px #fdd3e1 inset;
    outline: none;
  }

  ${'&:disabled, &[aria-disabled=true]'} {
    cursor: default;
    pointer-events: none;
  }
`;

const styledTextarea = css`
  min-height: 48px;
  width: 100%;
  border: none;
  outline: none;
  background: ${ColorStyle.WHITE_GRAY};
  font-size: 14px;
  line-height: 22px;
  padding: 13px 14px 13px 14px;
  transition: background-color 0.2s, box-shadow 0.2s;
  resize: vertical;
  box-sizing: border-box;

  &::placeholder {
    color: ${ColorStyle.GRAY_DEEP};
  }

  :focus {
    box-shadow: 0 0 0 2px #fdd3e1 inset;
    outline: none;
  }

  ${'&:disabled, &[aria-disabled=true]'} {
    cursor: default;
    pointer-events: none;
  }
`;

const errorLabel = css`
  margin-top: 4px;
  font-size: 11px;
  line-height: 16px;
  color: ${ColorStyle.ERROR};
`;

const styledInputMaxLength = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-right: 12px;
  display: flex;
  box-sizing: border-box;
  justify-content: flex-end;
  align-items: center;
  font-size: 11px;
  line-height: 16px;
  user-select: none;
  pointer-events: none;
  color: ${ColorStyle.TEXT_SECONDARY};
`;

const styledTextareaMaxLength = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-bottom: 12px;
  padding-right: 12px;
  display: flex;
  box-sizing: border-box;
  justify-content: flex-end;
  align-items: flex-end;
  font-size: 11px;
  line-height: 16px;
  user-select: none;
  pointer-events: none;
  color: ${ColorStyle.TEXT_SECONDARY};
`;

const inputWrapper = css`
  position: relative;
`;

const TextFieldComponent = ({
  id,
  label,
  placeholder,
  value,
  rows,
  cols,
  multiline = false,
  disabled = false,
  error,
  maxLength,
  showMaxLength = false,
  autoComplete,
  onKeyPress,
  onChangeValue,
}: Props) => {
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    onChangeValue(e.currentTarget.value);
  };
  return (
    <div css={container} aria-disabled={disabled}>
      {label && (
        <label id={`${id}-label`} htmlFor={id} css={styledLabel}>
          {label}
        </label>
      )}
      <div css={inputWrapper}>
        {showMaxLength && (
          <span css={!multiline ? styledInputMaxLength : styledTextareaMaxLength}>
            {value?.length}/{maxLength}
          </span>
        )}
        {!multiline ? (
          <input
            id={id}
            value={value}
            placeholder={placeholder}
            onChange={handleChange}
            css={styledInput}
            autoComplete={autoComplete}
            aria-disabled={disabled}
            onKeyPress={onKeyPress}
          />
        ) : (
          <textarea
            id={id}
            value={value}
            placeholder={placeholder}
            onChange={handleChange}
            rows={rows}
            cols={cols}
            css={styledTextarea}
            aria-disabled={disabled}
          />
        )}
      </div>
      {error && <p css={errorLabel}>{error}</p>}
    </div>
  );
};

export default TextFieldComponent;
