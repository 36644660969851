import { WorkThreadType } from '@/app/model/WorkThreadType';
import Pixelart from '@dotpict-lib/component/core/Pixelart';
import Text from '@dotpict-lib/foundation/typography/Text';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import useWorkThread from '@/app/component/page/work/thread/item/useWorkThread';
import { Row } from '@dotpict-lib/component/core/layout/Row';
import Column from '@dotpict-lib/component/core/layout/Column';
import { VerticalGravity } from '@dotpict-lib/component/core/layout/VerticalGravity';
import Clickable from '@dotpict-lib/component/core/Clickable';
import { Divider } from '@material-ui/core';
import { WorkThreadFooter } from '@/app/component/page/work/thread/item/WorkThreadFooter';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';

type WorkThreadProps = {
  isReply: boolean;
  isVisibleFooter: boolean;
  thread: WorkThreadType;
};

export type WorkThreadViewState = {
  profileImageUrl: string;
  userName: string;
  createdAt: string;
  text: string;
  replyCount: number;
  isLiked: boolean;
  likeCount: number;
  likedProfileImageUrl: string;
  onClickUser: () => void;
};

const WorkThread = ({ isReply, isVisibleFooter, thread }: WorkThreadProps) => {
  const {
    profileImageUrl,
    userName,
    createdAt,
    text,
    replyCount,
    isLiked,
    likeCount,
    likedProfileImageUrl,
    onClickUser,
  } = useWorkThread({ thread });

  return (
    <Column width="100%" background={ColorStyle.WHITE}>
      <Row verticalPadding={16}>
        {isReply && <Spacer width={16} />}
        <Column width="100%" horizontalPadding={16} innerPadding={8}>
          <Row verticalGravity={VerticalGravity.top} innerPadding={16}>
            <Clickable onClick={onClickUser} minWidth={48} minHeight={48}>
              <Pixelart alt={profileImageUrl} src={profileImageUrl} width={48} height={48} />
            </Clickable>
            <Column innerPadding={8}>
              <Text
                text={userName}
                textStyle={TextStyle.BOLD14}
                colorStyle={ColorStyle.TEXT_PRIMARY}
              />
              <Text
                text={text}
                textStyle={TextStyle.REGULAR12}
                colorStyle={ColorStyle.TEXT_PRIMARY}
              />
              <Text
                text={createdAt}
                textStyle={TextStyle.REGULAR12}
                colorStyle={ColorStyle.TEXT_PRIMARY}
              />
            </Column>
          </Row>
          {isVisibleFooter && (
            <>
              <Spacer height={16} />
              <Divider />
              <Spacer height={8} />
              <WorkThreadFooter
                workId={thread.workId}
                threadId={thread.id}
                replyCount={replyCount}
                isLiked={isLiked}
                likeCount={likeCount}
                likedProfileImageUrl={likedProfileImageUrl}
                isVisibleReply={!isReply}
              />
            </>
          )}
        </Column>
      </Row>
    </Column>
  );
};

export default WorkThread;
