import Text from '@dotpict-lib/foundation/typography/Text';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import Pixelart from '@dotpict-lib/component/core/Pixelart';
import Column from '@dotpict-lib/component/core/layout/Column';
import useTranslator from '@/app/hook/useTranslator';
import TranslationKeys from '@/app/translation/TranslationKeys';
import styled from '@emotion/styled';
import { HorizontalGravity } from '@dotpict-lib/component/core/layout/HorizontalGravity';
import OdaiMiniBanner from '@/app/component/partial/event/odaibanner/mini/OdaiMiniBanner';

export type OdaiBigBannerProps = {
  odaiTitle: string;
};

const Container = styled.div`
  position: relative;
  background: ${ColorStyle.PRIMARY};
  width: 100%;
`;

const DescriptionContainer = styled.div`
  position: absolute;
  top: 90px;
  left: 185px;
`;

const PickoContainer = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
`;

const FlowerContainer = styled.div`
  position: absolute;
  top: 40px;
  left: 40px;
`;

const PrimaryColorView = styled.div`
  height: 100px;
  width: 100%;
`;

const OdaiBigBanner = ({ odaiTitle }: OdaiBigBannerProps) => {
  const translator = useTranslator();

  return (
    <Container>
      <Column width="100%">
        <OdaiMiniBanner odaiTitle={odaiTitle} isVisibleLabel={false} isVisiblePicko={false} />
        <PrimaryColorView />
      </Column>
      <FlowerContainer>
        <Pixelart
          alt="odai flower"
          src="https://storage.googleapis.com/dotpict-images/web/odai/odai_flower.png"
          width={125}
          height={125}
        />
      </FlowerContainer>
      <PickoContainer>
        <Pixelart
          alt="odai picko"
          src="https://storage.googleapis.com/dotpict-images/web/odai/odai_picko.png"
          width={99}
          height={99}
        />
      </PickoContainer>
      <DescriptionContainer>
        <Column horizontalGravity={HorizontalGravity.start}>
          <Text
            text={translator(TranslationKeys.DailyThemeDescription1)}
            textStyle={TextStyle.BOLD16}
            colorStyle={ColorStyle.WHITE}
          />
          <Text
            text={translator(TranslationKeys.DailyThemeDescription2)}
            textStyle={TextStyle.BOLD16}
            colorStyle={ColorStyle.WHITE}
          />
        </Column>
      </DescriptionContainer>
    </Container>
  );
};

export default OdaiBigBanner;
