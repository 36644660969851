import { useMemo, useContext, ChangeEvent } from 'react';
import { SizeContext, SizeType } from '@/app/component/page/draw/Context/SizeContext';

const SizesMap = {
  '16x16': {
    width: 16,
    height: 16,
    label: '16x16',
  },
  '24x24': {
    width: 24,
    height: 24,
    label: '24x24',
  },
  '32x32': {
    width: 32,
    height: 32,
    label: '32x32',
  },
  '48x48': {
    width: 48,
    height: 48,
    label: '48x48',
  },
  '64x64': {
    width: 64,
    height: 64,
    label: '64x64',
  },
} as const;

type Props = {
  selectSize: (selected: SizeType) => () => void;
};

const CanvasSizeSelector = ({ selectSize }: Props) => {
  const canvasSize = useContext(SizeContext);

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const targetSize = e.target.value;
    const nextSize = Object.values(SizesMap).find(({ label }) => label === targetSize);
    if (nextSize === undefined) {
      return;
    }

    selectSize(nextSize)();
  };

  const sizes = useMemo(() => Object.values(SizesMap), []);

  return (
    <select onChange={handleChange} value={`${canvasSize.width}x${canvasSize.height}`}>
      {sizes.map((size) => (
        <option value={size.label} key={size.label}>
          {size.label}
        </option>
      ))}
    </select>
  );
};

export default CanvasSizeSelector;
