import { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { hsv, rgb, hex } from 'color-convert';
import HSVEditor from '@/app/component/page/draw/SideMenu/ToolSelector/EditColor/HSVTab/HSVEditor';
import ColorCodeInput from '@/app/component/page/draw/SideMenu/ToolSelector/EditColor/HSVTab/ColorCodeInput';

const PresetColorList = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: 16px;
  width: 373px;
  height: 268px;
  border: 2px solid #eee;
  box-sizing: border-box;
`;

type Props = {
  color: string;
  colorIndex: number;
  editPalette(index: number, color: string): void;
};

const PresetTabBody = ({ color, colorIndex, editPalette }: Props) => {
  const [hue, setHue] = useState<number>();
  const [saturation, setSaturation] = useState<number>();
  const [brightness, setBrightness] = useState<number>();
  const [colorCode, setColorCode] = useState<string>();
  const prevColorIndex = useRef<number>(colorIndex);

  useEffect(() => {
    prevColorIndex.current = colorIndex;
  }, [colorIndex]);

  // 初期化, 編集する色の対象を変更したときの変化をハンドル
  useEffect(() => {
    // .rawをつけないと丸められてしまうので注意
    const hsvColor = hex.hsv.raw(color);

    setHue(hsvColor[0]);
    setSaturation(hsvColor[1]);
    setBrightness(hsvColor[2]);
  }, [prevColorIndex.current]);

  // 対象の色選択やHSVの領域をクリックしたときの変化をハンドル
  useEffect(() => {
    if (hue === undefined || saturation === undefined || brightness === undefined) {
      return;
    }
    const rgbColor = hsv.rgb([hue, saturation, brightness]);
    setColorCode(rgb.hex(rgbColor));
  }, [hue, saturation, brightness]);

  // 表示されているカラーコードが変わったとき＝編集した色を反映する
  useEffect(() => {
    if (colorCode === undefined || colorIndex !== prevColorIndex.current) {
      return;
    }

    editPalette(colorIndex, colorCode);
  }, [colorCode]);

  if (
    colorCode === undefined ||
    hue === undefined ||
    saturation === undefined ||
    brightness === undefined
  ) {
    return <PresetColorList />;
  }

  return (
    <PresetColorList>
      <ColorCodeInput colorCode={colorCode} setColorCode={setColorCode} />
      <HSVEditor
        hue={hue}
        saturation={saturation}
        brightness={brightness}
        setHue={setHue}
        setSaturation={setSaturation}
        setBrightness={setBrightness}
      />
    </PresetColorList>
  );
};

export default PresetTabBody;
