import useUploadWork from '@/app/component/page/work/upload/useUploadWork';
import UploadWork, { UploadWorkContext } from '@/app/component/page/work/upload/UploadWork';

const UploadWorkPage = () => {
  const props = useUploadWork();

  return (
    <UploadWorkContext.Provider value={props}>
      <UploadWork />
    </UploadWorkContext.Provider>
  );
};

export default UploadWorkPage;
