import { CircularProgress } from '@material-ui/core';
import Column from './layout/Column';
import { HorizontalGravity } from './layout/HorizontalGravity';

type Props = {
  verticalPadding?: number;
};

const Loading = ({ verticalPadding }: Props) => (
  <Column
    width="100%"
    horizontalGravity={HorizontalGravity.center}
    verticalPadding={verticalPadding}
  >
    <CircularProgress />
  </Column>
);

export default Loading;
