import { useContext, useMemo } from 'react';
import { APIContext } from '@/Routing';
import { FeedbackContext, FeedbackDependencies } from '@/app/component/page/feedback/useFeedback';
import { Feedback } from '@/app/component/page/feedback/Feedback';

export const FeedbackPage = () => {
  const { client } = useContext(APIContext);

  const dependencies = useMemo((): FeedbackDependencies => {
    return {
      postFeedbackService: (message: string) => client.postFeedback(message),
    };
  }, [client]);

  return (
    <FeedbackContext.Provider value={dependencies}>
      <Feedback />
    </FeedbackContext.Provider>
  );
};
