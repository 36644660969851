const presetColorList = [
  'FFFFFF',
  'E7E7E7',
  'C3C3C3',
  'A8A8A8',
  '848484',
  '666666',
  '333333',
  '000000',
  'FFDEDE',
  'FFABAB',
  'FF6969',
  'F62727',
  'CC2121',
  '9C0D0D',
  '660606',
  '360000',
  'FFE3DB',
  'FFB9A5',
  'FF7951',
  'FF4200',
  'BD2F09',
  '931900',
  '6C0F00',
  '450A00',
  'FFEADE',
  'FFCDAE',
  'FFA269',
  'FC6100',
  'D54A00',
  'AB3400',
  '7E2000',
  '561300',
  'FFF1DB',
  'FFD596',
  'FFB84B',
  'FC9900',
  'C97100',
  '9C5700',
  '763700',
  '541E00',
  'FFF1C0',
  'FFE587',
  'FFD333',
  'FFC800',
  'D8A100',
  'A57300',
  '7B4700',
  '603300',
  'FFFFC0',
  'FFFF8A',
  'FFFF48',
  'FFFF00',
  'D2D200',
  'A6A600',
  '747400',
  '4C4C00',
  'EDFFCF',
  'DAFF9C',
  'BEFF54',
  'A2F616',
  '73C000',
  '4F9300',
  '3B6F00',
  '1F4500',
  'DEFFD2',
  'B2F799',
  '88F361',
  '66EA35',
  '2FB101',
  '278400',
  '196000',
  '0D3F00',
  'D8FFE2',
  'A5FDBC',
  '5BFF83',
  '39F069',
  '09B134',
  '007812',
  '005A0A',
  '00390D',
  'D8FFFF',
  'B4FEFF',
  '4FF4F6',
  '05E8EA',
  '009A9C',
  '006769',
  '00494B',
  '002B2D',
  'C9F0FF',
  '96E2FF',
  '3FC9FF',
  '06BAFF',
  '0075AE',
  '005181',
  '043969',
  '00213C',
  'C6E2FF',
  '87C1FF',
  '439BF9',
  '1285FF',
  '0156B7',
  '003996',
  '002572',
  '001A54',
  'CFD8FF',
  '9CADF7',
  '5874F3',
  '3457F0',
  '1D2ED0',
  '0818AA',
  '00097A',
  '000658',
  'D8CCFF',
  'AC95F1',
  '7957E1',
  '5A2FD8',
  '3714A2',
  '250684',
  '13005A',
  '0D0042',
  'F1DBFF',
  'DB9FFF',
  'B444F6',
  '9B18E7',
  '6E00B1',
  '460081',
  '25005D',
  '160045',
  'FFDBEB',
  'FFB4D5',
  'FF5DA4',
  'FC1378',
  'C90158',
  '960045',
  '5D002E',
  '3C001E',
  'F0E7E4',
  'BDAAA2',
  '98796E',
  '63473D',
  '3A2622',
  '2A1916',
  '1B110F',
  '140E0E',
  'E1E8EA',
  'B7C6CF',
  '8A9BA5',
  '667C89',
  '446272',
  '38505E',
  '22333E',
  '0F1803',
];

export default presetColorList;
