import { useContext, useMemo } from 'react';
import { APIContext } from '@/Routing';
import { useParams } from 'react-router-dom';
import {
  GrandNotesContext,
  GrandNotesDependencies,
} from '@/app/component/page/note/grandnotes/useGrandNotes';
import { GrandNotes } from '@/app/component/page/note/grandnotes/GrandNotes';

export const GrandNotesPage = () => {
  const { client } = useContext(APIContext);
  const { parentNoteId } = useParams();

  const dependencies = useMemo((): GrandNotesDependencies => {
    return {
      parentNoteId: Number(parentNoteId),
      getChildNotesService: () =>
        parentNoteId ? client.fetchChildNotes(parentNoteId) : Promise.reject(),
    };
  }, [client]);

  return (
    <GrandNotesContext.Provider value={dependencies}>
      <GrandNotes />
    </GrandNotesContext.Provider>
  );
};
