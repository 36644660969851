import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { css } from '@emotion/react';
import Works from '@/app/component/Works';
import DefaultGridStyle from '@/app/style/DefaultGridStyle';
import { APIContext, GlobalContext, LayoutContext, MeContext } from '@/Routing';
import { defaultDummyItemCount } from '@/app/common/constant';
import shouldFetchNext from '@/app/common/common';
import { Helmet } from 'react-helmet';
import { defaultTemplateResultState, ITemplateResultState } from '@/app/state/ITemplateResultState';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import Text from '@dotpict-lib/foundation/typography/Text';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import Pixelart from '@dotpict-lib/component/core/Pixelart';
import { DotpictAction } from '@/app/common/reducer';
import { VerticalGravity } from '@dotpict-lib/component/core/layout/VerticalGravity';
import { HorizontalGravity } from '@dotpict-lib/component/core/layout/HorizontalGravity';
import Column from '@dotpict-lib/component/core/layout/Column';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';
import { LanguageContext } from '@/Language';

const TemplateEventComponent = () => {
  const { eventName } = useParams();
  const { globalState, dispatch } = useContext(GlobalContext);
  const { layoutParams } = useContext(LayoutContext);
  const meState = useContext(MeContext);
  const { language } = useContext(LanguageContext);
  const { client } = useContext(APIContext);

  const [loading, setLoading] = useState(false);

  const GridStyle = css(DefaultGridStyle, {
    gridGap: layoutParams.thumbnailImageGridGap,
    gridTemplateColumns: `repeat(${Math.floor(
      layoutParams.contentWidth / layoutParams.thumbnailImageSize,
    )}, ${layoutParams.thumbnailImageSize}px)`,
  });

  const renderOgp = () => (
    <Helmet>
      <meta property="twitter:title" content={globalState.templateResultState.title} />
      <meta property="twitter:description" content={globalState.templateResultState.description} />
      <meta property="twitter:image" content={globalState.templateResultState.ogpImageUrl} />
      <meta property="og:title" content={globalState.templateResultState.title} />
      <meta property="og:description" content={globalState.templateResultState.description} />
      <meta property="og:image" content={globalState.templateResultState.ogpImageUrl} />
    </Helmet>
  );

  const fetchData = async (currentState: ITemplateResultState) => {
    if (eventName === undefined) return;
    setLoading(true);
    const requestedUserId = meState.user.id;
    const response = await client.fetchTemplateResult(meState.token, currentState.nextUrl);
    const action: DotpictAction = {
      type: 'UPDATE_TEMPLATE_RESULT_STATE',
      payload: {
        templateResultState: {
          requestedUserId,
          imageUrl: response.imageUrl,
          ogpImageUrl: response.ogpImageUrl,
          eventName,
          title: response.title,
          description: response.description,
          works: currentState.works.concat(response.works),
          nextUrl: response.nextUrl,
        },
      },
    };
    dispatch(action);
    setLoading(false);
  };

  const handleScroll = () => {
    if (!loading && shouldFetchNext(globalState.templateResultState.nextUrl)) {
      fetchData(globalState.templateResultState);
    }
  };

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  useEffect(() => {
    if (
      eventName !== globalState.templateResultState.eventName ||
      meState.user.id !== globalState.templateResultState.requestedUserId
    ) {
      window.scrollTo(0, 0);
      const state: ITemplateResultState = defaultTemplateResultState;
      state.nextUrl = `${client.getBaseUrl}/template_canvas_result/${eventName}?lang=${language}`;
      const action: DotpictAction = {
        type: 'UPDATE_TEMPLATE_RESULT_STATE',
        payload: { templateResultState: state },
      };
      dispatch(action);
      fetchData(state);
    }
  }, [eventName, meState.token]);

  return (
    <>
      {renderOgp()}
      <Column verticalGravity={VerticalGravity.center} horizontalGravity={HorizontalGravity.center}>
        <Spacer height={layoutParams.contentTopSpace} />
        <Column
          padding="0 48"
          background={ColorStyle.WHITE}
          width={layoutParams.contentWidth - 48 * 2}
          verticalGravity={VerticalGravity.center}
          horizontalGravity={HorizontalGravity.center}
        >
          <Spacer height={48} />
          <Pixelart
            alt="banner_image_url"
            src={globalState.templateResultState.imageUrl}
            width={layoutParams.isMobile ? 256 : 384}
            height={layoutParams.isMobile ? 128 : 192}
          />
          <Spacer height={32} />
          <Text
            text={globalState.templateResultState.title}
            textStyle={TextStyle.BOLD16}
            colorStyle={ColorStyle.TEXT_PRIMARY}
          />
          <Spacer height={32} />
          <Text
            text={globalState.templateResultState.description}
            textStyle={TextStyle.REGULAR16}
            colorStyle={ColorStyle.TEXT_PRIMARY}
          />
          <Spacer height={48} />
        </Column>
        <Spacer height={32} />
      </Column>
      <Works
        works={globalState.templateResultState.works}
        isLoading={loading}
        isVisibleEnd={globalState.templateResultState.nextUrl === ''}
        isVisibleAds={false}
        isVisibleLike={false}
        gridStyle={GridStyle}
        imageSize={layoutParams.thumbnailImageSize}
        dummyItemCount={defaultDummyItemCount}
      />
    </>
  );
};

export default TemplateEventComponent;
