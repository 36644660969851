import { useCallback, useState } from 'react';
import CropperWrapper from '@/app/component/page/work/upload/CropperWrapper';
import { Area } from 'react-easy-crop';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';
import Button from '@dotpict-lib/component/dotpict/Button';
import TranslationKeys from '@/app/translation/TranslationKeys';
import useTranslator from '@/app/hook/useTranslator';
import { Dialog } from '@material-ui/core';
import Text from '@dotpict-lib/foundation/typography/Text';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import { CropArea, Rect } from '@/app/component/page/work/upload/types';
import Column from '@dotpict-lib/component/core/layout/Column';
import { HorizontalGravity } from '@dotpict-lib/component/core/layout/HorizontalGravity';

export type Media = {
  url: string | undefined;
  width: number;
  height: number;
};

type Props = {
  media: Media;
  open: boolean;
  handleClose: () => void;
  handleDecide: (croppedArea: CropArea, croppedRect: Rect) => void;
};

const CropDialog = ({ media, open, handleClose, handleDecide }: Props) => {
  const [croppedArea, setCroppedArea] = useState<CropArea>({
    width: 0,
    height: 0,
    x: 0,
    y: 0,
    scale: 1,
  });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>({
    width: 0,
    height: 0,
    x: 0,
    y: 0,
  });
  const onCropComplete = useCallback(
    (newCroppedArea: Area, newCroppedAreaPixels: Area, zoomRate: number) => {
      setCroppedArea({
        x: newCroppedArea.x,
        y: newCroppedArea.y,
        width: newCroppedArea.width,
        height: newCroppedArea.height,
        scale: zoomRate,
      });
      setCroppedAreaPixels({
        x: newCroppedAreaPixels.x,
        y: newCroppedAreaPixels.y,
        width: newCroppedAreaPixels.width,
        height: newCroppedAreaPixels.height,
      });
    },
    [setCroppedAreaPixels],
  );

  const translator = useTranslator();

  return (
    <Dialog fullWidth maxWidth="md" open={open}>
      <Column horizontalGravity={HorizontalGravity.center}>
        <Spacer height={16} />
        <Text
          text={translator(TranslationKeys.CropPosition)}
          textStyle={TextStyle.BOLD20}
          colorStyle={ColorStyle.TEXT_PRIMARY}
        />
        <Spacer height={16} />
        <CropperWrapper media={media} onCropComplete={onCropComplete} />
        <Spacer height={4} />
        <Text
          text={translator(TranslationKeys.ZoomRate)}
          textStyle={TextStyle.REGULAR11}
          colorStyle={ColorStyle.TEXT_SECONDARY}
        />
        <Spacer height={24} />
        <Button
          width={320}
          height={48}
          text={translator(TranslationKeys.CropDecide)}
          isFilled
          handleClick={() => handleDecide(croppedArea, croppedAreaPixels)}
        />
        <Spacer height={8} />
        <Button
          width={320}
          height={48}
          text={translator(TranslationKeys.Cancel)}
          isFilled={false}
          isBorder={false}
          handleClick={handleClose}
        />
        <Spacer height={24} />
      </Column>
    </Dialog>
  );
};

export default CropDialog;
