import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';
import styled from '@emotion/styled';
import Text from '@dotpict-lib/foundation/typography/Text';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import Pixelart from '@dotpict-lib/component/core/Pixelart';
import Button from '@material-ui/core/Button';
import { Row } from '@dotpict-lib/component/core/layout/Row';
import { VerticalGravity } from '@dotpict-lib/component/core/layout/VerticalGravity';

export type EventColorCodeProps = {
  color: string;
};

const EventColorCode = ({ color }: EventColorCodeProps) => {
  const itemHeight = 32;
  const borderWidth = 2;

  const ColorRect = styled.div({
    border: `solid ${borderWidth}px ${ColorStyle.WHITE_GRAY}`,
    background: `#${color}`,
    width: itemHeight - borderWidth * 2,
    height: itemHeight - borderWidth * 2,
  });

  const NoPaddingButton = styled(Button)({
    display: 'inline-block',
    padding: 0,
    minHeight: 32,
    minWidth: 32,
  });

  const handleClickCopy = () => {
    navigator.clipboard.writeText(color);
  };

  return (
    <Row verticalGravity={VerticalGravity.center}>
      <ColorRect />
      <Spacer width={6} />
      <Row
        background={ColorStyle.WHITE_GRAY}
        height={itemHeight}
        verticalGravity={VerticalGravity.center}
      >
        <Spacer width={4} />
        <Text
          text={`#${color}`}
          textStyle={TextStyle.REGULAR11}
          colorStyle={ColorStyle.TEXT_PRIMARY}
        />
        <Spacer width={4} />
      </Row>
      <Spacer width={4} />
      <NoPaddingButton>
        <Row padding={4}>
          <Pixelart
            src="https://storage.googleapis.com/dotpict-images/web/event/copy.svg"
            alt="copy_color_code"
            width={24}
            height={24}
            handleClick={() => handleClickCopy()}
          />
        </Row>
      </NoPaddingButton>
    </Row>
  );
};

export default EventColorCode;
