import { createContext, useContext } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@dotpict-lib/component/dotpict/Button';
import TextFieldComponent from '@/app/component/TextFieldComponent';
import TranslationKeys from '@/app/translation/TranslationKeys';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';
import Text from '@dotpict-lib/foundation/typography/Text';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import Loading from '@dotpict-lib/component/core/Loading';
import TagSection from '@/app/component/partial/work/upload/tagsection/TagSection';
import useTranslator from '@/app/hook/useTranslator';
import EventSection from '@/app/component/partial/work/upload/eventsection/EventSection';
import EditWorkUploadBoxComponent from '@/app/component/page/work/edit/EditWorkUploadBoxComponent';
import { Media } from '@/app/component/page/work/upload/CropDialog';
import { CropArea, Rect } from '@/app/component/page/work/upload/types';
import Column from '@dotpict-lib/component/core/layout/Column';
import { Row } from '@dotpict-lib/component/core/layout/Row';
import { HorizontalGravity } from '@dotpict-lib/component/core/layout/HorizontalGravity';
import AlertDialog from '@dotpict-lib/component/dialog/alert/AlertDialog';
import ConfirmationDialog from '@dotpict-lib/component/dialog/confirmation/ConfirmationDialog';

const MultiSectionWrapper = styled.div`
  padding: 32px;
  background: #ffffff;
`;

const styledLabel = css`
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 12px;
`;

export type EditWorkState = {
  defaultMedia: Media;
  defaultCropArea: CropArea | undefined;
  title: string;
  caption: string;
  fixedTag: string;
  tags: string[];
  allowComment: boolean;
  loading: boolean;
  isVisibleJoiningEvent: boolean;
  joiningEventTitle: string;
  errorMessage: string;
};

export type EditWorkProps = EditWorkState & {
  handleFileChanged: (file: File | undefined) => void;
  handleTitleChanged: (newTitle: string) => void;
  handleTagsChanged: (newTags: string[]) => void;
  handleCaptionChanged: (newCaption: string) => void;
  handleAllowCommentChanged: (newAllowComment: boolean) => void;
  handleClickPost: () => void;
  handleClickDelete: () => void;
  handleCloseError: () => void;
  handleDecideCrop: (croppedRect: Rect | undefined) => void;
};

export const EditWorkContext = createContext<EditWorkProps>({} as EditWorkProps);

const EditWork = () => {
  const translator = useTranslator();

  const {
    defaultMedia,
    defaultCropArea,
    title,
    caption,
    fixedTag,
    tags,
    allowComment,
    loading,
    isVisibleJoiningEvent,
    joiningEventTitle,
    errorMessage,
    handleFileChanged,
    handleTitleChanged,
    handleTagsChanged,
    handleCaptionChanged,
    handleAllowCommentChanged,
    handleClickPost,
    handleClickDelete,
    handleCloseError,
    handleDecideCrop,
  } = useContext(EditWorkContext);

  if (loading) {
    return <Loading />;
  }

  return (
    <Column>
      <Spacer height={32} />
      <MultiSectionWrapper>
        <Text
          text={translator(TranslationKeys.EditWorkInfo)}
          textStyle={TextStyle.BOLD20}
          colorStyle={ColorStyle.TEXT_PRIMARY}
        />
        <Spacer height={16} />
        <EditWorkUploadBoxComponent
          defaultMedia={defaultMedia}
          defaultCropArea={defaultCropArea}
          handleDecideCrop={handleDecideCrop}
          handleFileChange={handleFileChanged}
        />
        <Spacer height={16} />
      </MultiSectionWrapper>
      <Spacer height={24} />
      <MultiSectionWrapper>
        <TextFieldComponent
          id="title"
          label={translator(TranslationKeys.Title)}
          placeholder={translator(TranslationKeys.Title)}
          value={title}
          onChangeValue={handleTitleChanged}
          showMaxLength
          maxLength={32}
        />
        <Spacer height={40} />
        <TagSection fixedTag={fixedTag} tags={tags} handleTagsChanged={handleTagsChanged} />
        {isVisibleJoiningEvent && (
          <>
            <Spacer height={40} />
            <EventSection eventTitle={joiningEventTitle} />
          </>
        )}
        <Spacer height={40} />
        <TextFieldComponent
          id="caption"
          label={translator(TranslationKeys.Caption)}
          placeholder={translator(TranslationKeys.CaptionHint)}
          value={caption}
          onChangeValue={handleCaptionChanged}
          multiline
          showMaxLength
          maxLength={500}
          rows={4}
        />
        <Spacer height={40} />
        <div css={styledLabel}>{translator(TranslationKeys.OtherSettings)}</div>
        <Row>
          <FormControlLabel
            value="allowComment"
            control={<Switch />}
            label={translator(TranslationKeys.AllowComments)}
            checked={allowComment}
            onChange={(_, checked) => handleAllowCommentChanged(checked)}
            labelPlacement="start"
          />
        </Row>
        <Spacer height={32} />
        <Column horizontalGravity={HorizontalGravity.center}>
          <Button
            width={240}
            height={52}
            text={translator(TranslationKeys.Update)}
            isFilled
            handleClick={() => handleClickPost()}
          />
        </Column>
      </MultiSectionWrapper>
      <Spacer height={24} />
      <MultiSectionWrapper>
        <Column horizontalGravity={HorizontalGravity.center}>
          <ConfirmationDialog
            title={translator(TranslationKeys.DeleteWorkConfirmation)}
            message=""
            isEnabled
            handleClickOk={handleClickDelete}
          >
            <Button
              width={240}
              height={52}
              text={translator(TranslationKeys.Delete)}
              isFilled={false}
              isBorder
            />
          </ConfirmationDialog>
        </Column>
      </MultiSectionWrapper>
      <Spacer height={120} />
      <AlertDialog
        title={translator(TranslationKeys.FailedToUpload)}
        message={errorMessage}
        open={errorMessage !== ''}
        handleClose={handleCloseError}
      />
    </Column>
  );
};

export default EditWork;
