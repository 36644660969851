import { useState, useCallback, createContext, useEffect, useContext } from 'react';
import {
  CanvasHistoryType,
  Color,
  PaletteType,
  PixelsType,
} from '@/app/component/page/draw/hooks/types';
import { SizeType } from '@/app/component/page/draw/Context/SizeContext';
import { useCurrentCanvasData } from '@/app/component/page/draw/hooks/storage';
import { HistoryContext } from '@/app/component/page/draw/Context/HistoryContext';

export type ColorContextType = {
  palette: PaletteType;
  color: Color;
  colorIndex: number;
  background: Color;
  pixels: PixelsType | undefined;
};

export const ColorContext = createContext<ColorContextType>({
  palette: [],
  color: 'ffffff',
  colorIndex: 0,
  background: 'ffffff',
  pixels: undefined,
});

export const useColors = (
  pushHistory: (history: CanvasHistoryType) => void,
  canvasSize: SizeType,
) => {
  const [storageData, _, __, setStorageDataForPalette] = useCurrentCanvasData();
  const [palette, setPalette] = useState<PaletteType>(storageData.palette);
  const [color, setColor] = useState<string>(storageData.palette[0]);
  const [index, setIndex] = useState<number>(0);
  const [pixels, setPixels] = useState<PixelsType>();
  const [backgroundColor, _setBackgroundColor] = useState<string>('ffffff');
  const history = useContext(HistoryContext);

  const editPalette = useCallback(
    (index: number, newColor: string) => {
      if (pixels === undefined) return;
      const newPalette = [...palette];
      newPalette[index] = newColor;
      const newPixels = pixels.map((pixelRow) =>
        pixelRow.map((pixel) => (pixel === palette[index] ? newColor : pixel)),
      );
      setPixels(newPixels);
      setPalette(newPalette);
      setColor(newColor);
      setStorageDataForPalette(newPalette);
      pushHistory({ pixels: newPixels, canvasSize, palette: newPalette });
    },
    [
      canvasSize,
      palette,
      pixels,
      setPalette,
      setPixels,
      setColor,
      pushHistory,
      setStorageDataForPalette,
    ],
  );

  const selectColor = useCallback(
    (selectedIndex: number) => {
      return () => {
        setIndex(selectedIndex);
      };
    },
    [setIndex],
  );

  const initializePixels = useCallback(() => {
    const newPixels = Array.from({ length: canvasSize.height }, () => {
      return Array.from({ length: canvasSize.width }, () => backgroundColor);
    });
    setPixels(newPixels);
    pushHistory({ pixels: newPixels, canvasSize, palette });
  }, [pushHistory, canvasSize, palette]);

  // ツールがキャンバスに変化を加えるために実行するAction
  const setPixelsAction = useCallback(
    (newPixels: PixelsType, ignoreHistory: boolean = false) => {
      setPixels(newPixels);

      // ペンツールで書き込むなど、pixelsに変更を加えたときはHistoryに追加
      if (!ignoreHistory) {
        pushHistory({ pixels: newPixels, canvasSize, palette });
      }
    },
    [pushHistory, canvasSize, setPixels],
  );

  useEffect(() => {
    setColor(palette[index]);
  }, [palette, index]);

  // Redo/UndoによりHistoryのpointerが変化して、pixelsに変更がある場合は変更する
  useEffect(() => {
    const newHistory = history.canvasHistories[history.pointer];
    if (newHistory !== undefined && newHistory.pixels !== undefined) {
      setPixels(newHistory.pixels);
      setPalette(newHistory.palette);
    }
  }, [history.pointer]);

  return [
    pixels,
    setPixelsAction,
    initializePixels,
    color,
    index,
    palette,
    selectColor,
    editPalette,
    backgroundColor,
  ] as [
    typeof pixels,
    typeof setPixelsAction,
    typeof initializePixels,
    typeof color,
    typeof index,
    typeof palette,
    typeof selectColor,
    typeof editPalette,
    typeof backgroundColor,
  ];
};
