import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { NotificationsBindModel } from '@/app/component/page/notifications/index';
import { APIContext, GlobalContext, MeContext } from '@/Routing';
import { NotificationType } from '@/app/model/NotificationType';
import { DotpictAction } from '@/app/common/reducer';
import shouldFetchNext from '@/app/common/common';

type OutputProps = {
  bindModel: NotificationsBindModel;
};

const useNotifications = (): OutputProps => {
  const { globalState, dispatch } = useContext(GlobalContext);
  const [isLoading, setLoading] = useState(false);
  const [isVisibleEnd, setVisibleEnd] = useState(false);
  const meState = useContext(MeContext);
  const { client } = useContext(APIContext);

  const fetchData = async (
    currentNotifications: NotificationType[],
    currentNextUrl: string | null,
  ) => {
    setLoading(true);
    setVisibleEnd(false);
    const requestedUserId = meState.user.id;
    const nextUrl = currentNextUrl || `${client.getBaseUrl}/me/notifications`;
    const response = await client.fetchNotifications(meState.token, nextUrl);
    const notifications = currentNotifications.concat(response.notifications);
    const action: DotpictAction = {
      type: 'UPDATE_NOTIFICATIONS_STATE',
      payload: {
        notificationsState: {
          notifications,
          nextUrl: response.nextUrl,
          requestedUserId,
        },
      },
    };
    dispatch(action);
    setVisibleEnd(response.nextUrl === '');
    setLoading(false);
    if (shouldFetchNext(response.nextUrl)) {
      fetchData(notifications, response.nextUrl);
    }
  };

  const handleScroll = () => {
    if (!isLoading && shouldFetchNext(globalState.notificationsState.nextUrl)) {
      fetchData(
        globalState.notificationsState.notifications,
        globalState.notificationsState.nextUrl,
      );
    }
  };

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  useEffect(() => {
    if (
      globalState.notificationsState.notifications.length === 0 ||
      globalState.notificationsState.requestedUserId !== meState.user.id
    ) {
      window.scrollTo(0, 0);
      fetchData([], null);
    }
  }, [meState]);

  return {
    bindModel: {
      notifications: globalState.notificationsState.notifications,
      isLoading,
      isVisibleEnd,
    },
  };
};

export default useNotifications;
