import { useContext, useMemo } from 'react';
import { APIContext } from '@/Routing';
import { PagingType } from '@dotpict-lib/model/PagingType';
import { useParams } from 'react-router-dom';
import { Dependencies, OdaiContext } from '@/app/component/page/event/odai/useOdai';
import Odai from '@/app/component/page/event/odai/Odai';

const OdaiPage = () => {
  const { client } = useContext(APIContext);
  const { odaiTag } = useParams();

  const dependencies = useMemo((): Dependencies => {
    return {
      getOdaiService: (odaiTag: string) => client.fetchOdaiDetail(odaiTag),
      getOdaiWorksService: (paging: PagingType) => client.fetchWorks(paging.nextUrl),
    };
  }, [client]);

  return (
    <OdaiContext.Provider value={dependencies}>
      <Odai odaiTag={odaiTag ?? ''} />
    </OdaiContext.Provider>
  );
};

export default OdaiPage;
