import { BattleTeamType, defaultTeam } from '@dotpict-sns-lib/model/OfficialEvent/BattleTeamType';

export type BattleInfoType = {
  description: string;
  teamOne: BattleTeamType;
  teamTwo: BattleTeamType;
};

export const defaultBattleInfo: BattleInfoType = {
  description: '',
  teamOne: defaultTeam,
  teamTwo: defaultTeam,
};
