import Text from '@dotpict-lib/foundation/typography/Text';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import TranslationKeys from '@/app/translation/TranslationKeys';
import { css } from '@emotion/react';
import useTranslator from '@/app/hook/useTranslator';
import Pixelart from '@dotpict-lib/component/core/Pixelart';
import { Row } from '@dotpict-lib/component/core/layout/Row';
import { HorizontalGravity } from '@dotpict-lib/component/core/layout/HorizontalGravity';
import { VerticalGravity } from '@dotpict-lib/component/core/layout/VerticalGravity';

export type FileSelectButtonProps = {
  handleFileSelect: (file: File | undefined) => void;
};

const styledLabel = css`
  width: 120px;
  height: 32px;
`;

const styledInput = css`
  display: none;
`;

const OverlayFileSelectButton = ({ handleFileSelect }: FileSelectButtonProps) => {
  const translator = useTranslator();

  return (
    <label css={styledLabel} htmlFor="upload-file">
      <Row
        width={120}
        height={32}
        innerPadding={8}
        background="rgba(0, 0, 0, 0.8)"
        horizontalGravity={HorizontalGravity.center}
        verticalGravity={VerticalGravity.center}
      >
        <Pixelart
          alt="image"
          src="https://storage.googleapis.com/dotpict-images/web/ic_image_white.svg"
          width={15}
          height={15}
        />
        <Text
          text={translator(TranslationKeys.ChangeImage)}
          textStyle={TextStyle.REGULAR14}
          colorStyle={ColorStyle.WHITE}
        />
      </Row>
      <input
        id="upload-file"
        accept="image/png,image/gif"
        css={styledInput}
        type="file"
        onChange={(event) => {
          const file = event.target.files?.item(0);
          if (!file) return;
          handleFileSelect(file);
        }}
      />
    </label>
  );
};

export default OverlayFileSelectButton;
