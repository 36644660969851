import styled from '@emotion/styled';
import Text from '@dotpict-lib/foundation/typography/Text';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import useTranslator from '@/app/hook/useTranslator';
import TranslationKeys from '@/app/translation/TranslationKeys';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';
import { Row } from '@dotpict-lib/component/core/layout/Row';
import { HorizontalGravity } from '@dotpict-lib/component/core/layout/HorizontalGravity';
import { VerticalGravity } from '@dotpict-lib/component/core/layout/VerticalGravity';
import FontStyle from '@dotpict-lib/foundation/typography/FontStyle';

const ScoreRateContainer = styled.div`
  position: relative;
  width: 100%;
`;

const LeftUpBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

const LeftDownBackground = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
`;

const RightUpBackground = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;

const RightDownBackground = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
`;

export type ScoreRateProps = {
  teamOneScore: number;
  teamOneColor: string;
  teamTwoScore: number;
  teamTwoColor: string;
  contentWidth: number;
  cropColor: string;
};

const ScoreRate = (props: ScoreRateProps) => {
  const { teamOneScore, teamOneColor, teamTwoScore, teamTwoColor, contentWidth, cropColor } = props;
  const scoreSum = teamOneScore + teamTwoScore;
  const translator = useTranslator();

  const TeamOneColorRect = styled.div({
    background: teamOneColor,
  });

  const TeamTwoColorRect = styled.div({
    background: teamTwoColor,
  });

  return (
    <Row width={contentWidth} height={40}>
      <ScoreRateContainer>
        <LeftUpBackground>
          <Row width={8} height={8} background={cropColor} />
        </LeftUpBackground>
        <LeftDownBackground>
          <Row width={8} height={8} background={cropColor} />
        </LeftDownBackground>
        <RightUpBackground>
          <Row width={8} height={8} background={cropColor} />
        </RightUpBackground>
        <RightDownBackground>
          <Row width={8} height={8} background={cropColor} />
        </RightDownBackground>
        <Row>
          <TeamOneColorRect>
            <Row
              width={(contentWidth * teamOneScore) / scoreSum}
              height={40}
              horizontalGravity={HorizontalGravity.start}
              verticalGravity={VerticalGravity.center}
            >
              <Spacer width={24} />
              <Text
                text={teamOneScore.toString()}
                textStyle={TextStyle.BOLD20}
                fontStyle={FontStyle.BOLD_PIXELMPLUS12}
                colorStyle={ColorStyle.WHITE}
              />
              <Text
                text={`${translator(TranslationKeys.Point)}`}
                textStyle={TextStyle.BOLD16}
                fontStyle={FontStyle.BOLD_PIXELMPLUS12}
                colorStyle={ColorStyle.WHITE}
              />
            </Row>
          </TeamOneColorRect>
          <TeamTwoColorRect>
            <Row
              width={(contentWidth * teamTwoScore) / scoreSum}
              height={40}
              horizontalGravity={HorizontalGravity.end}
              verticalGravity={VerticalGravity.center}
            >
              <Text
                text={teamTwoScore.toString()}
                textStyle={TextStyle.BOLD20}
                fontStyle={FontStyle.BOLD_PIXELMPLUS12}
                colorStyle={ColorStyle.WHITE}
              />
              <Text
                text={`${translator(TranslationKeys.Point)}`}
                textStyle={TextStyle.BOLD16}
                fontStyle={FontStyle.BOLD_PIXELMPLUS12}
                colorStyle={ColorStyle.WHITE}
              />
              <Spacer width={24} />
            </Row>
          </TeamTwoColorRect>
        </Row>
      </ScoreRateContainer>
    </Row>
  );
};

export default ScoreRate;
