import React, { useState, useEffect, useCallback } from 'react';
import styled from '@emotion/styled';
import Text from '@dotpict-lib/foundation/typography/Text';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';

const ColorCodeInputContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

const Input = styled.input`
  margin-left: 8px;
`;

type Props = {
  colorCode: string | undefined;
  setColorCode(newValue: string): void;
};

const ColorCodeInput = ({ colorCode, setColorCode }: Props) => {
  const [inputColorCode, setInputColorCode] = useState<string>('');

  const isValidColorCode = useCallback((code: string) => /[0-9A-Fa-f]{6}/.test(code), []);

  // カラーコードを直接入力したとき
  const handleChangeInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;

    setInputColorCode(newValue);

    // カラーコードのフォーマットに従っていない場合はcolorCodeの確定は行わない
    if (!isValidColorCode(newValue)) {
      return;
    }

    setColorCode(newValue);
  }, []);

  useEffect(() => {
    if (colorCode !== undefined) {
      setInputColorCode(colorCode);
    }
  }, [colorCode]);

  return (
    <ColorCodeInputContainer>
      <Text
        // TODO: 翻訳
        text="カラーコード"
        textStyle={TextStyle.BOLD14}
        colorStyle={ColorStyle.TEXT_PRIMARY}
      />
      <Input type="text" value={inputColorCode} onChange={handleChangeInput} />
    </ColorCodeInputContainer>
  );
};

export default ColorCodeInput;
