import { useContext, useState } from 'react';
import { APIContext, GlobalContext, MeContext } from '@/Routing';
import {
  ThreadInputAction,
  ThreadInputViewState,
} from '@/app/component/page/work/thread/input/WorkThreadInput';

type Props = {
  workId: number;
  parentThreadId?: number;
};

const useThreadInput = ({
  workId,
  parentThreadId,
}: Props): ThreadInputViewState & ThreadInputAction => {
  const meState = useContext(MeContext);
  const { dispatch } = useContext(GlobalContext);
  const [inputComment, setInputComment] = useState('');
  const [isLoading, setLoading] = useState(false);
  const { client } = useContext(APIContext);

  const postThread = async () => {
    setLoading(true);
    const response = await client.postWorkThread(
      `${workId}`,
      `${parentThreadId ?? 0}`,
      inputComment,
    );
    setInputComment('');
    dispatch({
      type: 'POSTED_THREAD',
      payload: {
        newThread: response.thread,
      },
    });
    dispatch({
      type: 'UPDATE_WORK',
      payload: {
        work: response.work,
      },
    });
    setLoading(false);
  };

  const handleInputCommentChange = (comment: string) => {
    setInputComment(comment);
  };

  const handleSendCommentClick = () => {
    if (inputComment === '') return;
    postThread();
  };

  return {
    profileImageUrl: meState.user.profileImageUrl,
    inputComment,
    isLoading,
    handleInputCommentChange,
    handleSendCommentClick,
  };
};

export default useThreadInput;
