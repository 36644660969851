import { createContext, useContext, useEffect, useState } from 'react';
import { GlobalContext } from '@/Routing';
import { DotpictAction } from '@/app/common/reducer';
import {
  dummyGetChildNotesService,
  GetChildNotesService,
} from '@/app/service/GetChildNotes/GetChildNotesService';
import { emptyChildNotesState } from '@/app/state/ChildNotesState';
import { ChildNotesViewProps } from '@/app/component/page/note/childnotes/ChildNotes';

export type ChildNotesDependencies = {
  parentNoteId: number;
  getChildNotesService: GetChildNotesService;
};

export const ChildNotesContext = createContext<ChildNotesDependencies>({
  parentNoteId: 0,
  getChildNotesService: dummyGetChildNotesService,
});

export const useChildNotes = (): ChildNotesViewProps => {
  const { dispatch, globalState } = useContext(GlobalContext);
  const { parentNoteId, getChildNotesService } = useContext(ChildNotesContext);
  const [isLoading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    dispatch({
      type: 'UPDATE_CHILD_NOTES_STATE',
      payload: {
        childNotesState: emptyChildNotesState,
      },
    });
    const response = await getChildNotesService();
    const action: DotpictAction = {
      type: 'UPDATE_CHILD_NOTES_STATE',
      payload: {
        childNotesState: {
          parentNote: response.parentNote,
          notes: response.notes,
        },
      },
    };
    dispatch(action);
    setLoading(false);
  };

  useEffect(() => {
    if (parentNoteId === 0 || globalState.childNotesState.parentNote.id === parentNoteId) return;
    fetchData();
  }, [parentNoteId]);

  return {
    parentNote: globalState.childNotesState.parentNote,
    notes: globalState.childNotesState.notes,
    isLoading,
  };
};
