import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { forceCheck } from 'react-lazyload';
import { APIContext, GlobalContext, MeContext } from '@/Routing';
import { DotpictAction } from '@/app/common/reducer';
import { EditWorkProps } from '@/app/component/page/work/edit/EditWork';
import { ErrorResponseType } from '@/app/model/responsetype/ErrorResponseType';
import { defaultWorkDetailState } from '@/app/state/IWorkDetailState';
import { WorkType } from '@/app/model/WorkType';
import { Media } from '@/app/component/page/work/upload/CropDialog';
import { CropArea, Rect } from '@/app/component/page/work/upload/types';

const useEditWork = (): EditWorkProps => {
  const { workId } = useParams();
  const navigator = useNavigate();
  const { dispatch } = useContext(GlobalContext);
  const meState = useContext(MeContext);
  const [title, setTitle] = useState('');
  const [joiningEventTitle, setJoiningEventTitle] = useState('');
  const [caption, setCaption] = useState('');
  const [fixedTag, setFixedTag] = useState<string>('');
  const [tags, setTags] = useState<string[]>([]);
  const [allowComment, setAllowComment] = useState(true);
  const [defaultMedia, setDefaultMedia] = useState<Media>({ url: '', width: 0, height: 0 });
  const [defaultCropArea, setDefaultCropArea] = useState<CropArea | undefined>();
  const [image, setImage] = useState<File | undefined>();
  const [colors, setColors] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [currentWork, setWork] = useState<WorkType | null>(null);
  const { client } = useContext(APIContext);
  const [cropRect, setCropRect] = useState<Rect | undefined>();

  const fetchWork = async () => {
    if (workId === undefined) return;
    setLoading(true);
    const { work } = await client.fetchWorkDetail(Number(workId));
    if (work.user.id !== meState.user.id) {
      navigator('/');
      return;
    }
    setWork(work);
    setTitle(work.title);
    setDefaultMedia({ url: work.imageUrl, width: work.width, height: work.height });
    setDefaultCropArea(
      work.cropRect === null
        ? undefined
        : {
            x: work.cropRect.x,
            y: work.cropRect.y,
            width: work.cropRect.width,
            height: work.cropRect.height,
            scale:
              work.width < work.height
                ? work.width / work.cropRect.width
                : work.height / work.cropRect.height,
          },
    );
    setCaption(work.caption);
    setColors(work.colorCodes.join(','));
    setAllowComment(work.allowThread);
    setFixedTag('');
    setTags(work.tags);
    if (work.userEventId !== 0) {
      const eventDetail = await client.fetchUserEventDetail(work.userEventId.toString());
      setJoiningEventTitle(eventDetail.title);
      setFixedTag(eventDetail.tag);
    }
    if (work.officialEventId !== 0) {
      const eventDetail = await client.fetchOfficialEventDetail(work.officialEventId.toString());
      setJoiningEventTitle(eventDetail.title);
      setFixedTag(eventDetail.tag);
    }
    setLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchWork();
  }, [workId]);

  useEffect(() => {
    // なぜかoekakiと写真が読み込まれないのでforcecheck
    setTimeout(forceCheck, 50);
  }, []);

  const handleFileChanged = (file: File | undefined) => {
    setColors('');
    setImage(file);
  };

  const handleTitleChanged = (newTitle: string) => {
    setTitle(newTitle);
  };

  const handleTagsChanged = (newTags: string[]) => {
    setTags(newTags);
  };

  const handleCaptionChanged = (newCaption: string) => {
    setCaption(newCaption);
  };

  const handleAllowCommentChanged = (newAllowComment: boolean) => {
    setAllowComment(newAllowComment);
  };

  const handleCloseError = () => {
    setErrorMessage('');
  };

  const handleDecideCrop = (croppedRect: Rect | undefined) => {
    setCropRect(croppedRect);
  };

  const handleClickDelete = async () => {
    if (workId === undefined || currentWork === null) {
      return;
    }
    setLoading(true);
    await client.deleteWork(workId);
    setLoading(false);
    const action: DotpictAction = {
      type: 'DELETE_WORK',
      payload: {
        workId: Number(workId),
      },
    };
    dispatch(action);
    navigator(-2);
  };

  const handleClickPost = async () => {
    if (workId === undefined || currentWork === null) {
      return;
    }
    setLoading(true);
    if (image) {
      const response = await client
        .postEditImage(workId, colors, cropRect, image)
        .catch((error: ErrorResponseType) => {
          setErrorMessage(error.message);
        });
      if (!response) {
        setLoading(false);
        return;
      }
      dispatch({
        type: 'UPDATE_WORK',
        payload: {
          work: response,
        },
      });
    } else if (cropRect) {
      const response = await client
        .postEditCropRect(workId, cropRect)
        .catch((error: ErrorResponseType) => {
          setErrorMessage(error.message);
        });
      if (!response) {
        setLoading(false);
        return;
      }
      dispatch({
        type: 'UPDATE_WORK',
        payload: {
          work: response,
        },
      });
    } else if (defaultCropArea && !cropRect) {
      const response = await client
        .postEditCropRect(workId, {
          x: 0,
          y: 0,
          width: currentWork.width,
          height: currentWork.height,
        })
        .catch((error: ErrorResponseType) => {
          setErrorMessage(error.message);
        });
      if (!response) {
        setLoading(false);
        return;
      }
      dispatch({
        type: 'UPDATE_WORK',
        payload: {
          work: response,
        },
      });
    }
    if (currentWork?.allowThread !== allowComment) {
      await client.postEditAllowThread(meState.token, workId, allowComment);
    }
    if (currentWork?.caption !== caption) {
      await client.postEditText(meState.token, workId, caption);
    }
    if (currentWork?.title !== title) {
      await client.postEditTitle(meState.token, workId, title);
    }
    if (currentWork?.tags !== tags) {
      await client.postEditTags(meState.token, workId, tags);
    }
    setLoading(false);
    const action: DotpictAction = {
      type: 'UPDATE_WORK_DETAIL_STATE',
      payload: {
        workDetailState: defaultWorkDetailState,
      },
    };
    dispatch(action);
    navigator(-1);
  };

  return {
    defaultMedia,
    defaultCropArea,
    title,
    caption,
    fixedTag,
    tags,
    allowComment,
    loading,
    isVisibleJoiningEvent: joiningEventTitle !== '',
    joiningEventTitle,
    errorMessage,
    handleFileChanged,
    handleTitleChanged,
    handleTagsChanged,
    handleCaptionChanged,
    handleAllowCommentChanged,
    handleClickPost,
    handleClickDelete,
    handleCloseError,
    handleDecideCrop,
  };
};

export default useEditWork;
