import Pixelart from '@dotpict-lib/component/core/Pixelart';
import Text from '@dotpict-lib/foundation/typography/Text';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import { Row } from '@dotpict-lib/component/core/layout/Row';
import Column from '@dotpict-lib/component/core/layout/Column';
import Clickable from '@dotpict-lib/component/core/Clickable';
import { VerticalGravity } from '@dotpict-lib/component/core/layout/VerticalGravity';
import { Divider } from '@material-ui/core';
import { NoteType } from '@dotpict-sns-lib/model/Note/NoteType';
import { useNote } from '@/app/component/page/note/item/useNote';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';
import { NoteFooter } from '@/app/component/page/note/item/NoteFooter';
import { NoteScope } from '@dotpict-sns-lib/component/NoteScope';

export type NoteProps = {
  isVisibleFooter: boolean;
  note: NoteType;
};

export type NoteImage = {
  imageUrl: string;
  width: number;
  height: number;
};

export type NoteState = {
  profileImageUrl: string;
  userName: string;
  text: string;
  image: NoteImage | null;
  scope: string | null;
  createdAt: string;
  onClickUser: () => void;
};

export const Note = ({ isVisibleFooter, note }: NoteProps) => {
  const { profileImageUrl, userName, text, image, scope, createdAt, onClickUser } = useNote({
    note,
  });

  return (
    <Row width="100%" background={ColorStyle.WHITE}>
      <Spacer width={note.type === 'root' ? 0 : 16} />
      <Column width="100%" padding={16} innerPadding={8}>
        <Row verticalGravity={VerticalGravity.top} innerPadding={16}>
          <Clickable onClick={onClickUser} minWidth={48} minHeight={48}>
            <Pixelart alt={profileImageUrl} src={profileImageUrl} width={48} height={48} />
          </Clickable>
          <Column innerPadding={8}>
            <Text
              text={userName}
              textStyle={TextStyle.BOLD14}
              colorStyle={ColorStyle.TEXT_PRIMARY}
            />
            <Text
              text={text}
              textStyle={TextStyle.REGULAR12}
              colorStyle={ColorStyle.TEXT_PRIMARY}
            />
            {image && (
              <Pixelart
                alt={image.imageUrl}
                src={image.imageUrl}
                width={352}
                height={(352 * image.width) / image.height}
              />
            )}
            <Row height={18} innerPadding={8} verticalGravity={VerticalGravity.center}>
              <Text
                text={createdAt}
                textStyle={TextStyle.REGULAR12}
                colorStyle={ColorStyle.TEXT_PRIMARY}
              />
              {scope && <NoteScope scope={scope} />}
            </Row>
          </Column>
        </Row>
        {isVisibleFooter && (
          <>
            <Divider />
            <Spacer height={8} />
            <NoteFooter note={note} />
          </>
        )}
      </Column>
    </Row>
  );
};
