import React, { useCallback, useRef, useState } from 'react';
import Pixelart from '@dotpict-lib/component/core/Pixelart';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import Text from '@dotpict-lib/foundation/typography/Text';
import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import styled from '@emotion/styled';
import useTranslator from '@/app/hook/useTranslator';
import TranslationKeys from '@/app/translation/TranslationKeys';

const MenuIcon = styled.div`
  cursor: pointer;
`;

type Props = {
  onDownload(): void;
};

const PopupMenu = ({ onDownload }: Props) => {
  const [openMenu, setOpenMenu] = useState(false);
  const menuAnchorRef = useRef(null);
  const translator = useTranslator();

  const handleClickMenuIcon = useCallback(() => {
    setOpenMenu((prevOpen) => !prevOpen);
  }, []);

  const handleClosePopper = useCallback(() => {
    setOpenMenu(false);
  }, []);

  const handleListKeyDown = useCallback((event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenMenu(false);
    }
  }, []);

  return (
    <>
      <Popper
        open={openMenu}
        anchorEl={menuAnchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClosePopper}>
                <MenuList
                  autoFocusItem={openMenu}
                  id="menu-list-grow"
                  onKeyDown={(e) => handleListKeyDown(e)}
                >
                  <MenuItem onClick={onDownload}>
                    <Text
                      text={translator(TranslationKeys.SaveCanvas)}
                      textStyle={TextStyle.BOLD14}
                      colorStyle={ColorStyle.TEXT_PRIMARY}
                    />
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      <MenuIcon
        ref={menuAnchorRef}
        aria-controls={openMenu ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleClickMenuIcon}
      >
        <Pixelart
          alt="menu"
          src="https://storage.googleapis.com/dotpict-images/web/ic_menu.svg"
          width={24}
          height={24}
        />
      </MenuIcon>
    </>
  );
};

export default PopupMenu;
