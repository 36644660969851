import { NotificationType } from '@/app/model/NotificationType';
import Notification from '@/app/component/partial/notification/Notification';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';
import DotpictLink from '@/app/component/DotpictLinkComponent';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import Text from '@dotpict-lib/foundation/typography/Text';
import useTranslator from '@/app/hook/useTranslator';
import TranslationKeys from '@/app/translation/TranslationKeys';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import useNotifications from '@/app/component/page/notifications/useNotifications';
import Loading from '@dotpict-lib/component/core/Loading';
import Column from '@dotpict-lib/component/core/layout/Column';
import End from '@dotpict-lib/component/core/End';

export type NotificationsBindModel = {
  notifications: NotificationType[];
  isLoading: boolean;
  isVisibleEnd: boolean;
};

const Notifications = () => {
  const translator = useTranslator();
  const { bindModel } = useNotifications();

  return (
    <div>
      <Column>
        <Spacer height={40} />
        <Text
          text={translator(TranslationKeys.Notifications)}
          textStyle={TextStyle.BOLD20}
          colorStyle={ColorStyle.TEXT_PRIMARY}
        />
        <Spacer height={16} />
        {bindModel.notifications.map((notification) => (
          <>
            <DotpictLink to={`${notification.url?.replace('https://dotpict.net', '')}`}>
              <Notification notification={notification} background={ColorStyle.WHITE} />
            </DotpictLink>
            <Spacer height={1} />
          </>
        ))}
        {bindModel.isLoading && <Loading />}
        {bindModel.isVisibleEnd && <End />}
      </Column>
    </div>
  );
};

export default Notifications;
