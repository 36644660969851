import { ListItem } from '@/app/component/partial/listitem/ListItem';

export type ConvertListItemsProps<T> = {
  models: any[];
  isVisibleEnd: boolean;
  notFoundMessage: string;
  firstVisibleAdsLine: number;
  insertAdsPerLines: number;
  removeAds: boolean;
  listItemConverter: (model: T) => ListItem;
};

export const convertListItems = <T>({
  models,
  isVisibleEnd,
  notFoundMessage,
  firstVisibleAdsLine,
  insertAdsPerLines,
  removeAds,
  listItemConverter,
}: ConvertListItemsProps<T>): ListItem[] => {
  const listItems: ListItem[] = [];
  if (models.length === 0) {
    listItems.push({
      type: 'MESSAGE',
      message: notFoundMessage,
    });
    listItems.push({ type: 'END' });
    if (!removeAds) {
      listItems.push({ type: 'RECTANGLE_ADS' });
    }
  }
  models.forEach((model, index) => {
    if (
      !removeAds &&
      index !== 0 &&
      index >= firstVisibleAdsLine &&
      (index - firstVisibleAdsLine) % insertAdsPerLines === 0
    ) {
      listItems.push({ type: 'RECTANGLE_ADS' });
    }
    listItems.push(listItemConverter(model));
  });
  if (isVisibleEnd) {
    listItems.push({ type: 'END' });
    if (!removeAds) {
      listItems.push({ type: 'RECTANGLE_ADS' });
    }
  }
  return listItems;
};
