import { WorkType } from '../model/WorkType';

export interface MyPageStateType {
  requestedUserId: number;
  type: string | null;
  works: WorkType[];
  nextUrl: string;
}

export const defaultMyPageState: MyPageStateType = {
  requestedUserId: 0,
  type: null,
  works: [],
  nextUrl: '',
};
