enum TextStyle {
  REGULAR9,
  REGULAR10,
  REGULAR11,
  REGULAR12,
  REGULAR13,
  REGULAR14,
  REGULAR16,
  BOLD11,
  BOLD12,
  BOLD14,
  BOLD13,
  BOLD16,
  BOLD20,
  BOLD36,
}

export default TextStyle;
