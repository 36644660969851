import { Button } from '@material-ui/core';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import Text from '@dotpict-lib/foundation/typography/Text';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import { useIntl } from 'react-intl';
import TranslationKeys from '@/app/translation/TranslationKeys';
import { MouseEventHandler } from 'react';
import { VerticalGravity } from '@dotpict-lib/component/core/layout/VerticalGravity';
import { HorizontalGravity } from '@dotpict-lib/component/core/layout/HorizontalGravity';
import { Row } from '@dotpict-lib/component/core/layout/Row';

interface IProps {
  isLiked: boolean;
  handleClick: MouseEventHandler;
}

const MakingLikeComponent = (props: IProps) => {
  const { isLiked, handleClick } = props;
  const intl = useIntl();

  return (
    <Button onClick={handleClick} disabled={isLiked}>
      <Row
        width="240"
        height="52"
        background={isLiked ? ColorStyle.PRIMARY_PALE : ColorStyle.PRIMARY}
        verticalGravity={VerticalGravity.center}
        horizontalGravity={HorizontalGravity.center}
      >
        <Text
          text={intl.formatMessage({ id: TranslationKeys.MakingLike })}
          textStyle={TextStyle.BOLD16}
          colorStyle={ColorStyle.WHITE}
        />
      </Row>
    </Button>
  );
};

export default MakingLikeComponent;
