import { GetWorkDetailService } from '@/app/service/GetWorkDetail/GetWorkDetailService';
import TestWorkJuneBride from '@/app/test/work/TestWorkJuneBride';
import TestUserSraim from '@/app/test/user/TestUserSraim';
import TestWorkPiplup from '@/app/test/work/TestWorkPiplup';
import TestWorkDogVsCat from '@/app/test/work/TestWorkDogVsCat';

const dummyGetWorkDetailService: GetWorkDetailService = (_: number) => {
  return Promise.resolve({
    work: TestWorkJuneBride,
    userSummary: {
      user: TestUserSraim,
      works: [TestWorkDogVsCat],
    },
    recommendedWorks: [TestWorkPiplup],
  });
};

export default dummyGetWorkDetailService;
