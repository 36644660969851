import { WorkThreadType } from '@/app/model/WorkThreadType';
import emptyWork, { WorkType } from '../model/WorkType';

export type WorkThreadsState = {
  work: WorkType;
  threads: WorkThreadType[];
};

export const defaultWorkThreadsState: WorkThreadsState = {
  work: emptyWork,
  threads: [],
};
