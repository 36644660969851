import { RequestType } from '@/app/model/RequestType';
import { Link } from 'react-router-dom';
import Pixelart from '@dotpict-lib/component/core/Pixelart';
import Text from '@dotpict-lib/foundation/typography/Text';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';
import useTranslator from '@/app/hook/useTranslator';
import TranslationKeys from '@/app/translation/TranslationKeys';
import Column from '@dotpict-lib/component/core/layout/Column';
import { Row } from '@dotpict-lib/component/core/layout/Row';
import { VerticalGravity } from '@dotpict-lib/component/core/layout/VerticalGravity';
import ConfirmationDialog from '@dotpict-lib/component/dialog/confirmation/ConfirmationDialog';

type Props = {
  request: RequestType;
  handleClickComplete: (request: RequestType) => void;
  handleClickDelete: (request: RequestType) => void;
};

const Request = ({ request, handleClickComplete, handleClickDelete }: Props) => {
  const translator = useTranslator();

  return (
    <Column width="100%" background={ColorStyle.WHITE}>
      <Spacer height={16} />
      <Row>
        <Spacer width={24} />
        <Link to={`/users/${request.sentUser.id}`}>
          <Pixelart
            alt={request.sentUser.name}
            src={request.sentUser.profileImageUrl}
            width={48}
            height={48}
          />
        </Link>
        <Spacer width={16} />
        <Column>
          <Link to={`/users/${request.sentUser.id}`}>
            <Text
              text={request.sentUser.name}
              textStyle={TextStyle.BOLD14}
              colorStyle={ColorStyle.TEXT_PRIMARY}
            />
          </Link>
          <Spacer height={4} />
          <Text
            text={request.text}
            textStyle={TextStyle.REGULAR14}
            colorStyle={ColorStyle.TEXT_PRIMARY}
          />
          <Spacer height={16} />
          <Row verticalGravity={VerticalGravity.center}>
            <Text
              text={request.createdAt}
              textStyle={TextStyle.REGULAR13}
              colorStyle={ColorStyle.GRAY_DEEP}
            />
            <Spacer width={24} />
            <Pixelart
              src={`https://storage.googleapis.com/dotpict-images/web/request-box/request_${
                request.isCompleted ? 'complete' : 'incomplete'
              }.svg`}
              alt="complete"
              width={24}
              height={24}
              handleClick={() => handleClickComplete(request)}
            />
            <Spacer width={24} />
            <ConfirmationDialog
              title={translator(TranslationKeys.RequestDeleteTitle)}
              message={translator(TranslationKeys.RequestDeleteMessage)}
              isEnabled
              handleClickOk={() => handleClickDelete(request)}
            >
              <Pixelart
                src="https://storage.googleapis.com/dotpict-images/web/request-box/request_remove.svg"
                alt="complete"
                width={24}
                height={24}
              />
            </ConfirmationDialog>
          </Row>
        </Column>
        <Spacer width={24} />
      </Row>
      <Spacer height={16} />
    </Column>
  );
};

export default Request;
