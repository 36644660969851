import { defaultGlobalState, GlobalStateType } from '@/app/state/GlobalStateType';
import { UserSummaryType } from '@/app/model/UserSummaryType';
import { WorksStateType } from '@/app/state/WorksStateType';
import { ISearchWorksState } from '@/app/state/ISearchWorksState';
import { ISearchUsersState } from '@/app/state/ISearchUsersState';
import { ILikeUsersState } from '@/app/state/ILikeUsersState';
import { IFollowedUsersState } from '@/app/state/IFollowedUsersState';
import { IFollowerUsersState } from '@/app/state/IFollowerUsersState';
import { IWorkDetailState } from '@/app/state/IWorkDetailState';
import { UserDetailStateType } from '@/app/state/UserDetailStateType';
import { MyPageStateType } from '@/app/state/MyPageState';
import { IMeState } from '@/app/state/IMeState';
import { ITemplateResultState } from '@/app/state/ITemplateResultState';
import { INotificationsState } from '@/app/state/INotificationsState';
import { WorkThreadType } from '@/app/model/WorkThreadType';
import { NetaStateType } from '@/app/state/NetaStateType';
import { OfficialEventDetailStateType } from '@/app/state/OfficialEventDetailStateType';
import { OfficialEventResultStateType } from '@/app/state/OfficialEventResultStateType';
import { OdaiDetailStateType } from '@/app/state/OdaiDetailStateType';
import { UserType } from '@dotpict-lib/model/User/UserType';
import { WorkType } from '@/app/model/WorkType';
import { UsersKey } from '@/app/component/page/user/list/UsersKey';
import { UsersStateType } from '@/app/state/UsersStateType';
import { TimelineStateType } from '@/app/state/TimelineStateType';
import { TimelineItemType } from '@/app/state/TimelineItemType';
import { NoteType } from '@dotpict-sns-lib/model/Note/NoteType';
import { WorkThreadsState } from '@/app/state/WorkThreadsState';
import { WorkRepliesState } from '@/app/state/WorkRepliesState';
import { ChildNotesState } from '@/app/state/ChildNotesState';

export type DotpictAction =
  | { type: 'UPDATE_TIMELINE_STATE'; payload: { timelineState: TimelineStateType } }
  | { type: 'UPDATE_FOLLOWING_USER_WORKS_STATE'; payload: { worksState: WorksStateType } }
  | { type: 'UPDATE_PICKUP_WORKS_STATE'; payload: { worksState: WorksStateType } }
  | { type: 'UPDATE_NEWEST_WORKS_STATE'; payload: { worksState: WorksStateType } }
  | { type: 'UPDATE_NOTIFICATIONS_STATE'; payload: { notificationsState: INotificationsState } }
  | { type: 'UPDATE_SEARCH_WORKS_STATE'; payload: { searchWorksState: ISearchWorksState } }
  | { type: 'UPDATE_SEARCH_USERS_STATE'; payload: { searchUsersState: ISearchUsersState } }
  | {
      type: 'UPDATE_ODAI_DETAIL_STATE';
      payload: { odaiDetailState: OdaiDetailStateType };
    }
  | {
      type: 'UPDATE_OFFICIAL_EVENT_DETAIL_STATE';
      payload: { officialEventDetailState: OfficialEventDetailStateType };
    }
  | {
      type: 'UPDATE_OFFICIAL_EVENT_RESULT_STATE';
      payload: { officialEventResultState: OfficialEventResultStateType };
    }
  | { type: 'UPDATE_LIKE_USERS_STATE'; payload: { likeUsersState: ILikeUsersState } }
  | { type: 'UPDATE_FOLLOWED_USERS_STATE'; payload: { followedUsersState: IFollowedUsersState } }
  | { type: 'UPDATE_FOLLOWER_USERS_STATE'; payload: { followerUsersState: IFollowerUsersState } }
  | { type: 'UPDATE_WORK_DETAIL_STATE'; payload: { workDetailState: IWorkDetailState } }
  | { type: 'UPDATE_WORK'; payload: { work: WorkType } }
  | { type: 'DELETE_WORK'; payload: { workId: number } }
  | { type: 'UPDATE_FOLLOW_STATE'; payload: { user: UserType } }
  | { type: 'UPDATE_USER_DETAIL_STATE'; payload: { userDetailState: UserDetailStateType } }
  | { type: 'UPDATE_USERS_STATE'; payload: { usersKey: UsersKey; usersState: UsersStateType } }
  | { type: 'UPDATE_MY_PAGE_STATE'; payload: { myPageState: MyPageStateType } }
  | { type: 'UPDATE_ME_STATE'; payload: { meState: IMeState } }
  | {
      type: 'UPDATE_NETA_STATE';
      payload: { netaState: NetaStateType };
    }
  | {
      type: 'UPDATE_TEMPLATE_RESULT_STATE';
      payload: { templateResultState: ITemplateResultState };
    }
  | { type: 'UPDATE_THREAD_STATE'; payload: { thread: WorkThreadType } }
  | { type: 'UPDATE_WORK_THREADS_STATE'; payload: { workThreadsState: WorkThreadsState } }
  | { type: 'UPDATE_WORK_REPLIES_STATE'; payload: { workRepliesState: WorkRepliesState } }
  | { type: 'POSTED_THREAD'; payload: { newThread: WorkThreadType } }
  | { type: 'UPDATE_NOTE'; payload: { note: NoteType } }
  | { type: 'UPDATE_CHILD_NOTES_STATE'; payload: { childNotesState: ChildNotesState } }
  | { type: 'UPDATE_GRAND_NOTES_STATE'; payload: { grandNotesState: ChildNotesState } }
  | { type: 'SHOW_MESSAGE'; payload: { message: string } };

export type DotpictReducer<S, A extends DotpictAction> = (state: S, action: A) => S;

const reducer: DotpictReducer<GlobalStateType, DotpictAction> = (
  state: GlobalStateType,
  action: DotpictAction,
) => {
  switch (action.type) {
    case 'UPDATE_TIMELINE_STATE': {
      const { timelineState } = action.payload;
      return {
        ...state,
        timelineState,
      };
    }
    case 'UPDATE_FOLLOWING_USER_WORKS_STATE': {
      const { worksState } = action.payload;
      return {
        ...state,
        followingUserWorksState: worksState,
      };
    }
    case 'UPDATE_NEWEST_WORKS_STATE': {
      const { worksState } = action.payload;
      return {
        ...state,
        newestWorksState: worksState,
      };
    }
    case 'UPDATE_NOTIFICATIONS_STATE': {
      const { notificationsState } = action.payload;
      return {
        ...state,
        notificationsState,
      };
    }
    case 'UPDATE_PICKUP_WORKS_STATE': {
      const { worksState } = action.payload;
      return {
        ...state,
        pickUpWorksState: worksState,
      };
    }
    case 'UPDATE_SEARCH_WORKS_STATE': {
      const { searchWorksState } = action.payload;
      return {
        ...state,
        searchWorksState,
      };
    }
    case 'UPDATE_SEARCH_USERS_STATE': {
      const { searchUsersState } = action.payload;
      return {
        ...state,
        searchUsersState,
      };
    }
    case 'UPDATE_ODAI_DETAIL_STATE': {
      const { odaiDetailState } = action.payload;
      return {
        ...state,
        odaiDetailState,
      };
    }
    case 'UPDATE_OFFICIAL_EVENT_DETAIL_STATE': {
      const { officialEventDetailState } = action.payload;
      return {
        ...state,
        officialEventDetailState,
      };
    }
    case 'UPDATE_OFFICIAL_EVENT_RESULT_STATE': {
      const { officialEventResultState } = action.payload;
      return {
        ...state,
        officialEventResultState,
      };
    }
    case 'UPDATE_USER_DETAIL_STATE': {
      const { userDetailState } = action.payload;
      return {
        ...state,
        userDetailState,
      };
    }
    case 'UPDATE_USERS_STATE': {
      const { usersKey, usersState } = action.payload;
      const { usersStateHash } = state;
      usersStateHash[usersKey] = usersState;
      return {
        ...state,
        usersStateHash,
      };
    }
    case 'UPDATE_TEMPLATE_RESULT_STATE': {
      const { templateResultState } = action.payload;
      return {
        ...state,
        templateResultState,
      };
    }
    case 'UPDATE_ME_STATE': {
      const { meState } = action.payload;
      if (state.meState.isLoggedIn === meState.isLoggedIn) {
        return {
          ...state,
          meState,
        };
      }
      return {
        ...defaultGlobalState,
        meState,
      };
    }
    case 'UPDATE_NETA_STATE': {
      const { netaState } = action.payload;
      return {
        ...state,
        netaState,
      };
    }
    case 'UPDATE_MY_PAGE_STATE': {
      const { myPageState } = action.payload;
      return {
        ...state,
        myPageState,
      };
    }
    case 'UPDATE_WORK_DETAIL_STATE': {
      const { workDetailState } = action.payload;
      return {
        ...state,
        workDetailState,
      };
    }
    case 'UPDATE_THREAD_STATE': {
      const { thread } = action.payload;
      return {
        ...state,
        workThreadsState: {
          ...state.workThreadsState,
          threads: state.workThreadsState.threads.map((v: WorkThreadType) =>
            v.id === thread.id ? thread : v,
          ),
        },
        workRepliesState: {
          ...state.workRepliesState,
          parentThread:
            state.workRepliesState.parentThread.id === thread.id
              ? thread
              : state.workRepliesState.parentThread,
          threads: state.workRepliesState.threads.map((v: WorkThreadType) =>
            v.id === thread.id ? thread : v,
          ),
        },
      };
    }
    case 'UPDATE_WORK': {
      const { work: targetWork } = action.payload;
      const timelineState = {
        ...state.timelineState,
        timelineItems: state.timelineState.timelineItems.map((timelineItem: TimelineItemType) =>
          timelineItem.work?.id === targetWork.id
            ? {
                ...timelineItem,
                work: targetWork,
              }
            : timelineItem,
        ),
      };
      const followingUserWorksState = {
        ...state.followingUserWorksState,
        works: state.followingUserWorksState.works.map((work: WorkType) =>
          work.id === targetWork.id ? targetWork : work,
        ),
      };
      const newestWorksState = {
        ...state.newestWorksState,
        works: state.newestWorksState.works.map((work: WorkType) =>
          work.id === targetWork.id ? targetWork : work,
        ),
      };
      const pickUpWorksState = {
        ...state.pickUpWorksState,
        works: state.pickUpWorksState.works.map((work: WorkType) =>
          work.id === targetWork.id ? targetWork : work,
        ),
      };
      const searchWorksState = {
        ...state.searchWorksState,
        works: state.searchWorksState.works.map((work: WorkType) =>
          work.id === targetWork.id ? targetWork : work,
        ),
      };
      const searchUsersState = {
        ...state.searchUsersState,
        userSummaries: state.searchUsersState.userSummaries.map((userSummary: UserSummaryType) => ({
          ...userSummary,
          works: userSummary.works.map((work: WorkType) =>
            work.id === targetWork.id ? targetWork : work,
          ),
        })),
      };
      const likeUsersState = {
        ...state.likeUsersState,
        userSummaries: state.likeUsersState.userSummaries.map((userSummary: UserSummaryType) => ({
          ...userSummary,
          works: userSummary.works.map((work: WorkType) =>
            work.id === targetWork.id ? targetWork : work,
          ),
        })),
      };
      const userDetailState = {
        ...state.userDetailState,
        works: state.userDetailState.works.map((work: WorkType) =>
          work.id === targetWork.id ? targetWork : work,
        ),
      };
      const myPageState = {
        ...state.myPageState,
        works: state.myPageState.works.map((work: WorkType) =>
          work.id === targetWork.id ? targetWork : work,
        ),
      };
      const workDetailState = {
        ...state.workDetailState,
        work: {
          ...(state.workDetailState.work.id === targetWork.id
            ? targetWork
            : state.workDetailState.work),
          user: state.workDetailState.work.user, // workはフォロー状態とってなくてリセットされるのでそのままにしておく
        },
        userSummary: {
          ...state.workDetailState.userSummary,
          works: state.workDetailState.userSummary.works.map((work: WorkType) =>
            work.id === targetWork.id ? targetWork : work,
          ),
        },
        recommendedWorks: state.workDetailState.recommendedWorks.map((work: WorkType) =>
          work.id === targetWork.id ? targetWork : work,
        ),
      };
      const officialEventResultState = {
        ...state.officialEventResultState,
        teamOneWorks: state.officialEventResultState.teamOneWorks.map((work: WorkType) =>
          work.id === targetWork.id ? targetWork : work,
        ),
        teamTwoWorks: state.officialEventResultState.teamTwoWorks.map((work: WorkType) =>
          work.id === targetWork.id ? targetWork : work,
        ),
        works: state.officialEventResultState.works.map((work: WorkType) =>
          work.id === targetWork.id ? targetWork : work,
        ),
      };
      const odaiDetailState = {
        ...state.odaiDetailState,
        works: state.odaiDetailState.works.map((work: WorkType) =>
          work.id === targetWork.id ? targetWork : work,
        ),
      };
      const officialEventDetailState = {
        ...state.officialEventDetailState,
        works: state.officialEventDetailState.works.map((work: WorkType) =>
          work.id === targetWork.id ? targetWork : work,
        ),
      };
      return {
        ...state,
        timelineState,
        followingUserWorksState,
        newestWorksState,
        pickUpWorksState,
        searchWorksState,
        searchUsersState,
        likeUsersState,
        userDetailState,
        myPageState,
        workDetailState,
        odaiDetailState,
        officialEventResultState,
        officialEventDetailState,
      };
    }
    case 'DELETE_WORK': {
      const { workId } = action.payload;
      const timelineState = {
        ...state.timelineState,
        works: state.timelineState.timelineItems.filter(
          (timelineItem: TimelineItemType) => timelineItem.work?.id !== workId,
        ),
      };
      const followingUserWorksState = {
        ...state.followingUserWorksState,
        works: state.followingUserWorksState.works.filter((work: WorkType) => work.id !== workId),
      };
      const newestWorksState = {
        ...state.newestWorksState,
        works: state.newestWorksState.works.filter((work: WorkType) => work.id !== workId),
      };
      const pickUpWorksState = {
        ...state.pickUpWorksState,
        works: state.pickUpWorksState.works.filter((work: WorkType) => work.id !== workId),
      };
      const searchWorksState = {
        ...state.searchWorksState,
        works: state.searchWorksState.works.filter((work: WorkType) => work.id !== workId),
      };
      const searchUsersState = {
        ...state.searchUsersState,
        userSummaries: state.searchUsersState.userSummaries.map((userSummary: UserSummaryType) => ({
          ...userSummary,
          works: userSummary.works.filter((work: WorkType) => work.id !== workId),
        })),
      };
      const likeUsersState = {
        ...state.likeUsersState,
        userSummaries: state.likeUsersState.userSummaries.map((userSummary: UserSummaryType) => ({
          ...userSummary,
          works: userSummary.works.filter((work: WorkType) => work.id !== workId),
        })),
      };
      const userDetailState = {
        ...state.userDetailState,
        works: state.userDetailState.works.filter((work: WorkType) => work.id !== workId),
      };
      const myPageState = {
        ...state.myPageState,
        works: state.myPageState.works.filter((work: WorkType) => work.id !== workId),
      };
      const officialEventResultState = {
        ...state.officialEventResultState,
        teamOneWorks: state.officialEventResultState.teamOneWorks.filter(
          (work: WorkType) => work.id !== workId,
        ),
        teamTwoWorks: state.officialEventResultState.teamTwoWorks.filter(
          (work: WorkType) => work.id !== workId,
        ),
        works: state.officialEventResultState.works.filter((work: WorkType) => work.id !== workId),
      };
      const odaiDetailState = {
        ...state.odaiDetailState,
        works: state.odaiDetailState.works.filter((work: WorkType) => work.id !== workId),
      };
      const officialEventDetailState = {
        ...state.officialEventDetailState,
        works: state.officialEventDetailState.works.filter((work: WorkType) => work.id !== workId),
      };
      return {
        ...state,
        timelineState,
        followingUserWorksState,
        newestWorksState,
        pickUpWorksState,
        searchWorksState,
        searchUsersState,
        likeUsersState,
        userDetailState,
        myPageState,
        odaiDetailState,
        officialEventResultState,
        officialEventDetailState,
      };
    }
    case 'UPDATE_FOLLOW_STATE': {
      const { user: targetUser } = action.payload;
      const userDetailState = {
        ...state.userDetailState,
        user:
          state.userDetailState.user.id === targetUser.id ? targetUser : state.userDetailState.user,
      };
      const workDetailState = {
        ...state.workDetailState,
        work: {
          ...state.workDetailState.work,
          user:
            state.workDetailState.work.user.id === targetUser.id
              ? targetUser
              : state.workDetailState.work.user,
        },
        userSummary: {
          ...state.workDetailState.userSummary,
          user:
            state.workDetailState.userSummary.user.id === targetUser.id
              ? targetUser
              : state.workDetailState.userSummary.user,
        },
      };
      return {
        ...state,
        userDetailState,
        workDetailState,
      };
    }
    case 'UPDATE_LIKE_USERS_STATE': {
      const { likeUsersState } = action.payload;
      return {
        ...state,
        likeUsersState,
      };
    }
    case 'UPDATE_FOLLOWED_USERS_STATE': {
      const { followedUsersState } = action.payload;
      return {
        ...state,
        followedUsersState,
      };
    }
    case 'UPDATE_FOLLOWER_USERS_STATE': {
      const { followerUsersState } = action.payload;
      return {
        ...state,
        followerUsersState,
      };
    }
    case 'UPDATE_WORK_THREADS_STATE': {
      const { workThreadsState } = action.payload;
      return {
        ...state,
        workThreadsState,
      };
    }
    case 'UPDATE_WORK_REPLIES_STATE': {
      const { workRepliesState } = action.payload;
      return {
        ...state,
        workRepliesState,
      };
    }
    case 'POSTED_THREAD': {
      const { newThread } = action.payload;
      return {
        ...state,
        workThreadsState: {
          ...state.workThreadsState,
          threads:
            newThread.parentId === 0 && newThread.workId === state.workThreadsState.work.id
              ? Array(newThread).concat(state.workThreadsState.threads)
              : state.workThreadsState.threads,
        },
        workRepliesState: {
          ...state.workRepliesState,
          threads:
            newThread.parentId > 0 && newThread.parentId === state.workRepliesState.parentThread.id
              ? state.workRepliesState.threads.concat(Array(newThread))
              : state.workRepliesState.threads,
        },
      };
    }
    case 'UPDATE_NOTE': {
      const { note } = action.payload;
      return {
        ...state,
        timelineState: {
          ...state.timelineState,
          timelineItems: state.timelineState.timelineItems.map((timelineItem: TimelineItemType) =>
            timelineItem.note?.id === note.id
              ? {
                  ...timelineItem,
                  note,
                }
              : timelineItem,
          ),
        },
        childNotesState: {
          parentNote:
            state.childNotesState.parentNote.id === note.id
              ? note
              : state.childNotesState.parentNote,
          notes: state.childNotesState.notes.map((childNote) =>
            childNote.id === note.id ? note : childNote,
          ),
        },
        grandNotesState: {
          parentNote:
            state.grandNotesState.parentNote.id === note.id
              ? note
              : state.grandNotesState.parentNote,
          notes: state.grandNotesState.notes.map((childNote) =>
            childNote.id === note.id ? note : childNote,
          ),
        },
      };
    }
    case 'UPDATE_CHILD_NOTES_STATE': {
      const { childNotesState } = action.payload;
      return {
        ...state,
        childNotesState,
      };
    }
    case 'UPDATE_GRAND_NOTES_STATE': {
      const { grandNotesState } = action.payload;
      return {
        ...state,
        grandNotesState,
      };
    }
    case 'SHOW_MESSAGE': {
      const { message } = action.payload;
      return {
        ...state,
        message,
      };
    }
    default:
      return state;
  }
};

export default reducer;
