import { useContext } from 'react';
import { APIContext, GlobalContext, LayoutContext } from '@/Routing';
import WorksPage from '@/app/component/page/work/list/WorksPage';

export const PickUpWorksPage = () => {
  const { globalState } = useContext(GlobalContext);
  const { layoutParams } = useContext(LayoutContext);
  const { client } = useContext(APIContext);

  return (
    <WorksPage
      type="UPDATE_PICKUP_WORKS_STATE"
      url={`${client.getBaseUrl}/works/trend`}
      imageSize={layoutParams.fullWidth < 256 * 2 + 8 ? (layoutParams.fullWidth - 8) / 2 : 256}
      workState={globalState.pickUpWorksState}
    />
  );
};
