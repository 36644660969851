export type UserType = {
  id: number;
  name: string;
  account: string;
  profileImageUrl: string;
  headerImageUrl: string;
  url: string;
  shareUrl: string;
  text: string;
  followedCount: number;
  followerCount: number;
  isFollowed: boolean;
  isFollower: boolean;
  isOpenedRequestBox: boolean;
  requestBoxText: string;
  registerAt: string;
  birthDate: string;
  isPremium: boolean;
};

const emptyUser: UserType = {
  id: -1,
  name: '',
  account: '',
  profileImageUrl: '',
  headerImageUrl: '',
  url: '',
  shareUrl: '',
  text: '',
  followedCount: 0,
  followerCount: 0,
  isFollowed: false,
  isFollower: false,
  isOpenedRequestBox: false,
  requestBoxText: '',
  registerAt: '2021/01/01',
  birthDate: '',
  isPremium: false,
};

export default emptyUser;
