import { OdaiType } from '@dotpict-sns-lib/model/Odai/OdaiType';

const TestOdaiTrolley: OdaiType = {
  id: 451,
  title: 'トロッコ',
  tag: 'Trolley',
  colorCodes: [],
  heldDate: '2022-09-08',
};

export default TestOdaiTrolley;
