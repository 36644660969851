import { useContext, useMemo } from 'react';
import styled from '@emotion/styled';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import { ToolContext, ToolsMap, ToolType } from '@/app/component/page/draw/Context/ToolContext';
import { HistoryContext } from '@/app/component/page/draw/Context/HistoryContext';
import Pixelart from '@dotpict-lib/component/core/Pixelart';
import Text from '@dotpict-lib/foundation/typography/Text';
import TranslationKeys from '@/app/translation/TranslationKeys';
import useTranslator from '@/app/hook/useTranslator';

const Tool = styled.button<{ selected: boolean; disabled: boolean }>`
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 64px;
  height: 64px;
  border-radius: 3px;
  background: transparent;
  appearance: none;
  padding: 0;
  border: none;
  outline: none;
  ${({ selected, disabled }) =>
    selected || disabled
      ? `
        cursor: default;
      `
      : `
        cursor: pointer;
        &:hover {
          background: #ffffff14;
        }
      `}
`;

const IconContainer = styled.div`
  padding: 6px;
`;

const ToolConstMap = {
  [ToolsMap.Pen]: {
    textKey: TranslationKeys.Pen,
    icon: 'https://storage.googleapis.com/dotpict-images/web/draw/icon/pencil.svg',
    activeIcon: 'https://storage.googleapis.com/dotpict-images/web/draw/icon/pencil_active.svg',
  },
  [ToolsMap.Eraser]: {
    textKey: TranslationKeys.Eraser,
    icon: 'https://storage.googleapis.com/dotpict-images/web/draw/icon/eraser.svg',
    activeIcon: 'https://storage.googleapis.com/dotpict-images/web/draw/icon/eraser_active.svg',
  },
  [ToolsMap.Bucket]: {
    textKey: TranslationKeys.Bucket,
    icon: 'https://storage.googleapis.com/dotpict-images/web/draw/icon/bucket.svg',
    activeIcon: 'https://storage.googleapis.com/dotpict-images/web/draw/icon/bucket_active.svg',
  },
  [ToolsMap.Line]: {
    textKey: TranslationKeys.Line,
    icon: 'https://storage.googleapis.com/dotpict-images/web/draw/icon/line.svg',
    activeIcon: 'https://storage.googleapis.com/dotpict-images/web/draw/icon/line_active.svg',
  },
  [ToolsMap.Move]: {
    textKey: TranslationKeys.Move,
    icon: 'https://storage.googleapis.com/dotpict-images/web/draw/icon/move.svg',
    activeIcon: 'https://storage.googleapis.com/dotpict-images/web/draw/icon/move_active.svg',
  },
  [ToolsMap.Redo]: {
    textKey: TranslationKeys.Redo,
    icon: 'https://storage.googleapis.com/dotpict-images/web/draw/icon/redo.svg',
    activeIcon: '',
  },
  [ToolsMap.Undo]: {
    textKey: TranslationKeys.Undo,
    icon: 'https://storage.googleapis.com/dotpict-images/web/draw/icon/undo.svg',
    activeIcon: '',
  },
  [ToolsMap.Clear]: {
    textKey: TranslationKeys.ClearAll,
    icon: 'https://storage.googleapis.com/dotpict-images/web/draw/icon/clear.svg',
    activeIcon: '',
  },
  [ToolsMap.EditColor]: {
    textKey: TranslationKeys.EditColor,
    icon: 'https://storage.googleapis.com/dotpict-images/web/draw/icon/palette.svg',
    activeIcon: '',
  },
} as const;

type Props = {
  toolType: ToolType;
  selectTool: (selected: ToolType) => () => void;
};

const ToolButton = ({ toolType, selectTool }: Props) => {
  const tool = useContext(ToolContext);
  const history = useContext(HistoryContext);
  const translator = useTranslator();
  const selected = tool === toolType;
  const { textKey, icon, activeIcon } = ToolConstMap[toolType];
  const disabled = useMemo(() => {
    if (toolType === ToolsMap.Redo) {
      return history.pointer === 0;
    }
    if (toolType === ToolsMap.Undo) {
      return history.pointer === history.canvasHistories.length - 1;
    }
    return selected;
  }, [toolType, selected, history]);

  return (
    <Tool onClick={selectTool(toolType)} selected={selected} disabled={disabled}>
      <IconContainer>
        <Pixelart
          alt={translator(textKey)}
          src={selected ? activeIcon : icon}
          width={36}
          height={36}
        />
      </IconContainer>
      <Text
        text={translator(textKey)}
        textStyle={TextStyle.BOLD11}
        colorStyle={selected ? ColorStyle.ACTIVE : ColorStyle.PRIMARY}
      />
    </Tool>
  );
};

export default ToolButton;
