import emptyUser, { UserType } from '@dotpict-lib/model/User/UserType';
import { WorkType } from './WorkType';

export type UserSummaryType = {
  user: UserType;
  works: WorkType[];
};

export const emptyUserSummary: UserSummaryType = {
  user: emptyUser,
  works: [],
};
