import { HorizontalGravity } from '@dotpict-lib/component/core/layout/HorizontalGravity';
import Column from '@dotpict-lib/component/core/layout/Column';
import Loading from '@dotpict-lib/component/core/Loading';
import { ListItem } from '@/app/component/partial/listitem/ListItem';
import { useTimeline } from '@/app/component/page/timeline/useTimeline';
import { renderListItem } from '@/app/component/partial/listitem/renderListItems';

export type TimelineViewState = {
  contentWidth: number;
  listItems: ListItem[];
  isLoading: boolean;
};

const Timeline = () => {
  const { contentWidth, listItems, isLoading } = useTimeline();

  return (
    <Column width="100%" horizontalGravity={HorizontalGravity.center}>
      <Column width={contentWidth} innerPadding={32} horizontalGravity={HorizontalGravity.center}>
        {listItems.map((listItem) => renderListItem(listItem))}
        {isLoading && <Loading />}
      </Column>
    </Column>
  );
};

export default Timeline;
