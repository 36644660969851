import { css } from '@emotion/react';
import { Skeleton } from '@material-ui/lab';
import LazyLoad from 'react-lazyload';

interface IProps {
  alt: string;
  src: string;
  width: number;
  height: number;
}

const ImageComponent = (props: IProps) => {
  const { alt, src, width, height } = props;

  const imageStyle = css({
    width,
    height,
  });

  if (src === '') {
    return <Skeleton variant="rect" width={width} height={height} />;
  }
  return (
    <LazyLoad height={height}>
      <img alt={alt} src={src} css={imageStyle} />
    </LazyLoad>
  );
};

export default ImageComponent;
