import { useMemo, useCallback } from 'react';
import styled from '@emotion/styled';
import { hsv } from 'color-convert';
import HSVEditorRect from '@/app/component/page/draw/SideMenu/ToolSelector/EditColor/HSVTab/HSVEditorRect';

const hueRectRgbs = [...Array(360)].map((_, i) => i).map((i) => `rgb(${hsv.rgb([i, 100, 100])})`);

const HSVContainer = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-rows: auto auto auto;
`;

type Props = {
  hue: number;
  saturation: number;
  brightness: number;
  setHue(newValue: number): void;
  setSaturation(newValue: number): void;
  setBrightness(newValue: number): void;
};

const HSVEditor = ({
  hue,
  saturation,
  brightness,
  setHue,
  setSaturation,
  setBrightness,
}: Props) => {
  const saturationRectRgbs = useMemo(
    () =>
      [...Array(360)].map((_, i) => i).map((i) => `rgb(${hsv.rgb([hue, i / 3.6, brightness])})`),
    [hue, brightness],
  );

  const brightnessRectRgbs = useMemo(
    () =>
      [...Array(360)].map((_, i) => i).map((i) => `rgb(${hsv.rgb([hue, saturation, i / 3.6])})`),
    [hue, saturation],
  );

  const handleSetHueNewRate = useCallback((newRate: number) => {
    setHue((newRate * 360) / 100);
  }, []);

  const handleSetSaturationNewRate = useCallback((newRate: number) => {
    setSaturation(newRate);
  }, []);

  const handleSetBrightnessNewRate = useCallback((newRate: number) => {
    setBrightness(newRate);
  }, []);

  return (
    <HSVContainer>
      <HSVEditorRect
        // TODO: 翻訳
        label="色あい"
        rgbs={hueRectRgbs}
        rate={(hue / 360) * 100}
        setNewRate={handleSetHueNewRate}
      />
      <HSVEditorRect
        // TODO: 翻訳
        label="鮮やかさ"
        rgbs={saturationRectRgbs}
        rate={saturation}
        setNewRate={handleSetSaturationNewRate}
      />
      <HSVEditorRect
        // TODO: 翻訳
        label="明るさ"
        rgbs={brightnessRectRgbs}
        rate={brightness}
        setNewRate={handleSetBrightnessNewRate}
      />
    </HSVContainer>
  );
};

export default HSVEditor;
