import { useContext } from 'react';
import Button from '@dotpict-lib/component/dotpict/Button';
import Text from '@dotpict-lib/foundation/typography/Text';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import TranslationKeys from '@/app/translation/TranslationKeys';
import useTranslator from '@/app/hook/useTranslator';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';
import Pixelart from '@dotpict-lib/component/core/Pixelart';
import TitleAndTags from '@/app/component/partial/work/titleandtags/TitleAndTags';
import User from '@/app/component/partial/user/item/User';
import EventColorCodes from '@/app/component/partial/event/colorcodes/EventColorCodes';
import Loading from '@dotpict-lib/component/core/Loading';
import useEventDetail from '@/app/component/page/event/user/detail/useEventDetail';
import Works from '@/app/component/Works';
import { defaultDummyItemCount } from '@/app/common/constant';
import { css } from '@emotion/react';
import { LayoutContext } from '@/Routing';
import useDownloadableImage from '@/app/component/page/event/user/detail/useDownloadableImage';
import { WorkType } from '@/app/model/WorkType';
import Column from '@dotpict-lib/component/core/layout/Column';
import { Row } from '@dotpict-lib/component/core/layout/Row';
import { VerticalGravity } from '@dotpict-lib/component/core/layout/VerticalGravity';
import emptyUser, { UserType } from '@dotpict-lib/model/User/UserType';

export type EventDetailBindModel = {
  isLoadingUserEvent: boolean;
  title: string;
  tags: string[];
  user: UserType;
  mainImageUrl: string;
  templateImageUrl: string;
  text: string;
  colors: string[];
  isLoadingWorks: boolean;
  isVisibleEnd: boolean;
  works: WorkType[];
};

export const InitialEventDetailBindModel: EventDetailBindModel = {
  isLoadingUserEvent: false,
  title: '',
  tags: [],
  user: emptyUser,
  mainImageUrl: '',
  templateImageUrl: '',
  text: '',
  colors: [],
  isLoadingWorks: false,
  isVisibleEnd: false,
  works: [],
};

const UserEventDetail = () => {
  const translator = useTranslator();
  const { layoutParams } = useContext(LayoutContext);
  const { bindModel, handleClickPost } = useEventDetail();
  const { renderImage, downloadImage } = useDownloadableImage({
    imageName: bindModel.title,
    imageUrl: bindModel.templateImageUrl,
  });

  const imageSize = layoutParams.fullWidth < 192 * 2 + 8 ? (layoutParams.fullWidth - 8) / 2 : 192;

  const gridStyle = css({
    display: 'grid',
    justifyContent: 'center',
    gridGap: '8px',
    gridTemplateColumns: `repeat(auto-fill, ${imageSize}px)`,
  });

  if (bindModel.isLoadingUserEvent) return <Loading />;

  const renderTemplate = () => (
    <Column>
      <Text
        text={translator(TranslationKeys.EventTemplate)}
        textStyle={TextStyle.BOLD16}
        colorStyle={ColorStyle.TEXT_PRIMARY}
      />
      <Spacer height={16} />
      <Row verticalGravity={VerticalGravity.center}>
        {renderImage()}
        <Pixelart src={bindModel.templateImageUrl} alt="template_image" width={128} height={128} />
        <Spacer width={24} />
        <Button
          width={280}
          height={40}
          text={translator(TranslationKeys.EventDownloadTemplate)}
          isBorder
          isFilled={false}
          handleClick={() => downloadImage()}
        />
      </Row>
    </Column>
  );

  return (
    <Column>
      <Spacer height={32} />
      <Column background={ColorStyle.WHITE} padding={24}>
        <Text
          text={translator(TranslationKeys.UserEventDescription1)}
          textStyle={TextStyle.BOLD20}
          colorStyle={ColorStyle.TEXT_PRIMARY}
        />
        <Spacer height={16} />
        <Text
          text={translator(TranslationKeys.UserEventDescription2)}
          textStyle={TextStyle.REGULAR14}
          colorStyle={ColorStyle.TEXT_PRIMARY}
        />
        <Spacer height={8} />
        <Text
          text={translator(TranslationKeys.UserEventDescription3)}
          textStyle={TextStyle.REGULAR14}
          colorStyle={ColorStyle.TEXT_PRIMARY}
        />
        <Spacer height={8} />
        <Text
          text={translator(TranslationKeys.UserEventDescription4)}
          textStyle={TextStyle.REGULAR14}
          colorStyle={ColorStyle.TEXT_PRIMARY}
        />
      </Column>
      <Spacer height={32} />
      <Column background={ColorStyle.WHITE} padding={24}>
        <Pixelart src={bindModel.mainImageUrl} alt="main_image" width={192} height={192} />
        <Spacer height={32} />
        <TitleAndTags title={bindModel.title} tags={bindModel.tags} />
        <Spacer height={16} />
        <User
          userId={bindModel.user.id}
          name={bindModel.user.name}
          text={bindModel.user.text}
          profileImageUrl={bindModel.user.profileImageUrl}
          isVisibleFollowInfo={false}
          isFollowed={false}
          isFollower={false}
        />
        <Spacer height={16} />
        <Text
          text={bindModel.text}
          textStyle={TextStyle.REGULAR14}
          colorStyle={ColorStyle.TEXT_PRIMARY}
        />
        {bindModel.templateImageUrl !== '' && (
          <>
            <Spacer height={32} />
            {renderTemplate()}
          </>
        )}
        <Spacer height={32} />
        <EventColorCodes paletteName={bindModel.title} colors={bindModel.colors} />
        <Spacer height={32} />
        <Button
          width="100%"
          height={50}
          text={translator(TranslationKeys.EventPostTitle)}
          isFilled
          handleClick={() => handleClickPost()}
        />
      </Column>
      <Spacer height={32} />
      <Text
        text={translator(TranslationKeys.ParticipatingWorks)}
        textStyle={TextStyle.BOLD20}
        colorStyle={ColorStyle.TEXT_PRIMARY}
      />
      <Spacer height={20} />
      <Works
        works={bindModel.works}
        isLoading={bindModel.isLoadingWorks}
        isVisibleEnd={bindModel.isVisibleEnd}
        isVisibleAds
        isVisibleLike
        gridStyle={gridStyle}
        imageSize={imageSize}
        dummyItemCount={defaultDummyItemCount}
      />
      <Spacer height={128} />
    </Column>
  );
};

export default UserEventDetail;
