import emptyUser, { UserType } from '@dotpict-lib/model/User/UserType';
import { NoteScopeType } from '@dotpict-sns-lib/model/Note/NoteScopeType';
import { NoteTypeType } from '@dotpict-sns-lib/model/Note/NoteTypeType';
import { NoteImageType } from '@dotpict-sns-lib/model/Note/NoteImageType';

export type NoteType = {
  id: number;
  parentId: number;
  user: UserType;
  text: string;
  childCount: number;
  scope: NoteScopeType;
  type: NoteTypeType;
  image: NoteImageType | null;
  isLiked: boolean;
  likeCount: number;
  likedProfileImageUrl: string;
  isEnabledDelete: boolean;
  createdAt: string;
};

export const emptyNote: NoteType = {
  id: 0,
  parentId: 0,
  user: emptyUser,
  text: '',
  childCount: 0,
  scope: 'all',
  type: 'root',
  image: null,
  isLiked: false,
  likeCount: 0,
  likedProfileImageUrl: '',
  isEnabledDelete: false,
  createdAt: '',
};
