import emptyWork from '@/app/model/WorkType';
import { defaultWorkThread } from '@/app/model/WorkThreadType';
import { WorkRepliesResponseType } from '@/app/model/responsetype/WorkRepliesResponseType';

export type GetWorkRepliesService = () => Promise<WorkRepliesResponseType>;

export const dummyGetWorkRepliesService: GetWorkRepliesService =
  (): Promise<WorkRepliesResponseType> => {
    return Promise.resolve({
      work: emptyWork,
      parentThread: defaultWorkThread,
      workThreads: [
        {
          ...defaultWorkThread,
          text: 'dummyText',
        },
      ],
    });
  };
