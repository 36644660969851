import { useRef } from 'react';

type Props = {
  imageName: string;
  imageUrl: string;
};

const useDownloadableImage = ({ imageName, imageUrl }: Props) => {
  const canvas = useRef<HTMLCanvasElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);

  const renderImage = () => {
    return (
      <canvas ref={canvas} style={{ display: 'none' }}>
        <img ref={imageRef} src={imageUrl} alt="downloadable_image" crossOrigin="anonymous" />
      </canvas>
    );
  };

  const downloadImage = () => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d')!;
    if (context && imageRef.current !== null) {
      const currentImage = imageRef.current;
      canvas.width = currentImage.width;
      canvas.height = currentImage.height;
      context.drawImage(currentImage, 0, 0, currentImage.width, currentImage.height);
    }
    const anchor: HTMLAnchorElement = document.createElement('a');
    canvas.toBlob((blob: any) => {
      if (anchor !== null && blob) {
        anchor.href = window.URL.createObjectURL(blob);
        anchor.download = `${imageName}_template.png`;
        anchor.click();
      }
    });
  };

  return {
    renderImage,
    downloadImage,
  };
};

export default useDownloadableImage;
