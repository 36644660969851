import { ColorCodeType } from '@/app/model/ColorCodeType';
import emptyUser, { UserType } from '@dotpict-lib/model/User/UserType';
import { UserEventType } from '@/app/model/UserEventType';
import { OfficialEventType } from '@dotpict-sns-lib/model/OfficialEvent/OfficialEventType';
import { OdaiType } from '@dotpict-sns-lib/model/Odai/OdaiType';

export type CropRectType = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export type WorkType = {
  id: number;
  title: string;
  caption: string;
  tags: string[];
  likeCount: number;
  repictCount: number;
  threadCount: number;
  thumbnailImageUrl: string;
  imageUrl: string;
  ogpImageUrl: string;
  width: number;
  height: number;
  user: UserType;
  isLiked: boolean;
  isRepicted: boolean;
  createdAt: string;
  allowThread: boolean;
  isAnimation: boolean;
  userEventId: number;
  userEvent: UserEventType | null;
  officialEventId: number;
  officialEvent: OfficialEventType | null;
  odaiId: number;
  odai: OdaiType | null;
  shareUrl: string;
  colorCodes: ColorCodeType[];
  cropRect: CropRectType | null;
};

const emptyWork: WorkType = {
  id: 0,
  title: '',
  caption: '',
  tags: [],
  likeCount: 0,
  repictCount: 0,
  threadCount: 0,
  thumbnailImageUrl: '',
  imageUrl: '',
  ogpImageUrl: '',
  width: 0,
  height: 0,
  user: emptyUser,
  isLiked: false,
  isRepicted: false,
  createdAt: '',
  allowThread: false,
  isAnimation: false,
  userEventId: 0,
  userEvent: null,
  officialEventId: 0,
  officialEvent: null,
  odaiId: 0,
  odai: null,
  shareUrl: '',
  colorCodes: [],
  cropRect: null,
};

export default emptyWork;
