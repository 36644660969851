import * as firebase from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { initializeAnalytics, getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyBZY9Ywvzl2sxp7Ks5lGmNUvTmdpGr8Uw0',
  authDomain: 'bold-streamer-828.firebaseapp.com',
  databaseURL: 'https://bold-streamer-828.firebaseio.com',
  projectId: 'bold-streamer-828',
  storageBucket: 'bold-streamer-828.appspot.com',
  messagingSenderId: '107766693448',
  appId: '1:107766693448:web:31bcfabaf292a7b3d1e27d',
};
const firebaseApp = firebase.initializeApp(firebaseConfig);
export const dotpictFirebaseAuth = getAuth(firebaseApp);

initializeAnalytics(firebaseApp);
export const analytics = getAnalytics(firebaseApp);
