import { ChangeEvent } from 'react';
import { SizeType } from '@/app/component/page/draw/Context/SizeContext';
import useTranslator from '@/app/hook/useTranslator';
import TranslationKeys from '@/app/translation/TranslationKeys';

const rateList = [
  { label: '1x', rate: 1 },
  { label: '2x', rate: 2 },
  { label: '3x', rate: 3 },
  { label: '4x', rate: 4 },
  { label: '5x', rate: 5 },
  { label: '6x', rate: 6 },
  { label: '7x', rate: 7 },
  { label: '8x', rate: 8 },
];

type Props = {
  canvasSize: SizeType;
  onSelectRate(selected: number): void;
};

const DownloadSizeSelector = ({ canvasSize, onSelectRate }: Props) => {
  const translator = useTranslator();

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const targetRate = Number(e.target.value);
    onSelectRate(targetRate);
  };

  return (
    <select onChange={handleChange}>
      {rateList.map(({ rate, label }) => (
        <option value={rate} key={rate}>
          {translator(TranslationKeys.SaveSize, {
            scale: label,
            size: `${canvasSize.width * rate}x${canvasSize.height * rate}`,
          })}
        </option>
      ))}
    </select>
  );
};

export default DownloadSizeSelector;
