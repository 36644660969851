import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import { css } from '@emotion/react';
import { MouseEventHandler } from 'react';
import { styled } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FontStyle from '@dotpict-lib/foundation/typography/FontStyle';

type Props = {
  text: string;
  textStyle: TextStyle;
  fontStyle?: FontStyle;
  maxLines?: number;
  textAlign?: 'center' | undefined;
  padding?: string | undefined;
  colorStyle: ColorStyle | string;
  flex?: number | string;
  handleClick?: MouseEventHandler;
};

const Text = ({
  text,
  textStyle,
  fontStyle,
  maxLines,
  textAlign,
  padding,
  colorStyle,
  flex,
  handleClick,
}: Props) => {
  // textでしか使わないからここに書いても良いかな、、、
  const getTextStyle = (dotpictTextStyle: TextStyle) => {
    // noinspection JSRedundantSwitchStatement
    switch (dotpictTextStyle) {
      case TextStyle.REGULAR9:
        return css({
          fontSize: '0.5625rem',
          fontWeight: 'normal',
        });
      case TextStyle.REGULAR10:
        return css({
          fontSize: '0.625rem',
          fontWeight: 'normal',
        });
      case TextStyle.REGULAR11:
        return css({
          fontSize: '0.6875rem',
          fontWeight: 'normal',
        });
      case TextStyle.REGULAR12:
        return css({
          fontSize: '0.75rem',
          fontWeight: 'normal',
        });
      case TextStyle.REGULAR13:
        return css({
          fontSize: '0.8125rem',
          fontWeight: 'normal',
        });
      case TextStyle.REGULAR14:
        return css({
          fontSize: '0.875rem',
          lineHeight: '1.375rem',
          fontWeight: 'normal',
        });
      case TextStyle.REGULAR16:
        return css({
          fontSize: '1rem',
          fontWeight: 'normal',
        });
      case TextStyle.BOLD11:
        return css({
          fontSize: '0.6875rem',
          fontWeight: 'bold',
        });
      case TextStyle.BOLD12:
        return css({
          fontSize: '0.75rem',
          fontWeight: 'bold',
        });
      case TextStyle.BOLD13:
        return css({
          fontSize: '0.8125rem',
          fontWeight: 'bold',
        });
      case TextStyle.BOLD14:
        return css({
          fontSize: '0.875rem',
          fontWeight: 'bold',
        });
      case TextStyle.BOLD16:
        return css({
          fontSize: '1rem',
          fontWeight: 'bold',
        });
      case TextStyle.BOLD20:
        return css({
          fontSize: '1.25rem',
          fontWeight: 'bold',
        });
      case TextStyle.BOLD36:
        return css({
          fontSize: '2.25rem',
          fontWeight: 'bold',
        });
      default:
        return css({
          fontSize: '0.875rem',
          fontWeight: 'normal',
        });
    }
  };

  const getFontStyle = (fontStyle?: FontStyle) => {
    switch (fontStyle) {
      case FontStyle.BOLD_PIXELMPLUS12:
        return css({
          fontFamily: 'PixelMplus12-Bold',
        });
      default:
        return css({});
    }
  };

  const style = css(
    getFontStyle(fontStyle),
    css(getTextStyle(textStyle), {
      color: colorStyle,
      overflowWrap: 'anywhere',
      padding,
      textAlign,
      whiteSpace: 'normal',
      flex: flex ?? '0 1 auto',
      lineClamp: maxLines,
    }),
  );

  const NoPaddingButton = styled(Button)({
    display: 'inline-block',
    padding: 0,
    minHeight: 0,
    minWidth: 0,
  });

  if (handleClick === undefined) {
    return <div css={style}>{text}</div>;
  }

  return (
    <NoPaddingButton
      onClick={(e) => {
        handleClick(e);
      }}
    >
      <div css={style}>{text}</div>
    </NoPaddingButton>
  );
};

export default Text;
