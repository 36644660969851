import React from 'react';
import { Link, useLocation } from 'react-router-dom';

interface Props {
  children: React.ReactNode;
  to: string;
}

const DotpictLink = ({ children, to }: Props) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const isDeepLink = params.get('deepLink') === 'true';

  if (!isDeepLink) {
    return <Link to={to}>{children}</Link>;
  }

  const deepLinkLocation = new URL(window.location.origin);
  deepLinkLocation.pathname = to;
  deepLinkLocation.protocol = 'dotpict:';
  return <a href={deepLinkLocation.toString()}>{children}</a>;
};

export default DotpictLink;
