import Text from '@dotpict-lib/foundation/typography/Text';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import Pixelart from '@dotpict-lib/component/core/Pixelart';
import Column from '@dotpict-lib/component/core/layout/Column';
import useTranslator from '@/app/hook/useTranslator';
import TranslationKeys from '@/app/translation/TranslationKeys';
import { Row } from '@dotpict-lib/component/core/layout/Row';
import { VerticalGravity } from '@dotpict-lib/component/core/layout/VerticalGravity';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';
import styled from '@emotion/styled';
import { HorizontalGravity } from '@dotpict-lib/component/core/layout/HorizontalGravity';

export type OdaiMiniBannerProps = {
  odaiTitle: string;
  isVisibleLabel?: boolean;
  isVisiblePicko?: boolean;
};

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const InnerContainer = styled.div`
  position: relative;
  border-width: 16px 32px;
  box-sizing: border-box;
  border-color: ${ColorStyle.PRIMARY};
  background: ${ColorStyle.PRIMARY};
  border-style: solid;
  width: 100%;
`;

const LabelContainer = styled.div`
  position: absolute;
  left: 60px;
  top: 50%;
  transform: translateY(-50%);
`;

const TagContainer = styled.div`
  border-radius: 100px;
  background: ${ColorStyle.WHITE};
  width: 100%;
`;

const PickoContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 10px;
`;

const OdaiMiniBanner = ({ odaiTitle, isVisibleLabel, isVisiblePicko }: OdaiMiniBannerProps) => {
  const translator = useTranslator();

  return (
    <Container>
      <InnerContainer>
        <TagContainer>
          <Text
            text={`# ${odaiTitle}`}
            textStyle={TextStyle.BOLD20}
            padding="12px 0px"
            textAlign="center"
            colorStyle={ColorStyle.TEXT_PRIMARY}
          />
        </TagContainer>
      </InnerContainer>
      {(isVisibleLabel ?? true) && (
        <LabelContainer>
          <Column horizontalGravity={HorizontalGravity.start}>
            <Text
              text={translator(TranslationKeys.DailyTheme1)}
              textStyle={TextStyle.BOLD16}
              colorStyle={ColorStyle.PRIMARY}
            />
            <Row verticalGravity={VerticalGravity.baseline}>
              <Text text="32" textStyle={TextStyle.BOLD20} colorStyle={ColorStyle.PRIMARY} />
              <Spacer width={2} />
              <Text
                text={translator(TranslationKeys.DailyTheme2)}
                textStyle={TextStyle.BOLD16}
                colorStyle={ColorStyle.PRIMARY}
              />
            </Row>
          </Column>
        </LabelContainer>
      )}
      {(isVisiblePicko ?? true) && (
        <PickoContainer>
          <Pixelart
            alt="odai picko"
            src="https://storage.googleapis.com/dotpict-images/web/odai/odai_picko_cropped.png"
            width={99}
            height={78}
          />
        </PickoContainer>
      )}
    </Container>
  );
};

export default OdaiMiniBanner;
