import { FacebookIcon, FacebookShareButton } from 'react-share';
import Text from '@dotpict-lib/foundation/typography/Text';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import { useIntl } from 'react-intl';
import TranslationKeys from '@/app/translation/TranslationKeys';
import { VerticalGravity } from '@dotpict-lib/component/core/layout/VerticalGravity';
import { HorizontalGravity } from '@dotpict-lib/component/core/layout/HorizontalGravity';
import { Row } from '@dotpict-lib/component/core/layout/Row';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';

interface IProps {
  url: string;
  title: string;
  width: number;
  height: number;
}

const FacebookShareComponent = (props: IProps) => {
  const { url, title, width, height } = props;
  const intl = useIntl();

  return (
    <FacebookShareButton url={url} title={title}>
      <Row
        background="#3b5998"
        width={width}
        height={height}
        verticalGravity={VerticalGravity.center}
        horizontalGravity={HorizontalGravity.center}
      >
        <FacebookIcon size={24} />
        <Text
          text={intl.formatMessage({ id: TranslationKeys.Share })}
          textStyle={TextStyle.BOLD11}
          colorStyle={ColorStyle.WHITE}
        />
        <Spacer width={8} />
      </Row>
    </FacebookShareButton>
  );
};

export default FacebookShareComponent;
