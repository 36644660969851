import { useCallback } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Pixelart from '@dotpict-lib/component/core/Pixelart';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import { MoveToolDirection, CanvasSizes } from '@/app/component/page/draw/hooks/types';

const IconContainer = styled.div<{ width: number; height: number }>`
  position: absolute;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: pointer;
`;

const IconCss = css`
  border-radius: 3px;
  border: 1px solid transparent;

  &:hover {
    border-color: ${ColorStyle.PRIMARY};
  }
`;

const TopIconContainer = styled.div`
  position: absolute;
  top: -57px; // 48 + 9
  left: 50%;
  transform: translateX(-50%);

  ${IconCss};
`;

const BottomIconContainer = styled.div`
  position: absolute;
  bottom: -57px; // 48 + 9
  left: 50%;
  transform: translateX(-50%);

  ${IconCss};
`;

const LeftIconContainer = styled.div`
  position: absolute;
  left: -57px; // 48 + 9
  top: 50%;
  transform: translateY(-50%);

  ${IconCss};
`;

const RightIconContainer = styled.div`
  position: absolute;
  right: -57px; // 48 + 9
  top: 50%;
  transform: translateY(-50%);

  ${IconCss};
`;

type Props = {
  canvasSizes: CanvasSizes;
  onClickMove(direction: MoveToolDirection): void;
};

const MoveTool = ({ canvasSizes, onClickMove }: Props) => {
  const handleClickMove = useCallback(
    (direction: 'top' | 'bottom' | 'left' | 'right') => {
      return () => {
        onClickMove(direction);
      };
    },
    [onClickMove],
  );

  return (
    <IconContainer width={canvasSizes.canvasRenderWidth} height={canvasSizes.canvasRenderHeight}>
      <TopIconContainer onClick={handleClickMove('top')}>
        <Pixelart
          alt="move_top"
          src="https://storage.googleapis.com/dotpict-images/web/draw/icon/move_top.svg"
          width={48}
          height={48}
        />
      </TopIconContainer>
      <BottomIconContainer onClick={handleClickMove('bottom')}>
        <Pixelart
          alt="move_bottom"
          src="https://storage.googleapis.com/dotpict-images/web/draw/icon/move_bottom.svg"
          width={48}
          height={48}
        />
      </BottomIconContainer>
      <LeftIconContainer onClick={handleClickMove('left')}>
        <Pixelart
          alt="move_left"
          src="https://storage.googleapis.com/dotpict-images/web/draw/icon/move_left.svg"
          width={48}
          height={48}
        />
      </LeftIconContainer>
      <RightIconContainer onClick={handleClickMove('right')}>
        <Pixelart
          alt="move_right"
          src="https://storage.googleapis.com/dotpict-images/web/draw/icon/move_right.svg"
          width={48}
          height={48}
        />
      </RightIconContainer>
    </IconContainer>
  );
};
export default MoveTool;
