import { useContext, useState } from 'react';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';
import Pixelart from '@dotpict-lib/component/core/Pixelart';
import { LayoutContext } from '@/Routing';
import Works from '@/app/component/Works';
import { defaultDummyItemCount } from '@/app/common/constant';
import { css } from '@emotion/react';
import Button from '@dotpict-lib/component/dotpict/Button';
import TranslationKeys from '@/app/translation/TranslationKeys';
import useTranslator from '@/app/hook/useTranslator';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { Dialog } from '@material-ui/core';
import HowToJoinEvent from '@/app/component/partial/event/howtojoin/HowToJoinEvent';
import { OfficialEventDetailStateType } from '@/app/state/OfficialEventDetailStateType';
import { Helmet } from 'react-helmet';
import Text from '@dotpict-lib/foundation/typography/Text';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import ScoreRate from '@/app/component/page/event/official/result/ScoreRate';
import HowToUsePalette from '@/app/component/partial/event/howtousepalette/HowToUsePalette';
import { Row } from '@dotpict-lib/component/core/layout/Row';
import { VerticalGravity } from '@dotpict-lib/component/core/layout/VerticalGravity';
import { HorizontalGravity } from '@dotpict-lib/component/core/layout/HorizontalGravity';
import Column from '@dotpict-lib/component/core/layout/Column';
import { Grid } from '@dotpict-lib/component/core/layout/Grid';
import Loading from '@dotpict-lib/component/core/Loading';
import useOfficialEvent from '@/app/component/page/event/official/normal/useOfficialEvent';

export type OfficialEventDetailProps = OfficialEventDetailStateType & {
  isVisibleJoinButton: boolean;
  isVisibleTemplate: boolean;
  handlePost: () => void;
  handleDraw: () => void;
  handleJoinTeam: (teamTag: string) => void;
};

type Props = {
  officialEventTag: string;
};

const OfficialEvent = ({ officialEventTag }: Props) => {
  const translator = useTranslator();
  const {
    mainImageUrl,
    tag,
    templateImageUrl,
    colors,
    shareTitle,
    twitterShareUrl,
    facebookShareUrl,
    bannerImageUrl,
    isLoadingDetail,
    isLoadingWorks,
    isVisibleEnd,
    works,
    isBattleEvent,
    description,
    teamOneTag,
    teamOneText,
    teamTwoText,
    teamOneScore,
    teamOneColor,
    teamTwoTag,
    teamTwoScore,
    teamTwoColor,
    isVisibleJoinButton,
    isVisibleTemplate,
    handlePost,
    handleDraw,
    handleJoinTeam,
  } = useOfficialEvent({ officialEventTag });
  const { layoutParams } = useContext(LayoutContext);
  const [modalOpen, setModalOpen] = useState(false);

  const imageSize = layoutParams.fullWidth < 192 * 2 + 8 ? (layoutParams.fullWidth - 8) / 2 : 192;

  const gridStyle = css({
    display: 'grid',
    justifyContent: 'center',
    gridGap: '8px',
    gridTemplateColumns: `repeat(auto-fill, ${imageSize}px)`,
  });

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleHowToJoin = () => {
    setModalOpen(true);
  };

  const renderOgp = () => (
    <Helmet>
      <meta property="twitter:title" content={shareTitle} />
      <meta property="twitter:description" content={shareTitle} />
      <meta property="twitter:image" content={bannerImageUrl} />
      <meta property="og:title" content={shareTitle} />
      <meta property="og:description" content={shareTitle} />
      <meta property="og:image" content={bannerImageUrl} />
    </Helmet>
  );

  const renderNormalEventHeader = () => (
    <Row
      width="100%"
      horizontalGravity={HorizontalGravity.center}
      verticalGravity={VerticalGravity.top}
    >
      {isVisibleJoinButton && (
        <>
          <Column horizontalGravity={HorizontalGravity.center}>
            <Button
              width={240}
              height={48}
              text={translator(TranslationKeys.Post)}
              textStyle={TextStyle.BOLD16}
              isFilled
              handleClick={handlePost}
            />
            <Spacer height={8} />
            <Text
              text={translator(TranslationKeys.HowToPost)}
              textStyle={TextStyle.REGULAR13}
              colorStyle={ColorStyle.PRIMARY}
              handleClick={handleHowToJoin}
            />
          </Column>
          <Spacer width={28} />
          <Button
            width={240}
            height={48}
            text={translator(TranslationKeys.DrawPixelart)}
            textStyle={TextStyle.BOLD16}
            isFilled={false}
            isBorder
            handleClick={handleDraw}
          />
          <Spacer width={52} />
        </>
      )}
      <Row verticalGravity={VerticalGravity.center}>
        <Pixelart
          src="https://storage.googleapis.com/dotpict-images/web/share_event.png"
          alt="title"
          width={89}
          height={30}
        />
        <Spacer width={12} />
        <TwitterShareButton url={twitterShareUrl} title={shareTitle}>
          <Pixelart
            src="https://storage.googleapis.com/dotpict-images/web/twitter.png"
            alt="title"
            width={44}
            height={44}
          />
        </TwitterShareButton>
        <Spacer width={12} />
        <FacebookShareButton url={facebookShareUrl} title={shareTitle}>
          <Pixelart
            src="https://storage.googleapis.com/dotpict-images/web/facebook.png"
            alt="title"
            width={44}
            height={44}
          />
        </FacebookShareButton>
      </Row>
    </Row>
  );

  const renderBattleEventHeader = () => (
    <Column horizontalGravity={HorizontalGravity.center}>
      <Text
        text={description}
        textStyle={TextStyle.REGULAR12}
        colorStyle={ColorStyle.TEXT_PRIMARY}
      />
      <Spacer height={24} />
      <Text
        text={`${translator(TranslationKeys.CurrentScoreRate)}`}
        textStyle={TextStyle.BOLD14}
        colorStyle={ColorStyle.TEXT_PRIMARY}
      />
      <Spacer height={12} />
      <ScoreRate
        teamOneScore={teamOneScore}
        teamOneColor={teamOneColor}
        teamTwoScore={teamTwoScore}
        teamTwoColor={teamTwoColor}
        contentWidth={layoutParams.contentWidth - 52}
        cropColor={ColorStyle.WHITE_GRAY}
      />
      <Spacer height={6} />
      <Text
        text={`${translator(TranslationKeys.BattleEventPointRule1)}`}
        textStyle={TextStyle.REGULAR12}
        colorStyle={ColorStyle.TEXT_SECONDARY}
      />
      <Spacer height={2} />
      <Text
        text={`${translator(TranslationKeys.BattleEventPointRule2)}`}
        textStyle={TextStyle.REGULAR12}
        colorStyle={ColorStyle.TEXT_SECONDARY}
      />
      {isVisibleJoinButton && (
        <>
          <Spacer height={24} />
          <Text
            text={`${translator(TranslationKeys.WhichJoin)}`}
            textStyle={TextStyle.BOLD14}
            colorStyle={ColorStyle.TEXT_PRIMARY}
          />
          <Spacer height={10} />
          <Grid innerPadding={12}>
            <Button
              handleClick={() => handleJoinTeam(teamOneTag)}
              width={320}
              height={52}
              text={teamOneText}
              isFilled
              color={teamOneColor}
            />
            <Button
              handleClick={() => handleJoinTeam(teamTwoTag)}
              width={320}
              height={52}
              text={teamTwoText}
              isFilled
              color={teamTwoColor}
            />
          </Grid>
          <Spacer height={28} />
          <Column background={ColorStyle.WHITE} width="100%" padding="24px 48px">
            <HowToUsePalette paletteName={tag} colors={colors} />
          </Column>
        </>
      )}
    </Column>
  );

  if (isLoadingDetail) return <Loading />;

  return (
    <>
      {renderOgp()}
      <Column width={layoutParams.contentWidth}>
        <Spacer height={40} />
        <Pixelart
          alt="header_image"
          width={layoutParams.contentWidth}
          height={layoutParams.contentWidth / (1024 / 592)}
          src={bannerImageUrl}
        />
        <Spacer height={28} />
        {isBattleEvent ? renderBattleEventHeader() : renderNormalEventHeader()}
        <Spacer height={52} />
        <Column width="100%" horizontalGravity={HorizontalGravity.center}>
          <Pixelart
            src="https://storage.googleapis.com/dotpict-images/web/everyone_pixelart.png"
            alt="title"
            width={277}
            height={30}
          />
        </Column>
        <Spacer height={28} />
        <Works
          works={works}
          isLoading={isLoadingWorks}
          isVisibleEnd={isVisibleEnd}
          isVisibleAds
          isVisibleLike
          gridStyle={gridStyle}
          imageSize={imageSize}
          dummyItemCount={defaultDummyItemCount}
        />
        <Spacer height={128} />
      </Column>
      <Dialog maxWidth="xl" open={modalOpen} onClose={handleClose} scroll="body">
        <HowToJoinEvent
          mainImageUrl={mainImageUrl}
          tag={tag}
          isVisibleTemplate={isVisibleTemplate}
          templateImageUrl={templateImageUrl}
          colors={colors}
        />
      </Dialog>
    </>
  );
};

export default OfficialEvent;
