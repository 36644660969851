import { createContext, useContext } from 'react';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';
import Pixelart from '@dotpict-lib/component/core/Pixelart';
import { LayoutContext } from '@/Routing';
import Works from '@/app/component/Works';
import { defaultDummyItemCount } from '@/app/common/constant';
import { css } from '@emotion/react';
import Loading from '@dotpict-lib/component/core/Loading';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import { Helmet } from 'react-helmet';
import Text from '@dotpict-lib/foundation/typography/Text';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import { OfficialEventResultStateType } from '@/app/state/OfficialEventResultStateType';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { WorkType } from '@/app/model/WorkType';
import WorkThumbnail from '@/app/component/partial/work/thumbnail/WorkThumbnail';
import Column from '@dotpict-lib/component/core/layout/Column';
import { HorizontalGravity } from '@dotpict-lib/component/core/layout/HorizontalGravity';
import { Row } from '@dotpict-lib/component/core/layout/Row';
import { VerticalGravity } from '@dotpict-lib/component/core/layout/VerticalGravity';
import TeamName from '@/app/component/page/event/official/result/TeamName';
import TeamPoint from '@/app/component/page/event/official/result/TeamPoint';
import ScoreRate from '@/app/component/page/event/official/result/ScoreRate';
import TranslationKeys from '@/app/translation/TranslationKeys';
import useTranslator from '@/app/hook/useTranslator';

export const OfficialEventResultContext = createContext<OfficialEventResultStateType>(
  {} as OfficialEventResultStateType,
);

type PickUpType = {
  fromApp: boolean;
  work: WorkType;
  imageSize: number;
};

const PickUpImage = ({ fromApp, work, imageSize }: PickUpType) => {
  return (
    <Column horizontalGravity={HorizontalGravity.center}>
      <WorkThumbnail
        work={work}
        imageSize={imageSize}
        isVisibleLike={false}
        isVisibleEvent={false}
        isDeepLink={fromApp}
      />
      <Spacer height={10} />
      <Text text={work.title} textStyle={TextStyle.BOLD12} colorStyle={ColorStyle.TEXT_PRIMARY} />
      <Spacer height={8} />
      <Row verticalGravity={VerticalGravity.center} innerPadding={8}>
        <Pixelart src={work.thumbnailImageUrl} alt={work.title} width={24} height={24} />
        <Text
          text={work.user.name}
          textStyle={TextStyle.REGULAR12}
          colorStyle={ColorStyle.TEXT_PRIMARY}
        />
      </Row>
    </Column>
  );
};

const OfficialEventResult = () => {
  const translator = useTranslator();

  const {
    fromApp,
    teamOneName,
    teamOneColor,
    teamOnePoint,
    teamOneWorks,
    teamTwoName,
    teamTwoColor,
    teamTwoPoint,
    teamTwoWorks,
    description,
    closingTexts,
    shareTitle,
    twitterShareUrl,
    facebookShareUrl,
    ogpImageUrl,
    bannerImageUrl,
    works,
    isLoadingWorks,
    isVisibleEnd,
  } = useContext(OfficialEventResultContext);
  const { layoutParams } = useContext(LayoutContext);

  const imageSize = layoutParams.fullWidth < 192 * 3 + 16 ? (layoutParams.fullWidth - 16) / 3 : 192;

  const gridStyle = css({
    display: 'grid',
    justifyContent: 'center',
    gridGap: '8px',
    gridTemplateColumns: `repeat(auto-fill, ${imageSize}px)`,
  });

  const renderOgp = () => (
    <Helmet>
      <meta property="twitter:title" content={shareTitle} />
      <meta property="twitter:description" content={closingTexts.join()} />
      <meta property="twitter:image" content={ogpImageUrl} />
      <meta property="og:title" content={shareTitle} />
      <meta property="og:description" content={closingTexts.join()} />
      <meta property="og:image" content={ogpImageUrl} />
    </Helmet>
  );

  if (isLoadingWorks && works.length === 0) return <Loading />;

  return (
    <>
      {renderOgp()}
      <Column width={layoutParams.contentWidth} horizontalGravity={HorizontalGravity.center}>
        <Spacer height={8} />
        <Pixelart
          alt="header_image"
          width={layoutParams.contentWidth}
          height={layoutParams.contentWidth / (1024 / 592)}
          src={bannerImageUrl}
        />
        <Column width={layoutParams.contentWidth}>
          <Column background={ColorStyle.PRIMARY} padding="0px 24px 12px 24px">
            <Column
              background={ColorStyle.WHITE}
              horizontalGravity={HorizontalGravity.center}
              padding={12}
            >
              <Text
                text={description}
                textStyle={TextStyle.BOLD12}
                colorStyle={ColorStyle.TEXT_PRIMARY}
              />
              <Spacer height={4} />
              {closingTexts.map((closingText) => (
                <Text
                  text={closingText}
                  textStyle={TextStyle.BOLD12}
                  colorStyle={ColorStyle.TEXT_PRIMARY}
                />
              ))}
              <Spacer height={12} />
              <Row verticalGravity={VerticalGravity.center}>
                <Pixelart
                  src="https://storage.googleapis.com/dotpict-images/web/share_event.png"
                  alt="title"
                  width={89}
                  height={30}
                />
                <Spacer width={12} />
                <TwitterShareButton url={twitterShareUrl} title={shareTitle}>
                  <Pixelart
                    src="https://storage.googleapis.com/dotpict-images/web/twitter.png"
                    alt="title"
                    width={44}
                    height={44}
                  />
                </TwitterShareButton>
                <Spacer width={12} />
                <FacebookShareButton url={facebookShareUrl} title={shareTitle}>
                  <Pixelart
                    src="https://storage.googleapis.com/dotpict-images/web/facebook.png"
                    alt="title"
                    width={44}
                    height={44}
                  />
                </FacebookShareButton>
              </Row>
            </Column>
          </Column>
          <Spacer height={32} />
          <Column width="100%" innerPadding={16} horizontalGravity={HorizontalGravity.center}>
            <Text
              text={`${translator(TranslationKeys.BattleEventResult)}`}
              textStyle={TextStyle.BOLD20}
              colorStyle={ColorStyle.TEXT_PRIMARY}
            />
            <ScoreRate
              teamOneScore={teamOnePoint}
              teamOneColor={teamOneColor}
              teamTwoScore={teamTwoPoint}
              teamTwoColor={teamTwoColor}
              contentWidth={layoutParams.contentWidth - 52}
              cropColor={ColorStyle.WHITE_GRAY}
            />
            <Row width="100%" innerPadding={24} horizontalPadding={24}>
              <Column
                width={(layoutParams.contentWidth - 24 * 3) / 2}
                innerPadding={12}
                horizontalGravity={HorizontalGravity.center}
              >
                <TeamName teamName={teamOneName} teamColor={teamOneColor} />
                <TeamPoint teamPoint={teamOnePoint} teamColor={teamOneColor} />
              </Column>
              <Column
                width={(layoutParams.contentWidth - 24 * 3) / 2}
                innerPadding={12}
                horizontalGravity={HorizontalGravity.center}
              >
                <TeamName teamName={teamTwoName} teamColor={teamTwoColor} />
                <TeamPoint teamPoint={teamTwoPoint} teamColor={teamTwoColor} />
              </Column>
            </Row>
          </Column>
          <Spacer height={32} />
          <Column width="100%" horizontalGravity={HorizontalGravity.center}>
            <Pixelart
              alt="pickup"
              width={152}
              height={30}
              src="https://storage.googleapis.com/dotpict-images/web/puckup.svg"
            />
            <Spacer height={32} />
            <Row width="100%" innerPadding={8} horizontalPadding={24}>
              <Column innerPadding={32}>
                {teamOneWorks.map((work) => (
                  <PickUpImage
                    fromApp={fromApp}
                    work={work}
                    imageSize={(layoutParams.contentWidth - 8 - 24 * 2) / 2}
                  />
                ))}
              </Column>
              <Column innerPadding={32}>
                {teamTwoWorks.map((work) => (
                  <PickUpImage
                    fromApp={fromApp}
                    work={work}
                    imageSize={(layoutParams.contentWidth - 8 - 24 * 2) / 2}
                  />
                ))}
              </Column>
            </Row>
          </Column>
          <Spacer height={32} />
          <Column horizontalGravity={HorizontalGravity.center}>
            <Pixelart
              src="https://storage.googleapis.com/dotpict-images/web/everyone_pixelart.png"
              alt="title"
              width={277}
              height={30}
            />
          </Column>
          <Spacer height={8} />
          <Works
            works={works}
            isLoading={isLoadingWorks}
            isVisibleEnd={isVisibleEnd}
            isVisibleAds={false}
            isVisibleLike={false}
            isDeepLink={fromApp}
            gridStyle={gridStyle}
            imageSize={imageSize}
            dummyItemCount={defaultDummyItemCount}
          />
          <Spacer height={128} />
        </Column>
      </Column>
    </>
  );
};

export default OfficialEventResult;
