import { WorkType } from '@/app/model/WorkType';
import TestUserSraim from '@/app/test/user/TestUserSraim';
import { TestOfficialEventDogVsCat } from '@/app/test/officialevent/TestOfficialEventDogVsCat';

const TestWorkJuneBride: WorkType = {
  id: 4129524,
  title: '🐶🐱',
  caption:
    '公式企画のドット絵描かせていただきました🐶🐱いろんなわんちゃん、猫ちゃんを描いてみてください✨ I made dotpict official event pixelart! Thank you.',
  tags: ['Dog_vs_Cat'],
  likeCount: 346,
  repictCount: 3,
  threadCount: 12,
  thumbnailImageUrl:
    'https://img.dotpicko.net/work/2022/08/29/13/08/cb9127170dd5ebd23077974a9ee3cd785eb47ef9bcdce837a9537e57e674f6d4.png',
  imageUrl:
    'https://img.dotpicko.net/work/2022/08/29/13/08/cb9127170dd5ebd23077974a9ee3cd785eb47ef9bcdce837a9537e57e674f6d4.png',
  ogpImageUrl:
    'https://img.dotpicko.net/ogp_work/2022/08/29/13/08/cb9127170dd5ebd23077974a9ee3cd785eb47ef9bcdce837a9537e57e674f6d4.png',
  width: 128,
  height: 64,
  user: TestUserSraim,
  isLiked: false,
  isRepicted: false,
  createdAt: '2022/08/29',
  allowThread: true,
  isAnimation: false,
  userEventId: 0,
  userEvent: null,
  officialEventId: 62,
  officialEvent: TestOfficialEventDogVsCat,
  odaiId: 0,
  odai: null,
  shareUrl: 'https://dotpict.net/works/4129524',
  colorCodes: [
    { red: 64, green: 42, blue: 37, alpha: 255 },
    { red: 105, green: 59, blue: 17, alpha: 255 },
    { red: 147, green: 83, blue: 0, alpha: 255 },
    { red: 190, green: 109, blue: 23, alpha: 255 },
    { red: 209, green: 131, blue: 52, alpha: 255 },
    { red: 237, green: 177, blue: 117, alpha: 255 },
    { red: 88, green: 166, blue: 221, alpha: 255 },
    { red: 164, green: 238, blue: 255, alpha: 255 },
    { red: 255, green: 250, blue: 157, alpha: 255 },
    { red: 87, green: 94, blue: 94, alpha: 255 },
    { red: 113, green: 125, blue: 126, alpha: 255 },
    { red: 138, green: 165, blue: 167, alpha: 255 },
    { red: 156, green: 190, blue: 192, alpha: 255 },
    { red: 255, green: 165, blue: 151, alpha: 255 },
    { red: 255, green: 232, blue: 216, alpha: 255 },
    { red: 255, green: 255, blue: 255, alpha: 255 },
  ],
  cropRect: null,
};

export default TestWorkJuneBride;
