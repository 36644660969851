import { Grid } from '@dotpict-lib/component/core/layout/Grid';
import Color from '@dotpict-lib/component/core/Color';

export type ColorsProps = {
  colors: string[];
  colorWidth: number;
  colorHeight: number;
};

const Colors = ({ colors, colorWidth, colorHeight }: ColorsProps) => (
  <Grid>
    {colors.map((color) => (
      <Color key={color} hexColor={color} width={colorWidth} height={colorHeight} />
    ))}
  </Grid>
);

export default Colors;
