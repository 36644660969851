import { HomeProps } from '@/app/component/page/home/HomePage';
import { useContext, useEffect, useState } from 'react';
import { APIContext, GlobalContext, LayoutContext, MeContext } from '@/Routing';
import { useLocation, useNavigate } from 'react-router-dom';
import TranslationKeys from '@/app/translation/TranslationKeys';
import useTranslator from '@/app/hook/useTranslator';
import useLocalStorage from '@/app/hook/useLocalStorage';
import { LanguageContext } from '@/Language';

const useHome = (): HomeProps => {
  const meState = useContext(MeContext);
  const types = meState.isLoggedIn
    ? ['timeline', 'following_user_works', 'pickup', 'newest']
    : ['pickup', 'newest'];
  const { globalState, dispatch } = useContext(GlobalContext);
  const navigator = useNavigate();
  const location = useLocation();
  const translator = useTranslator();
  const [tabItems, setTabItems] = useState<string[]>([]);
  const [homeType, setHomeType] = useLocalStorage('homeType', 'pickup');
  const [selectedIndex, setSelectedIndex] = useState<number>(types.indexOf(homeType));
  const { language } = useContext(LanguageContext);
  const { layoutParams } = useContext(LayoutContext);
  const { client } = useContext(APIContext);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const type = params.get('type');
    if (type !== null && types.includes(type)) {
      setHomeType(type);
    }
  }, [location]);

  const reloadNeta = async () => {
    const response = await client.fetchNeta(meState.token);
    dispatch({
      type: 'UPDATE_NETA_STATE',
      payload: {
        netaState: {
          officialEvent: response.officialEvent,
          odai: response.odai,
        },
      },
    });
  };

  useEffect(() => {
    reloadNeta();
  }, []);

  const handleClickOfficialEvent = () => {
    navigator(`/officialEvents/${globalState.netaState.officialEvent.tag}`);
  };

  const handleClickOdai = () => {
    navigator(`/odais/${globalState.netaState.odai.tag}`);
  };

  const handleClickTagItem = (index: number) => {
    setHomeType(types[index]);
  };

  useEffect(() => {
    if (!meState.isLoggedIn) {
      setTabItems([
        translator(TranslationKeys.PickupWorks),
        translator(TranslationKeys.NewestWorks),
      ]);
    } else {
      setTabItems([
        translator(TranslationKeys.Timeline),
        translator(TranslationKeys.FollowingWorks),
        translator(TranslationKeys.PickupWorks),
        translator(TranslationKeys.NewestWorks),
      ]);
    }
    const newIndex = types.indexOf(homeType);
    setSelectedIndex(newIndex >= 0 ? newIndex : 0);
  }, [meState.isLoggedIn, homeType, language]);

  return {
    isVisibleOfficialEvent: globalState.netaState.officialEvent.id > 0,
    isVisibleOdai: !layoutParams.isMobile && globalState.netaState.odai.title !== '',
    isVisibleAboutDotpict: !meState.isLoggedIn,
    bannerWidth: Math.min(512, layoutParams.contentWidth),
    officialEventBannerImageUrl: globalState.netaState.officialEvent.bannerImageUrl,
    officialEventBannerHeight: Math.min(
      (512 * 592) / 1024,
      (layoutParams.contentWidth * 592) / 1024,
    ),
    odaiTitle: globalState.netaState.odai.title,
    selectedIndex,
    types,
    tabItems,
    handleClickOfficialEvent,
    handleClickOdai,
    handleClickTagItem,
  };
};

export default useHome;
