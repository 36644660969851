import { useContext } from 'react';
import Text from '@dotpict-lib/foundation/typography/Text';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import { LayoutContext } from '@/Routing';
import TranslationKeys from '@/app/translation/TranslationKeys';
import Ads from '@/app/component/partial/ads/Ads';
import DotpictAdsType from '@/app/ads/DotpictAdsType';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';
import useTranslator from '@/app/hook/useTranslator';
import Button from '@dotpict-lib/component/dotpict/Button';
import useWorkDetail from '@/app/component/page/work/detail/useWorkDetail';
import Column from '@dotpict-lib/component/core/layout/Column';
import { HorizontalGravity } from '@dotpict-lib/component/core/layout/HorizontalGravity';
import OGP from '@dotpict-lib/component/ogp/OGP';
import { Work } from '@/app/component/partial/work/item/Work';
import End from '@dotpict-lib/component/core/End';
import UserSummary from '@/app/component/partial/user/summary/UserSummary';
import { UserSummaryType } from '@/app/model/UserSummaryType';
import { WorkType } from '@/app/model/WorkType';
import Works from '@/app/component/Works';
import { css } from '@emotion/react';

export type WorkDetailState = {
  ogpImageUrl: string;
  workTitle: string;
  workCaption: string;
  workImageUrl: string;
  tags: string[];
  isLiked: boolean;
  likeCount: number;
  isRepicted: boolean;
  repictCount: number;
  threadCount: number;
  allowThread: boolean;
  width: number;
  height: number;
  createdAt: string;
  shareUrl: string;
  userId: number;
  userName: string;
  profileImageUrl: string;
  officialEventTag: string;
  officialEventTitle: string;
  userEventId: number;
  userEventTitle: string;
  odaiTag: string;
  odaiTitle: string;
  isVisibleEdit: boolean;
  userSummary: UserSummaryType;
  recommendedWorks: WorkType[];
  isVisibleAds: boolean;
  isLoading: boolean;
};

export type WorkDetailProps = WorkDetailState & {
  handleClickEditWork: () => void;
};

type Props = {
  workId: number;
};

const imageSize = 128;

const gridStyle = css({
  display: 'grid',
  justifyContent: 'center',
  columnGap: 4,
  rowGap: 4,
  gridTemplateColumns: `repeat(auto-fill, ${imageSize}px)`,
});

const WorkDetail = ({ workId }: Props) => {
  const { layoutParams } = useContext(LayoutContext);
  const translator = useTranslator();
  const {
    ogpImageUrl,
    workTitle,
    workCaption,
    workImageUrl,
    tags,
    isLiked,
    likeCount,
    isRepicted,
    repictCount,
    threadCount,
    allowThread,
    width,
    height,
    createdAt,
    userId,
    userName,
    profileImageUrl,
    officialEventTag,
    officialEventTitle,
    userEventId,
    userEventTitle,
    odaiTag,
    odaiTitle,
    isVisibleEdit,
    userSummary,
    recommendedWorks,
    isVisibleAds,
    isLoading,
    handleClickEditWork,
  } = useWorkDetail({ workId });

  return (
    <>
      <OGP title={workTitle} description={`${workTitle} - ${userName}`} imageUrl={ogpImageUrl} />
      <Column horizontalGravity={HorizontalGravity.center}>
        <Spacer height={layoutParams.contentTopSpace} />
        {isVisibleEdit && (
          <>
            <Button
              width={layoutParams.contentWidth - 24 * 2}
              height={48}
              text={translator(TranslationKeys.EditWorkInfo)}
              isFilled={false}
              isBorder
              handleClick={handleClickEditWork}
            />
            <Spacer height={32} />
          </>
        )}
        <Work
          workId={workId}
          title={workTitle}
          caption={workCaption}
          imageUrl={workImageUrl}
          tags={tags}
          width={width}
          height={height}
          createdAt={createdAt}
          isLiked={isLiked}
          isRepicted={isRepicted}
          userId={userId}
          userName={userName}
          officialEventTag={officialEventTag}
          officialEventTitle={officialEventTitle}
          userEventId={userEventId}
          userEventTitle={userEventTitle}
          odaiTag={odaiTag}
          odaiTitle={odaiTitle}
          profileImageUrl={profileImageUrl}
          likeCount={likeCount}
          repictCount={repictCount}
          threadCount={threadCount}
          allowThread={allowThread}
          isVisibleUser={false}
          maxImageSize={512}
        />
        <Spacer height={16} />
        <Column width="100%" background={ColorStyle.WHITE}>
          <UserSummary
            userId={userSummary.user.id}
            name={userSummary.user.name}
            text={userSummary.user.text}
            profileImageUrl={userSummary.user.profileImageUrl}
            isFollowed={userSummary.user.isFollowed}
            isFollower={userSummary.user.isFollower}
            works={userSummary.works}
            isVisibleFollow
            isLoading={isLoading}
          />
        </Column>
        {recommendedWorks.length !== 0 && (
          <>
            {isVisibleAds && (
              <>
                <Spacer height={40} />
                <Ads adsType={DotpictAdsType.RECTANGLE_LARGE} />
              </>
            )}
            <Spacer height={24} />
            <Column width="100%" horizontalPadding={16}>
              <Text
                text={translator(TranslationKeys.RecommendedWorks)}
                textStyle={TextStyle.BOLD16}
                colorStyle={ColorStyle.TEXT_PRIMARY}
              />
            </Column>
            <Spacer height={8} />
            <Column width="100%" padding={16} background={ColorStyle.WHITE}>
              <Works
                works={recommendedWorks}
                isLoading={isLoading}
                isVisibleEnd={false}
                isVisibleAds={false}
                isVisibleLike
                gridStyle={gridStyle}
                imageSize={imageSize}
                dummyItemCount={6}
              />
            </Column>
          </>
        )}
        {isVisibleAds && (
          <>
            <Spacer height={40} />
            <Ads adsType={DotpictAdsType.RECTANGLE_LARGE} />
          </>
        )}
        <Spacer height={40} />
        <End />
        <Spacer height={40} />
      </Column>
    </>
  );
};

export default WorkDetail;
