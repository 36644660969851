import { useState, useCallback, useRef, useContext } from 'react';
import styled from '@emotion/styled';
import ColorSelector from '@/app/component/page/draw/SideMenu/ColorSelector';
import { ToolsMap, ToolType } from '@/app/component/page/draw/Context/ToolContext';
import ToolButton from '@/app/component/page/draw/SideMenu/ToolSelector/ToolButton';
import TabLayout from '@/app/component/page/draw/SideMenu/ToolSelector/EditColor/TabLayout';
import PresetTabBody from '@/app/component/page/draw/SideMenu/ToolSelector/EditColor/PresetTabBody';
import HSVTab from '@/app/component/page/draw/SideMenu/ToolSelector/EditColor/HSVTab';
import { ClickAwayListener, Grow, MenuList, Paper, Popper } from '@material-ui/core';
import Pixelart from '@dotpict-lib/component/core/Pixelart';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';
import { ColorContext } from '@/app/component/page/draw/Context/ColorContext';
import Column from '@dotpict-lib/component/core/layout/Column';
import { HorizontalGravity } from '@dotpict-lib/component/core/layout/HorizontalGravity';

const MenuBody = styled.div`
  padding: 14px;
`;

const ColorSelectorContainer = styled.div`
  margin-bottom: -16px;
`;

const HideColorIcons = styled.div`
  position: relative;
  height: 24px;
  background: #fff;
`;

const ToolButtonWrapper = styled.div``;

type Props = {
  editPalette(index: number, color: string): void;
  selectTool: (selected: ToolType) => () => void;
  selectColor: (selectedIndex: number) => () => void;
};

const EditColor = ({ editPalette, selectTool, selectColor }: Props) => {
  const { palette, colorIndex, color } = useContext(ColorContext);
  const [openMenu, setOpenMenu] = useState(false);
  const menuAnchorRef = useRef(null);

  // TODO: 翻訳
  const tabItems = ['プリセット', '値'];
  const [tabSelectIndex, setTabSelectIndex] = useState(0);
  const handleClickTab = useCallback((index: number) => {
    setTabSelectIndex(index);
  }, []);

  const selectTarget = useCallback(
    (index: number) => {
      return () => {
        selectColor(index)();
      };
    },
    [selectColor],
  );

  const handleClickToolButton = useCallback(() => {
    setOpenMenu(true);
  }, []);

  const handleClosePopper = useCallback(() => {
    setOpenMenu(false);
  }, []);

  return (
    <div>
      <Popper
        open={openMenu}
        anchorEl={menuAnchorRef.current}
        role={undefined}
        transition
        placement="left"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClosePopper}>
                <MenuList autoFocusItem={openMenu} id="menu-list-grow">
                  <MenuBody>
                    <Column horizontalGravity={HorizontalGravity.end}>
                      <Pixelart
                        src="https://storage.googleapis.com/dotpict-images/web/ic_close.svg"
                        alt="copy_color_code"
                        width={32}
                        height={32}
                        handleClick={() => handleClosePopper()}
                      />
                    </Column>

                    <Spacer height={8} />

                    <TabLayout
                      items={tabItems}
                      selectedIndex={tabSelectIndex}
                      onClick={handleClickTab}
                    />

                    <Spacer height={24} />

                    <ColorSelectorContainer>
                      <ColorSelector
                        palette={palette}
                        vertical={false}
                        selectColor={selectTarget}
                        selectedColorIndex={colorIndex}
                      />
                    </ColorSelectorContainer>
                    <HideColorIcons />

                    {tabSelectIndex === 0 ? (
                      <PresetTabBody editPalette={editPalette} colorIndex={colorIndex} />
                    ) : (
                      <HSVTab color={color} editPalette={editPalette} colorIndex={colorIndex} />
                    )}
                  </MenuBody>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      <ToolButtonWrapper
        ref={menuAnchorRef}
        aria-controls={openMenu ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleClickToolButton}
      >
        <ToolButton toolType={ToolsMap.EditColor} selectTool={selectTool} />
      </ToolButtonWrapper>
    </div>
  );
};

export default EditColor;
