import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import Text from '@dotpict-lib/foundation/typography/Text';

interface IProps {
  text: string;
  url: string;
  textStyle: TextStyle;
  colorStyle: ColorStyle;
}

const ExternalLinkComponent = (props: IProps) => {
  const { text, url, textStyle, colorStyle } = props;

  return (
    // eslint-disable-next-line jsx-a11y/control-has-associated-label
    <a href={url}>
      <Text text={text} textStyle={textStyle} colorStyle={colorStyle} />
    </a>
  );
};

export default ExternalLinkComponent;
