import {
  Dependencies,
  OfficialEventContext,
} from '@/app/component/page/event/official/normal/useOfficialEvent';
import { useContext, useMemo } from 'react';
import { APIContext } from '@/Routing';
import { PagingType } from '@dotpict-lib/model/PagingType';
import OfficialEvent from '@/app/component/page/event/official/normal/OfficialEvent';
import { useParams } from 'react-router-dom';

const OfficialEventPage = () => {
  const { client } = useContext(APIContext);
  const { officialEventTag } = useParams();

  const dependencies = useMemo((): Dependencies => {
    return {
      getOfficialEventService: (officialEventTag: string) =>
        client.fetchOfficialEventDetail(officialEventTag),
      getOfficialEventWorksService: (paging: PagingType) => client.fetchWorks(paging.nextUrl),
    };
  }, [client]);

  return (
    <OfficialEventContext.Provider value={dependencies}>
      <OfficialEvent officialEventTag={officialEventTag ?? ''} />
    </OfficialEventContext.Provider>
  );
};

export default OfficialEventPage;
