import { MouseEventHandler } from 'react';
import Text from '@dotpict-lib/foundation/typography/Text';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import Pixelart from '@dotpict-lib/component/core/Pixelart';
import { css } from '@emotion/react';
import { Row } from '@dotpict-lib/component/core/layout/Row';

export type TagProps = {
  fixed: boolean;
  tagName: string;
  onClick?: MouseEventHandler;
};

const styledButton = css`
  background-color: transparent;
  border: none;
  overflow: hidden;
  outline: none;
`;

const Tag = ({ fixed, tagName, onClick }: TagProps) => (
  <Row>
    <Text text={tagName} textStyle={TextStyle.REGULAR14} colorStyle={ColorStyle.PRIMARY} />
    {!fixed && (
      <button css={styledButton} type="button" onClick={onClick}>
        <Pixelart
          alt="close"
          src="https://storage.googleapis.com/dotpict-images/web/ic_delete.svg"
          width={16}
          height={16}
        />
      </button>
    )}
  </Row>
);

export default Tag;
