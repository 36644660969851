import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import { NoteImage, NoteState } from '@/app/component/page/note/item/Note';
import { NoteType } from '@dotpict-sns-lib/model/Note/NoteType';
import useTranslator from '@/app/hook/useTranslator';
import TranslationKeys from '@/app/translation/TranslationKeys';
import { NoteScopeType } from '@dotpict-sns-lib/model/Note/NoteScopeType';

const resizeImage = (
  width: number,
  height: number,
  maxSize: number = 128,
): { width: number; height: number } => {
  // 画像が正方形の場合
  if (width === height) {
    return {
      width: maxSize,
      height: maxSize,
    };
  }
  // 画像が横長の場合
  if (width > height) {
    return {
      width: maxSize,
      height: Math.round((height / width) * maxSize),
    };
  }
  // 画像が縦長の場合
  return {
    width: Math.round((width / height) * maxSize),
    height: maxSize,
  };
};

type Props = {
  note: NoteType;
};

export const useNote = ({ note }: Props): NoteState => {
  const navigator = useNavigate();
  const translator = useTranslator();

  const adminNoteImage = useMemo((): NoteImage | null => {
    if (note.image === null) {
      return null;
    }
    const { width, height } = resizeImage(note.image.width, note.image.height);
    return {
      imageUrl: note.image.imageUrl,
      width,
      height,
    };
  }, [note.image]);

  const onClickUser = () => {
    navigator(`/users/${note.user.id}`);
  };

  const scopeTranslation = (noteScope: NoteScopeType): string | null => {
    switch (noteScope) {
      case 'followers':
        return translator(TranslationKeys.FollowersOnly);
      case 'mutual_followers':
        return translator(TranslationKeys.MutualFollowOnly);
      default:
        return null;
    }
  };

  return {
    profileImageUrl: note.user.profileImageUrl,
    userName: note.user.name,
    text: note.text,
    image: adminNoteImage,
    scope: scopeTranslation(note.scope),
    createdAt: note.createdAt,
    onClickUser,
  };
};
