export interface ILayoutParams {
  contentTopSpace: number;
  contentBottomSpace: number;
  rectangleAdsWidth: number;
  rectangleAdsHeight: number;
  contentWidth: number;
  mainContainerWidth: number;
  betweenContentAndSideSpace: number;
  sideComponentWidth: number;
  fullWidth: number;
  thumbnailImageSize: number;
  smallThumbnailImageSize: number;
  thumbnailImageGridGap: number;
  smallThumbnailImageGridGap: number;
  homeSectionInfoSideMargin: number;
  makingCarouselPadding: number;
  makingCarouselItemSpace: number;
  isMobile: boolean;
}

export const defaultLayoutParams: ILayoutParams = {
  contentBottomSpace: 0,
  contentTopSpace: 0,
  rectangleAdsWidth: 0,
  rectangleAdsHeight: 0,
  contentWidth: 0,
  mainContainerWidth: 0,
  betweenContentAndSideSpace: 0,
  sideComponentWidth: 0,
  fullWidth: 0,
  thumbnailImageSize: 0,
  smallThumbnailImageSize: 0,
  thumbnailImageGridGap: 0,
  smallThumbnailImageGridGap: 0,
  homeSectionInfoSideMargin: 0,
  makingCarouselPadding: 0,
  makingCarouselItemSpace: 0,
  isMobile: false,
};
