enum ColorStyle {
  PRIMARY = '#F15D94',
  PRIMARY_DEEP = '#D24474',
  PRIMARY_PALE = '#FDD3E1',
  TEXT_PRIMARY = '#313235',
  TEXT_SECONDARY = '#5A5F69',
  GRAY_DEEP = '#B2B2B2',
  WHITE = '#FFFFFF',
  WHITE_GRAY = '#F4F6F9',
  BLUE = '#00C2FF',
  NIGHT_BACKGROUND = '#313235',
  ERROR = '#DD5757',
  PALE_LIGHTER = '#FAFAFA',
  ACTIVE = '#5EBCF3',
  MONO00 = '#FFFFFF',
  MONO20 = '#EEEEF4',
  MONO50 = '#ABB4C6',
}

export default ColorStyle;
