import { createContext, useContext, useState } from 'react';
import {
  dummyPostFeedbackService,
  PostFeedbackService,
} from '@/app/service/PostFeedbackService/PostFeedbackService';
import { FeedbackViewProps } from '@/app/component/page/feedback/Feedback';
import { GlobalContext } from '@/Routing';

export type FeedbackDependencies = {
  postFeedbackService: PostFeedbackService;
};

export const FeedbackContext = createContext<FeedbackDependencies>({
  postFeedbackService: dummyPostFeedbackService,
});

export const useFeedback = (): FeedbackViewProps => {
  const { dispatch } = useContext(GlobalContext);
  const { postFeedbackService } = useContext(FeedbackContext);
  const [message, setMessage] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [feedbackSucceeded, setFeedbackSucceeded] = useState(false);

  const send = async () => {
    if (message === '') return;
    setLoading(true);
    const response = await postFeedbackService(message).catch((error: Error) => {
      dispatch({
        type: 'SHOW_MESSAGE',
        payload: { message: error.message },
      });
    });
    if (response) {
      setFeedbackSucceeded(true);
    }
    setLoading(false);
  };

  const onChangeMessageChanged = (newMessage: string) => {
    setMessage(newMessage);
  };

  const onClickSend = () => {
    send();
  };

  return {
    message,
    isLoading,
    feedbackSucceeded,
    onChangeMessageChanged,
    onClickSend,
  };
};
