import { WorkType } from '@/app/model/WorkType';
import TestUserSraim from '@/app/test/user/TestUserSraim';

const TestWorkPiplup: WorkType = {
  id: 3684895,
  title: 'ポッチャマ',
  caption: 'ぽっさまかわいい( *´꒳`*)',
  tags: [],
  likeCount: 513,
  repictCount: 3,
  threadCount: 12,
  thumbnailImageUrl:
    'https://img.dotpicko.net/thumbnail_work/2022/03/01/07/42/thumb_1ea8a2a2e254bb0ac65d58963e74ecc3c68332f7e0dcfd547dab70d5e99b2fc7.png',
  imageUrl:
    'https://img.dotpicko.net/work/2022/03/01/07/42/1ea8a2a2e254bb0ac65d58963e74ecc3c68332f7e0dcfd547dab70d5e99b2fc7.gif',
  ogpImageUrl:
    'https://img.dotpicko.net/ogp_work/2022/03/01/07/42/1ea8a2a2e254bb0ac65d58963e74ecc3c68332f7e0dcfd547dab70d5e99b2fc7.gif',
  width: 96,
  height: 64,
  user: TestUserSraim,
  isLiked: false,
  isRepicted: false,
  createdAt: '2022/03/01',
  allowThread: true,
  isAnimation: false,
  userEventId: 0,
  userEvent: null,
  officialEventId: 0,
  officialEvent: null,
  odaiId: 0,
  odai: null,
  shareUrl: 'https://dotpict.net/works/3684895',
  colorCodes: [
    { red: 96, green: 78, blue: 97, alpha: 255 },
    { red: 93, green: 104, blue: 145, alpha: 255 },
    { red: 115, green: 149, blue: 192, alpha: 255 },
    { red: 117, green: 178, blue: 203, alpha: 255 },
    { red: 153, green: 208, blue: 220, alpha: 255 },
    { red: 216, green: 234, blue: 237, alpha: 255 },
    { red: 254, green: 254, blue: 254, alpha: 255 },
    { red: 253, green: 234, blue: 224, alpha: 255 },
    { red: 243, green: 166, blue: 178, alpha: 255 },
    { red: 249, green: 222, blue: 103, alpha: 255 },
  ],
  cropRect: null,
};

export default TestWorkPiplup;
