type Props = {
  paletteName: string;
  colors: string[];
};

const useDownloadPalette = ({ paletteName, colors }: Props) => {
  return () => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d')!;
    canvas.width = colors.length;
    canvas.height = 1;
    colors.forEach((color, index) => {
      context.fillStyle = `#${color}`;
      context.fillRect(index, 0, 1, 1);
    });
    const anchor: HTMLAnchorElement = document.createElement('a');
    canvas.toBlob((blob: any) => {
      if (anchor !== null && blob) {
        anchor.href = window.URL.createObjectURL(blob);
        anchor.download = `${paletteName}_colors.png`;
        anchor.click();
      }
    });
  };
};

export default useDownloadPalette;
