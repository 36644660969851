import UserDetail from '@/app/component/page/user/detail/UserDetail';
import { useContext, useMemo } from 'react';
import { APIContext } from '@/Routing';
import { Dependencies, UserDetailContext } from '@/app/component/page/user/detail/useUserDetail';
import { useParams } from 'react-router-dom';
import { PagingType } from '@dotpict-lib/model/PagingType';

const UserDetailPage = () => {
  const { client } = useContext(APIContext);
  const { userId, accountName } = useParams();

  const dependencies = useMemo((): Dependencies => {
    return {
      getUserDetailService: (account: string) => client.fetchUsersByAccount(account),
      getUserWorksService: (paging: PagingType) => client.fetchUserWorks(paging),
    };
  }, [client]);

  return (
    <UserDetailContext.Provider value={dependencies}>
      <UserDetail userId={Number(userId ?? 0)} account={accountName ?? ''} />
    </UserDetailContext.Provider>
  );
};

export default UserDetailPage;
