import { createContext, useContext, useEffect, useState } from 'react';
import { GlobalContext } from '@/Routing';
import { WorkDetailProps } from '@/app/component/page/work/detail/WorkDetail';
import { defaultWorkDetailState } from '@/app/state/IWorkDetailState';
import { useNavigate } from 'react-router-dom';
import { GetWorkDetailService } from '@/app/service/GetWorkDetail/GetWorkDetailService';
import dummyGetWorkDetailService from '@/app/service/GetWorkDetail/dummyGetWorkDetailService';
import { useIsPremium } from '@/app/hook/useIsPremium';

export type Dependencies = {
  getWorkDetailService: GetWorkDetailService;
};

export const WorkDetailContext = createContext<Dependencies>({
  getWorkDetailService: dummyGetWorkDetailService,
});

type Props = {
  workId: number;
};

const useWorkDetail = ({ workId }: Props): WorkDetailProps => {
  const { globalState, dispatch } = useContext(GlobalContext);
  const navigator = useNavigate();
  const isPremium = useIsPremium();
  const { getWorkDetailService } = useContext(WorkDetailContext);
  const [isLoading, setLoading] = useState(false);

  const reload = async () => {
    if (workId === 0) return;
    setLoading(true);
    const response = await getWorkDetailService(workId);
    dispatch({
      type: 'UPDATE_WORK_DETAIL_STATE',
      payload: {
        workDetailState: {
          work: response.work,
          userSummary: response.userSummary,
          recommendedWorks: response.recommendedWorks,
          shouldReload: false,
        },
      },
    });
    setLoading(false);
  };

  useEffect(() => {
    const storedWorkId = globalState.workDetailState.work.id;
    if (workId === storedWorkId && !globalState.workDetailState.shouldReload) {
      return;
    }
    dispatch({
      type: 'UPDATE_WORK_DETAIL_STATE',
      payload: {
        workDetailState:
          workId === storedWorkId ? globalState.workDetailState : defaultWorkDetailState,
      },
    });
    window.scrollTo(0, 0);
    reload();
  }, [workId]);

  const handleClickEditWork = () => {
    navigator(`/works/${workId}/edit`);
  };

  return {
    ogpImageUrl: globalState.workDetailState.work.ogpImageUrl,
    workTitle: globalState.workDetailState.work.title,
    workCaption: globalState.workDetailState.work.caption,
    workImageUrl: globalState.workDetailState.work.imageUrl,
    tags: globalState.workDetailState.work.tags,
    isLiked: globalState.workDetailState.work.isLiked,
    likeCount: globalState.workDetailState.work.likeCount,
    isRepicted: globalState.workDetailState.work.isRepicted,
    repictCount: globalState.workDetailState.work.repictCount,
    threadCount: globalState.workDetailState.work.threadCount,
    allowThread: globalState.workDetailState.work.allowThread,
    width: globalState.workDetailState.work.width,
    height: globalState.workDetailState.work.height,
    createdAt: globalState.workDetailState.work.createdAt,
    shareUrl: globalState.workDetailState.work.shareUrl,
    userId: globalState.workDetailState.work.user.id,
    userName: globalState.workDetailState.work.user.name,
    officialEventTag: globalState.workDetailState.work.officialEvent?.tag ?? '',
    officialEventTitle: globalState.workDetailState.work.officialEvent?.title ?? '',
    userEventId: globalState.workDetailState.work.userEvent?.id ?? 0,
    userEventTitle: globalState.workDetailState.work.userEvent?.title ?? '',
    odaiTag: globalState.workDetailState.work.odai?.tag ?? '',
    odaiTitle: globalState.workDetailState.work.odai?.title ?? '',
    profileImageUrl: globalState.workDetailState.work.user.profileImageUrl,
    isVisibleEdit:
      globalState.meState.user.id > 0 &&
      globalState.meState.user.id === globalState.workDetailState.work.user.id,
    userSummary: globalState.workDetailState.userSummary,
    recommendedWorks: globalState.workDetailState.recommendedWorks,
    isVisibleAds: !isPremium,
    isLoading,
    handleClickEditWork,
  };
};

export default useWorkDetail;
