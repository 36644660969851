import styled from '@emotion/styled';
import { css } from '@emotion/react';
import ColorBody from '@/app/component/page/draw/SideMenu/ColorSelector/Icon/ColorBody';
import HeadColor from '@/app/component/page/draw/SideMenu/ColorSelector/Icon/HeadColor';
import Outline from '@/app/component/page/draw/SideMenu/ColorSelector/Icon/Outline';

const Selector = styled.button<{ color: string; selected: boolean; vertical: boolean }>`
  position: relative;
  width: ${({ vertical }) => (vertical ? '80px' : '20px')};
  height: ${({ vertical }) => (vertical ? '20px' : '80px')};
  color: ${({ color }) => `#${color}`};
  padding: 0;
  background: transparent;
  appearance: none;
  border: none;
  outline: none;
`;

const IconContainer = styled.div<{ selected: boolean; vertical: boolean }>`
  ${({ vertical, selected }) =>
    vertical
      ? `
      /*
        * rotateZ：回転
        * translateX：回転後の縦方向の位置調整(中心を基準に回転するので)
        * translateY：回転後の横方向の位置調整
        *   40pxのうち半分がサイドバーに埋まっている状態(20px隠れる)
        *   非選択時はもう10px表示する
        *   選択時はもう20px表示する
        */
        transform: rotateZ(90deg) translateX(30px) translateY(${selected ? '-20px' : '-10px'});
    `
      : `
      transform: translateY(${selected ? '-50px' : '-40px'});
    `};

  transition: transform 0.2s;
  cursor: ${({ selected }) => (selected ? 'default' : 'pointer')};
`;

const IconStyle = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 80px;
`;

const StyledColorBody = styled(ColorBody)`
  ${IconStyle};
`;
const StyledHeadColor = styled(HeadColor)<{ color: string }>`
  ${IconStyle};
  // えんぴつの木の部分。基本的には白だが、色が白のときだけ変える。
  color: ${({ color }) => (color.toLocaleLowerCase() === 'ffffff' ? '#ffe8d8' : '#fff')};
`;
const StyledOutline = styled(Outline)`
  ${IconStyle};
`;

type Props = {
  color: string;
  index: number;
  selectedIndex: number;
  vertical: boolean;
  selectColor: (selectedIndex: number) => () => void;
};

const ColorButton = ({ color, index, selectedIndex, vertical, selectColor }: Props) => {
  const selected = index === selectedIndex;

  return (
    <Selector color={color} selected={selected} vertical={vertical} onClick={selectColor(index)}>
      <IconContainer selected={selected} vertical={vertical}>
        <StyledHeadColor color={color} />
        <StyledColorBody />
        <StyledOutline />
      </IconContainer>
    </Selector>
  );
};

export default ColorButton;
