import React, { useContext, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';
import Text from '@dotpict-lib/foundation/typography/Text';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import { APIContext, LayoutContext, MeContext } from '@/Routing';
import SearchIcon from '@/app/image/search.svg';
import { dotpictFirebaseAuth } from '@/app/library/dotpictFirebaseAuth';
import Pixelart from '@dotpict-lib/component/core/Pixelart';
import TranslationKeys from '@/app/translation/TranslationKeys';
import { NotificationType } from '@/app/model/NotificationType';
import Notification from '@/app/component/partial/notification/Notification';
import useTranslator from '@/app/hook/useTranslator';
import { styled } from '@material-ui/core/styles';
import Column from '@dotpict-lib/component/core/layout/Column';
import { HorizontalGravity } from '@dotpict-lib/component/core/layout/HorizontalGravity';
import { Row } from '@dotpict-lib/component/core/layout/Row';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';
import Loading from '@dotpict-lib/component/core/Loading';
import { VerticalGravity } from '@dotpict-lib/component/core/layout/VerticalGravity';
import Clickable from '@dotpict-lib/component/core/Clickable';
import { LanguageContext } from '@/Language';

const menuWidth = 280;
const notificationWidth = 280;
const inputWidth = 240;
const inputStartEndSpace = 12;
const inputBetweenImageAndTextFieldSpace = 8;

const inputStyle = css({
  border: 'none',
  outline: 'none',
  background: 'transparent',
  fontSize: '13px',
  width: inputWidth - inputStartEndSpace * 2 - inputBetweenImageAndTextFieldSpace,
});

const NoPaddingButton = styled(Button)({
  display: 'inline-block',
  padding: 0,
  minHeight: 0,
  minWidth: 48,
});

const DPMenuSectionHeader = ({ title }: { title: string }) => (
  <Column padding="12 16 8 16" horizontalGravity={HorizontalGravity.start}>
    <Text text={title} textStyle={TextStyle.BOLD14} colorStyle={ColorStyle.TEXT_PRIMARY} />
  </Column>
);

const MenuItemContainer = ({ children }: { children: React.ReactNode }) => (
  <Column
    width={menuWidth - 16 * 2}
    height={40}
    horizontalGravity={HorizontalGravity.start}
    verticalGravity={VerticalGravity.center}
    padding="0 16"
    background={ColorStyle.WHITE}
  >
    {children}
  </Column>
);

const MenuItemLabel = ({ title }: { title: string }) => (
  <Text text={title} textStyle={TextStyle.REGULAR13} colorStyle={ColorStyle.TEXT_PRIMARY} />
);

const HeaderComponent = () => {
  const meState = useContext(MeContext);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [notifications, setNotifications] = useState<NotificationType[]>([]);
  const [isVisibleMoreNotifications, setVisibleMoreNotifications] = useState<boolean>(false);
  const [isLoadingNotifications, setLoadingNotifications] = useState<boolean>(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [openMobileSearch, setOpenMobileSearch] = useState(false);
  const { layoutParams } = useContext(LayoutContext);
  const navigator = useNavigate();
  const [renderHeader, setRenderHeader] = useState(true);
  const location = useLocation();
  const translator = useTranslator();
  const menuAnchorRef = React.useRef(null);
  const notificationAnchorRef = React.useRef(null);
  const { client } = useContext(APIContext);
  const { language, setLanguage } = useContext(LanguageContext);

  const mobileButtonStyle = {
    maxWidth: 56,
    maxHeight: 44,
    minWidth: 56,
    minHeight: 44,
  };

  const notificationImageStyle = css({
    width: 30,
    height: 30,
  });

  const handleClosePopper = () => {
    setOpenMenu(false);
    setOpenNotifications(false);
  };

  const handleClickNotification = (notification: NotificationType) => {
    if (notification.url) {
      navigator(notification.url.replace('https://dotpict.net', ''));
      handleClosePopper();
    }
  };

  const handleClickMoreNotifications = () => {
    navigator('/notifications');
    handleClosePopper();
  };

  const handleClickNav = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };

  const handleClickNotifications = async () => {
    if (openNotifications) {
      setOpenNotifications((prevOpen) => !prevOpen);
      return;
    }
    setOpenNotifications((prevOpen) => !prevOpen);
    setVisibleMoreNotifications(false);
    setNotifications([]);
    setLoadingNotifications(true);
    const nextUrl = `${client.getBaseUrl}/me/notifications`;
    const notificationResponse = await client.fetchNotifications(meState.token, nextUrl);
    setVisibleMoreNotifications(notificationResponse.notifications.length > 3);
    setNotifications(notificationResponse.notifications.slice(0, 3));
    setLoadingNotifications(false);
  };

  useEffect(() => {
    if (window.gtag) {
      window.gtag('config', 'UA-55893133-2', {
        page_path: location.pathname,
      });
    }
    const params = new URLSearchParams(location.search);
    setRenderHeader(params.get('noHeader') !== 'true');
  }, [location]);

  const handlePostWork = () => {
    navigator('/upload');
    handleClosePopper();
  };

  const handleClickDraw = () => {
    navigator('/canvases');
    handleClosePopper();
  };

  const handleMyPage = () => {
    navigator('/me/postedWorks');
    handleClosePopper();
  };

  const handleRequestBox = () => {
    navigator('/me/requestBox');
    handleClosePopper();
  };

  const handleClickFeedback = () => {
    navigator('/feedback');
    handleClosePopper();
  };

  const handleClickGuidelines = () => {
    navigator('/guidelines');
    handleClosePopper();
  };

  const handleClickPrivacyPolicy = () => {
    navigator('/privacy');
    handleClosePopper();
  };

  const handleClickCompany = () => {
    window.location.href = 'https://dotpict.com';
    handleClosePopper();
  };

  const handleClickEnglish = () => {
    setLanguage('en');
  };

  const handleClickJapanese = () => {
    setLanguage('ja');
  };

  const handleLogout = () => {
    const logout = async () => {
      await dotpictFirebaseAuth.signOut();
    };
    logout();
    handleClosePopper();
  };

  function handleListKeyDown(event: any) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenMenu(false);
    }
  }

  const handleSearchKeyPress = (event: any) => {
    const query = event.target.value;
    // 13 => enterkeyのwhich
    if (event.which === 13 && query !== '') {
      navigator(`/search/works/title/${query}`);
      setOpenMobileSearch(false);
    }
  };

  const handleMobileSearchClick = () => {
    setOpenMobileSearch(true);
  };

  const handleMobileSearchCancel = () => {
    setOpenMobileSearch(false);
  };

  const renderLogoComponent = () => (
    <Link to="/">
      <img
        src="https://storage.googleapis.com/dotpict-images/web/logo.svg"
        alt="dotpict"
        width={108}
        height={20}
      />
    </Link>
  );

  const renderMenu = () => (
    <Popper open={openMenu} anchorEl={menuAnchorRef.current} role={undefined} transition>
      {({ TransitionProps, placement }) => (
        <Grow
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...TransitionProps}
          style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClosePopper}>
              <MenuList
                autoFocusItem={openMenu}
                id="menu-list-grow"
                onKeyDown={(e) => handleListKeyDown(e)}
              >
                <Column
                  horizontalGravity={HorizontalGravity.start}
                  background={ColorStyle.WHITE_GRAY}
                >
                  {meState.isLoggedIn && (
                    <>
                      <DPMenuSectionHeader title={translator(TranslationKeys.Menu)} />
                      <Column innerPadding={1} horizontalGravity={HorizontalGravity.start}>
                        <Clickable onClick={handleMyPage}>
                          <MenuItemContainer>
                            <MenuItemLabel title={translator(TranslationKeys.MyPage)} />
                          </MenuItemContainer>
                        </Clickable>
                        <Clickable onClick={handleRequestBox}>
                          <MenuItemContainer>
                            <MenuItemLabel title={translator(TranslationKeys.RequestBox)} />
                          </MenuItemContainer>
                        </Clickable>
                        <Clickable onClick={handleLogout}>
                          <MenuItemContainer>
                            <MenuItemLabel title={translator(TranslationKeys.Logout)} />
                          </MenuItemContainer>
                        </Clickable>
                      </Column>
                    </>
                  )}
                  <DPMenuSectionHeader title={translator(TranslationKeys.Others)} />
                  <Column innerPadding={1} horizontalGravity={HorizontalGravity.start}>
                    <Clickable onClick={handleClickFeedback}>
                      <MenuItemContainer>
                        <MenuItemLabel title={translator(TranslationKeys.Feedback)} />
                      </MenuItemContainer>
                    </Clickable>
                    <Clickable onClick={handleClickGuidelines}>
                      <MenuItemContainer>
                        <MenuItemLabel title={translator(TranslationKeys.Guidelines)} />
                      </MenuItemContainer>
                    </Clickable>
                    <Clickable onClick={handleClickPrivacyPolicy}>
                      <MenuItemContainer>
                        <MenuItemLabel title={translator(TranslationKeys.PrivacyTitle)} />
                      </MenuItemContainer>
                    </Clickable>
                    <Clickable onClick={handleClickCompany}>
                      <MenuItemContainer>
                        <MenuItemLabel title={translator(TranslationKeys.Company)} />
                      </MenuItemContainer>
                    </Clickable>
                  </Column>
                  <DPMenuSectionHeader title={translator(TranslationKeys.Language)} />
                  <MenuItemContainer>
                    <Row innerPadding={16}>
                      <Clickable onClick={handleClickEnglish}>
                        <Text
                          text="English"
                          textStyle={TextStyle.REGULAR13}
                          colorStyle={
                            language === 'en' ? ColorStyle.PRIMARY : ColorStyle.TEXT_PRIMARY
                          }
                        />
                      </Clickable>
                      <Clickable onClick={handleClickJapanese}>
                        <Text
                          text="日本語"
                          textStyle={TextStyle.REGULAR13}
                          colorStyle={
                            language === 'ja' ? ColorStyle.PRIMARY : ColorStyle.TEXT_PRIMARY
                          }
                        />
                      </Clickable>
                    </Row>
                  </MenuItemContainer>
                </Column>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );

  const renderNotifications = () => (
    <Popper
      style={{ width: notificationWidth }}
      open={openNotifications}
      anchorEl={notificationAnchorRef.current}
      role={undefined}
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...TransitionProps}
          style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
        >
          <Paper>
            <ClickAwayListener onClickAway={() => handleClosePopper()}>
              <MenuList
                autoFocusItem={openNotifications}
                id="menu-list-grow"
                onKeyDown={(e) => handleListKeyDown(e)}
              >
                {notifications.map((notification) => (
                  <MenuItem
                    key={notification.id}
                    style={{ padding: 0 }}
                    onClick={() => handleClickNotification(notification)}
                  >
                    <Notification notification={notification} />
                  </MenuItem>
                ))}
                {isVisibleMoreNotifications && (
                  <MenuItem
                    key={translator(TranslationKeys.LoadMore)}
                    onClick={() => handleClickMoreNotifications()}
                  >
                    <Column width="100%" horizontalGravity={HorizontalGravity.center}>
                      <Text
                        text={translator(TranslationKeys.LoadMore)}
                        textStyle={TextStyle.BOLD14}
                        colorStyle={ColorStyle.PRIMARY}
                      />
                    </Column>
                  </MenuItem>
                )}
                {isLoadingNotifications && <Loading />}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );

  const renderSearchComponent = () => (
    <Row
      width={inputWidth}
      height="36"
      background={ColorStyle.WHITE_GRAY}
      verticalGravity={VerticalGravity.center}
    >
      <Spacer width={inputStartEndSpace} />
      <Pixelart alt="search" src={SearchIcon} width={16} height={16} />
      <Spacer width={inputBetweenImageAndTextFieldSpace} />
      <input
        css={inputStyle}
        type="text"
        placeholder={translator(TranslationKeys.Search)}
        onKeyPress={handleSearchKeyPress}
      />
      <Spacer width={inputStartEndSpace} />
    </Row>
  );

  const renderSignUpComponent = (textStyle: TextStyle) => (
    <Link to="/signup">
      <Row>
        <Spacer width={8} />
        <Text
          text={translator(TranslationKeys.SignUp)}
          textStyle={textStyle}
          colorStyle={ColorStyle.PRIMARY}
        />
        <Spacer width={8} />
      </Row>
    </Link>
  );

  const renderLoginComponent = () => (
    <Link to="/login">
      <Row>
        <Spacer width={8} />
        <Text
          text={translator(TranslationKeys.Login)}
          textStyle={TextStyle.BOLD16}
          colorStyle={ColorStyle.PRIMARY}
        />
        <Spacer width={8} />
      </Row>
    </Link>
  );

  const renderHeaderComponent = () => (
    <Row
      height="80"
      width={layoutParams.fullWidth}
      horizontalGravity={HorizontalGravity.spaceBetween}
      verticalGravity={VerticalGravity.center}
    >
      <Row verticalGravity={VerticalGravity.center}>
        {renderLogoComponent()}
        <Spacer width={96} />
        {renderSearchComponent()}
      </Row>
      <Row verticalGravity={VerticalGravity.center}>
        {meState.isLoggedIn ? (
          <>
            <NoPaddingButton onClick={() => handlePostWork()}>
              <Column horizontalGravity={HorizontalGravity.center} innerPadding={6}>
                <img
                  css={notificationImageStyle}
                  alt="upload"
                  src="https://storage.googleapis.com/dotpict-images/web/ic_upload.svg"
                />
                <Text
                  text={translator(TranslationKeys.PostWork)}
                  textStyle={TextStyle.BOLD12}
                  colorStyle={ColorStyle.PRIMARY}
                />
              </Column>
            </NoPaddingButton>
            <Spacer width={24} />
            <NoPaddingButton onClick={() => handleClickDraw()}>
              <Column horizontalGravity={HorizontalGravity.center} innerPadding={6}>
                <img
                  css={notificationImageStyle}
                  alt="draw"
                  src="https://storage.googleapis.com/dotpict-images/web/ic_draw.svg"
                />
                <Text
                  text={translator(TranslationKeys.Draw)}
                  textStyle={TextStyle.BOLD12}
                  colorStyle={ColorStyle.PRIMARY}
                />
              </Column>
            </NoPaddingButton>
            <Spacer width={24} />
            <NoPaddingButton
              ref={notificationAnchorRef}
              aria-controls={openNotifications ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={handleClickNotifications}
            >
              <Column horizontalGravity={HorizontalGravity.center} innerPadding={6}>
                <img
                  css={notificationImageStyle}
                  alt="notification"
                  src="https://storage.googleapis.com/dotpict-images/web/ic_notice_active.svg"
                />
                <Text
                  text={translator(TranslationKeys.Notice)}
                  textStyle={TextStyle.BOLD12}
                  colorStyle={ColorStyle.PRIMARY}
                />
              </Column>
            </NoPaddingButton>
            <Spacer width={24} />
            <Button
              style={{ textTransform: 'none' }}
              ref={menuAnchorRef}
              aria-controls={openMenu ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={handleClickNav}
            >
              <Pixelart
                alt={meState.user.name}
                src={meState.user.profileImageUrl}
                width={48}
                height={48}
              />
            </Button>
          </>
        ) : (
          <>
            {renderLoginComponent()}
            {renderSignUpComponent(TextStyle.BOLD16)}
            <Button
              style={{ textTransform: 'none' }}
              ref={menuAnchorRef}
              aria-controls={openMenu ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={handleClickNav}
            >
              <Pixelart
                alt="nav_icon"
                src="https://storage.googleapis.com/dotpict-images/web/ic_nav.svg?v2"
                width={48}
                height={48}
              />
            </Button>
          </>
        )}
      </Row>
    </Row>
  );

  const renderMobileHeaderComponent = () => (
    <Column>
      <Row
        height="44"
        width={layoutParams.fullWidth}
        horizontalGravity={HorizontalGravity.spaceBetween}
        verticalGravity={VerticalGravity.center}
      >
        <Row width="50" horizontalGravity={HorizontalGravity.start}>
          <Button style={mobileButtonStyle} onClick={handleMobileSearchClick}>
            <Pixelart alt="search" src={SearchIcon} width={16} height={16} />
          </Button>
        </Row>
        {renderLogoComponent()}
        <Row width="50" horizontalGravity={HorizontalGravity.end}>
          {meState.isLoggedIn ? (
            <Button
              style={mobileButtonStyle}
              ref={menuAnchorRef}
              aria-controls={openMenu ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={handleClickNav}
            >
              <Pixelart
                alt="profile_image"
                src={meState.user.profileImageUrl}
                width={24}
                height={24}
              />
            </Button>
          ) : (
            renderSignUpComponent(TextStyle.BOLD12)
          )}
        </Row>
      </Row>
      {openMobileSearch && (
        <Row>
          <Spacer width={16} />
          <Column>
            <Spacer height={16} />
            <Text
              text={translator(TranslationKeys.Search)}
              textStyle={TextStyle.BOLD16}
              colorStyle={ColorStyle.TEXT_PRIMARY}
            />
            <Spacer height={16} />
            <Row verticalGravity={VerticalGravity.center}>
              {renderSearchComponent()}
              <Spacer width={16} />
              <Button onClick={handleMobileSearchCancel}>
                <Text
                  text={translator(TranslationKeys.Cancel)}
                  textStyle={TextStyle.BOLD12}
                  colorStyle={ColorStyle.PRIMARY}
                />
              </Button>
            </Row>
            <Spacer height={16} />
          </Column>
        </Row>
      )}
    </Column>
  );

  if (!renderHeader) {
    return null;
  }

  return (
    <Row width="100%" horizontalGravity={HorizontalGravity.center} background={ColorStyle.WHITE}>
      {layoutParams.isMobile ? renderMobileHeaderComponent() : renderHeaderComponent()}
      {renderMenu()}
      {renderNotifications()}
    </Row>
  );
};

export default HeaderComponent;
