import { Button } from '@material-ui/core';
import AppStoreImg from '@/app/image/appstore.png';

const AppStore = () => {
  const handleClickAppStore = () => {
    window.location.href = 'https://apps.apple.com/jp/app/dotpict/id995653343';
  };

  return (
    <Button onClick={handleClickAppStore}>
      <img alt="app store" src={AppStoreImg} width={120} />
    </Button>
  );
};

export default AppStore;
