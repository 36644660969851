import { emptyUserSummary, UserSummaryType } from '@/app/model/UserSummaryType';
import emptyWork, { WorkType } from '@/app/model/WorkType';

export interface IWorkDetailState {
  work: WorkType;
  userSummary: UserSummaryType;
  recommendedWorks: WorkType[];
  shouldReload: boolean; // 一覧から移動した場合に強制reload
}

export const defaultWorkDetailState: IWorkDetailState = {
  work: emptyWork,
  userSummary: emptyUserSummary,
  recommendedWorks: [],
  shouldReload: false,
};
