import { TabLayout } from '@dotpict-lib/component/core/TabLayout';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';
import AboutDotpict from '@/app/component/partial/misc/aboutdotpict/AboutDotpict';
import Timeline from '@/app/component/page/timeline/Timeline';
import Pixelart from '@dotpict-lib/component/core/Pixelart';
import useHome from '@/app/component/page/home/useHome';
import { Helmet } from 'react-helmet';
import Column from '@dotpict-lib/component/core/layout/Column';
import { HorizontalGravity } from '@dotpict-lib/component/core/layout/HorizontalGravity';
import { Row } from '@dotpict-lib/component/core/layout/Row';
import OdaiMiniBanner from '@/app/component/partial/event/odaibanner/mini/OdaiMiniBanner';
import Clickable from '@dotpict-lib/component/core/Clickable';
import { PickUpWorksPage } from '@/app/component/page/work/list/PickUpWorksPage';
import { NewestWorksPage } from '@/app/component/page/work/list/NewestWorksPage';
import { FollowingUserWorksPage } from '@/app/component/page/work/list/FollowingWorksPage';

export type HomeState = {
  isVisibleOfficialEvent: boolean;
  isVisibleOdai: boolean;
  isVisibleAboutDotpict: boolean;
  bannerWidth: number;
  officialEventBannerImageUrl: string;
  officialEventBannerHeight: number;
  odaiTitle: string;
  selectedIndex: number;
  types: string[];
  tabItems: string[];
};

export type HomeProps = HomeState & {
  handleClickOfficialEvent: () => void;
  handleClickOdai: () => void;
  handleClickTagItem: (index: number) => void;
};

export const HomePage = () => {
  const {
    isVisibleOfficialEvent,
    isVisibleOdai,
    isVisibleAboutDotpict,
    bannerWidth,
    officialEventBannerImageUrl,
    officialEventBannerHeight,
    odaiTitle,
    selectedIndex,
    types,
    tabItems,
    handleClickOfficialEvent,
    handleClickOdai,
    handleClickTagItem,
  } = useHome();

  const renderContent = (index: number) => {
    switch (types[index]) {
      case 'timeline':
        return <Timeline />;
      case 'following_user_works':
        return <FollowingUserWorksPage />;
      case 'pickup':
        return <PickUpWorksPage />;
      default:
        return <NewestWorksPage />;
    }
  };

  const renderMeta = () => (
    <Helmet>
      <meta
        name="description"
        content="dotpictはドット絵好きのためのコミュニティサイトです！dotpictでドット絵を作ることもできます ！"
      />
      <meta
        name="keywords"
        content="ドット絵,ドットピクト,dotpict,アニメーション,ドット,パレット,イラスト,illustration,pixelart"
      />
    </Helmet>
  );

  return (
    <>
      {renderMeta()}
      <Column>
        <Spacer height={28} />
        {isVisibleAboutDotpict && (
          <>
            <AboutDotpict />
            <Spacer height={28} />
          </>
        )}
        {isVisibleOfficialEvent && (
          <Column horizontalGravity={HorizontalGravity.center}>
            <Pixelart
              alt="official_event"
              src={officialEventBannerImageUrl}
              width={bannerWidth}
              height={officialEventBannerHeight}
              handleClick={handleClickOfficialEvent}
            />
          </Column>
        )}
        {isVisibleOdai && (
          <>
            <Spacer height={28} />
            <Column horizontalGravity={HorizontalGravity.center}>
              <Column width={bannerWidth}>
                <Clickable onClick={handleClickOdai}>
                  <OdaiMiniBanner odaiTitle={odaiTitle} />
                </Clickable>
              </Column>
            </Column>
          </>
        )}
        <Spacer height={28} />
        <Row horizontalGravity={HorizontalGravity.center}>
          <TabLayout items={tabItems} selectedIndex={selectedIndex} onClick={handleClickTagItem} />
        </Row>
        <Spacer height={28} />
        {renderContent(selectedIndex)}
      </Column>
    </>
  );
};
