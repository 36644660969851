import { UserType } from '@dotpict-lib/model/User/UserType';

const TestUserSraim: UserType = {
  id: 2266,
  name: 'すらいむ。',
  account: '',
  profileImageUrl:
    'https://img.dotpicko.net/61bcc6a1a5187cee5ae32882aa39e2fc47d3bbf9f3acb893e005eb18d81340dd.png',
  headerImageUrl:
    'https://img.dotpicko.net/header_54d0ae1fbee7f4f5c82c43f76aad97a92bc88c60e2a14d967fc84ce3ad441300.png',
  url: 'https://twitter.com/0412sraim?s=09',
  shareUrl: 'https://dotpict.net/users/2266',
  text: 'Twitterで#毎日ドット絵やってます。いいねやコメントなどありがとうございます！°ʚ(*´꒳`*)ɞ°\nThank you for always seeing my work!✨',
  followedCount: 0,
  followerCount: 13584,
  isFollowed: false,
  isFollower: false,
  isOpenedRequestBox: false,
  requestBoxText: '',
  registerAt: '2018/03/05',
  birthDate: '',
  isPremium: true,
};

export default TestUserSraim;
