import TranslationKeys from '@/app/translation/TranslationKeys';
import { articleLineSpace } from '@/app/common/constant';
import { IOldArticle } from '@/app/article/IOldArticle';
import ArticleContentType from '@/app/article/ArticleContentType';

const GuidelineArticle: IOldArticle = {
  titleTranslationKey: TranslationKeys.GuidelineTitle,
  contents: [
    { type: ArticleContentType.TEXT, content: TranslationKeys.GuidelineText1 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },

    { type: ArticleContentType.BOLD_TEXT, content: TranslationKeys.GuidelineText2 },
    { type: ArticleContentType.TEXT, content: TranslationKeys.GuidelineText3 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },

    { type: ArticleContentType.BOLD_TEXT, content: TranslationKeys.GuidelineText4 },
    { type: ArticleContentType.TEXT, content: TranslationKeys.GuidelineText5 },
    { type: ArticleContentType.TEXT, content: TranslationKeys.GuidelineText6 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.TEXT, content: TranslationKeys.GuidelineText7 },
    { type: ArticleContentType.TEXT, content: TranslationKeys.GuidelineText8 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },

    { type: ArticleContentType.BOLD_TEXT, content: TranslationKeys.GuidelineText9 },
    { type: ArticleContentType.TEXT, content: TranslationKeys.GuidelineText10 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.TEXT, content: TranslationKeys.GuidelineText11 },
    { type: ArticleContentType.TEXT, content: TranslationKeys.GuidelineText12 },
    { type: ArticleContentType.TEXT, content: TranslationKeys.GuidelineText13 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.TEXT, content: TranslationKeys.GuidelineText14 },
    { type: ArticleContentType.TEXT, content: TranslationKeys.GuidelineText15 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },

    { type: ArticleContentType.BOLD_TEXT, content: TranslationKeys.GuidelineText16 },
    { type: ArticleContentType.TEXT, content: TranslationKeys.GuidelineText17 },
    { type: ArticleContentType.TEXT, content: TranslationKeys.GuidelineText18 },
    { type: ArticleContentType.TEXT, content: TranslationKeys.GuidelineText19 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },

    { type: ArticleContentType.BOLD_TEXT, content: TranslationKeys.GuidelineText20 },
    { type: ArticleContentType.TEXT, content: TranslationKeys.GuidelineText21 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },

    { type: ArticleContentType.BOLD_TEXT, content: TranslationKeys.GuidelineText22 },
    { type: ArticleContentType.TEXT, content: TranslationKeys.GuidelineText23 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },

    { type: ArticleContentType.BOLD_TEXT, content: TranslationKeys.GuidelineText24 },
    { type: ArticleContentType.TEXT, content: TranslationKeys.GuidelineText25 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },

    { type: ArticleContentType.TEXT, content: TranslationKeys.GuidelineText26 },
    { type: ArticleContentType.TEXT, content: TranslationKeys.GuidelineText27 },
    { type: ArticleContentType.TEXT, content: TranslationKeys.GuidelineText28 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },

    { type: ArticleContentType.BOLD_TEXT, content: TranslationKeys.GuidelineText29 },
    { type: ArticleContentType.TEXT, content: TranslationKeys.GuidelineText30 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },

    { type: ArticleContentType.BOLD_TEXT, content: TranslationKeys.GuidelineText31 },
    { type: ArticleContentType.TEXT, content: TranslationKeys.GuidelineText32 },
    { type: ArticleContentType.TEXT, content: TranslationKeys.GuidelineText33 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },

    { type: ArticleContentType.TEXT, content: TranslationKeys.GuidelineText34 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },

    { type: ArticleContentType.TEXT, content: TranslationKeys.GuidelineText35 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },

    { type: ArticleContentType.BOLD_TEXT, content: TranslationKeys.GuidelineText36 },
  ],
};

export default GuidelineArticle;
