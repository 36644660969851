const Outline = ({ className }: { className?: string }) => {
  return (
    <svg
      width="10"
      height="40"
      viewBox="0 0 10 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect x="4" width="2" height="1" fill="#52465A" />
      <rect x="3" y="1" width="1" height="2" fill="#52465A" />
      <rect x="7" y="3" width="1" height="2" fill="#52465A" />
      <rect x="2" y="3" width="1" height="2" fill="#52465A" />
      <rect x="8" y="5" width="1" height="2" fill="#52465A" />
      <rect x="1" y="5" width="1" height="2" fill="#52465A" />
      <rect y="7" width="1" height="33" fill="#52465A" />
      <rect x="9" y="7" width="1" height="33" fill="#52465A" />
      <rect x="6" y="1" width="1" height="2" fill="#52465A" />
    </svg>
  );
};

export default Outline;
