import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import Text from '@dotpict-lib/foundation/typography/Text';
import useTranslator from '@/app/hook/useTranslator';
import TranslationKeys from '@/app/translation/TranslationKeys';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import Loading from '@dotpict-lib/component/core/Loading';
import { WorkThreadType } from '@/app/model/WorkThreadType';
import { useWorkThreads } from '@/app/component/page/work/thread/threads/useWorkThreads';
import { WorkThreadInput } from '@/app/component/page/work/thread/input/WorkThreadInput';
import Column from '@dotpict-lib/component/core/layout/Column';
import { WorkType } from '@/app/model/WorkType';
import WorkThread from '@/app/component/page/work/thread/item/WorkThread';
import { HorizontalGravity } from '@dotpict-lib/component/core/layout/HorizontalGravity';
import Pixelart from '@dotpict-lib/component/core/Pixelart';
import End from '@dotpict-lib/component/core/End';

export type WorkThreadsProps = {
  work: WorkType;
  threads: WorkThreadType[];
  workTitle: string;
  workThumbnailImageUrl: string;
  isLoading: boolean;
};

export const WorkThreads = () => {
  const translator = useTranslator();
  const { work, threads, workTitle, workThumbnailImageUrl, isLoading } = useWorkThreads();

  return (
    <Column verticalPadding={32} innerPadding={32}>
      <Text
        text={translator(TranslationKeys.Threads)}
        textStyle={TextStyle.BOLD20}
        colorStyle={ColorStyle.TEXT_PRIMARY}
      />
      <Pixelart alt={workTitle} src={workThumbnailImageUrl} width={128} height={128} />
      <WorkThreadInput isReply={false} workId={work.id} />
      {threads.length !== 0 && (
        <Column innerPadding={8}>
          {threads.map((thread) => (
            <WorkThread key={thread.id} isReply={false} isVisibleFooter thread={thread} />
          ))}
        </Column>
      )}
      {isLoading && <Loading />}
      {!isLoading && threads.length === 0 && (
        <Column width="100%" horizontalGravity={HorizontalGravity.center}>
          <Text
            text={translator(TranslationKeys.NoThreads)}
            textStyle={TextStyle.REGULAR14}
            colorStyle={ColorStyle.TEXT_PRIMARY}
          />
        </Column>
      )}
      {!isLoading && <End />}
    </Column>
  );
};
