import { createContext, useCallback, useEffect, useState } from 'react';
import {
  useCurrentCanvasData,
  useCurrentStorageKey,
} from '@/app/component/page/draw/hooks/storage';
import useTranslator from '@/app/hook/useTranslator';
import TranslationKeys from '@/app/translation/TranslationKeys';

export const TitleContext = createContext<string>('');

export const useTitle = () => {
  const [storageData, _, setStorageDataForTitle] = useCurrentCanvasData();
  const currentId = useCurrentStorageKey();
  const translator = useTranslator();
  const [title, setTitle] = useState<string>(
    `${translator(TranslationKeys.DefaultCanvasTitle)}${currentId}`,
  );

  const editTitle = useCallback((newTitle: string) => {
    setStorageDataForTitle(newTitle);
    setTitle(newTitle);
  }, []);

  // ページ初期化時にLocalStorageに前に描いたキャンバスのデータがあればtitleを引き継ぐ
  useEffect(() => {
    if (storageData !== null && storageData.title !== undefined) {
      const { title } = storageData;
      setTitle(title);
    }
  }, []);

  return [title, editTitle] as [typeof title, typeof editTitle];
};
