import {
  useState,
  useEffect,
  useCallback,
  useContext,
  useMemo,
  RefObject,
  ForwardedRef,
  PointerEvent,
  MouseEvent as ReactMouseEvent,
} from 'react';
import { ToolContext, ToolsMap } from '@/app/component/page/draw/Context/ToolContext';
import { SizeContext, SizeType } from '@/app/component/page/draw/Context/SizeContext';
import { ColorContext } from '@/app/component/page/draw/Context/ColorContext';
import { Position, CanvasSizes, PixelsType, CanvasHistoryType } from './types';

// iPadなど、MouseイベントではなくTouchイベントのデバイスかどうかを判定する
export const useTouchDevice = () => {
  const isTouchDevice = useMemo(() => 'ontouchend' in document, [document]);
  return isTouchDevice;
};

// iPadやスマホだとキャンバスでMoveを行ったときに画面がスクロールしてしまうので防ぐ
export const useDetectScrollOnTouchDevice = (canvasRef: ForwardedRef<HTMLCanvasElement>) => {
  const isTouchDevice = useTouchDevice();
  const detectScrollOnTouchDevice = useCallback(
    (e: TouchEvent) => {
      if (canvasRef === null || !('current' in canvasRef)) {
        return;
      }

      if (e.target === canvasRef.current) {
        e.preventDefault();
      }
    },
    [canvasRef],
  );

  useEffect(() => {
    if (isTouchDevice) {
      window.addEventListener('touchmove', detectScrollOnTouchDevice, { passive: false });
    }
    return () => {
      if (isTouchDevice) {
        window.removeEventListener('touchmove', detectScrollOnTouchDevice);
      }
    };
  }, [isTouchDevice]);
};

// キャンバスのcontextを管理する
export const useCanvasContext = (canvasRef: ForwardedRef<HTMLCanvasElement> | null) => {
  const [context, setContext] = useState<CanvasRenderingContext2D | null>(null);

  // キャンバスの初期化
  useEffect(() => {
    if (canvasRef === null || !('current' in canvasRef)) {
      return;
    }

    if (canvasRef.current !== null) {
      const renderCtx = canvasRef.current.getContext('2d');

      if (renderCtx) {
        setContext(renderCtx);
      }
    }
  }, [context, canvasRef]);

  return [context];
};

// キャンバスのサイズ関係を管理する
export const useCanvasSizes = (
  context: CanvasRenderingContext2D | null,
  canvasRef: ForwardedRef<HTMLCanvasElement>,
  containerRef: RefObject<HTMLDivElement>,
) => {
  const canvasSize = useContext(SizeContext);
  const [pixelSize, setPixelSize] = useState<number>(16); // とりあえず16px
  const [canvasRenderWidth, setCanvasRenderWidth] = useState<number>(512); // とりあえず512px
  const [canvasRenderHeight, setCanvasRenderHeight] = useState<number>(512); // とりあえず512px
  const [canvasOffsetTop, setCanvasOffsetTop] = useState(0);
  const [canvasOffsetLeft, setCanvasOffsetLeft] = useState(0);

  // ウィンドウサイズに対するキャンバスのブラウザ上のレンダリングサイズを計算
  const initializeCanvasRenderSize = useCallback(() => {
    if (containerRef.current !== null) {
      const { clientWidth, clientHeight } = containerRef.current;
      const minClientSize = Math.min(clientWidth, clientHeight);
      const maxCanvasSize = Math.max(canvasSize.width, canvasSize.height);
      const pixelSize = minClientSize / maxCanvasSize;
      setPixelSize(pixelSize);
      setCanvasRenderWidth(canvasSize.width * pixelSize);
      setCanvasRenderHeight(canvasSize.height * pixelSize);
    }
  }, [containerRef, canvasSize]);

  useEffect(() => {
    if (containerRef.current !== null) {
      initializeCanvasRenderSize();
      // ウィンドウサイズを途中で変えたらキャンバスサイズも再計算する
      window.addEventListener('resize', initializeCanvasRenderSize);
    }
  }, [containerRef, canvasSize]);

  // キャンバスの初期化
  useEffect(() => {
    if (canvasRef === null || !('current' in canvasRef)) {
      return;
    }

    // UI反映が終わってから再度座標計算
    process.nextTick(() => {
      if (canvasRef.current !== null) {
        const rect = canvasRef.current.getBoundingClientRect();
        setCanvasOffsetTop(rect.top + window.scrollY);
        setCanvasOffsetLeft(rect.left + window.scrollX);
      }
    });
  }, [canvasRef, canvasRenderWidth, canvasRenderHeight]);

  // Retinaディスプレイだとドットがボケてしまうため収縮して対応
  // https://qiita.com/kozo002/items/33690c0ac11dec454df8#retina%E3%83%87%E3%82%A3%E3%82%B9%E3%83%97%E3%83%AC%E3%82%A4%E3%81%A0%E3%81%A8%E3%83%9C%E3%82%B1%E3%82%8B
  useEffect(() => {
    if (canvasRef === null || !('current' in canvasRef)) {
      return;
    }

    if (context !== null && canvasRef.current !== null) {
      const dpr = window.devicePixelRatio;

      /* eslint-disable no-param-reassign */
      canvasRef.current.width = canvasRenderWidth * dpr;
      canvasRef.current.height = canvasRenderHeight * dpr;
      canvasRef.current.style.width = `${canvasRenderWidth}px`;
      canvasRef.current.style.height = `${canvasRenderHeight}px`;
      /* eslint-enable no-param-reassign */

      context.scale(dpr, dpr);
    }
  }, [context, canvasRenderWidth, canvasRenderHeight]);

  return {
    pixelSize,
    canvasRenderWidth,
    canvasRenderHeight,
    canvasOffsetTop,
    canvasOffsetLeft,
  } as CanvasSizes;
};

// キャンバス内のマウス操作などのハンドラーと押された位置を管理する
export const useCanvasEvents = ({
  canvasRenderWidth,
  canvasRenderHeight,
  canvasOffsetLeft,
  canvasOffsetTop,
}: CanvasSizes) => {
  const isTouchDevice = useTouchDevice();
  const [startPosition, setStartPosition] = useState<Position>({ x: 0, y: 0 });
  const [endPosition, setEndPosition] = useState<Position>({ x: 0, y: 0 });
  const [withMove, setWithMove] = useState(false);
  const [withLeave, setWithLeave] = useState(false);
  const [down, setDown] = useState<boolean>(false);
  const tool = useContext(ToolContext);

  const calculatePoint = useCallback(
    (e: PointerEvent | MouseEvent) => {
      const x = e.pageX - canvasOffsetLeft - 2; // 2pxはボーダー分
      const y = e.pageY - canvasOffsetTop - 2;

      // キャンバス外（ボーダー上も）のクリックは無視
      if (x < 0 || x >= canvasRenderWidth || y < 0 || y >= canvasRenderHeight) {
        return null;
      }

      return { x, y };
    },
    [canvasRenderWidth, canvasRenderHeight, canvasOffsetLeft, canvasOffsetTop, isTouchDevice],
  );

  const handlePointerDown = useCallback(
    (e: PointerEvent<HTMLCanvasElement>) => {
      const point = calculatePoint(e);
      if (point === null) {
        return;
      }
      setStartPosition(point);

      setWithLeave(false);

      // バケツのときはマウス押しっぱは無効
      if (tool === ToolsMap.Bucket) {
        return;
      }

      setDown(true);
    },
    [canvasOffsetLeft, canvasOffsetTop, tool],
  );

  // 右クリックだった場合は書き込みを中断する
  // 右クリックでも一回左クリックの処理が走ってしまうため必要
  const handleRightMouseDown = useCallback((_e: ReactMouseEvent<HTMLCanvasElement>) => {
    setDown(false);
  }, []);

  const handlePointerUp = useCallback(
    (e: PointerEvent<HTMLCanvasElement>) => {
      // マウス押しっぱのとき以外は無視
      if (!down) {
        return;
      }

      const point = calculatePoint(e);
      if (point === null) {
        // Touchデバイスで直線などを描画中にMoveしてキャンバス外に出てUpしたときは描画を終了する
        // eslint-disable-next-line no-use-before-define
        handlePointerLeave();
        return;
      }
      setEndPosition(point);
      setDown(false);
      setWithMove(false);
    },
    [canvasOffsetLeft, canvasOffsetTop, down],
  );

  const handlePointerMove = useCallback(
    (e: PointerEvent<HTMLCanvasElement>) => {
      // マウス押しっぱのとき以外は無視
      if (!down) {
        return;
      }

      const point = calculatePoint(e);
      if (point === null) {
        return;
      }
      setEndPosition(point);
      setWithMove(true);
    },
    [canvasOffsetLeft, canvasOffsetTop, down],
  );

  const handlePointerLeave = useCallback(() => {
    setDown(false);
    setWithMove(false);
    setWithLeave(true);
  }, []);

  // PCで直線などを描画中にマウスをMoveしてキャンバス外に出てUpしたときは描画を終了する
  const handleWindowMouseUp = useCallback(
    (e: MouseEvent) => {
      // 事前にキャンバス内でMouseDownしていなければ無視
      if (!down) {
        return;
      }

      // キャンバス内のMouseUpなら無視
      const point = calculatePoint(e);
      if (point !== null) {
        return;
      }

      // キャンバス外でMouseUpしたので描画を終了
      handlePointerLeave();
    },
    [down],
  );

  // PCのみ、↑のMouseUpイベントの登録を行う
  useEffect(() => {
    if (!isTouchDevice && down) {
      window.addEventListener('mouseup', handleWindowMouseUp);
    }

    return () => {
      if (!isTouchDevice && down) {
        window.removeEventListener('mouseup', handleWindowMouseUp);
      }
    };
    // キャンバス内でMouseDownするたびにイベント登録されてしまうが、ハンドラでdown中かどうかを判定するためやむを得ない
  }, [down]);

  return [
    startPosition,
    endPosition,
    withMove,
    withLeave,
    handlePointerDown,
    handlePointerMove,
    handlePointerUp,
    handleRightMouseDown,
  ] as [
    Position,
    Position,
    boolean,
    boolean,
    typeof handlePointerDown,
    typeof handlePointerMove,
    typeof handlePointerUp,
    typeof handleRightMouseDown,
  ];
};

// 渡したピクセルデータをキャンバスに描画する
export const usePaintCanvas = () => {
  const paint = useCallback(
    (
      context: CanvasRenderingContext2D | null,
      pixelWidth: number,
      pixelHeight: number,
      pixels: PixelsType,
    ) => {
      if (context === null) {
        return;
      }

      pixels.forEach((rowData, row) => {
        rowData.forEach((pixelData, column) => {
          context.fillStyle = `#${pixelData}`;
          context.fillRect(pixelWidth * column, pixelHeight * row, pixelWidth, pixelHeight);
        });
      });
    },
    [],
  );

  return [paint];
};

// 一時的なピクセルデータの管理と、ピクセルデータが変化した際にキャンバスに反映する
export const useTempPixels = (
  context: CanvasRenderingContext2D | null,
  canvasSizes: CanvasSizes,
  pushHistory: (history: CanvasHistoryType) => void,
  setPixels: (pixels: PixelsType, ignoreHistory?: boolean) => void,
) => {
  // ペン・直線ツールなどで高速に描画し続けた際、Contextのピクセルデータを直接書き換えてしまうとコンポーネントのレンダリングが極端にパフォーマンス低下してしまうため
  // そのため確定させるまで（Historyに追加するまで）ローカルでピクセルデータを持つ
  const [tempPixels, setTempPixels] = useState<PixelsType>();
  const canvasSize = useContext(SizeContext);
  const { palette, background, pixels } = useContext(ColorContext);
  const [prevCanvasSize, setPrevCanvasSize] = useState<SizeType>();
  const [paint] = usePaintCanvas();

  // ツールがキャンバスに変化を加えるために実行するAction
  const setPixelsAction = useCallback(
    (pixels: PixelsType, ignoreHistory: boolean = false) => {
      setTempPixels(pixels);

      // 変更を確定させる（Historyに追加）ときはContextのピクセルデータを書き換える
      if (!ignoreHistory) {
        setPixels(pixels);
      }
    },
    [canvasSize, setPixels],
  );

  // Contextのピクセルデータに追従する
  useEffect(() => {
    if (pixels !== undefined) {
      setTempPixels([...pixels]);
    }
  }, [pixels]);

  // ピクセルデータの初期化・キャンバスサイズ変更
  useEffect(() => {
    let newPixels: PixelsType;
    if (tempPixels === undefined) {
      // キャンバスの初期化時
      newPixels = Array.from({ length: canvasSize.height }, () => {
        return Array.from({ length: canvasSize.width }, () => background);
      });
    } else {
      // Redo/Undoによるキャンバスサイズ変更の場合は前のキャンバスを引き継ぐ処理は行わない
      if (!canvasSize.manual || prevCanvasSize === undefined) {
        setPrevCanvasSize(canvasSize);
        return;
      }

      // サイズ変えたときは中央を基準として前のキャンバスの内容を引き継ぐ
      const widthSub = Math.floor((prevCanvasSize.width - canvasSize.width) / 2);
      const heightSub = Math.floor((prevCanvasSize.height - canvasSize.height) / 2);
      newPixels = Array.from({ length: canvasSize.height }, (_, row) => {
        return Array.from({ length: canvasSize.width }, (_, column) => {
          // サイズ変更前のキャンバス内であれば状態を復元し、キャンバス外であれば背景色にする
          const innerPrevCanvas =
            heightSub + row >= 0 &&
            heightSub + row < prevCanvasSize.height &&
            widthSub + column >= 0 &&
            widthSub + column < prevCanvasSize.width;

          return innerPrevCanvas ? tempPixels[heightSub + row][widthSub + column] : 'ffffff';
        });
      });
    }

    setPixels(newPixels);
    setTempPixels([...newPixels]);
    setPrevCanvasSize(canvasSize);

    // 初期化・キャンバスサイズ変更時の状態をHistoryに追加する
    pushHistory({ pixels: newPixels, canvasSize, palette });
  }, [canvasSize]);

  // ペンツールで描かれたときなど、ピクセルデータが更新されたときにキャンバスに反映する
  useEffect(() => {
    if (context && tempPixels !== undefined) {
      const { pixelSize } = canvasSizes;
      paint(context, pixelSize, pixelSize, tempPixels);
    }
  }, [tempPixels, canvasSizes]);

  return [tempPixels, setPixelsAction] as [typeof tempPixels, typeof setPixelsAction];
};
