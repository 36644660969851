import { createUserWithEmailAndPassword } from 'firebase/auth';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import Button from '@dotpict-lib/component/dotpict/Button';
import InputComponent from '@/app/component/InputComponent';
import { APIContext, GlobalContext, LayoutContext, MeContext } from '@/Routing';
import Text from '@dotpict-lib/foundation/typography/Text';
import { dotpictFirebaseAuth } from '@/app/library/dotpictFirebaseAuth';
import { ErrorResponseType } from '@/app/model/responsetype/ErrorResponseType';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import TranslationKeys from '@/app/translation/TranslationKeys';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';
import ReCAPTCHA from 'react-google-recaptcha';
import { DotpictAction } from '@/app/common/reducer';
import { VerticalGravity } from '@dotpict-lib/component/core/layout/VerticalGravity';
import { HorizontalGravity } from '@dotpict-lib/component/core/layout/HorizontalGravity';
import Column from '@dotpict-lib/component/core/layout/Column';

const SignUpComponent = () => {
  const { dispatch } = useContext(GlobalContext);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [recaptcha, setRecaptcha] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const meState = useContext(MeContext);
  const { layoutParams } = useContext(LayoutContext);
  const navigator = useNavigate();
  const intl = useIntl();
  const { client } = useContext(APIContext);

  const handleChangeRecaptcha = (v: string | null) => {
    setRecaptcha(v);
  };

  const handleSignUpButtonClick = async () => {
    if (recaptcha === null || recaptcha === '') {
      setErrorMessage('Please check reCAPTCHA');
      return;
    }
    setErrorMessage('');

    const credential = await createUserWithEmailAndPassword(
      dotpictFirebaseAuth,
      email,
      password,
    ).then();
    const token = await credential.user.getIdToken();

    // NOTE: もしAPIサーバが死んでいた場合はFirebaseとの不整合が生じる可能性がある。レアケースだと思うのでそんな対応はしない
    const user = await client.createUsers(token, recaptcha).catch((error: ErrorResponseType) => {
      setErrorMessage(error.message);
    });
    if (!user) {
      return;
    }
    const action: DotpictAction = {
      type: 'UPDATE_ME_STATE',
      payload: { meState: { isLoggedIn: true, token, user } },
    };
    dispatch(action);
  };

  useEffect(() => {
    if (meState.user.id <= 0) return;
    navigator('/');
  }, [meState]);

  return (
    <Column verticalGravity={VerticalGravity.center} horizontalGravity={HorizontalGravity.center}>
      <Spacer height={layoutParams.contentTopSpace} />
      <Column
        width="400"
        background={ColorStyle.WHITE}
        verticalGravity={VerticalGravity.center}
        horizontalGravity={HorizontalGravity.center}
      >
        <Spacer height={32} />
        <InputComponent
          width={320}
          height={48}
          placeHolder={intl.formatMessage({ id: TranslationKeys.Email })}
          isHidden={false}
          onTextChange={(text: string) => setEmail(text)}
        />
        <Spacer height={20} />
        <InputComponent
          width={320}
          height={48}
          placeHolder={intl.formatMessage({ id: TranslationKeys.SignUpPassword })}
          isHidden
          onTextChange={(text: string) => setPassword(text)}
        />
        {errorMessage !== '' && (
          <>
            <Spacer height={8} />
            <Text text={errorMessage} textStyle={TextStyle.BOLD12} colorStyle={ColorStyle.ERROR} />
          </>
        )}
        <Spacer height={32} />
        <ReCAPTCHA
          sitekey="6LezqT4cAAAAANsqSlBA7k37ZhLQCIzv0BWqwA9s"
          onChange={(v) => handleChangeRecaptcha(v)}
        />
        <Spacer height={32} />
        <Button
          width={320}
          height={48}
          text={intl.formatMessage({ id: TranslationKeys.SignUpSubmit })}
          isFilled
          handleClick={handleSignUpButtonClick}
        />
        <Spacer height={8} />
        <Button
          width={320}
          height={48}
          text={intl.formatMessage({ id: TranslationKeys.AlreadyHaveAccount })}
          isFilled={false}
          isBorder={false}
          handleClick={() => navigator('/login')}
        />
        <Spacer height={32} />
      </Column>
    </Column>
  );
};

export default SignUpComponent;
