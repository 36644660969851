export type BattleTeamType = {
  name: string;
  tag: string;
  point: number;
  colorCode: string;
};

export const defaultTeam: BattleTeamType = {
  name: '',
  tag: '',
  point: 0,
  colorCode: '',
};
