import { GetUserWorksService } from '@/app/service/GetUserWorks/GetUserWorksService';
import { PagingType } from '@dotpict-lib/model/PagingType';
import TestUserSraim from '@/app/test/user/TestUserSraim';
import TestWorkJuneBride from '@/app/test/work/TestWorkJuneBride';

const dummyGetUserWorksService: GetUserWorksService = (_: PagingType) => {
  return Promise.resolve({
    user: TestUserSraim,
    works: [TestWorkJuneBride],
    nextUrl: '',
  });
};

export default dummyGetUserWorksService;
