import Text from '@dotpict-lib/foundation/typography/Text';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';

export type HeadlineProps = {
  text: string;
};

const MakingHeadline = ({ text }: HeadlineProps) => (
  <Text text={text} textStyle={TextStyle.BOLD20} colorStyle={ColorStyle.TEXT_PRIMARY} />
);

export default MakingHeadline;
