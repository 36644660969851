import React from 'react';
import Text from '@dotpict-lib/foundation/typography/Text';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import { Link } from 'react-router-dom';
import { Grid } from '@dotpict-lib/component/core/layout/Grid';
import { VerticalGravity } from '@dotpict-lib/component/core/layout/VerticalGravity';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';

export type TitleAndTagsProps = {
  title: string;
  tags: string[];
};

const TitleAndTags = ({ title, tags }: TitleAndTagsProps) => {
  return (
    <Grid verticalGravity={VerticalGravity.center}>
      <Text text={title} textStyle={TextStyle.BOLD20} colorStyle={ColorStyle.TEXT_PRIMARY} />
      {tags.map((tag) => (
        <React.Fragment key={tag}>
          <Spacer width={12} />
          <Link to={`/search/works/tag/${tag}`}>
            <Text text={`#${tag}`} textStyle={TextStyle.BOLD16} colorStyle={ColorStyle.PRIMARY} />
          </Link>
        </React.Fragment>
      ))}
    </Grid>
  );
};

export default TitleAndTags;
