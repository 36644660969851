import styled from '@emotion/styled';

const StyledSvg = styled.svg`
  fill: currentColor;
`;

const ColorBody = ({ className }: { className?: string }) => {
  return (
    <StyledSvg
      width="10"
      height="40"
      viewBox="0 0 10 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect x="3" y="1" width="4" height="2" />
      <rect x="4" width="2" height="1" />
      <rect y="7" width="10" height="33" />
    </StyledSvg>
  );
};

export default ColorBody;
