import Pixelart from '@dotpict-lib/component/core/Pixelart';
import Text from '@dotpict-lib/foundation/typography/Text';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import Column from '@dotpict-lib/component/core/layout/Column';
import { HorizontalGravity } from '@dotpict-lib/component/core/layout/HorizontalGravity';

export type TutorialItemProps = {
  imageUrl: string;
  text: string;
};

const TutorialItem = ({ imageUrl, text }: TutorialItemProps) => {
  return (
    <Column horizontalGravity={HorizontalGravity.center}>
      <Pixelart alt="tutorial_image" src={imageUrl} width={180} height={180} />
      <Spacer height={60} />
      <Text text={text} textStyle={TextStyle.REGULAR16} colorStyle={ColorStyle.TEXT_PRIMARY} />
    </Column>
  );
};

export default TutorialItem;
