import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { APIContext, GlobalContext } from '@/Routing';
import shouldFetchNext from '@/app/common/common';
import { DotpictAction } from '@/app/common/reducer';
import { defaultDummyItemCount } from '@/app/common/constant';
import Works from '@/app/component/Works';
import { css } from '@emotion/react';
import { WorkType } from '@/app/model/WorkType';
import { WorksStateType } from '@/app/state/WorksStateType';

type Props = {
  type:
    | 'UPDATE_FOLLOWING_USER_WORKS_STATE'
    | 'UPDATE_PICKUP_WORKS_STATE'
    | 'UPDATE_NEWEST_WORKS_STATE';
  url: string;
  imageSize: number;
  workState: WorksStateType;
};

const WorksPage = ({ type, url, imageSize, workState }: Props) => {
  const { dispatch } = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);
  const { client } = useContext(APIContext);

  const gridStyle = css({
    display: 'grid',
    justifyContent: 'center',
    gridGap: '8px',
    gridTemplateColumns: `repeat(auto-fill, ${imageSize}px)`,
  });

  const fetchData = async (currentWorks: WorkType[], currentNextUrl: string | null) => {
    setLoading(true);
    const nextUrl = currentNextUrl || url;
    const response = await client.fetchWorks(nextUrl);
    const works = currentWorks.concat(response.works);
    const action: DotpictAction = {
      type,
      payload: {
        worksState: {
          works,
          nextUrl: response.nextUrl,
        },
      },
    };
    dispatch(action);
    setLoading(false);
    if (shouldFetchNext(response.nextUrl)) {
      fetchData(works, response.nextUrl);
    }
  };

  const handleScroll = () => {
    if (!loading && shouldFetchNext(workState.nextUrl)) {
      fetchData(workState.works, workState.nextUrl);
    }
  };

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  useEffect(() => {
    if (workState.works.length !== 0) return;
    fetchData([], null);
  }, []);

  return (
    <Works
      works={workState.works}
      isLoading={loading}
      isVisibleEnd={workState.nextUrl === ''}
      isVisibleAds
      isVisibleLike
      gridStyle={gridStyle}
      imageSize={imageSize}
      dummyItemCount={defaultDummyItemCount}
    />
  );
};

export default WorksPage;
