enum TranslationKeys {
  Empty = 'Empty',
  Comment = 'Comment',
  Send = 'Send',
  Ads = 'Ads',
  Tweet = 'Tweet',
  Share = 'Share',
  CommentHint = 'CommentHint',
  ReplyHint = 'ReplyHint',
  CanNotPostThreads = 'CanNotPostThreads',
  NoThreads = 'NoThreads',
  NoReplies = 'NoReplies',
  Follow = 'Follow',
  Followed = 'Followed',
  Follower = 'Follower',
  PickUpWorks = 'PickUpWorks',
  LoadMore = 'LoadMore',
  NewestWorks = 'NewestWorks',
  PickupWorks = 'PickupWorks',
  FollowingWorks = 'FollowingWorks',
  Guidelines = 'Guidelines',
  Title = 'Title',
  Tag = 'Tag',
  User = 'User',
  Email = 'Email',
  Password = 'Password',
  Login = 'Login',
  SignUpFromApp = 'SignUpFromApp',
  Search = 'Search',
  Cancel = 'Cancel',
  MyPage = 'MyPage',
  Logout = 'Logout',
  LikeCount = 'LikeCount',
  PostedWorks = 'PostedWorks',
  LikedWorks = 'LikedWorks',
  NotFoundTitle = 'NotFoundTitle',
  NotFoundMessage1 = 'NotFoundMessage1',
  NotFoundMessage2 = 'NotFoundMessage2',
  NotFoundGoToTop = 'NotFoundGoToTop',
  DrawPixelartFromApp = 'DrawPixelartFromApp',
  Company = 'Company',
  SignUp = 'SignUp',
  SignUpSubmit = 'SignUpSubmit',
  SignUpPassword = 'SignUpPassword',
  AlreadyHaveAccount = 'AlreadyHaveAccount',
  Edit = 'Edit',
  EditProfile = 'EditProfile',
  ChangeImage = 'ChangeImage',
  UserName = 'UserName',
  UserNamePlaceholder = 'UserNamePlaceholder',
  Text = 'Text',
  TextPlaceholder = 'TextPlaceholder',
  WebSite = 'WebSite',
  WebSitePlaceholder = 'WebSitePlaceholder',
  Save = 'Save',
  AccountName = 'AccountName',
  AccountNamePlaceholder = 'AccountNamePlaceholder',
  Next = 'Next',
  PostWork = 'PostWork',
  ViewGuideline = 'ViewGuideline',
  Caption = 'Caption',
  CaptionHint = 'CaptionHint',
  AllowComments = 'AllowComments',
  SizeLimit = 'SizeLimit',
  AddFile = 'AddFile',
  OtherSettings = 'OtherSettings',
  PostTutorialTitle = 'PostTutorialTitle',
  PostTutorialTitle2 = 'PostTutorialTitle2',
  PostTutorial1 = 'PostTutorial1',
  PostTutorial2 = 'PostTutorial2',
  PostTutorial3 = 'PostTutorial3',
  PostTutorial4 = 'PostTutorial4',
  PostTutorial5 = 'PostTutorial5',
  AgreeTheGuideline = 'AgreeTheGuideline',
  Back = 'Back',
  Post = 'Post',
  PostSectionTitle = 'PostSectionTitle',
  PostCautionTitle = 'PostCautionTitle',
  PostCautionSummary = 'PostCautionSummary',
  PostCautionSummary2 = 'PostCautionSummary2',
  PostCautionGuidelines = 'PostCautionGuidelines',
  PostCautionExample = 'PostCautionExample',
  PostCautionExampleDetail = 'PostCautionExampleDetail',
  PostCautionExampleDetail2 = 'PostCautionExampleDetail2',
  Pixelart = 'Pixelart',
  Binaryart = 'Binaryart',
  Convert = 'Convert',
  Oekaki = 'Oekaki',
  Picture = 'Picture',
  PostCautionPixelart = 'PostCautionPixelart',
  PostCautionBinaryart = 'PostCautionBinaryart',
  PostCautionConvert = 'PostCautionConvert',
  PostCautionOekaki = 'PostCautionOekaki',
  PostCautionPicture = 'PostCautionPicture',
  MaxTags = 'MaxTags',
  ErrorImageSizeTooLarge = 'ErrorImageSizeTooLarge',
  UnknownError = 'UnknownError',
  Notifications = 'Notifications',
  AboutDotpict = 'AboutDotpict',
  AboutDotpictText1 = 'AboutDotpictText1',
  AboutDotpictText2 = 'AboutDotpictText2',
  AboutDotpictText3 = 'AboutDotpictText3',
  RequestBox = 'RequestBox',
  RequestBoxSettings = 'RequestBoxSettings',
  RequestBoxEnabled = 'RequestBoxEnabled',
  RequestBoxSummaryTitle = 'RequestBoxSummaryTitle',
  RequestBoxSummaryText = 'RequestBoxSummaryText',
  RequestBoxSummaryHint = 'RequestBoxSummaryHint',
  RequestBoxSend = 'RequestBoxSend',
  RequestBoxView = 'RequestBoxView',
  RequestReceived = 'RequestReceived',
  RequestCompleted = 'RequestCompleted',
  RequestAll = 'RequestAll',
  RequestSucceeded = 'RequestSucceeded',
  RequestTextZero = 'RequestTextZero',
  RequestTextOver = 'RequestTextOver',
  RequestDeleteTitle = 'RequestDeleteTitle',
  RequestDeleteMessage = 'RequestDeleteMessage',
  RequestDeleted = 'RequestDeleted',
  RequestBoxSendHint = 'RequestBoxSendHint',
  RequestNotifications = 'RequestNotifications',
  RequestSend = 'RequestSend',
  Replies = 'Replies',
  Threads = 'Threads',
  ViewReply = 'ViewReply',
  SearchResult = 'SearchResult',
  MoveToWork = 'MoveToWork',
  UserEventDescription1 = 'UserEventDescription1',
  UserEventDescription2 = 'UserEventDescription2',
  UserEventDescription3 = 'UserEventDescription3',
  UserEventDescription4 = 'UserEventDescription4',
  EventTemplate = 'EventTemplate',
  EventColorCode = 'EventColorCode',
  EventDownloadTemplate = 'EventDownloadTemplate',
  EventDownloadColorCodes = 'EventDownloadColorCodes',
  EventPostTitle = 'EventPostTitle',
  ParticipatingWorks = 'ParticipatingWorks',
  JoiningEvent = 'JoiningEvent',
  RegistrationDate = 'RegistrationDate',
  Birthday = 'Birthday',
  HoldingPeriod = 'HoldingPeriod',
  BattleEventResult = 'BattleEventResult',
  CurrentScoreRate = 'CurrentScoreRate',
  WhichJoin = 'WhichJoin',
  BattleEventPointRule1 = 'BattleEventPointRule1',
  BattleEventPointRule2 = 'BattleEventPointRule2',
  FailedLoginError = 'FailedLoginError',
  IncorrectPasswordError = 'IncorrectPasswordError',
  AddMyPageHeaderImage = 'AddMyPageHeaderImage',
  ChangeMyPageHeaderImage = 'ChangeMyPageHeaderImage',
  MyPageHeaderSettingTitle = 'MyPageHeaderSettingTitle',
  MyPageHeaderNote = 'MyPageHeaderNote',
  ViewImageGuideline = 'ViewImageGuideline',
  Point = 'Point',
  JoinTeam = 'JoinTeam',
  EventResult = 'EventResult',
  ThumbnailAlt = 'ThumbnailAlt',
  JoinEvent = 'JoinEvent',
  HowToPost = 'HowToPost',
  Download = 'Download',
  SelectImage = 'SelectImage',
  DownloadTemplate = 'DownloadTemplate',
  UsePalette = 'UsePalette',
  EnableUseOtherColors = 'EnableUseOtherColors',
  HowToUsePaletteInAseprite = 'HowToUsePaletteInAseprite',
  HideColorCodes = 'HideColorCodes',
  ShowColorCodes = 'ShowColorCodes',
  PostToDotpict = 'PostToDotpict',
  NewCanvas = 'NewCanvas',
  MyCanvases = 'MyCanvases',
  DefaultCanvasTitle = 'DefaultCanvasTitle',
  DeleteCanvas = 'DeleteCanvas',
  SaveCanvas = 'SaveCanvas',
  PostCanvas = 'PostCanvas',
  ConfirmDeleteCanvas = 'ConfirmDeleteCanvas',
  BackToCanvases = 'BackToCanvases',
  Pen = 'Pen',
  Eraser = 'Eraser',
  Bucket = 'Bucket',
  Line = 'Line',
  Move = 'Move',
  Undo = 'Undo',
  Redo = 'Redo',
  ClearAll = 'ClearAll',
  SelectSaveSize = 'SelectSaveSize',
  SaveSize = 'SaveSize',
  SelectSize = 'SelectSize',
  SelectPalette = 'SelectPalette',
  SelectPaletteDescription1 = 'SelectPaletteDescription1',
  SelectPaletteDescription2 = 'SelectPaletteDescription2',
  Create = 'Create',
  TrialTitle = 'TrialTitle',
  TrialMessage1 = 'TrialMessage1',
  TrialMessage2 = 'TrialMessage2',
  TrialMessage3 = 'TrialMessage3',
  PleaseLogin = 'PleaseLogin',
  MustLoginToPost = 'MustLoginToPost',
  SendFeedback = 'SendFeedback',
  Draw = 'Draw',
  Notice = 'Notice',
  Making = 'Making',
  MakingTitle = 'MakingTitle',
  MakingShare = 'MakingShare',
  MakingOgpTitle = 'MakingOgpTitle',
  MakingShareText = 'MakingShareText',
  MakingLikeTitle = 'MakingLikeTitle',
  MakingLikedTitle = 'MakingLikedTitle',
  MakingLike = 'MakingLike',
  MakingTwitter = 'MakingTwitter',
  ViewWorks = 'ViewWorks',
  MakingDescription = 'MakingDescription',
  AnotherMakings = 'AnotherMakings',
  TableOfContents = 'TableOfContents',
  FailedToUpload = 'FailedToUpload',
  EditColor = 'EditColor',
  DrawPixelart = 'DrawPixelart',
  ImageReduction = 'ImageReduction',
  ImageReductionDetail = 'ImageReductionDetail',
  CropPosition = 'CropPosition',
  ResetCropPosition = 'ResetCropPosition',
  CropDecide = 'CropDecide',
  ZoomRate = 'ZoomRate',
  EditWorkInfo = 'EditWorkInfo',
  UpdateWorkInfo = 'UpdateWorkInfo',
  EditImage = 'EditImage',
  DailyTheme1 = 'DailyTheme1',
  DailyTheme2 = 'DailyTheme2',
  DailyThemeDescription1 = 'DailyThemeDescription1',
  DailyThemeDescription2 = 'DailyThemeDescription2',
  DailyTheme = 'DailyTheme',
  Update = 'Update',
  Delete = 'Delete',
  DeleteWorkConfirmation = 'DeleteWorkConfirmation',
  DeletedWork = 'DeletedWork',
  Menu = 'Menu',
  Language = 'Language',
  Others = 'Others',
  Timeline = 'Timeline',
  Everyone = 'Everyone',
  MutualFollowOnly = 'MutualFollowOnly',
  FollowersOnly = 'FollowersOnly',
  AccountRegistrationRequired = 'AccountRegistrationRequired',
  NotePostUnavailableFromWeb = 'NotePostUnavailableFromWeb',
  NotFound = 'NotFound',
  RecommendedWorks = 'RecommendedWorks',
  IsFollower = 'IsFollower',
  Feedback = 'Feedback',
  FeedbackSummary = 'FeedbackSummary',
  FeedbackSummaryPlaceholder = 'FeedbackSummaryPlaceholder',
  FeedbackSucceeded = 'FeedbackSucceeded',
  PrivacyTitle = 'PrivacyTitle',
  PrivacyText1 = 'PrivacyText1',
  PrivacyText2 = 'PrivacyText2',
  PrivacyText3 = 'PrivacyText3',
  PrivacyText4 = 'PrivacyText4',
  PrivacyText5 = 'PrivacyText5',
  PrivacyText6 = 'PrivacyText6',
  PrivacyText7 = 'PrivacyText7',
  PrivacyText8 = 'PrivacyText8',
  PrivacyText82 = 'PrivacyText82',
  PrivacyText9 = 'PrivacyText9',
  PrivacyText10 = 'PrivacyText10',
  PrivacyText11 = 'PrivacyText11',
  PrivacyText12 = 'PrivacyText12',
  PrivacyText13 = 'PrivacyText13',
  PrivacyText14 = 'PrivacyText14',
  PrivacyText15 = 'PrivacyText15',
  PrivacyText16 = 'PrivacyText16',
  PrivacyText17 = 'PrivacyText17',
  PrivacyText18 = 'PrivacyText18',
  PrivacyText19 = 'PrivacyText19',
  PrivacyText20 = 'PrivacyText20',
  PrivacyText21 = 'PrivacyText21',
  PrivacyText22 = 'PrivacyText22',
  PrivacyText23 = 'PrivacyText23',
  PrivacyText24 = 'PrivacyText24',
  PrivacyText25 = 'PrivacyText25',
  PrivacyText26 = 'PrivacyText26',
  PrivacyText27 = 'PrivacyText27',
  PrivacyText28 = 'PrivacyText28',
  PrivacyText29 = 'PrivacyText29',
  PrivacyText30 = 'PrivacyText30',
  GuidelineTitle = 'GuidelineTitle',
  GuidelineText1 = 'GuidelineText1',
  GuidelineText2 = 'GuidelineText2',
  GuidelineText3 = 'GuidelineText3',
  GuidelineText4 = 'GuidelineText4',
  GuidelineText5 = 'GuidelineText5',
  GuidelineText6 = 'GuidelineText6',
  GuidelineText7 = 'GuidelineText7',
  GuidelineText8 = 'GuidelineText8',
  GuidelineText9 = 'GuidelineText9',
  GuidelineText10 = 'GuidelineText10',
  GuidelineText11 = 'GuidelineText11',
  GuidelineText12 = 'GuidelineText12',
  GuidelineText13 = 'GuidelineText13',
  GuidelineText14 = 'GuidelineText14',
  GuidelineText15 = 'GuidelineText15',
  GuidelineText16 = 'GuidelineText16',
  GuidelineText17 = 'GuidelineText17',
  GuidelineText18 = 'GuidelineText18',
  GuidelineText19 = 'GuidelineText19',
  GuidelineText20 = 'GuidelineText20',
  GuidelineText21 = 'GuidelineText21',
  GuidelineText22 = 'GuidelineText22',
  GuidelineText23 = 'GuidelineText23',
  GuidelineText24 = 'GuidelineText24',
  GuidelineText25 = 'GuidelineText25',
  GuidelineText26 = 'GuidelineText26',
  GuidelineText27 = 'GuidelineText27',
  GuidelineText28 = 'GuidelineText28',
  GuidelineText29 = 'GuidelineText29',
  GuidelineText30 = 'GuidelineText30',
  GuidelineText31 = 'GuidelineText31',
  GuidelineText32 = 'GuidelineText32',
  GuidelineText33 = 'GuidelineText33',
  GuidelineText34 = 'GuidelineText34',
  GuidelineText35 = 'GuidelineText35',
  GuidelineText36 = 'GuidelineText36',
  VerifiedAccountTitle = 'VerifiedAccountTitle',
  VerifiedAccountText1 = 'VerifiedAccountText1',
  VerifiedAccountText2 = 'VerifiedAccountText2',
  VerifiedAccountText3 = 'VerifiedAccountText3',
  VerifiedAccountText4 = 'VerifiedAccountText4',
  VerifiedAccountText5 = 'VerifiedAccountText5',
  VerifiedAccountText6 = 'VerifiedAccountText6',
  VerifiedAccountText7 = 'VerifiedAccountText7',
  VerifiedAccountText8 = 'VerifiedAccountText8',
  SixTitle = 'SixTitle',
  SixText1 = 'SixText1',
  SixText2 = 'SixText2',
  SixText3 = 'SixText3',
  SixText4 = 'SixText4',
  SixText5 = 'SixText5',
  SixText6 = 'SixText6',
  SixText7 = 'SixText7',
  SixText8 = 'SixText8',
  SixText9 = 'SixText9',
  SixText10 = 'SixText10',
  SixText11 = 'SixText11',
  SixText12 = 'SixText12',
  SixText13 = 'SixText13',
  SixText14 = 'SixText14',
  SixText15 = 'SixText15',
  SixText16 = 'SixText16',
  SixText17 = 'SixText17',
  SixText18 = 'SixText18',
  SixText19 = 'SixText19',
  SixText20 = 'SixText20',
  SixText21 = 'SixText21',
  SixText22 = 'SixText22',
  SixText23 = 'SixText23',
  SixText24 = 'SixText24',
  SixText25 = 'SixText25',
  SixText26 = 'SixText26',
  SixText27 = 'SixText27',
  SixText28 = 'SixText28',
  SixText29 = 'SixText29',
  SixText30 = 'SixText30',
  SixText31 = 'SixText31',
  SixText32 = 'SixText32',
  SixText33 = 'SixText33',
  SixText34 = 'SixText34',
  SixText35 = 'SixText35',
  SixText36 = 'SixText36',
  RequestboxTitle = 'RequestboxTitle',
  RequestboxText1 = 'RequestboxText1',
  RequestboxText2 = 'RequestboxText2',
  RequestboxText3 = 'RequestboxText3',
  RequestboxText4 = 'RequestboxText4',
}

export default TranslationKeys;
