import { createContext, useContext, useEffect, useLayoutEffect, useState } from 'react';
import { GlobalContext, MeContext } from '@/Routing';
import shouldFetchNext from '@/app/common/common';
import { GetUsersService } from '@/app/service/GetUsers/GetUsersService';
import { dummyGetUsersService } from '@/app/service/GetUsers/dummyGetUsersService';
import { UsersState } from '@/app/component/page/user/list/Users';
import { UsersKey } from '@/app/component/page/user/list/UsersKey';
import { defaultUsersState, UsersStateType } from '@/app/state/UsersStateType';
import { convertListItems } from '@/app/component/partial/listitem/convertListItems';
import { UserSummaryType } from '@/app/model/UserSummaryType';
import useTranslator from '@/app/hook/useTranslator';
import TranslationKeys from '@/app/translation/TranslationKeys';
import { ListItem } from '@/app/component/partial/listitem/ListItem';

export type Dependencies = {
  getUsersService: GetUsersService;
};

export const UsersContext = createContext<Dependencies>({
  getUsersService: dummyGetUsersService,
});

type Props = {
  usersKey: UsersKey;
};

export const useUsers = ({ usersKey }: Props): UsersState => {
  const { getUsersService } = useContext(UsersContext);
  const { globalState, dispatch } = useContext(GlobalContext);
  const meState = useContext(MeContext);
  const usersState = globalState.usersStateHash[usersKey] ?? defaultUsersState;
  const translator = useTranslator();
  const [isLoading, setLoading] = useState(false);

  const fetchUsers = async (currentState: UsersStateType) => {
    setLoading(true);
    const response = await getUsersService({ nextUrl: currentState.nextUrl });
    const newUserSummaries = currentState.userSummaries.concat(response.userSummaries);
    const newNextUrl = response.nextUrl;
    const newState = {
      ...currentState,
      userSummaries: newUserSummaries,
      nextUrl: newNextUrl,
    };
    dispatch({
      type: 'UPDATE_USERS_STATE',
      payload: {
        usersKey,
        usersState: newState,
      },
    });
    setLoading(false);
    if (shouldFetchNext(newNextUrl)) {
      fetchUsers(newState);
    }
  };

  const fetchNextIfNeeded = () => {
    if (
      isLoading ||
      !shouldFetchNext(usersState.nextUrl) ||
      usersState.userSummaries.length === 0
    ) {
      return;
    }
    fetchUsers(usersState);
  };

  useLayoutEffect(() => {
    window.addEventListener('scroll', fetchNextIfNeeded);
    window.addEventListener('resize', fetchNextIfNeeded);
    return () => {
      window.removeEventListener('scroll', fetchNextIfNeeded);
      window.removeEventListener('resize', fetchNextIfNeeded);
    };
  });

  useEffect(() => {
    fetchNextIfNeeded();
  }, [usersState.userSummaries.length]);

  useEffect(() => {
    if (usersState.nextUrl === '') {
      return;
    }
    fetchUsers(usersState);
  }, [usersState.nextUrl]);

  return {
    listItems:
      isLoading && usersState.userSummaries.length === 0
        ? []
        : convertListItems<UserSummaryType>({
            models: usersState.userSummaries,
            isVisibleEnd: usersState.nextUrl === '',
            notFoundMessage: translator(TranslationKeys.NotFound),
            firstVisibleAdsLine: 3,
            insertAdsPerLines: 5,
            removeAds: meState.user.isPremium,
            listItemConverter: (userSummary: UserSummaryType): ListItem => ({
              type: 'USER',
              userSummary,
            }),
          }),
    isLoading,
  };
};
