import React, { useRef, useCallback, useState } from 'react';
import styled from '@emotion/styled';
import PixelArt from '@dotpict-lib/component/core/Pixelart';
import Text from '@dotpict-lib/foundation/typography/Text';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';

const Container = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: auto 1fr auto;
  align-items: center;
`;

const RectContainer = styled.div`
  position: relative;
`;

const Tip = styled.div<{ rate: number }>`
  position: absolute;
  top: -12px;
  left: ${({ rate }) => `${rate}%`};
  transform: translateX(-50%);
  user-select: none;
`;

const Down = styled.div`
  user-select: none;
  cursor: pointer;
`;

const Up = styled.div`
  user-select: none;
  cursor: pointer;
`;

const Rect = styled.div<{ rgbs: string[] }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  background: linear-gradient(to right, ${({ rgbs }) => rgbs.join(',')});
  user-select: none;
  cursor: pointer;
`;

type Props = {
  rate: number;
  rgbs: string[];
  label: string;
  setNewRate(newValue: number): void;
};

const HSVEditorRect = ({ rate, rgbs, label, setNewRate }: Props) => {
  const rectRef = useRef<HTMLDivElement>(null);
  const [drag, setDrag] = useState<boolean>(false);
  // Up, Down 1回で増減する値
  const upDownDelta = 100 / 360;

  const handleUp = useCallback(() => {
    setNewRate(Math.min(rate + upDownDelta, 100));
  }, [rate]);

  const handleDown = useCallback(() => {
    setNewRate(Math.max(rate - upDownDelta, 0));
  }, [rate]);

  const handleSelectRect = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (rectRef.current === null) {
        return;
      }

      const clientRect = rectRef.current.getBoundingClientRect();
      if (clientRect === undefined) {
        return;
      }

      // ClickEventからはウィンドウの絶対座標しか取得できないので、要素内の相対的なX座標を計算
      const positionX = clientRect.left + window.pageXOffset;
      const clickX = e.pageX;
      const x = clickX - positionX;

      // 相対的なX座標から何%の位置なのか計算
      setNewRate((x / (clientRect.width - 1)) * 100);
    },
    [rectRef.current],
  );

  const handleMouseUp = useCallback(() => {
    setDrag(false);
  }, [setDrag]);

  const handleMouseDown = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      setDrag(true);
      handleSelectRect(e);
    },
    [setDrag],
  );

  const handleMouseMove = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (drag) {
        handleSelectRect(e);
      }
    },
    [drag],
  );

  return (
    <Container>
      <Down onClick={handleDown}>
        <PixelArt
          alt="back"
          src="https://storage.googleapis.com/dotpict-images/web/draw/icon/decrement_arrow.svg"
          width={16}
          height={16}
        />
      </Down>
      <RectContainer
        ref={rectRef}
        onMouseUp={handleMouseUp}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
      >
        <Rect rgbs={rgbs}>
          <Text text={label} textStyle={TextStyle.REGULAR14} colorStyle={ColorStyle.WHITE} />
        </Rect>
        <Tip rate={rate}>
          <PixelArt
            alt="move_bottom"
            src="https://storage.googleapis.com/dotpict-images/web/draw/icon/move_bottom.svg"
            width={16}
            height={16}
          />
        </Tip>
      </RectContainer>
      <Up onClick={handleUp}>
        <PixelArt
          alt="back"
          src="https://storage.googleapis.com/dotpict-images/web/draw/icon/increment_arrow.svg"
          width={16}
          height={16}
        />
      </Up>
    </Container>
  );
};

export default HSVEditorRect;
