import { WorkThreadType } from '@/app/model/WorkThreadType';
import { WorkThreadViewState } from '@/app/component/page/work/thread/item/WorkThread';
import { useNavigate } from 'react-router-dom';

type Props = {
  thread: WorkThreadType;
};

const useWorkThread = ({ thread }: Props): WorkThreadViewState => {
  const navigator = useNavigate();

  const onClickUser = () => {
    navigator(`/users/${thread.user.id}`);
  };

  return {
    profileImageUrl: thread.user.profileImageUrl,
    userName: thread.user.name,
    createdAt: thread.createdAt,
    text: thread.text,
    replyCount: thread.threadCount,
    isLiked: thread.isLiked,
    likeCount: thread.likeCount,
    likedProfileImageUrl: thread.likedProfileImageUrl,
    onClickUser,
  };
};

export default useWorkThread;
