import React, { useContext, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { Link, useLocation, useParams } from 'react-router-dom';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import Text from '@dotpict-lib/foundation/typography/Text';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import { APIContext, LayoutContext, MeContext } from '@/Routing';
import {
  articleContentBottomSpace,
  articleContentTopSpace,
  articleTitleBottomSpace,
} from '@/app/common/constant';
import { ArticleComponentType } from '@/app/article/ArticleComponentType';
import { IArticleTextComponent } from '@/app/article/IArticleTextComponent';
import ImageComponent from '@/app/component/ImageComponent';
import Pixelart from '@dotpict-lib/component/core/Pixelart';
import ExternalLinkComponent from '@/app/component/ExternalLinkComponent';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';
import Column from '@dotpict-lib/component/core/layout/Column';
import Loading from '@dotpict-lib/component/core/Loading';
import { VerticalGravity } from '@dotpict-lib/component/core/layout/VerticalGravity';
import { HorizontalGravity } from '@dotpict-lib/component/core/layout/HorizontalGravity';
import { Row } from '@dotpict-lib/component/core/layout/Row';
import { LanguageContext } from '@/Language';

const ArticleDetailComponent = () => {
  const { articleId } = useParams();
  const { layoutParams } = useContext(LayoutContext);
  const [renderTitle, setRenderTitle] = useState(true);
  const [isNightMode, setNightMode] = useState(false);
  const location = useLocation();
  const meState = useContext(MeContext);
  const { language } = useContext(LanguageContext);
  const [title, setTitle] = useState<string>('');
  const [articleComponents, setArticleComponents] = useState<ArticleComponentType[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const { client } = useContext(APIContext);

  const imageContainerStyle = css({
    border: `solid 2px ${ColorStyle.TEXT_PRIMARY}`,
  });

  const fetchData = async () => {
    if (articleId === undefined) return;
    setLoading(true);
    const articleResponse = await client.fetchArticle(meState.token, language, articleId);
    setTitle(articleResponse.title);
    setArticleComponents(articleResponse.components);
    setLoading(false);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setRenderTitle(params.get('noTitle') !== 'true' || title === '');
    setNightMode(params.get('isNightMode') === 'true');
    setTitle('');
    setArticleComponents([]);
    fetchData();
  }, [articleId, language]);

  const renderContent = (component: ArticleComponentType) => {
    switch (component.kind) {
      case 'text':
        return (
          <Text
            text={component.text}
            textStyle={
              (component as IArticleTextComponent).isBold ? TextStyle.BOLD14 : TextStyle.REGULAR14
            }
            colorStyle={isNightMode ? ColorStyle.WHITE : ColorStyle.TEXT_PRIMARY}
          />
        );
      case 'internal_link':
        return (
          <Link to={component.path}>
            <Text
              text={component.text}
              textStyle={TextStyle.REGULAR14}
              colorStyle={ColorStyle.PRIMARY}
            />
          </Link>
        );
      case 'external_link':
        return (
          <ExternalLinkComponent
            text={component.text}
            url={component.path}
            textStyle={TextStyle.BOLD14}
            colorStyle={ColorStyle.PRIMARY}
          />
        );
      case 'image':
        return (
          <Row
            verticalGravity={VerticalGravity.center}
            horizontalGravity={HorizontalGravity.center}
          >
            <div css={imageContainerStyle}>
              <ImageComponent
                alt="article_image"
                width={component.width}
                height={component.height}
                src={component.imageUrl}
              />
            </div>
          </Row>
        );
      case 'pixelart':
        return (
          <Row
            verticalGravity={VerticalGravity.center}
            horizontalGravity={HorizontalGravity.center}
          >
            <div css={imageContainerStyle}>
              <Pixelart
                alt="article_image"
                width={component.width}
                height={component.height}
                src={component.imageUrl}
              />
            </div>
          </Row>
        );
      case 'space':
        return <Spacer height={component.size} />;
      case 'button':
        return (
          <Row
            verticalGravity={VerticalGravity.center}
            horizontalGravity={HorizontalGravity.center}
          >
            <ExternalLinkComponent
              text={component.text}
              url={component.url}
              textStyle={TextStyle.BOLD14}
              colorStyle={ColorStyle.PRIMARY}
            />
          </Row>
        );
      default:
        return '';
    }
  };

  return (
    <Column>
      {renderTitle && <Spacer height={layoutParams.contentTopSpace} />}
      <Column
        background={isNightMode ? ColorStyle.NIGHT_BACKGROUND : ColorStyle.WHITE}
        padding="0 32"
        width={layoutParams.contentWidth - 32 * 2}
      >
        <Spacer height={articleContentTopSpace} />
        {renderTitle && (
          <>
            <Text text={title} textStyle={TextStyle.BOLD20} colorStyle={ColorStyle.TEXT_PRIMARY} />
            <Spacer height={articleTitleBottomSpace} />
          </>
        )}
        {isLoading && <Loading />}
        {articleComponents.map((articleComponent: ArticleComponentType, index: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={index}>{renderContent(articleComponent)}</React.Fragment>
        ))}
        <Spacer height={articleContentBottomSpace} />
      </Column>
      {renderTitle && <Spacer height={layoutParams.contentBottomSpace} />}
    </Column>
  );
};

export default ArticleDetailComponent;
