import Text from '@dotpict-lib/foundation/typography/Text';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import styled from '@emotion/styled';

type Props = {
  scope: string;
};

const ScopeContainer = styled.div`
  border-radius: 11px;
  border: 1px solid #dfdfe8;
  padding: 4px 8px;
`;

export const NoteScope = ({ scope }: Props) => (
  <ScopeContainer>
    <Text text={scope} textStyle={TextStyle.REGULAR12} colorStyle={ColorStyle.TEXT_PRIMARY} />
  </ScopeContainer>
);
