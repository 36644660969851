import { css } from '@emotion/react';
import TextField from '@material-ui/core/TextField';
import Pixelart from '@dotpict-lib/component/core/Pixelart';
import TranslationKeys from '@/app/translation/TranslationKeys';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import useTranslator from '@/app/hook/useTranslator';
import useWorkThreadInput from '@/app/component/page/work/thread/input/useThreadInput';
import { Row } from '@dotpict-lib/component/core/layout/Row';
import { VerticalGravity } from '@dotpict-lib/component/core/layout/VerticalGravity';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';
import { useContext, useEffect, useState } from 'react';
import Button from '@dotpict-lib/component/dotpict/Button';
import { LayoutContext } from '@/Routing';

export type ThreadInputProps = {
  isReply: boolean;
  workId: number;
  parentThreadId?: number;
};

export type ThreadInputViewState = {
  profileImageUrl: string;
  inputComment: string;
  isLoading: boolean;
};

export type ThreadInputAction = {
  handleInputCommentChange: (comment: string) => void;
  handleSendCommentClick: () => void;
};

const replyMarkWidth = 16;
const widthExcludeTextField = 16 + 48 + 16 + 16 + 64 + 16;

export const WorkThreadInput = ({ isReply, workId, parentThreadId }: ThreadInputProps) => {
  const translator = useTranslator();
  const { layoutParams } = useContext(LayoutContext);
  const [textFieldWidth, setTextFieldWidth] = useState<number>(512);
  const {
    profileImageUrl,
    inputComment,
    isLoading,
    handleInputCommentChange,
    handleSendCommentClick,
  } = useWorkThreadInput({
    workId,
    parentThreadId,
  });

  useEffect(() => {
    setTextFieldWidth(
      layoutParams.mainContainerWidth - widthExcludeTextField - (isReply ? replyMarkWidth : 0),
    );
  }, [layoutParams.mainContainerWidth]);

  const commentFieldStyle = css({
    width: textFieldWidth,
  });

  return (
    <Row width="100%" background={ColorStyle.WHITE} verticalGravity={VerticalGravity.center}>
      <Row verticalPadding={16}>
        {isReply && <Spacer width={16} />}
        <Row horizontalPadding={16} innerPadding={16} verticalGravity={VerticalGravity.center}>
          <Pixelart alt="profile_image_url" src={profileImageUrl} width={48} height={48} />
          <TextField
            value={inputComment}
            onChange={(e) => handleInputCommentChange(e.target.value)}
            css={commentFieldStyle}
            id="commentForm"
            label={translator(isReply ? TranslationKeys.ReplyHint : TranslationKeys.CommentHint)}
            variant="outlined"
            maxRows={5}
            multiline
          />
          <Button
            width={64}
            height={52}
            text={translator(TranslationKeys.Send)}
            isFilled
            isLoading={isLoading}
            handleClick={() => handleSendCommentClick()}
          />
        </Row>
      </Row>
    </Row>
  );
};
