import { TimelineItemType } from '@/app/state/TimelineItemType';

export type TimelineStateType = {
  timelineItems: TimelineItemType[];
  nextUrl: string;
};

export const defaultTimelineState: TimelineStateType = {
  timelineItems: [],
  nextUrl: '',
};
