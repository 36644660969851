import React, { MouseEventHandler } from 'react';

type Props = {
  padding?: number | string;
  minWidth?: number;
  minHeight?: number;
  onClick?: MouseEventHandler | undefined;
  children: React.ReactNode;
};

const Clickable = ({ padding, minWidth, minHeight, onClick, children }: Props) => {
  const buttonStyle = {
    backgroundColor: 'transparent',
    backgroundRepeat: 'no-repeat',
    border: 'none',
    cursor: 'pointer',
    overflow: 'hidden',
    outline: 'none',
    padding: padding ?? 0,
    minHeight: minHeight ?? 0,
    minWidth: minWidth ?? 0,
  };

  return (
    <button type="button" style={buttonStyle} onClick={onClick}>
      {children}
    </button>
  );
};

export default Clickable;
