import { useEffect, useState } from 'react';
import Lottie, { Options } from 'react-lottie';
import { css } from '@emotion/react';
import Clickable from '@dotpict-lib/component/core/Clickable';

type Props = {
  size: number;
  isStopped: boolean;
  isOn: boolean;
  offToOnAnimationData: any;
  onToOffAnimationData: any;
  handleClick: () => void;
};

export const AnimatableActionButton = ({
  size,
  isStopped,
  isOn,
  offToOnAnimationData,
  onToOffAnimationData,
  handleClick,
}: Props) => {
  const offToOnAnimationOptions: Options = {
    loop: false,
    autoplay: false,
    animationData: offToOnAnimationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid meet',
    },
  };

  const onToOffAnimationOptions: Options = {
    loop: false,
    autoplay: false,
    animationData: onToOffAnimationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid meet',
    },
  };

  const [options, setOptions] = useState<Options>(offToOnAnimationOptions);

  // Lottieファイルの縦幅に占めるいいねアイコンサイズの割合
  const likeIconRatioInLottie = 0.4545;
  const width = size;
  // eslint-disable-next-line no-mixed-operators
  const height = (size * 24) / 26;

  const rootStyle = css({
    position: 'relative',
    width: size,
    height: size,
  });

  // いいねアイコンの幅 : それ以外の余白 = size : x
  const likeStyle = css({
    position: 'absolute',
    top: -(((1 - likeIconRatioInLottie) * size) / likeIconRatioInLottie / 2),
    left: -(((1 - likeIconRatioInLottie) * size) / likeIconRatioInLottie / 2) + size * 0.09, // なぜか横ずれるので微調整の0.09
  });

  useEffect(() => {
    setOptions(isOn ? onToOffAnimationOptions : offToOnAnimationOptions);
  }, [isOn]);

  return (
    <div css={rootStyle}>
      <Clickable onClick={handleClick}>
        <div css={likeStyle}>
          <Lottie
            options={options}
            height={width / likeIconRatioInLottie}
            width={height / likeIconRatioInLottie}
            isClickToPauseDisabled
            isStopped={isStopped}
          />
        </div>
      </Clickable>
    </div>
  );
};
