import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { APIContext, GlobalContext, LayoutContext, MeContext } from '@/Routing';
import { TimelineItemType } from '@/app/state/TimelineItemType';
import { DotpictAction } from '@/app/common/reducer';
import shouldFetchNext from '@/app/common/common';
import { defaultTimelineState } from '@/app/state/TimelineStateType';
import { TimelineViewState } from '@/app/component/page/timeline/Timeline';
import { convertListItems } from '@/app/component/partial/listitem/convertListItems';
import TranslationKeys from '@/app/translation/TranslationKeys';
import useTranslator from '@/app/hook/useTranslator';
import { ListItem } from '@/app/component/partial/listitem/ListItem';

export const useTimeline = (): TimelineViewState => {
  const { globalState, dispatch } = useContext(GlobalContext);
  const meState = useContext(MeContext);
  const [isLoading, setLoading] = useState(false);
  const { client } = useContext(APIContext);
  const translator = useTranslator();
  const { layoutParams } = useContext(LayoutContext);
  const [contentWidth, setContentWidth] = useState<number>(512);

  useEffect(() => {
    setContentWidth(Math.min(512, layoutParams.contentWidth));
  }, [layoutParams.contentWidth]);

  const fetchData = async (currentTimelineItems: TimelineItemType[], currentNextUrl: string) => {
    setLoading(true);
    const response = await client.fetchTimeline(currentNextUrl);
    const timelineItems = currentTimelineItems.concat(response.timeline);
    const action: DotpictAction = {
      type: 'UPDATE_TIMELINE_STATE',
      payload: {
        timelineState: {
          timelineItems,
          nextUrl: response.nextUrl,
        },
      },
    };
    dispatch(action);
    setLoading(false);
    if (shouldFetchNext(globalState.timelineState.nextUrl)) {
      fetchData(timelineItems, response.nextUrl);
    }
  };

  const handleScroll = () => {
    if (!isLoading && shouldFetchNext(globalState.timelineState.nextUrl)) {
      fetchData(globalState.timelineState.timelineItems, globalState.timelineState.nextUrl);
    }
  };

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  useEffect(() => {
    if (globalState.timelineState.timelineItems.length !== 0) return;
    window.scrollTo(0, 0);
    const action: DotpictAction = {
      type: 'UPDATE_TIMELINE_STATE',
      payload: {
        timelineState: defaultTimelineState,
      },
    };
    dispatch(action);
    fetchData([], `${client.getBaseUrl}/me/timeline`);
  }, []);

  return {
    contentWidth,
    listItems:
      isLoading && globalState.timelineState.timelineItems.length === 0
        ? []
        : convertListItems<TimelineItemType>({
            models: globalState.timelineState.timelineItems,
            isVisibleEnd: globalState.timelineState.nextUrl === '',
            notFoundMessage: translator(TranslationKeys.NotFound),
            firstVisibleAdsLine: 3,
            insertAdsPerLines: 5,
            removeAds: meState.user.isPremium,
            listItemConverter: (timelineItem: TimelineItemType): ListItem => {
              switch (timelineItem.type) {
                case 'work':
                  return {
                    type: 'WORK',
                    work: timelineItem.work!,
                    headerInfo: timelineItem.info!,
                    maxImageSize: contentWidth - 16 * 2,
                  };
                case 'note':
                  return {
                    type: 'NOTE',
                    note: timelineItem.note!,
                  };
                default:
                  return {
                    type: 'EMPTY',
                  };
              }
            },
          }),
    isLoading,
  };
};
