import { createContext, useContext, useEffect, useState } from 'react';
import { GlobalContext } from '@/Routing';
import { DotpictAction } from '@/app/common/reducer';
import { WorkRepliesViewProps } from '@/app/component/page/work/thread/replies/WorkReplies';
import {
  dummyGetWorkRepliesService,
  GetWorkRepliesService,
} from '@/app/service/GetWorkThreads/GetWorkRepliesService';
import { defaultWorkRepliesState } from '@/app/state/WorkRepliesState';

export type WorkRepliesDependencies = {
  threadId: number;
  getWorkRepliesService: GetWorkRepliesService;
};

export const WorkRepliesContext = createContext<WorkRepliesDependencies>({
  threadId: 0,
  getWorkRepliesService: dummyGetWorkRepliesService,
});

export const useWorkReplies = (): WorkRepliesViewProps => {
  const { dispatch, globalState } = useContext(GlobalContext);
  const { threadId, getWorkRepliesService } = useContext(WorkRepliesContext);
  const [isLoading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    dispatch({
      type: 'UPDATE_WORK_REPLIES_STATE',
      payload: {
        workRepliesState: defaultWorkRepliesState,
      },
    });
    const response = await getWorkRepliesService();
    const action: DotpictAction = {
      type: 'UPDATE_WORK_REPLIES_STATE',
      payload: {
        workRepliesState: {
          work: response.work,
          parentThread: response.parentThread,
          threads: response.workThreads,
        },
      },
    };
    dispatch(action);
    setLoading(false);
  };

  useEffect(() => {
    if (threadId === 0 || globalState.workThreadsState.work.id === threadId) return;
    fetchData();
  }, [threadId]);

  return {
    work: globalState.workRepliesState.work,
    parentThread: globalState.workRepliesState.parentThread,
    threads: globalState.workRepliesState.threads,
    isLoading,
  };
};
