import ExternalLinkComponent from '@/app/component/ExternalLinkComponent';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import Column from '@dotpict-lib/component/core/layout/Column';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';

const DeepLinkTestComponent = () => (
  <Column>
    <ExternalLinkComponent
      text="dotpict://dotpict.net/works/1"
      url="dotpict://dotpict.net/works/1"
      textStyle={TextStyle.BOLD14}
      colorStyle={ColorStyle.PRIMARY}
    />
    <Spacer height={12} />
    <ExternalLinkComponent
      text="dotpict://dotpict.net/users/1"
      url="dotpict://dotpict.net/users/1"
      textStyle={TextStyle.BOLD14}
      colorStyle={ColorStyle.PRIMARY}
    />
    <Spacer height={12} />
    <ExternalLinkComponent
      text="dotpict://dotpict.net/users/1/works"
      url="dotpict://dotpict.net/users/1/works"
      textStyle={TextStyle.BOLD14}
      colorStyle={ColorStyle.PRIMARY}
    />
  </Column>
);

export default DeepLinkTestComponent;
