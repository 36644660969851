import { UserSummaryType } from '@/app/model/UserSummaryType';

export interface IFollowerUsersState {
  userId: number;
  userSummaries: UserSummaryType[];
  nextUrl: string | null;
  requestedUserId: number;
}

export const defaultFollowerUsersState: IFollowerUsersState = {
  userId: 0,
  userSummaries: [],
  nextUrl: null,
  requestedUserId: 0,
};
