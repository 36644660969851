import styled from '@emotion/styled';
import { ToolType } from '@/app/component/page/draw/Context/ToolContext';
import ToolSelector from '@/app/component/page/draw/SideMenu/ToolSelector';
import ColorSelector from '@/app/component/page/draw/SideMenu/ColorSelector';
import { useContext } from 'react';
import { ColorContext } from '@/app/component/page/draw/Context/ColorContext';

const SideMenuContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  z-index: 0;
`;

type Props = {
  selectTool: (selected: ToolType) => () => void;
  selectColor: (selectedIndex: number) => () => void;
  editPalette(index: number, newColor: string): void;
};

const SideMenu = ({ selectTool, selectColor, editPalette }: Props) => {
  const { palette, colorIndex } = useContext(ColorContext);

  return (
    <SideMenuContainer>
      <ToolSelector selectTool={selectTool} selectColor={selectColor} editPalette={editPalette} />
      <ColorSelector
        palette={palette}
        vertical
        selectColor={selectColor}
        selectedColorIndex={colorIndex}
      />
    </SideMenuContainer>
  );
};

export default SideMenu;
