import { UserSummaryType } from '@/app/model/UserSummaryType';

export type UsersStateType = {
  key: string;
  userSummaries: UserSummaryType[];
  nextUrl: string;
};

export const defaultUsersState: UsersStateType = {
  key: '',
  userSummaries: [],
  nextUrl: '',
};
