import { useContext, useEffect, useLayoutEffect } from 'react';
import { APIContext, GlobalContext, MeContext } from '@/Routing';
import shouldFetchNext from '@/app/common/common';
import { useLocation, useParams } from 'react-router-dom';
import { OfficialEventResultStateType } from '@/app/state/OfficialEventResultStateType';

const useOfficialEventResult = () => {
  const { officialEventTag } = useParams();
  const meState = useContext(MeContext);
  const { client } = useContext(APIContext);
  const { globalState, dispatch } = useContext(GlobalContext);
  const location = useLocation();

  const fetchOfficialEventWorks = async (currentState: OfficialEventResultStateType) => {
    dispatch({
      type: 'UPDATE_OFFICIAL_EVENT_RESULT_STATE',
      payload: { officialEventResultState: currentState },
    });
    const response = await client.fetchWorks(currentState.nextUrl);
    const newWorks = currentState.works.concat(response.works);
    const newNextUrl = response.nextUrl;
    const newState: OfficialEventResultStateType = {
      ...currentState,
      isLoadingWorks: false,
      isVisibleEnd: newNextUrl === '',
      works: newWorks,
      nextUrl: newNextUrl,
    };
    dispatch({
      type: 'UPDATE_OFFICIAL_EVENT_RESULT_STATE',
      payload: {
        officialEventResultState: newState,
      },
    });
    if (shouldFetchNext(newNextUrl)) {
      fetchOfficialEventWorks(newState);
    }
  };

  const fetchOfficialEvent = async () => {
    if (officialEventTag === undefined) return;
    const officialEventResult = await client.fetchOfficialEventResult(
      meState.token,
      officialEventTag,
    );
    const newState: OfficialEventResultStateType = {
      ...officialEventResult,
      fromApp: new URLSearchParams(location.search).get('fromApp') === 'true',
      teamOneName: officialEventResult.officialEvent.battleInfo.teamOne.name,
      teamOneColor: `#${officialEventResult.officialEvent.battleInfo.teamOne.colorCode}`,
      teamOnePoint: officialEventResult.officialEvent.battleInfo.teamOne.point,
      teamTwoName: officialEventResult.officialEvent.battleInfo.teamTwo.name,
      teamTwoColor: `#${officialEventResult.officialEvent.battleInfo.teamTwo.colorCode}`,
      teamTwoPoint: officialEventResult.officialEvent.battleInfo.teamTwo.point,
      description: officialEventResult.description,
      closingTexts: officialEventResult.closingText.split('\n'),
      shareTitle: officialEventResult.shareText,
      twitterShareUrl: officialEventResult.shareUrl,
      facebookShareUrl: officialEventResult.shareUrl,
      ogpImageUrl: officialEventResult.ogpImageUrl,
      bannerImageUrl: officialEventResult.bannerImageUrl,
      works: [],
      nextUrl: `${client.getBaseUrl}/official_events/${officialEventResult.officialEvent.id}/works`,
      isLoadingWorks: true,
      isVisibleEnd: false,
      requestedUserId: meState.user.id,
    };
    dispatch({
      type: 'UPDATE_OFFICIAL_EVENT_RESULT_STATE',
      payload: { officialEventResultState: newState },
    });
    await fetchOfficialEventWorks(newState);
  };

  const handleScroll = () => {
    if (
      globalState.officialEventResultState.isLoadingWorks ||
      !shouldFetchNext(globalState.officialEventResultState.nextUrl) ||
      globalState.officialEventResultState.works.length === 0
    ) {
      return;
    }
    fetchOfficialEventWorks({
      ...globalState.officialEventResultState,
      isLoadingWorks: true,
    });
  };

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  useEffect(() => {
    if (
      officialEventTag === globalState.officialEventResultState.officialEvent.tag &&
      meState.user.id === globalState.officialEventResultState.requestedUserId
    ) {
      return;
    }
    fetchOfficialEvent();
  }, [officialEventTag, meState.token]);

  return {
    ...globalState.officialEventResultState,
  };
};

export default useOfficialEventResult;
