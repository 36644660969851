import Column from '@dotpict-lib/component/core/layout/Column';
import Text from '@dotpict-lib/foundation/typography/Text';
import useTranslator from '@/app/hook/useTranslator';
import TranslationKeys from '@/app/translation/TranslationKeys';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';

export type EventSectionProps = {
  eventTitle: string;
};

const EventSection = ({ eventTitle }: EventSectionProps) => {
  const translator = useTranslator();

  return (
    <Column>
      <Text
        text={translator(TranslationKeys.JoiningEvent)}
        textStyle={TextStyle.BOLD14}
        colorStyle={ColorStyle.TEXT_PRIMARY}
      />
      <Spacer height={10} />
      <Text
        text={eventTitle}
        textStyle={TextStyle.REGULAR14}
        colorStyle={ColorStyle.TEXT_PRIMARY}
      />
    </Column>
  );
};

export default EventSection;
