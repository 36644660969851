import ArticleComponent from '@/app/component/page/article/ArticleComponent';
import SixArticle from '@/app/article/SixArticle';

const SixArticleComponent = () => (
  <div>
    <ArticleComponent article={SixArticle} />
  </div>
);

export default SixArticleComponent;
