import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';
import Text from '@dotpict-lib/foundation/typography/Text';
import Canvases from '@/app/component/page/draw/canvases/canvases/Canvases';
import useTranslator from '@/app/hook/useTranslator';
import TranslationKeys from '@/app/translation/TranslationKeys';
import NewCanvasDialog from '@/app/component/page/draw/canvases/newcanvasdialog/NewCanvasDialog';
import { useState } from 'react';
import DrawTrial from '@/app/component/page/draw/canvases/drawtrial/DrawTrial';
import Column from '@dotpict-lib/component/core/layout/Column';
import useCanvases from '@/app/component/page/draw/canvases/useCanvases';
import { HorizontalGravity } from '@dotpict-lib/component/core/layout/HorizontalGravity';
import Pixelart from '@dotpict-lib/component/core/Pixelart';
import Clickable from '@dotpict-lib/component/core/Clickable';
import OdaiMiniBanner from '@/app/component/partial/event/odaibanner/mini/OdaiMiniBanner';
import End from '@dotpict-lib/component/core/End';

export type CanvasesState = {
  isVisibleOfficialEvent: boolean;
  bannerWidth: number;
  officialEventBannerImageUrl: string;
  officialEventBannerHeight: number;
  odaiTitle: string;
};

export type CanvasesProps = CanvasesState & {
  handleClickOfficialEvent: () => void;
  handleClickOdai: () => void;
};

const CanvasesPageRoot = () => {
  const [openNewCanvas, setOpenNewCanvas] = useState(false);
  const translator = useTranslator();

  const {
    isVisibleOfficialEvent,
    bannerWidth,
    officialEventBannerImageUrl,
    officialEventBannerHeight,
    odaiTitle,
    handleClickOfficialEvent,
    handleClickOdai,
  } = useCanvases();

  return (
    <>
      <Column>
        <Spacer height={32} />
        <Spacer height={28} />
        {isVisibleOfficialEvent && (
          <Column horizontalGravity={HorizontalGravity.center}>
            <Pixelart
              alt="official_event"
              src={officialEventBannerImageUrl}
              width={bannerWidth}
              height={officialEventBannerHeight}
              handleClick={handleClickOfficialEvent}
            />
          </Column>
        )}
        {odaiTitle !== '' && (
          <>
            <Spacer height={28} />
            <Column horizontalGravity={HorizontalGravity.center}>
              <Column width={bannerWidth}>
                <Clickable onClick={handleClickOdai}>
                  <OdaiMiniBanner odaiTitle={odaiTitle} />
                </Clickable>
              </Column>
            </Column>
          </>
        )}
        <Spacer height={28} />
        <Text
          text={translator(TranslationKeys.MyCanvases)}
          textStyle={TextStyle.BOLD20}
          colorStyle={ColorStyle.TEXT_PRIMARY}
        />
        <Spacer height={32} />
        <Canvases setOpenNewCanvas={setOpenNewCanvas} />
        <Spacer height={32} />
        <DrawTrial />
      </Column>
      <NewCanvasDialog open={openNewCanvas} setOpen={setOpenNewCanvas} />
      <End />
    </>
  );
};
export default CanvasesPageRoot;
