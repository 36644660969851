import { useContext, useState } from 'react';
import { APIContext, GlobalContext, MeContext } from '@/Routing';
import { DotpictAction } from '@/app/common/reducer';
import likeAnimationData from '@/assets/lottie/BtnLikeOn_64x64.json';
import deleteLikeAnimationData from '@/assets/lottie/BtnLikeOff_64x64.json';
import { AnimatableActionButton } from '@/app/component/AnimatableActionButton';
import TranslationKeys from '@/app/translation/TranslationKeys';
import useTranslator from '@/app/hook/useTranslator';

type Props = {
  workId: number;
  isLiked: boolean;
  size: number;
};

const WorkLike = ({ workId, isLiked, size }: Props) => {
  const { dispatch } = useContext(GlobalContext);
  const meState = useContext(MeContext);
  const [isStopped, setStopped] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const { client } = useContext(APIContext);
  const translator = useTranslator();

  const handleClick = async () => {
    if (!meState.isLoggedIn) {
      // eslint-disable-next-line no-alert
      window.alert(translator(TranslationKeys.AccountRegistrationRequired));
      return;
    }
    if (isLoading) return;
    setStopped(false);
    setLoading(true);
    const request = async () => {
      const work = isLiked
        ? await client.deleteLike(meState.token, `${workId}`)
        : await client.postLike(meState.token, `${workId}`);
      const action: DotpictAction = {
        type: 'UPDATE_WORK',
        payload: { work },
      };
      dispatch(action);
    };
    await request();
    setLoading(false);
    setStopped(true);
  };

  return (
    <AnimatableActionButton
      size={size}
      isStopped={isStopped}
      isOn={isLiked}
      offToOnAnimationData={likeAnimationData}
      onToOffAnimationData={deleteLikeAnimationData}
      handleClick={handleClick}
    />
  );
};

export default WorkLike;
