import { useEffect, useRef, useContext } from 'react';
import styled from '@emotion/styled';
import { SizeContext } from '@/app/component/page/draw/Context/SizeContext';
import { CanvasSizes } from '@/app/component/page/draw/hooks/types';

const StyledCanvas = styled.canvas`
  position: absolute;
  pointer-events: none;
`;

type Props = {
  canvasSizes: CanvasSizes;
};

const CanvasGrid = ({ canvasSizes }: Props) => {
  const canvasSize = useContext(SizeContext);
  const gridRef = useRef<HTMLCanvasElement>(null);

  // グリッドを描く
  useEffect(() => {
    if (gridRef.current) {
      const context = gridRef.current.getContext('2d');

      if (context) {
        // Retinaディスプレイだとボケるので収縮して対応している
        // https://qiita.com/kozo002/items/33690c0ac11dec454df8#retina%E3%83%87%E3%82%A3%E3%82%B9%E3%83%97%E3%83%AC%E3%82%A4%E3%81%A0%E3%81%A8%E3%83%9C%E3%82%B1%E3%82%8B
        const dpr = window.devicePixelRatio;
        gridRef.current.width = canvasSizes.canvasRenderWidth * dpr;
        gridRef.current.height = canvasSizes.canvasRenderHeight * dpr;
        gridRef.current.style.width = `${canvasSizes.canvasRenderWidth}px`;
        gridRef.current.style.height = `${canvasSizes.canvasRenderHeight}px`;
        context.scale(dpr, dpr);

        // 以下の処理でグリッドを描く
        context.clearRect(0, 0, canvasSizes.canvasRenderWidth, canvasSizes.canvasRenderHeight);

        context.strokeStyle = 'rgba(0, 0, 0, 0.1)';
        context.lineWidth = 1;

        context.beginPath();

        // 縦線
        // eslint-disable-next-line no-plusplus
        for (let i = 1; i < canvasSize.width; i++) {
          context.moveTo(i * canvasSizes.pixelSize, 0);
          context.lineTo(i * canvasSizes.pixelSize, canvasSizes.canvasRenderHeight);
        }

        // 横線
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < canvasSize.height + 1; i++) {
          context.moveTo(0, i * canvasSizes.pixelSize);
          context.lineTo(canvasSizes.canvasRenderWidth, i * canvasSizes.pixelSize);
        }

        context.stroke();
      }
    }
  }, [canvasSize, canvasSizes]);

  return (
    <StyledCanvas
      ref={gridRef}
      width={canvasSizes.canvasRenderWidth}
      height={canvasSizes.canvasRenderHeight}
    />
  );
};

export default CanvasGrid;
