import ArticleComponent from '@/app/component/page/article/ArticleComponent';
import PrivacyArticle from '@/app/article/PrivacyArticle';

const PrivacyComponent = () => (
  <div>
    <ArticleComponent article={PrivacyArticle} />
  </div>
);

export default PrivacyComponent;
