import { useContext, useMemo } from 'react';
import { APIContext } from '@/Routing';
import { useParams } from 'react-router-dom';
import {
  WorkThreadsDependencies,
  WorkThreadsContext,
} from '@/app/component/page/work/thread/threads/useWorkThreads';
import { WorkThreads } from '@/app/component/page/work/thread/threads/WorkThreads';

export const WorkThreadsPage = () => {
  const { client } = useContext(APIContext);
  const { workId } = useParams();

  const dependencies = useMemo((): WorkThreadsDependencies => {
    return {
      workId: Number(workId),
      getWorkThreadsService: () => (workId ? client.fetchWorkThreads(workId) : Promise.reject()),
    };
  }, [client]);

  return (
    <WorkThreadsContext.Provider value={dependencies}>
      <WorkThreads />
    </WorkThreadsContext.Provider>
  );
};
