import EditWork, { EditWorkContext } from '@/app/component/page/work/edit/EditWork';
import useEditWork from '@/app/component/page/work/edit/useEditWork';

const EditWorkPage = () => {
  const props = useEditWork();

  return (
    <EditWorkContext.Provider value={props}>
      <EditWork />
    </EditWorkContext.Provider>
  );
};

export default EditWorkPage;
