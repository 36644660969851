import Pixelart from '@dotpict-lib/component/core/Pixelart';
import Text from '@dotpict-lib/foundation/typography/Text';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import { Row } from '@dotpict-lib/component/core/layout/Row';
import { VerticalGravity } from '@dotpict-lib/component/core/layout/VerticalGravity';
import { NoteType } from '@dotpict-sns-lib/model/Note/NoteType';
import { NoteLike } from '@/app/component/page/note/item/NoteLike';
import DotpictLink from '@/app/component/DotpictLinkComponent';
import styled from '@emotion/styled';

export type NoteFooterProps = {
  note: NoteType;
};

const actionButtonWidth = 32;
const actionCountLeftPadding = 0;
const actionCountWidth = 8 + 32;
const actionWidth = actionButtonWidth + actionCountLeftPadding + actionCountWidth;
const actionMargin = 2;

const Container = styled.div`
  position: relative;
`;

const ThreadContainer = styled.div`
  position: absolute;
  left: 0;
`;

const LikeButtonContainer = styled.div<{ isVisibleReply: boolean }>`
  position: absolute;
  left: ${({ isVisibleReply }) => `${(actionWidth + actionMargin) * (isVisibleReply ? 1 : 0)}`};
`;

const LikeCountContainer = styled.div<{ isVisibleReply: boolean }>`
  position: absolute;
  left: ${({ isVisibleReply }) =>
    `${
      (actionWidth + actionMargin) * (isVisibleReply ? 1 : 0) +
      actionButtonWidth +
      actionCountLeftPadding
    }`};
`;

export const NoteFooter = ({ note }: NoteFooterProps) => {
  const isVisibleReply = note.type !== 'grand';
  return (
    <Row height={32}>
      <Container>
        <LikeButtonContainer isVisibleReply={isVisibleReply}>
          <NoteLike noteId={note.id} isLiked={note.isLiked} size={32} />
        </LikeButtonContainer>
        {isVisibleReply && (
          <ThreadContainer>
            <DotpictLink
              to={`/notes/${note.id}/${note.type === 'root' ? 'childNotes' : 'grandNotes'}`}
            >
              <Row verticalGravity={VerticalGravity.center} innerPadding={8}>
                <Pixelart
                  alt="comment"
                  src="https://storage.googleapis.com/dotpict-images/web/ic_comment.svg"
                  width={32}
                  height={32}
                />
                <Row width={32} height={30} verticalGravity={VerticalGravity.center}>
                  <Text
                    text={`${note.childCount > 0 ? note.childCount : ''}`}
                    textStyle={TextStyle.REGULAR10}
                    colorStyle={ColorStyle.TEXT_SECONDARY}
                  />
                </Row>
              </Row>
            </DotpictLink>
          </ThreadContainer>
        )}
        <LikeCountContainer isVisibleReply={isVisibleReply}>
          <DotpictLink to={`/notes/${note.id}/likeUsers`}>
            <Row
              padding="0 0 0 8"
              width={32}
              height={30}
              innerPadding={4}
              verticalGravity={VerticalGravity.center}
            >
              <Text
                text={`${note.likeCount > 0 ? note.likeCount : ''}`}
                textStyle={TextStyle.REGULAR10}
                colorStyle={ColorStyle.TEXT_SECONDARY}
              />
              {note.likedProfileImageUrl !== '' && (
                <Pixelart
                  alt="liked_by_author"
                  src={note.likedProfileImageUrl}
                  width={16}
                  height={16}
                />
              )}
            </Row>
          </DotpictLink>
        </LikeCountContainer>
      </Container>
    </Row>
  );
};
