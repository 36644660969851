import React, { useContext, useEffect, useState } from 'react';
import DotpictLink from '@/app/component/DotpictLinkComponent';
import Pixelart from '@dotpict-lib/component/core/Pixelart';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';
import Text from '@dotpict-lib/foundation/typography/Text';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import Column from '@dotpict-lib/component/core/layout/Column';
import { Row } from '@dotpict-lib/component/core/layout/Row';
import { Grid } from '@dotpict-lib/component/core/layout/Grid';
import { VerticalGravity } from '@dotpict-lib/component/core/layout/VerticalGravity';
import { Divider } from '@material-ui/core';
import { WorkFooter } from '@/app/component/partial/work/item/WorkFooter';
import { HorizontalGravity } from '@dotpict-lib/component/core/layout/HorizontalGravity';
import { LayoutContext } from '@/Routing';
import { useNavigate } from 'react-router-dom';
import Clickable from '@dotpict-lib/component/core/Clickable';

export type WorkProps = {
  workId: number;
  title: string;
  caption: string;
  imageUrl: string;
  tags: string[];
  width: number;
  height: number;
  createdAt: string;
  isLiked: boolean;
  isRepicted: boolean;
  userId: number;
  userName: string;
  officialEventTag: string;
  officialEventTitle: string;
  userEventId: number;
  userEventTitle: string;
  odaiTag: string;
  odaiTitle: string;
  profileImageUrl: string;
  likeCount: number;
  repictCount: number;
  threadCount: number;
  allowThread: boolean;
  isVisibleUser: boolean;
  maxImageSize?: number;
  handleImageClick?: () => void;
  header?: React.ReactNode;
};

export const Work = ({
  workId,
  title,
  caption,
  imageUrl,
  tags,
  width,
  height,
  createdAt,
  isLiked,
  isRepicted,
  userId,
  userName,
  officialEventTag,
  officialEventTitle,
  userEventId,
  userEventTitle,
  odaiTag,
  odaiTitle,
  profileImageUrl,
  likeCount,
  repictCount,
  threadCount,
  allowThread,
  isVisibleUser,
  maxImageSize,
  handleImageClick,
  header,
}: WorkProps) => {
  const { layoutParams } = useContext(LayoutContext);
  const [imageSize, setImageSize] = useState<number>(512);
  const navigator = useNavigate();

  const onClickUserEvent = () => {
    navigator(`/userEvents/${userEventId}`);
  };

  const onClickOfficialEvent = () => {
    navigator(`/officialEvents/${officialEventTag}`);
  };

  const onClickOdai = () => {
    navigator(`/odais/${odaiTag}`);
  };

  useEffect(() => {
    setImageSize(
      Math.min(
        layoutParams.contentWidth - 16 * 2,
        maxImageSize ?? layoutParams.contentWidth - 16 * 2,
      ),
    );
  }, [layoutParams.contentWidth]);

  return (
    <Column width="100%" background={ColorStyle.WHITE} padding={16}>
      {header && header}
      <Column horizontalGravity={HorizontalGravity.center}>
        <Pixelart
          alt={`${workId}`}
          src={imageUrl}
          width={imageSize}
          height={imageSize}
          handleClick={handleImageClick}
        />
      </Column>
      <Spacer height={16} />
      {officialEventTag !== '' && (
        <>
          <Clickable onClick={onClickOfficialEvent}>
            <Row innerPadding={8} verticalGravity={VerticalGravity.center}>
              <Pixelart
                src="https://storage.googleapis.com/dotpict-images/web/ic_official_event.svg"
                alt="ic_official_event"
                width={129}
                height={24}
              />
              <Text
                text={officialEventTitle}
                textStyle={TextStyle.BOLD13}
                colorStyle={ColorStyle.PRIMARY}
                maxLines={1}
              />
            </Row>
          </Clickable>
          <Spacer height={8} />
        </>
      )}
      {userEventId > 0 && (
        <>
          <Clickable onClick={onClickUserEvent}>
            <Row innerPadding={8} verticalGravity={VerticalGravity.center}>
              <Pixelart
                src="https://storage.googleapis.com/dotpict-images/web/ic_user_event.svg"
                alt="ic_user_event"
                width={97}
                height={24}
              />
              <Text
                text={userEventTitle}
                textStyle={TextStyle.BOLD13}
                colorStyle={ColorStyle.PRIMARY}
                maxLines={1}
              />
            </Row>
          </Clickable>
          <Spacer height={8} />
        </>
      )}
      {odaiTag !== '' && (
        <>
          <Clickable onClick={onClickOdai}>
            <Row innerPadding={8} verticalGravity={VerticalGravity.center}>
              <Pixelart
                src="https://storage.googleapis.com/dotpict-images/web/ic_today_event.svg"
                alt="ic_today_event"
                width={121}
                height={24}
              />
              <Text
                text={odaiTitle}
                textStyle={TextStyle.BOLD13}
                colorStyle={ColorStyle.PRIMARY}
                maxLines={1}
              />
            </Row>
          </Clickable>
          <Spacer height={8} />
        </>
      )}
      <Grid verticalGravity={VerticalGravity.center}>
        <Text text={title} textStyle={TextStyle.BOLD13} colorStyle={ColorStyle.TEXT_PRIMARY} />
        {tags.map((tag) => (
          <React.Fragment key={tag}>
            <Spacer width={8} />
            <DotpictLink to={`/search/works/tag/${tag}`}>
              <Text text={`#${tag}`} textStyle={TextStyle.BOLD13} colorStyle={ColorStyle.PRIMARY} />
            </DotpictLink>
          </React.Fragment>
        ))}
      </Grid>
      {caption.length > 0 && (
        <>
          <Spacer height={8} />
          <Text
            text={caption}
            textStyle={TextStyle.REGULAR11}
            colorStyle={ColorStyle.TEXT_PRIMARY}
          />
        </>
      )}
      <Spacer height={8} />
      <Row innerPadding={12}>
        <Text
          text={`${width}x${height}`}
          textStyle={TextStyle.REGULAR12}
          colorStyle={ColorStyle.TEXT_SECONDARY}
        />
        <Text
          text={createdAt}
          textStyle={TextStyle.REGULAR12}
          colorStyle={ColorStyle.TEXT_SECONDARY}
        />
      </Row>
      {isVisibleUser && (
        <>
          <Spacer height={12} />
          <DotpictLink to={`/users/${userId}`}>
            <Row innerPadding={12} verticalGravity={VerticalGravity.center}>
              <Pixelart alt={userName} src={profileImageUrl} width={24} height={24} />
              <Text
                text={userName}
                textStyle={TextStyle.BOLD12}
                colorStyle={ColorStyle.TEXT_PRIMARY}
              />
            </Row>
          </DotpictLink>
        </>
      )}
      <Spacer height={16} />
      <Divider />
      <Spacer height={8} />
      <WorkFooter
        workId={workId}
        isLiked={isLiked}
        isRepicted={isRepicted}
        likeCount={likeCount}
        repictCount={repictCount}
        threadCount={threadCount}
        allowThread={allowThread}
      />
    </Column>
  );
};
