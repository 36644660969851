import dayjs from 'dayjs';

import md5 from 'md5';
import { EmptyResponseType } from '@/app/model/responsetype/EmptyResponseType';
import { ErrorResponseType } from '@/app/model/responsetype/ErrorResponseType';
import { MeResponseType } from '@/app/model/responsetype/MeResponseType';
import { TemplateResultResponseType } from '@/app/model/responsetype/TemplateResultResponseType';
import { WorkThreadType } from '@/app/model/WorkThreadType';
import { WorkThreadsResponseType } from '@/app/model/responsetype/WorkThreadsResponseType';
import { UserSummariesResponseType } from '@/app/model/responsetype/UserSummariesResponseType';
import { UserSummaryType } from '@/app/model/UserSummaryType';
import { UserWorksResponseType } from '@/app/model/responsetype/UserWorksResponseType';
import { WorksResponseType } from '@/app/model/responsetype/WorksResponseType';
import { ArticleComponentType } from '@/app/article/ArticleComponentType';
import { IArticleResponse } from '@/app/article/IArticleResponse';
import { NotificationsResponseType } from '@/app/model/responsetype/NotificationsResponseType';
import { NotificationType } from '@/app/model/NotificationType';
import { RequestsResponse } from '@/app/model/RequestsResponse';
import { RequestType } from '@/app/model/RequestType';
import { RequestBoxSettingsType } from '@/app/model/RequestBoxSettingsType';
import { UserEventType } from '@/app/model/UserEventType';
import { ColorCodeType } from '@/app/model/ColorCodeType';
import { LanguageType } from '@/app/model/Language';
import { PaletteType } from '@/app/model/PaletteType';
import { PalettesResponseType } from '@/app/model/responsetype/PalettesResponseType';
import { MakingType } from '@/app/making/MakingType';
import MakingContentEnum from '@/app/making/MakingContentEnum';
import { UserType } from '@dotpict-lib/model/User/UserType';
import { WorkType } from '@/app/model/WorkType';
import { dotpictFirebaseAuth } from '@/app/library/dotpictFirebaseAuth';
import { Rect } from '@/app/component/page/work/upload/types';
import { PagingType } from '@dotpict-lib/model/PagingType';
import rgbToHex from '@dotpict-lib/util/rgbToHex';
import { TranslatorType } from '@/app/hook/useTranslator';
import TranslationKeys from '@/app/translation/TranslationKeys';
import { OdaiType } from '@dotpict-sns-lib/model/Odai/OdaiType';
import { convertArrayToOdai } from '@dotpict-sns-lib/model/Odai/convertArrayToOdai';
import {
  defaultOfficialEvent,
  OfficialEventType,
} from '@dotpict-sns-lib/model/OfficialEvent/OfficialEventType';
import { convertArrayToOfficialEvent } from '@dotpict-sns-lib/model/OfficialEvent/convertArrayToOfficialEvent';
import { NetaResponseType } from '@dotpict-sns-lib/model/ResponseType/NetaResponseType';
import { convertArrayToUser } from '@dotpict-lib/model/User/convertArrayToUser';
import { TimelineResponseType } from '@/app/model/responsetype/TimelineResponseType';
import { TimelineItemType } from '@/app/state/TimelineItemType';
import { convertArrayToNote } from '@dotpict-sns-lib/model/Note/convertArrayToNote';
import { NoteType } from '@dotpict-sns-lib/model/Note/NoteType';
import { WorkRepliesResponseType } from '@/app/model/responsetype/WorkRepliesResponseType';
import { ChildNotesResponseType } from '@/app/model/responsetype/ChildNotesResponseType';
import { WorkThreadResponseType } from '@/app/model/responsetype/WorkThreadResponseType';
import { WorkDetailResponseType } from '@/app/model/responsetype/WorkDetailResponseType';
import { OfficialEventResultType } from '@/app/model/OfficialEventResultType';

export interface IDotpictClient {
  fetchMaking: (token: string, slug: string) => Promise<MakingType>;
  fetchMakings: (token: string) => Promise<MakingType[]>;
  fetchWorkDetail: (workId: number) => Promise<WorkDetailResponseType>;
  fetchUserWorks: (paging: PagingType) => Promise<UserWorksResponseType>;
  fetchTemplateResult: (token: string, nextUrl: string) => Promise<TemplateResultResponseType>;
  fetchMe: (token: string) => Promise<MeResponseType>;
  fetchWorks: (nextUrl: string) => Promise<WorksResponseType>;
  fetchTimeline: (nextUrl: string) => Promise<TimelineResponseType>;
  fetchNotifications: (token: string, nextUrl: string) => Promise<NotificationsResponseType>;
  fetchWorkThreads: (workId: string) => Promise<WorkThreadsResponseType>;
  fetchWorkReplies: (workId: string, threadId: string) => Promise<WorkRepliesResponseType>;
  fetchChildNotes: (parentNoteId: string) => Promise<ChildNotesResponseType>;
  fetchRequests: (token: string, nextUrl: string) => Promise<RequestsResponse>;
  fetchUsers: (nextUrl: string) => Promise<UserSummariesResponseType>;
  fetchOfficialPalettes: (token: string, nextUrl: string) => Promise<PalettesResponseType>;
  fetchUsersByAccount: (account: string) => Promise<UserType>;
  fetchArticle: (token: string, lang: string, articleId: string) => Promise<IArticleResponse>;
  fetchNeta: (token: string) => Promise<NetaResponseType>;
  createUsers: (token: string, recaptcha: string) => Promise<UserType>;
  postLike: (token: string, workId: string) => Promise<WorkType>;
  deleteLike: (token: string, workId: string) => Promise<WorkType>;
  postRepict: (token: string, workId: string) => Promise<WorkType>;
  deleteRepict: (token: string, workId: string) => Promise<WorkType>;
  postLikeThread: (workId: number, threadId: number) => Promise<WorkThreadType>;
  deleteLikeThread: (workId: number, threadId: number) => Promise<WorkThreadType>;
  postLikeNote: (token: string, noteId: string) => Promise<NoteType>;
  deleteLikeNote: (token: string, noteId: string) => Promise<NoteType>;
  follow: (token: string, userId: string) => Promise<UserType>;
  unfollow: (token: string, userId: string) => Promise<UserType>;
  postComplete: (token: string, requestId: number) => Promise<EmptyResponseType>;
  deleteComplete: (token: string, requestId: number) => Promise<EmptyResponseType>;
  deleteRequest: (token: string, requestId: number) => Promise<EmptyResponseType>;
  saveRequestBoxSettings: (
    token: string,
    isOpened: boolean,
    text: string,
  ) => Promise<EmptyResponseType>;
  sendRequest: (token: string, userId: number, text: string) => Promise<EmptyResponseType>;
  fetchRequestBoxSettings: (token: string) => Promise<RequestBoxSettingsType>;
  fetchUserEventDetail: (userEventId: string) => Promise<UserEventType>;
  fetchOdaiDetail: (odaiTag: string) => Promise<OdaiType>;
  fetchOfficialEventDetail: (officialEventTag: string) => Promise<OfficialEventType>;
  fetchOfficialEventResult: (
    token: string,
    officialEventTag: string,
  ) => Promise<OfficialEventResultType>;
  postWorkThread: (
    workId: string,
    parentId: string,
    text: string,
  ) => Promise<WorkThreadResponseType>;
  deleteWorkThread: (workId: string, threadId: string) => Promise<WorkType>;
  postMakingLike: (token: string, makingSlug: string) => Promise<EmptyResponseType>;
  postProfileImage: (token: string, file: File) => Promise<EmptyResponseType>;
  postHeaderImage: (token: string, file: File) => Promise<EmptyResponseType>;
  postName: (token: string, newName: string) => Promise<EmptyResponseType>;
  postAccount: (token: string, newAccount: string) => Promise<EmptyResponseType>;
  postUrl: (token: string, newUrl: string) => Promise<EmptyResponseType>;
  postText: (token: string, newText: string) => Promise<EmptyResponseType>;
  postWork: (
    token: string,
    title: string,
    text: string,
    image: File,
    colors: string,
    tags: string[],
    allowThread: boolean,
    odaiId: string | null,
    userEventId: string | null,
    officialEventId: string | null,
    rect: Rect | undefined,
  ) => Promise<EmptyResponseType>;
  postEditImage: (
    workId: string,
    colors: string,
    rect: Rect | undefined,
    image: File,
  ) => Promise<WorkType>;
  postEditCropRect: (workId: string, rect: Rect) => Promise<WorkType>;
  postEditAllowThread: (
    token: string,
    workId: string,
    allowThread: boolean,
  ) => Promise<EmptyResponseType>;
  postEditTitle: (token: string, workId: string, title: string) => Promise<EmptyResponseType>;
  postEditText: (token: string, workId: string, text: string) => Promise<EmptyResponseType>;
  postEditTags: (token: string, workId: string, tags: string[]) => Promise<EmptyResponseType>;
  deleteWork: (workId: string) => Promise<EmptyResponseType>;
  postFeedback: (message: string) => Promise<EmptyResponseType>;
  getBaseUrl: string;
}

export class DotpictClient implements IDotpictClient {
  static formatDateTimeString = (unixTime: number): string =>
    dayjs(unixTime * 1000).format('YYYY/MM/DD HH:mm:ss');

  static formatDateString = (unixTime: number): string =>
    dayjs(unixTime * 1000).format('YYYY/MM/DD');

  private readonly baseUrl: string;

  private readonly language: string;

  private readonly translator: TranslatorType;

  constructor(baseUrl: string, language: LanguageType, translator: TranslatorType) {
    this.baseUrl = `${baseUrl}`;
    this.language = language;
    this.translator = translator;
  }

  get getBaseUrl(): string {
    return this.baseUrl;
  }

  get buildCommonHeader(): { [key: string]: string } {
    const now = dayjs().unix();
    const currentTime = Math.floor(now / 60) * 60;
    return {
      'X-TOKEN1': md5(`aS!e$M${currentTime}`),
      'X-TOKEN2': md5(`UwY@a3${currentTime}`),
      'Accept-Language': this.language,
    };
  }

  static convertPalette = (json: any): PaletteType => ({
    id: json.id,
    title: json.title,
    text: json.text,
    colorCodes: json.color_codes.map((colorCode: ColorCodeType) =>
      rgbToHex(colorCode.red, colorCode.green, colorCode.blue),
    ),
    imageUrl: json.image_url,
  });

  static convertWork = (json: any): WorkType => ({
    id: json.id,
    title: json.title,
    caption: json.text,
    tags: json.tags,
    likeCount: json.like_count,
    repictCount: json.repict_count,
    threadCount: json.thread_count,
    thumbnailImageUrl: json.thumbnail_image_url,
    imageUrl: json.image_url,
    ogpImageUrl: json.ogp_image_url,
    width: json.width,
    height: json.height,
    user: convertArrayToUser(json.user),
    isLiked: json.is_like,
    isRepicted: json.is_repicted,
    createdAt: DotpictClient.formatDateTimeString(json.created_at),
    allowThread: json.allow_thread,
    isAnimation: json.is_animation,
    userEventId: json.user_event_id,
    userEvent: json.user_event === null ? null : DotpictClient.convertUserEvent(json.user_event),
    officialEventId: json.official_event_id,
    officialEvent:
      json.official_event === null ? null : convertArrayToOfficialEvent(json.official_event),
    odaiId: json.odai_id,
    odai: json.odai === null ? null : convertArrayToOdai(json.odai),
    shareUrl: json.share_url,
    colorCodes: json.color_codes.map((colorCode: ColorCodeType) =>
      rgbToHex(colorCode.red, colorCode.green, colorCode.blue),
    ),
    cropRect:
      json.crop_rect === null
        ? null
        : {
            x: json.crop_rect.x,
            y: json.crop_rect.y,
            width: json.crop_rect.width,
            height: json.crop_rect.height,
          },
  });

  static convertNotification = (json: any): NotificationType => ({
    id: json.id,
    imageUrl: json.image_url,
    title: json.title,
    message: json.message,
    url: json.url,
    createdAt: DotpictClient.formatDateTimeString(json.created_at),
  });

  static convertRequest = (json: any): RequestType => ({
    id: json.id,
    sentUser: convertArrayToUser(json.sent_user),
    text: json.text,
    isCompleted: json.is_completed,
    createdAt: DotpictClient.formatDateTimeString(json.created_at),
  });

  static convertTemplateResult = (json: any): TemplateResultResponseType => ({
    title: json.data.title,
    ogpImageUrl: json.data.ogp_image_url,
    imageUrl: json.data.image_url,
    description: json.data.description,
    works: json.data.works.map((workJson: any) => DotpictClient.convertWork(workJson)),
    nextUrl: json.data.next_url,
  });

  static convertError = (json: any): ErrorResponseType => ({
    message: json.message,
    code: json.code,
  });

  static convertThread = (json: any): WorkThreadType => ({
    id: json.id,
    parentId: json.parent_id,
    workId: json.work_id,
    user: convertArrayToUser(json.user),
    text: json.text,
    threadCount: json.thread_count,
    isLiked: json.is_liked,
    likeCount: json.like_count,
    likedProfileImageUrl: json.liked_profile_image_url,
    isLikedByAuthor: json.is_liked_by_author,
    createdAt: DotpictClient.formatDateTimeString(json.created_at),
  });

  static convertUserEvent = (json: any): UserEventType => ({
    id: json.id,
    user: convertArrayToUser(json.user),
    title: json.title,
    text: json.text,
    tag: json.tag,
    imageUrl: json.image_url,
    width: json.width,
    height: json.height,
    createdAt: DotpictClient.formatDateTimeString(json.created_at),
    templateCanvasColorCodes: json.template_canvas_color_codes.map((colorCode: ColorCodeType) =>
      rgbToHex(colorCode.red, colorCode.green, colorCode.blue),
    ),
    templateCanvasImageUrl: json.template_canvas_image_url,
  });

  static convertRequestBoxSettings = (json: any): RequestBoxSettingsType => ({
    isOpened: json.is_opened,
    text: json.text,
  });

  static convertArticleComponent = (json: any): ArticleComponentType => {
    switch (json.type) {
      case 'space':
        return {
          kind: 'space',
          size: json.space_component.size,
        };
      case 'internal_link':
        return {
          kind: 'internal_link',
          text: json.internal_link_component.text,
          path: json.internal_link_component.path,
        };
      case 'external_link':
        return {
          kind: 'external_link',
          text: json.external_link_component.text,
          path: json.external_link_component.path,
        };
      case 'image':
        return {
          kind: 'image',
          imageUrl: json.image_component.image_url,
          width: json.image_component.width,
          height: json.image_component.height,
        };
      case 'pixelart':
        return {
          kind: 'pixelart',
          imageUrl: json.pixelart_component.image_url,
          width: json.pixelart_component.width,
          height: json.pixelart_component.height,
        };
      case 'button':
        return {
          kind: 'button',
          text: json.button_component.text,
          width: json.button_component.width,
          height: json.button_component.height,
          url: json.button_component.url,
        };
      default:
        return {
          kind: 'text',
          text: json.text_component.text,
          isBold: json.text_component.is_bold,
        };
    }
  };

  static convertMaking = (json: any): MakingType => ({
    key: json.slug,
    userId: json.user_id,
    mainImage: json.main_image_url,
    mainImageSize: json.main_image_size,
    ogpImage: json.ogp_image_url,
    authorName: json.author_name,
    profileImage: json.profile_image_url,
    profileTexts: json.profile_texts,
    links: json.links.map((link: any) => ({ serviceName: link.service_name, url: link.url })),
    theme: json.theme,
    tableOfContents: json.table_of_contents,
    contents: json.contents.map((content: any) => {
      switch (content.type) {
        case 'headline':
          return { type: MakingContentEnum.HEADLINE, content: content.content };
        case 'text':
          return { type: MakingContentEnum.TEXT, content: content.content };
        case 'pixelart':
          return {
            type: MakingContentEnum.PIXELART,
            content: content.content,
            width: content.width ?? 320,
            height: content.height ?? 320,
          };
        case 'image':
          return {
            type: MakingContentEnum.IMAGE,
            content: content.content,
            width: content.width ?? 320,
            height: content.height ?? 320,
          };
        case 'space':
          return { type: MakingContentEnum.SPACE, content: content.content };
        default:
          return { type: MakingContentEnum.TEXT, content: '' };
      }
    }),
  });

  public fetchMaking = async (token: string, slug: string): Promise<MakingType> => {
    const options = {
      headers: new Headers({ Authorization: token, ...this.buildCommonHeader }),
    };
    const json = await fetch(`${this.baseUrl}/makings/${slug}`, options).then((res) => res.json());
    return DotpictClient.convertMaking(json.data.making);
  };

  public fetchMakings = async (token: string): Promise<MakingType[]> => {
    const options = {
      headers: new Headers({ Authorization: token, ...this.buildCommonHeader }),
    };
    const json = await fetch(`${this.baseUrl}/makings`, options).then((res) => res.json());
    return json.data.makings.map((making: any) => DotpictClient.convertMaking(making));
  };

  public fetchWorkDetail = async (workId: number): Promise<WorkDetailResponseType> => {
    const token = await dotpictFirebaseAuth.currentUser?.getIdToken();
    const options = {
      headers: new Headers(
        token === undefined
          ? this.buildCommonHeader
          : { Authorization: token, ...this.buildCommonHeader },
      ),
    };
    const json = await fetch(`${this.baseUrl}/works/${workId}/detail?work_count=6`, options).then(
      (res) => res.json(),
    );
    return {
      work: DotpictClient.convertWork(json.data.work),
      userSummary: {
        user: convertArrayToUser(json.data.user_summary.user),
        works: json.data.user_summary.works.map((workJson: any) =>
          DotpictClient.convertWork(workJson),
        ),
      },
      recommendedWorks: json.data.works.map((workJson: any) => DotpictClient.convertWork(workJson)),
    };
  };

  public fetchUserWorks = async (paging: PagingType): Promise<UserWorksResponseType> => {
    const token = await dotpictFirebaseAuth.currentUser?.getIdToken();
    const options = {
      headers: new Headers(
        token === undefined
          ? this.buildCommonHeader
          : { Authorization: token, ...this.buildCommonHeader },
      ),
    };
    const json = await fetch(paging.nextUrl, options).then((res) => res.json());
    const user: UserType = convertArrayToUser(json.data.user);
    const works: WorkType[] = json.data.works.map((workJson: any) =>
      DotpictClient.convertWork(workJson),
    );
    return {
      works,
      user,
      nextUrl: json.data.next_url,
    };
  };

  // eslint-disable-next-line class-methods-use-this
  public fetchTemplateResult = async (
    token: string,
    nextUrl: string,
  ): Promise<TemplateResultResponseType> => {
    const options = {
      headers: new Headers({ Authorization: token, ...this.buildCommonHeader }),
    };
    const json = await fetch(nextUrl, options).then((res) => res.json());
    return DotpictClient.convertTemplateResult(json);
  };

  public fetchMe = async (token: string): Promise<MeResponseType> => {
    const options = {
      headers: new Headers({ Authorization: token, ...this.buildCommonHeader }),
    };
    const json = await fetch(`${this.baseUrl}/me`, options).then((res) => res.json());
    const user: UserType = convertArrayToUser(json.data.user);
    return {
      user,
    };
  };

  // eslint-disable-next-line class-methods-use-this
  public fetchWorks = async (nextUrl: string): Promise<WorksResponseType> => {
    const token = await dotpictFirebaseAuth.currentUser?.getIdToken();
    const options = {
      headers: new Headers(
        token === undefined
          ? this.buildCommonHeader
          : { Authorization: token, ...this.buildCommonHeader },
      ),
    };
    const json = await fetch(nextUrl, options).then((res) => res.json());
    const works: WorkType[] = json.data.works.map((workJson: any) =>
      DotpictClient.convertWork(workJson),
    );
    return {
      works,
      nextUrl: json.data.next_url,
    };
  };

  public fetchTimeline = async (nextUrl: string): Promise<TimelineResponseType> => {
    const token = await dotpictFirebaseAuth.currentUser?.getIdToken();
    const options = {
      headers: new Headers(
        token === undefined
          ? this.buildCommonHeader
          : { Authorization: token, ...this.buildCommonHeader },
      ),
    };
    const json = await fetch(nextUrl, options).then((res) => res.json());
    const timeline: TimelineItemType[] = json.data.timeline.map((timelineJson: any) => ({
      type: timelineJson.type,
      info:
        timelineJson.info === null
          ? null
          : {
              type: timelineJson.info.type,
              iconImageUrl: timelineJson.info.icon_image_url,
              title: timelineJson.info.title,
              text: timelineJson.info.text,
              user:
                timelineJson.info.user === null ? null : convertArrayToUser(timelineJson.info.user),
            },
      work: timelineJson.work === null ? null : DotpictClient.convertWork(timelineJson.work),
      note: timelineJson.note === null ? null : convertArrayToNote(timelineJson.note),
    }));
    return {
      timeline,
      nextUrl: json.data.next_url,
    };
  };

  // eslint-disable-next-line class-methods-use-this
  public fetchNotifications = async (
    token: string,
    nextUrl: string,
  ): Promise<NotificationsResponseType> => {
    const options = {
      headers: new Headers({ Authorization: token, ...this.buildCommonHeader }),
    };
    const json = await fetch(nextUrl, options).then((res) => res.json());
    const notifications: NotificationType[] = json.data.notifications.map((notificationJson: any) =>
      DotpictClient.convertNotification(notificationJson),
    );
    return {
      notifications,
      nextUrl: json.data.next_url,
    };
  };

  // eslint-disable-next-line class-methods-use-this
  public fetchWorkThreads = async (workId: string): Promise<WorkThreadsResponseType> => {
    const token = await dotpictFirebaseAuth.currentUser?.getIdToken();
    const options = {
      headers: new Headers(
        token === undefined
          ? this.buildCommonHeader
          : { Authorization: token, ...this.buildCommonHeader },
      ),
    };
    const json = await fetch(`${this.baseUrl}/works/${workId}/threads`, options).then((res) =>
      res.json(),
    );
    const threads: WorkThreadType[] = json.data.work_threads.map((threadJson: any) =>
      DotpictClient.convertThread(threadJson),
    );
    return {
      work: DotpictClient.convertWork(json.data.work),
      workThreads: threads,
    };
  };

  // eslint-disable-next-line class-methods-use-this
  public fetchWorkReplies = async (
    workId: string,
    threadId: string,
  ): Promise<WorkRepliesResponseType> => {
    const token = await dotpictFirebaseAuth.currentUser?.getIdToken();
    const options = {
      headers: new Headers(
        token === undefined
          ? this.buildCommonHeader
          : { Authorization: token, ...this.buildCommonHeader },
      ),
    };
    const json = await fetch(`${this.baseUrl}/works/${workId}/threads/${threadId}`, options).then(
      (res) => res.json(),
    );
    const threads: WorkThreadType[] = json.data.work_threads.map((threadJson: any) =>
      DotpictClient.convertThread(threadJson),
    );
    return {
      work: DotpictClient.convertWork(json.data.work),
      parentThread: DotpictClient.convertThread(json.data.work_thread),
      workThreads: threads,
    };
  };

  // eslint-disable-next-line class-methods-use-this
  public fetchChildNotes = async (parentNoteId: string): Promise<ChildNotesResponseType> => {
    const token = await dotpictFirebaseAuth.currentUser?.getIdToken();
    const options = {
      headers: new Headers(
        token === undefined
          ? this.buildCommonHeader
          : { Authorization: token, ...this.buildCommonHeader },
      ),
    };
    const json = await fetch(`${this.baseUrl}/notes/${parentNoteId}/child_notes`, options).then(
      (res) => res.json(),
    );
    return {
      parentNote: convertArrayToNote(json.data.note),
      notes: json.data.notes.map((noteJson: any) => convertArrayToNote(noteJson)),
    };
  };

  // eslint-disable-next-line class-methods-use-this
  public fetchRequests = async (token: string, nextUrl: string): Promise<RequestsResponse> => {
    const options = {
      headers: new Headers({ Authorization: token, ...this.buildCommonHeader }),
    };
    const json = await fetch(nextUrl, options).then((res) => res.json());
    const requests: RequestType[] = json.data.requests.map((requestJson: any) =>
      DotpictClient.convertRequest(requestJson),
    );
    return {
      requests,
      nextUrl: json.data.next_url,
    };
  };

  public createUsers = async (token: string, recaptcha: string): Promise<UserType> => {
    const formData = new FormData();
    formData.append('token', token);
    formData.append('recaptcha', recaptcha);
    const options = {
      method: 'POST',
      body: formData,
      headers: new Headers({ ...this.buildCommonHeader }),
    };
    const json = await fetch(`${this.baseUrl}/users/create`, options)
      .then((res) => res.json())
      .catch((error) => Promise.reject(DotpictClient.convertError(error.json())));
    return convertArrayToUser(json.data.user);
  };

  public fetchUsers = async (nextUrl: string): Promise<UserSummariesResponseType> => {
    const token = await dotpictFirebaseAuth.currentUser?.getIdToken();
    const options = {
      headers: new Headers(
        token === undefined
          ? this.buildCommonHeader
          : { Authorization: token, ...this.buildCommonHeader },
      ),
    };
    const json = await fetch(nextUrl, options).then((res) => res.json());
    const userSummaries: UserSummaryType[] = json.data.user_summaries.map(
      (userSummaryJson: any) => ({
        user: convertArrayToUser(userSummaryJson.user),
        works: userSummaryJson.works.map((workJson: any) => DotpictClient.convertWork(workJson)),
      }),
    );
    return {
      userSummaries,
      nextUrl: json.data.next_url,
    };
  };

  public fetchOfficialPalettes = async (
    token: string,
    nextUrl: string,
  ): Promise<PalettesResponseType> => {
    const options = {
      headers: new Headers({ Authorization: token, ...this.buildCommonHeader }),
    };
    const json = await fetch(nextUrl, options)
      .then((res) => res.json())
      .catch((error) => Promise.reject(DotpictClient.convertError(error.json())));
    return {
      palettes: json.data.official_palettes.map((json: any) => DotpictClient.convertPalette(json)),
      nextUrl: json.data.next_url,
    };
  };

  public fetchUsersByAccount = async (account: string): Promise<UserType> => {
    const token = await dotpictFirebaseAuth.currentUser?.getIdToken();
    const options = {
      headers: new Headers(
        token === undefined
          ? this.buildCommonHeader
          : { Authorization: token, ...this.buildCommonHeader },
      ),
    };
    const json = await fetch(`${this.getBaseUrl}/users/@${account}`, options)
      .then((res) => res.json())
      .catch((error) => Promise.reject(DotpictClient.convertError(error.json())));

    return convertArrayToUser(json.data.user);
  };

  public fetchArticle = async (
    token: string,
    lang: string,
    articleId: string,
  ): Promise<IArticleResponse> => {
    const options = {
      headers: new Headers({
        Authorization: token,
        ...this.buildCommonHeader,
        'Accept-Language': lang,
      }),
    };
    const json = await fetch(`${this.getBaseUrl}/articles/${articleId}`, options).then((res) =>
      res.json(),
    );
    return {
      id: articleId,
      title: json.data.article.title,
      components: json.data.article.components.map((componentJson: any) =>
        DotpictClient.convertArticleComponent(componentJson),
      ),
    };
  };

  public fetchNeta = async (token: string): Promise<NetaResponseType> => {
    const options = {
      headers: new Headers({ Authorization: token, ...this.buildCommonHeader }),
    };
    const json = await fetch(`${this.getBaseUrl}/neta`, options)
      .then((res) => res.json())
      .catch((error) => Promise.reject(DotpictClient.convertError(error.json())));

    const officialEvent =
      json.data.neta.official_event === null
        ? defaultOfficialEvent
        : convertArrayToOfficialEvent(json.data.neta.official_event);
    const odai = convertArrayToOdai(json.data.neta.odai);
    return { officialEvent, odai };
  };

  public postLike = async (token: string, workId: string): Promise<WorkType> => {
    const options = {
      method: 'POST',
      headers: new Headers({ Authorization: token, ...this.buildCommonHeader }),
    };
    const json = await fetch(`${this.baseUrl}/works/${workId}/like`, options).then((res) =>
      res.json(),
    );
    return DotpictClient.convertWork(json.data.work);
  };

  public deleteLike = async (token: string, workId: string): Promise<WorkType> => {
    const options = {
      method: 'DELETE',
      headers: new Headers({ Authorization: token, ...this.buildCommonHeader }),
    };
    const json = await fetch(`${this.baseUrl}/works/${workId}/like`, options).then((res) =>
      res.json(),
    );
    return DotpictClient.convertWork(json.data.work);
  };

  public postRepict = async (token: string, workId: string): Promise<WorkType> => {
    const options = {
      method: 'POST',
      headers: new Headers({ Authorization: token, ...this.buildCommonHeader }),
    };
    const json = await fetch(`${this.baseUrl}/works/${workId}/repict`, options).then((res) =>
      res.json(),
    );
    return DotpictClient.convertWork(json.data.work);
  };

  public deleteRepict = async (token: string, workId: string): Promise<WorkType> => {
    const options = {
      method: 'DELETE',
      headers: new Headers({ Authorization: token, ...this.buildCommonHeader }),
    };
    const json = await fetch(`${this.baseUrl}/works/${workId}/repict`, options).then((res) =>
      res.json(),
    );
    return DotpictClient.convertWork(json.data.work);
  };

  public postLikeThread = async (workId: number, threadId: number): Promise<WorkThreadType> => {
    const token = await dotpictFirebaseAuth.currentUser?.getIdToken();
    if (token === undefined) return Promise.reject();
    const options = {
      method: 'POST',
      headers: new Headers({ Authorization: token, ...this.buildCommonHeader }),
    };
    const json = await fetch(
      `${this.baseUrl}/works/${workId}/threads/${threadId}/like`,
      options,
    ).then((res) => res.json());
    return DotpictClient.convertThread(json.data.work_thread);
  };

  public deleteLikeThread = async (workId: number, threadId: number): Promise<WorkThreadType> => {
    const token = await dotpictFirebaseAuth.currentUser?.getIdToken();
    if (token === undefined) return Promise.reject();
    const options = {
      method: 'DELETE',
      headers: new Headers({ Authorization: token, ...this.buildCommonHeader }),
    };
    const json = await fetch(
      `${this.baseUrl}/works/${workId}/threads/${threadId}/like`,
      options,
    ).then((res) => res.json());
    return DotpictClient.convertThread(json.data.work_thread);
  };

  public postLikeNote = async (token: string, noteId: string): Promise<NoteType> => {
    const options = {
      method: 'POST',
      headers: new Headers({ Authorization: token, ...this.buildCommonHeader }),
    };
    const json = await fetch(`${this.baseUrl}/notes/${noteId}/like`, options).then((res) =>
      res.json(),
    );
    return convertArrayToNote(json.data.note);
  };

  public deleteLikeNote = async (token: string, noteId: string): Promise<NoteType> => {
    const options = {
      method: 'DELETE',
      headers: new Headers({ Authorization: token, ...this.buildCommonHeader }),
    };
    const json = await fetch(`${this.baseUrl}/notes/${noteId}/like`, options).then((res) =>
      res.json(),
    );
    return convertArrayToNote(json.data.note);
  };

  public follow = async (token: string, userId: string): Promise<UserType> => {
    const options = {
      method: 'POST',
      headers: new Headers({ Authorization: token, ...this.buildCommonHeader }),
    };
    const json = await fetch(`${this.baseUrl}/users/${userId}/follow`, options).then((res) =>
      res.json(),
    );
    return convertArrayToUser(json.data.user);
  };

  public unfollow = async (token: string, userId: string): Promise<UserType> => {
    const options = {
      method: 'DELETE',
      headers: new Headers({ Authorization: token, ...this.buildCommonHeader }),
    };
    const json = await fetch(`${this.baseUrl}/users/${userId}/follow`, options).then((res) =>
      res.json(),
    );
    return convertArrayToUser(json.data.user);
  };

  public postComplete = async (token: string, requestId: number): Promise<EmptyResponseType> => {
    const options = {
      method: 'POST',
      headers: new Headers({ Authorization: token, ...this.buildCommonHeader }),
    };
    await fetch(`${this.baseUrl}/me/requests/${requestId}/complete`, options);
    return {};
  };

  public deleteComplete = async (token: string, requestId: number): Promise<EmptyResponseType> => {
    const options = {
      method: 'DELETE',
      headers: new Headers({ Authorization: token, ...this.buildCommonHeader }),
    };
    await fetch(`${this.baseUrl}/me/requests/${requestId}/complete`, options);
    return {};
  };

  public deleteRequest = async (token: string, requestId: number): Promise<EmptyResponseType> => {
    const options = {
      method: 'DELETE',
      headers: new Headers({ Authorization: token, ...this.buildCommonHeader }),
    };
    await fetch(`${this.baseUrl}/me/requests/${requestId}`, options);
    return {};
  };

  public saveRequestBoxSettings = async (
    token: string,
    isOpened: boolean,
    text: string,
  ): Promise<EmptyResponseType> => {
    const formDataForIsOpen = new FormData();
    formDataForIsOpen.append('is_opened', isOpened.toString());
    const optionsForIsOpen = {
      method: 'POST',
      headers: new Headers({ Authorization: token, ...this.buildCommonHeader }),
      body: formDataForIsOpen,
    };
    await fetch(`${this.baseUrl}/me/request_box/edit_is_opened`, optionsForIsOpen);

    const formDataForText = new FormData();
    formDataForText.append('text', text);
    const optionsForText = {
      method: 'POST',
      headers: new Headers({ Authorization: token, ...this.buildCommonHeader }),
      body: formDataForText,
    };
    await fetch(`${this.baseUrl}/me/request_box/edit_text`, optionsForText);
    return {};
  };

  public sendRequest = async (
    token: string,
    userId: number,
    text: string,
  ): Promise<EmptyResponseType> => {
    const formData = new FormData();
    formData.append('text', text);
    const options = {
      method: 'POST',
      headers: new Headers({ Authorization: token, ...this.buildCommonHeader }),
      body: formData,
    };
    await fetch(`${this.baseUrl}/users/${userId}/request`, options).then((res) => res.json());
    return {};
  };

  public fetchRequestBoxSettings = async (token: string): Promise<RequestBoxSettingsType> => {
    const options = {
      headers: new Headers({ Authorization: token, ...this.buildCommonHeader }),
    };
    const json = await fetch(`${this.getBaseUrl}/me/request_box_settings`, options)
      .then(async (res) => {
        if (!res.ok) {
          throw Error(DotpictClient.convertError(await res.json()).message);
        }
        return res.json();
      })
      .catch((err) => Promise.reject(err));
    return DotpictClient.convertRequestBoxSettings(json.data.request_box_settings);
  };

  public fetchUserEventDetail = async (userEventId: string): Promise<UserEventType> => {
    const token = await dotpictFirebaseAuth.currentUser?.getIdToken();
    const options = {
      headers: new Headers(
        token === undefined
          ? this.buildCommonHeader
          : { Authorization: token, ...this.buildCommonHeader },
      ),
    };
    const json = await fetch(`${this.getBaseUrl}/user_events/${userEventId}`, options)
      .then(async (res) => {
        if (!res.ok) {
          throw Error(DotpictClient.convertError(await res.json()).message);
        }
        return res.json();
      })
      .catch((err) => Promise.reject(err));
    return DotpictClient.convertUserEvent(json.data.user_event);
  };

  public fetchOdaiDetail = async (odaiTag: string): Promise<OdaiType> => {
    const token = await dotpictFirebaseAuth.currentUser?.getIdToken();
    const options = {
      headers: new Headers(
        token === undefined
          ? this.buildCommonHeader
          : { Authorization: token, ...this.buildCommonHeader },
      ),
    };
    const json = await fetch(`${this.getBaseUrl}/odais/${odaiTag}`, options)
      .then(async (res) => {
        if (!res.ok) {
          throw Error(DotpictClient.convertError(await res.json()).message);
        }
        return res.json();
      })
      .catch((err) => Promise.reject(err));
    return convertArrayToOdai(json.data.odai);
  };

  public fetchOfficialEventDetail = async (
    officialEventTag: string,
  ): Promise<OfficialEventType> => {
    const token = await dotpictFirebaseAuth.currentUser?.getIdToken();
    const options = {
      headers: new Headers(
        token === undefined
          ? this.buildCommonHeader
          : { Authorization: token, ...this.buildCommonHeader },
      ),
    };
    const json = await fetch(`${this.getBaseUrl}/official_events/${officialEventTag}`, options)
      .then(async (res) => {
        if (!res.ok) {
          throw Error(DotpictClient.convertError(await res.json()).message);
        }
        return res.json();
      })
      .catch((err) => Promise.reject(err));
    return convertArrayToOfficialEvent(json.data.official_event);
  };

  public fetchOfficialEventResult = async (
    token: string,
    officialEventTag: string,
  ): Promise<OfficialEventResultType> => {
    const options = {
      headers: new Headers({ Authorization: token, ...this.buildCommonHeader }),
    };
    const json = await fetch(
      `${this.getBaseUrl}/official_events/${officialEventTag}/result`,
      options,
    )
      .then(async (res) => {
        if (!res.ok) {
          throw Error(DotpictClient.convertError(await res.json()).message);
        }
        return res.json();
      })
      .catch((err) => Promise.reject(err));
    return {
      officialEvent: convertArrayToOfficialEvent(json.data.official_event_result.official_event),
      teamOneWorks: json.data.official_event_result.team_one_works.map((workJson: any) =>
        DotpictClient.convertWork(workJson),
      ),
      teamTwoWorks: json.data.official_event_result.team_two_works.map((workJson: any) =>
        DotpictClient.convertWork(workJson),
      ),
      description: json.data.official_event_result.description,
      closingText: json.data.official_event_result.closing_text,
      shareText: json.data.official_event_result.share_text,
      shareUrl: json.data.official_event_result.share_url,
      ogpImageUrl: json.data.official_event_result.ogp_image_url,
      bannerImageUrl: json.data.official_event_result.banner_image_url,
    };
  };

  public postWorkThread = async (
    workId: string,
    parentId: string,
    text: string,
  ): Promise<WorkThreadResponseType> => {
    const token = await dotpictFirebaseAuth.currentUser?.getIdToken();
    if (!token) throw Error();
    const formData = new FormData();
    formData.append('text', text);
    formData.append('parent_thread_id', parentId);
    const options = {
      method: 'POST',
      headers: new Headers({ Authorization: token, ...this.buildCommonHeader }),
      body: formData,
    };
    const json = await fetch(`${this.baseUrl}/works/${workId}/thread`, options)
      .then(async (res) => {
        if (!res.ok) {
          throw Error(DotpictClient.convertError(await res.json()).message);
        }
        return res.json();
      })
      .catch((err) => Promise.reject(err));
    return {
      work: DotpictClient.convertWork(json.data.work),
      thread: DotpictClient.convertThread(json.data.work_thread),
    };
  };

  public deleteWorkThread = async (workId: string, threadId: string): Promise<WorkType> => {
    const token = await dotpictFirebaseAuth.currentUser?.getIdToken();
    if (!token) throw Error();
    const options = {
      method: 'DELETE',
      headers: new Headers({ Authorization: token, ...this.buildCommonHeader }),
    };
    const json = await fetch(`${this.baseUrl}/works/${workId}/threads/${threadId}`, options).then(
      (res) => res.json(),
    );
    return DotpictClient.convertWork(json.data.work);
  };

  public postMakingLike = async (token: string, makingSlug: string): Promise<EmptyResponseType> => {
    const options = {
      method: 'POST',
      headers: new Headers({ Authorization: token, ...this.buildCommonHeader }),
    };
    await fetch(`${this.baseUrl}/makings/${makingSlug}/like`, options);
    return {};
  };

  public postProfileImage = async (token: string, file: File): Promise<EmptyResponseType> => {
    const formData = new FormData();
    formData.append('image', file);
    const options = {
      method: 'POST',
      headers: new Headers({ Authorization: token, ...this.buildCommonHeader }),
      body: formData,
    };
    await fetch(`${this.baseUrl}/me/edit_profile_image`, options);
    return {};
  };

  public postHeaderImage = async (token: string, file: File): Promise<EmptyResponseType> => {
    const formData = new FormData();
    formData.append('image', file);
    const options = {
      method: 'POST',
      headers: new Headers({ Authorization: token, ...this.buildCommonHeader }),
      body: formData,
    };
    await fetch(`${this.baseUrl}/me/edit_header_image`, options);
    return {};
  };

  public postName = async (token: string, newName: string): Promise<EmptyResponseType> => {
    const formData = new FormData();
    formData.append('name', newName);
    const options = {
      method: 'POST',
      headers: new Headers({ Authorization: token, ...this.buildCommonHeader }),
      body: formData,
    };
    await fetch(`${this.baseUrl}/me/edit_name`, options);
    return {};
  };

  public postAccount = async (token: string, newAccount: string): Promise<EmptyResponseType> => {
    const formData = new FormData();
    formData.append('account', newAccount);
    const options = {
      method: 'POST',
      headers: new Headers({ Authorization: token, ...this.buildCommonHeader }),
      body: formData,
    };
    await fetch(`${this.baseUrl}/me/edit_account`, options)
      .then(async (res) => {
        if (!res.ok) {
          throw Error(DotpictClient.convertError(await res.json()).message);
        }
        return res.json();
      })
      .catch((err) => Promise.reject(err));
    return {};
  };

  public postUrl = async (token: string, newUrl: string): Promise<EmptyResponseType> => {
    const formData = new FormData();
    formData.append('url', newUrl);
    const options = {
      method: 'POST',
      headers: new Headers({ Authorization: token, ...this.buildCommonHeader }),
      body: formData,
    };
    await fetch(`${this.baseUrl}/me/edit_url`, options);
    return {};
  };

  public postText = async (token: string, newText: string): Promise<EmptyResponseType> => {
    const formData = new FormData();
    formData.append('text', newText);
    const options = {
      method: 'POST',
      headers: new Headers({ Authorization: token, ...this.buildCommonHeader }),
      body: formData,
    };
    await fetch(`${this.baseUrl}/me/edit_text`, options);
    return {};
  };

  public postWork = async (
    token: string,
    title: string,
    text: string,
    image: File,
    colors: string,
    tags: string[],
    allowThread: boolean,
    odaiId: string | null,
    userEventId: string | null,
    officialEventId: string | null,
    rect: Rect | undefined,
  ): Promise<EmptyResponseType> => {
    const formData = new FormData();
    formData.append('title', title);
    formData.append('text', text);
    formData.append('image', image);
    formData.append('colors', colors);
    tags.forEach((tag) => {
      formData.append('tags[]', tag);
    });
    formData.append('allow_thread', allowThread.toString());
    formData.append('drawn_with_dotpict', false.toString());
    if (userEventId !== null) {
      formData.append('user_event_id', userEventId);
    }
    if (odaiId !== null) {
      formData.append('odai_id', odaiId);
    }
    if (officialEventId !== null) {
      formData.append('official_event_id', officialEventId);
    }
    if (rect) {
      formData.append('rect', `{"0":${rect.x},"1":${rect.y},"2":${rect.width},"3":${rect.height}}`);
    }
    const options = {
      method: 'POST',
      headers: new Headers({ Authorization: token, ...this.buildCommonHeader }),
      body: formData,
    };
    await fetch(`${this.baseUrl}/upload/work`, options)
      .then(async (response) => {
        if (!response.ok) {
          const json = await response.json();
          throw Error(json.message);
        }
        return response;
      })
      .catch((error) => Promise.reject(error.message));
    return {};
  };

  public postEditImage = async (
    workId: string,
    colors: string,
    rect: Rect | undefined,
    image: File,
  ): Promise<WorkType> => {
    const token = await dotpictFirebaseAuth.currentUser?.getIdToken();
    if (!token) throw Error();
    const formData = new FormData();
    formData.append('image', image);
    formData.append('colors', colors);
    if (rect) {
      formData.append('rect', JSON.stringify(rect));
    }
    const options = {
      method: 'POST',
      headers: new Headers({ Authorization: token, ...this.buildCommonHeader }),
      body: formData,
    };
    const json = await fetch(`${this.baseUrl}/works/${workId}/edit_image`, options)
      .then(async (res) => {
        if (!res.ok) {
          throw Error(DotpictClient.convertError(await res.json()).message);
        }
        return res.json();
      })
      .catch((err) => Promise.reject(err));
    return DotpictClient.convertWork(json.data.work);
  };

  public postEditCropRect = async (workId: string, rect: Rect): Promise<WorkType> => {
    const token = await dotpictFirebaseAuth.currentUser?.getIdToken();
    if (!token) throw Error();
    const formData = new FormData();
    formData.append('rect', JSON.stringify(rect));
    const options = {
      method: 'POST',
      headers: new Headers({ Authorization: token, ...this.buildCommonHeader }),
      body: formData,
    };
    const json = await fetch(`${this.baseUrl}/works/${workId}/edit_crop_rect`, options)
      .then(async (res) => {
        if (!res.ok) {
          throw Error(DotpictClient.convertError(await res.json()).message);
        }
        return res.json();
      })
      .catch((err) => Promise.reject(err));
    return DotpictClient.convertWork(json.data.work);
  };

  public postEditAllowThread = async (
    token: string,
    workId: string,
    allowThread: boolean,
  ): Promise<EmptyResponseType> => {
    const formData = new FormData();
    formData.append('allow_thread', allowThread.toString());
    const options = {
      method: 'POST',
      headers: new Headers({ Authorization: token, ...this.buildCommonHeader }),
      body: formData,
    };
    await fetch(`${this.baseUrl}/works/${workId}/edit_allow_thread`, options);
    return {};
  };

  public postEditTitle = async (
    token: string,
    workId: string,
    title: string,
  ): Promise<EmptyResponseType> => {
    const formData = new FormData();
    formData.append('title', title);
    const options = {
      method: 'POST',
      headers: new Headers({ Authorization: token, ...this.buildCommonHeader }),
      body: formData,
    };
    await fetch(`${this.baseUrl}/works/${workId}/edit_title`, options);
    return {};
  };

  public postEditText = async (
    token: string,
    workId: string,
    text: string,
  ): Promise<EmptyResponseType> => {
    const formData = new FormData();
    formData.append('text', text);
    const options = {
      method: 'POST',
      headers: new Headers({ Authorization: token, ...this.buildCommonHeader }),
      body: formData,
    };
    await fetch(`${this.baseUrl}/works/${workId}/edit_text`, options);
    return {};
  };

  public postEditTags = async (
    token: string,
    workId: string,
    tags: string[],
  ): Promise<EmptyResponseType> => {
    const formData = new FormData();
    tags.forEach((tag) => {
      formData.append('tags[]', tag);
    });
    const options = {
      method: 'POST',
      headers: new Headers({ Authorization: token, ...this.buildCommonHeader }),
      body: formData,
    };
    await fetch(`${this.baseUrl}/works/${workId}/edit_tags`, options);
    return {};
  };

  public deleteWork = async (workId: string): Promise<EmptyResponseType> => {
    const token = await dotpictFirebaseAuth.currentUser?.getIdToken();
    if (!token) {
      throw Error(this.translator(TranslationKeys.PleaseLogin));
    }
    const options = {
      method: 'DELETE',
      headers: new Headers({ Authorization: token, ...this.buildCommonHeader }),
    };
    await fetch(`${this.baseUrl}/works/${workId}`, options);
    return {};
  };

  public postFeedback = async (message: string): Promise<EmptyResponseType> => {
    const token = await dotpictFirebaseAuth.currentUser?.getIdToken();
    if (token === undefined) return Promise.reject();
    const formData = new FormData();
    formData.append('message', message);
    const options = {
      method: 'POST',
      headers: new Headers({ Authorization: token, ...this.buildCommonHeader }),
      body: formData,
    };
    await fetch(`${this.baseUrl}/feedback`, options)
      .then(async (res) => {
        if (!res.ok) {
          throw Error(DotpictClient.convertError(await res.json()).message);
        }
        return res.json();
      })
      .catch((err) => Promise.reject(err));
    return {};
  };
}
