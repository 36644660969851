import { createContext, useContext, useEffect, useLayoutEffect } from 'react';
import { GlobalContext, LayoutContext } from '@/Routing';
import shouldFetchNext from '@/app/common/common';
import {
  defaultOfficialEventDetailState,
  OfficialEventDetailStateType,
} from '@/app/state/OfficialEventDetailStateType';
import { useNavigate } from 'react-router-dom';
import { useCreateCanvasData } from '@/app/component/page/draw/hooks/storage';
import useAnalytics from '@/app/hook/useAnalytics';
import rgbToHex from '@dotpict-lib/util/rgbToHex';
import TranslationKeys from '@/app/translation/TranslationKeys';
import useTranslator from '@/app/hook/useTranslator';
import { GetWorksService } from '@/app/service/GetWorks/GetWorksService';
import dummyGetWorksService from '@/app/service/GetWorks/dummyGetWorksService';
import { GetOfficialEventService } from '@/app/service/GetOfficialEvent/GetOfficialEventService';
import dummyGetOfficialEventJuneBrideService from '@/app/service/GetOfficialEvent/dummyGetOfficialEventJuneBrideService';
import usePagingBuilder from '@/app/apiclient/PagingBuilder';
import { OfficialEventDetailProps } from '@/app/component/page/event/official/normal/OfficialEvent';

export type Dependencies = {
  getOfficialEventService: GetOfficialEventService;
  getOfficialEventWorksService: GetWorksService;
};

export const OfficialEventContext = createContext<Dependencies>({
  getOfficialEventService: dummyGetOfficialEventJuneBrideService,
  getOfficialEventWorksService: dummyGetWorksService,
});

type Props = {
  officialEventTag: string;
};

const useOfficialEvent = ({ officialEventTag }: Props): OfficialEventDetailProps => {
  const { getOfficialEventService, getOfficialEventWorksService } =
    useContext(OfficialEventContext);
  const pagingBuilder = usePagingBuilder();
  const { createCanvasData } = useCreateCanvasData();
  const { globalState, dispatch } = useContext(GlobalContext);
  const navigator = useNavigate();
  const analytics = useAnalytics();
  const translator = useTranslator();
  const { layoutParams } = useContext(LayoutContext);

  const fetchOfficialEventWorks = async (currentState: OfficialEventDetailStateType) => {
    dispatch({
      type: 'UPDATE_OFFICIAL_EVENT_DETAIL_STATE',
      payload: {
        officialEventDetailState: {
          ...currentState,
          isLoadingWorks: true,
        },
      },
    });
    const response = await getOfficialEventWorksService({ nextUrl: currentState.nextUrl });
    const newWorks = currentState.works.concat(response.works);
    const newNextUrl = response.nextUrl;
    const newState: OfficialEventDetailStateType = {
      ...currentState,
      isLoadingWorks: false,
      isVisibleEnd: newNextUrl === '',
      works: newWorks,
      nextUrl: newNextUrl,
    };
    dispatch({
      type: 'UPDATE_OFFICIAL_EVENT_DETAIL_STATE',
      payload: {
        officialEventDetailState: newState,
      },
    });
    if (shouldFetchNext(newNextUrl)) {
      fetchOfficialEventWorks(newState);
    }
  };

  const refreshOfficialEvent = async (officialEventTag: string) => {
    dispatch({
      type: 'UPDATE_OFFICIAL_EVENT_DETAIL_STATE',
      payload: {
        officialEventDetailState: {
          ...defaultOfficialEventDetailState,
          isLoadingWorks: false,
        },
      },
    });
    const officialEvent = await getOfficialEventService(officialEventTag);
    const newState: OfficialEventDetailStateType = {
      officialEvent,
      mainImageUrl: officialEvent.imageUrl,
      tag: officialEvent.tag,
      templateImageUrl: officialEvent.layerImageUrl0,
      colors: officialEvent.colorCodes,
      shareTitle: officialEvent.shareText,
      twitterShareUrl: officialEvent.shareUrl,
      facebookShareUrl: officialEvent.shareUrl,
      bannerImageUrl: officialEvent.bannerImageUrl,
      isLoadingDetail: false,
      isLoadingWorks: true,
      isVisibleEnd: false,
      works: [],
      nextUrl: pagingBuilder(`/official_events/${officialEvent.id}/works`),
      isBattleEvent: officialEvent.isBattleEvent,
      description: officialEvent.battleInfo.description ?? '',
      teamOneText: translator(TranslationKeys.JoinTeam, {
        value: officialEvent.battleInfo.teamOne.name,
      }),
      teamOneTag: officialEvent.battleInfo.teamOne.tag,
      teamOneScore: officialEvent.battleInfo.teamOne.point,
      teamOneColor: `#${officialEvent.battleInfo.teamOne.colorCode}`,
      teamTwoText: translator(TranslationKeys.JoinTeam, {
        value: officialEvent.battleInfo.teamTwo.name,
      }),
      teamTwoTag: officialEvent.battleInfo.teamTwo.tag,
      teamTwoScore: officialEvent.battleInfo.teamTwo.point,
      teamTwoColor: `#${officialEvent.battleInfo.teamTwo.colorCode}`,
    };
    dispatch({
      type: 'UPDATE_OFFICIAL_EVENT_DETAIL_STATE',
      payload: { officialEventDetailState: newState },
    });
    await fetchOfficialEventWorks(newState);
  };

  const handleDraw = () => {
    const { officialEvent } = globalState.officialEventDetailState;
    const image = new Image();
    image.onload = () => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d')!;
      canvas.width = image.width;
      canvas.height = image.height;
      context.drawImage(image, 0, 0, image.width, image.height);
      const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
      const newPixels = Array.from({ length: image.height }, (_, h) => {
        return Array.from({ length: image.width }, (_, w) =>
          imageData.data[h * imageData.width * 4 + w * 4 + 2] === 0
            ? 'FFFFFF'
            : rgbToHex(
                imageData.data[h * imageData.width * 4 + w * 4],
                imageData.data[h * imageData.width * 4 + w * 4 + 1],
                imageData.data[h * imageData.width * 4 + w * 4 + 2],
              ),
        );
      });
      const newCanvasId = createCanvasData(
        { width: image.width, height: image.height },
        officialEvent.colorCodes,
        newPixels,
        officialEvent.tag,
        officialEvent.battleInfo.teamOne.tag !== ''
          ? officialEvent.battleInfo.teamOne.tag
          : undefined,
      );
      analytics('draw_official_event', { official_event_id: `${officialEvent.id}` });
      navigator(`/draw?current=${newCanvasId}`);
    };
    image.setAttribute('crossOrigin', '');
    image.src = officialEvent.layerImageUrl0;
  };

  // @ts-ignore
  const handlePost = () => {
    navigator(`/upload?officialEventTag=${globalState.officialEventDetailState.tag}`);
  };

  const handleJoinTeam = (teamTag: string) => {
    navigator(
      `/upload?officialBattleEventTag=${globalState.officialEventDetailState.tag}&officialBattleEventTeam=${teamTag}`,
    );
  };

  const handleScroll = () => {
    if (
      globalState.officialEventDetailState.isLoadingWorks ||
      !shouldFetchNext(globalState.officialEventDetailState.nextUrl) ||
      globalState.officialEventDetailState.works.length === 0
    ) {
      return;
    }
    fetchOfficialEventWorks({
      ...globalState.officialEventDetailState,
      isLoadingWorks: true,
    });
  };

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  useEffect(() => {
    if (!officialEventTag || officialEventTag === globalState.officialEventDetailState.tag) return;
    refreshOfficialEvent(officialEventTag);
  }, []);

  return {
    ...globalState.officialEventDetailState,
    isVisibleJoinButton: !layoutParams.isMobile,
    isVisibleTemplate: globalState.officialEventDetailState.templateImageUrl !== '',
    handlePost,
    handleDraw,
    handleJoinTeam,
  };
};

export default useOfficialEvent;
