import Text from '@dotpict-lib/foundation/typography/Text';
import useTranslator from '@/app/hook/useTranslator';
import TranslationKeys from '@/app/translation/TranslationKeys';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';
import GooglePlay from '@/app/component/partial/app/googleplay/GooglePlay';
import AppStore from '@/app/component/partial/app/appstore/AppStore';
import ExternalLinkComponent from '@/app/component/ExternalLinkComponent';
import Column from '@dotpict-lib/component/core/layout/Column';
import { HorizontalGravity } from '@dotpict-lib/component/core/layout/HorizontalGravity';
import { Row } from '@dotpict-lib/component/core/layout/Row';

const DrawTrial = () => {
  const translator = useTranslator();
  return (
    <Column padding={24} background={ColorStyle.WHITE} horizontalGravity={HorizontalGravity.center}>
      <Text
        text={translator(TranslationKeys.TrialTitle)}
        textStyle={TextStyle.BOLD16}
        colorStyle={ColorStyle.TEXT_PRIMARY}
      />
      <Spacer height={16} />
      <Text
        text={translator(TranslationKeys.TrialMessage1)}
        textStyle={TextStyle.REGULAR13}
        colorStyle={ColorStyle.TEXT_PRIMARY}
      />
      <Spacer height={16} />
      <Row>
        <GooglePlay />
        <Spacer width={4} />
        <AppStore />
      </Row>
      <Spacer height={16} />
      <Text
        text={translator(TranslationKeys.TrialMessage2)}
        textStyle={TextStyle.REGULAR13}
        colorStyle={ColorStyle.TEXT_PRIMARY}
      />
      <Spacer height={16} />
      <Text
        text={translator(TranslationKeys.TrialMessage3)}
        textStyle={TextStyle.REGULAR13}
        colorStyle={ColorStyle.TEXT_PRIMARY}
      />
      <Spacer height={16} />
      <ExternalLinkComponent
        text={translator(TranslationKeys.SendFeedback)}
        url="https://docs.google.com/forms/d/e/1FAIpQLSdgTBP6YblQ3FYUSqztdSci8L0zcbgNfcyiAg7rjQOrzgVjPg/viewform"
        textStyle={TextStyle.REGULAR13}
        colorStyle={ColorStyle.PRIMARY}
      />
    </Column>
  );
};

export default DrawTrial;
