import { Children } from 'react';
import { css } from '@emotion/react';
import { LayoutModifier } from './LayoutModifier';
import Spacer from './Spacer';

const Column = ({
  width,
  height,
  padding,
  innerPadding,
  verticalPadding,
  horizontalPadding,
  background,
  horizontalGravity,
  verticalGravity,
  children,
}: LayoutModifier) => {
  const style = css({
    width,
    height,
    padding:
      padding ??
      (verticalPadding && horizontalPadding
        ? `${verticalPadding} ${horizontalPadding}`
        : undefined) ??
      (verticalPadding ? `${verticalPadding} 0` : undefined) ??
      (horizontalPadding ? `0 ${horizontalPadding}` : undefined),
    background,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: verticalGravity,
    alignItems: horizontalGravity,
    boxSizing: 'border-box',
  });

  const childCount = Children.count(children);
  const firstIndex = Children.toArray(children).findIndex((child) => !!child);

  if (childCount === 0) {
    return <div css={style}>{children}</div>;
  }

  return (
    <div css={style}>
      {children &&
        Children.map(
          children,
          (child, index) =>
            child && (
              <>
                {innerPadding && index !== firstIndex && <Spacer height={innerPadding} />}
                {child}
              </>
            ),
        )}
    </div>
  );
};

export default Column;
