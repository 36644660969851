import { useContext } from 'react';
import { LayoutContext } from '@/Routing';
import { UserDetailHeaderHeaderBindModel } from '@/app/component/page/user/detail/UserDetailHeader';
import TranslationKeys from '@/app/translation/TranslationKeys';
import useTranslator from '@/app/hook/useTranslator';
import { UserType } from '@dotpict-lib/model/User/UserType';

type InputProps = {
  user: UserType;
};

export type OutputProps = {
  bindModel: UserDetailHeaderHeaderBindModel;
};

const useUserDetailHeader = ({ user }: InputProps): OutputProps => {
  const { layoutParams } = useContext(LayoutContext);
  const translator = useTranslator();

  return {
    bindModel: {
      id: user.id,
      name: user.name,
      account: user.account,
      headerImageUrl: user.headerImageUrl,
      profileImageUrl: user.profileImageUrl,
      url: user.url,
      text: user.text,
      followedCount: user.followedCount,
      followerCount: user.followerCount,
      isFollowed: user.isFollowed,
      isOpenedRequestBox: user.isOpenedRequestBox,
      registrationDate: translator(TranslationKeys.RegistrationDate) + user.registerAt,
      birthDate: user.birthDate,
      contentWidth: layoutParams.contentWidth,
    },
  };
};

export default useUserDetailHeader;
