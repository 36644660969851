import Pixelart from '@dotpict-lib/component/core/Pixelart';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import Text from '@dotpict-lib/foundation/typography/Text';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import TranslationKeys from '@/app/translation/TranslationKeys';
import Column from '@dotpict-lib/component/core/layout/Column';
import { VerticalGravity } from '@dotpict-lib/component/core/layout/VerticalGravity';
import { Row } from '@dotpict-lib/component/core/layout/Row';
import Badge from '@/app/component/partial/common/badge/Badge';
import useTranslator from '@/app/hook/useTranslator';
import { MakingType } from '../making/MakingType';

const infoContainerPadding = 16;

interface IProps {
  making: MakingType;
  width: number;
  height: number; // height = imageSize
}

const MakingThumbnailComponent = (props: IProps) => {
  const translator = useTranslator();
  const { making, width, height } = props;

  return (
    <Row width={width} height={height} background={ColorStyle.WHITE}>
      <Pixelart alt="making" src={making.mainImage} width={height} height={height} />
      <Column>
        <Column
          padding={infoContainerPadding}
          width={width - height - infoContainerPadding * 2}
          height="100%"
          verticalGravity={VerticalGravity.spaceBetween}
        >
          <Badge
            text={translator(TranslationKeys.Making)}
            color={ColorStyle.BLUE}
            width={120}
            height={32}
            textStyle={TextStyle.BOLD14}
          />
          <div style={{ textAlign: 'center' }}>
            <Text
              text={translator(making.theme)}
              textStyle={TextStyle.BOLD16}
              colorStyle={ColorStyle.TEXT_PRIMARY}
            />
          </div>
          <div style={{ textAlign: 'end' }}>
            <Text
              text={`by ${translator(making.authorName)}`}
              textStyle={TextStyle.BOLD12}
              colorStyle={ColorStyle.TEXT_PRIMARY}
            />
          </div>
        </Column>
      </Column>
    </Row>
  );
};

export default MakingThumbnailComponent;
