import styled from '@emotion/styled';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import { ToolsMap, ToolType } from '@/app/component/page/draw/Context/ToolContext';
import ToolButton from '@/app/component/page/draw/SideMenu/ToolSelector/ToolButton';
import EditColor from '@/app/component/page/draw/SideMenu/ToolSelector/EditColor';

type Props = {
  selectTool: (selected: ToolType) => () => void;
  selectColor: (selectedIndex: number) => () => void;
  editPalette(index: number, newColor: string): void;
};

const SideBar = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 112px);
  padding: 56px 16px;
  background: ${ColorStyle.TEXT_PRIMARY};
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 64px 64px;
  grid-template-rows: 64px;
  grid-gap: 16px;
`;

const Space = styled.div`
  height: 64px;
`;

const ToolSelector = ({ selectTool, selectColor, editPalette }: Props) => {
  return (
    <SideBar>
      <Grid>
        <ToolButton toolType={ToolsMap.Pen} selectTool={selectTool} />
        <ToolButton toolType={ToolsMap.Eraser} selectTool={selectTool} />
        <ToolButton toolType={ToolsMap.Bucket} selectTool={selectTool} />
        <EditColor editPalette={editPalette} selectTool={selectTool} selectColor={selectColor} />
        <ToolButton toolType={ToolsMap.Line} selectTool={selectTool} />
        <ToolButton toolType={ToolsMap.Move} selectTool={selectTool} />
      </Grid>
      <Space />
      <Grid>
        <ToolButton toolType={ToolsMap.Undo} selectTool={selectTool} />
        <ToolButton toolType={ToolsMap.Redo} selectTool={selectTool} />
      </Grid>
      <Space />
      <Grid>
        <ToolButton toolType={ToolsMap.Clear} selectTool={selectTool} />
      </Grid>
    </SideBar>
  );
};

export default ToolSelector;
