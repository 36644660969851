import TranslationKeys from '@/app/translation/TranslationKeys';
import { articleLineSpace } from '@/app/common/constant';
import { IOldArticle } from '@/app/article/IOldArticle';
import ArticleContentType from '@/app/article/ArticleContentType';
import { IArticleImageContent } from '@/app/article/IArticleImageContent';

const imageWidth = 280;
const imageHeight = 554;

const LayerImage: IArticleImageContent = {
  type: ArticleContentType.IMAGE,
  content: 'https://storage.googleapis.com/dotpict-images/web/6th/layer.jpg',
  width: imageWidth,
  height: imageHeight,
};

const PaletteImage: IArticleImageContent = {
  type: ArticleContentType.IMAGE,
  content: 'https://storage.googleapis.com/dotpict-images/web/6th/palette.gif',
  width: imageWidth,
  height: 606,
};

const PenSizeImage: IArticleImageContent = {
  type: ArticleContentType.IMAGE,
  content: 'https://storage.googleapis.com/dotpict-images/web/6th/pensize.jpg',
  width: imageWidth,
  height: imageHeight,
};

const MagicWandImage: IArticleImageContent = {
  type: ArticleContentType.IMAGE,
  content: 'https://storage.googleapis.com/dotpict-images/web/6th/magicwand.jpg',
  width: imageWidth,
  height: 282,
};

const PreviewImage: IArticleImageContent = {
  type: ArticleContentType.IMAGE,
  content: 'https://storage.googleapis.com/dotpict-images/web/6th/previewinfo.jpg',
  width: imageWidth,
  height: imageHeight,
};

const Guide9Image: IArticleImageContent = {
  type: ArticleContentType.IMAGE,
  content: 'https://storage.googleapis.com/dotpict-images/web/6th/guide9.jpg',
  width: imageWidth,
  height: imageHeight,
};

const GuideWhiteImage: IArticleImageContent = {
  type: ArticleContentType.IMAGE,
  content: 'https://storage.googleapis.com/dotpict-images/web/6th/guidewhite.jpg',
  width: imageWidth,
  height: imageHeight,
};

const IpadHorizontalImage: IArticleImageContent = {
  type: ArticleContentType.IMAGE,
  content: 'https://storage.googleapis.com/dotpict-images/web/6th/ipadhorizontal.jpg',
  width: imageWidth,
  height: 195,
};

const MyCanvasesImage: IArticleImageContent = {
  type: ArticleContentType.IMAGE,
  content: 'https://storage.googleapis.com/dotpict-images/web/6th/mycanvases.jpg',
  width: imageWidth,
  height: imageHeight,
};

const SixArticle: IOldArticle = {
  titleTranslationKey: TranslationKeys.SixTitle,
  contents: [
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.TEXT, content: TranslationKeys.SixText1 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.TEXT, content: TranslationKeys.SixText2 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.TEXT, content: TranslationKeys.SixText3 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.TEXT, content: TranslationKeys.SixText4 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.TEXT, content: TranslationKeys.SixText5 },

    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.SPACE, content: articleLineSpace },

    { type: ArticleContentType.HEADLINE, content: TranslationKeys.SixText6 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.TEXT, content: TranslationKeys.SixText7 },
    { type: ArticleContentType.TEXT, content: TranslationKeys.SixText8 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    LayerImage,

    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.SPACE, content: articleLineSpace },

    { type: ArticleContentType.HEADLINE, content: TranslationKeys.SixText9 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.TEXT, content: TranslationKeys.SixText10 },
    { type: ArticleContentType.TEXT, content: TranslationKeys.SixText11 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    PaletteImage,

    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.SPACE, content: articleLineSpace },

    { type: ArticleContentType.HEADLINE, content: TranslationKeys.SixText12 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.TEXT, content: TranslationKeys.SixText13 },
    { type: ArticleContentType.TEXT, content: TranslationKeys.SixText14 },
    { type: ArticleContentType.TEXT, content: TranslationKeys.SixText15 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    PenSizeImage,

    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.SPACE, content: articleLineSpace },

    { type: ArticleContentType.HEADLINE, content: TranslationKeys.SixText16 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.TEXT, content: TranslationKeys.SixText17 },
    { type: ArticleContentType.TEXT, content: TranslationKeys.SixText18 },
    { type: ArticleContentType.TEXT, content: TranslationKeys.SixText19 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    MagicWandImage,

    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.SPACE, content: articleLineSpace },

    { type: ArticleContentType.HEADLINE, content: TranslationKeys.SixText20 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.TEXT, content: TranslationKeys.SixText21 },
    { type: ArticleContentType.TEXT, content: TranslationKeys.SixText22 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    PreviewImage,

    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.SPACE, content: articleLineSpace },

    { type: ArticleContentType.HEADLINE, content: TranslationKeys.SixText23 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.TEXT, content: TranslationKeys.SixText24 },
    { type: ArticleContentType.TEXT, content: TranslationKeys.SixText25 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    Guide9Image,
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    GuideWhiteImage,

    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.SPACE, content: articleLineSpace },

    { type: ArticleContentType.HEADLINE, content: TranslationKeys.SixText26 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    IpadHorizontalImage,

    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.SPACE, content: articleLineSpace },

    { type: ArticleContentType.HEADLINE, content: TranslationKeys.SixText27 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.TEXT, content: TranslationKeys.SixText28 },
    { type: ArticleContentType.TEXT, content: TranslationKeys.SixText29 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    MyCanvasesImage,

    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.SPACE, content: articleLineSpace },

    { type: ArticleContentType.HEADLINE, content: TranslationKeys.SixText30 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.TEXT, content: TranslationKeys.SixText31 },
    { type: ArticleContentType.TEXT, content: TranslationKeys.SixText32 },
    { type: ArticleContentType.TEXT, content: TranslationKeys.SixText33 },
    { type: ArticleContentType.TEXT, content: TranslationKeys.SixText34 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },

    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.SPACE, content: articleLineSpace },

    { type: ArticleContentType.TEXT, content: TranslationKeys.SixText35 },

    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.SPACE, content: articleLineSpace },

    { type: ArticleContentType.TEXT, content: TranslationKeys.SixText36 },
  ],
};

export default SixArticle;
