import ArticleComponent from '@/app/component/page/article/ArticleComponent';
import GuidelineArticle from '@/app/article/GuidelineArticle';

const GuidelineComponent = () => (
  <div>
    <ArticleComponent article={GuidelineArticle} />
  </div>
);

export default GuidelineComponent;
