import { PagingType } from '@dotpict-lib/model/PagingType';
import TestWorkJuneBride from '@/app/test/work/TestWorkJuneBride';
import { GetWorksService } from '@/app/service/GetWorks/GetWorksService';

const dummyGetWorksService: GetWorksService = (_: PagingType) => {
  return Promise.resolve({
    works: [TestWorkJuneBride],
    nextUrl: '',
  });
};

export default dummyGetWorksService;
