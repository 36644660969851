enum MakingContentEnum {
  HEADLINE,
  TEXT,
  PIXELART,
  IMAGE,
  IMAGE_EN,
  IMAGE_JA,
  SPACE,
}

export default MakingContentEnum;
