import { ChildNotesResponseType } from '@/app/model/responsetype/ChildNotesResponseType';
import { emptyNote } from '@dotpict-sns-lib/model/Note/NoteType';

export type GetChildNotesService = () => Promise<ChildNotesResponseType>;

export const dummyGetChildNotesService: GetChildNotesService =
  (): Promise<ChildNotesResponseType> => {
    return Promise.resolve({
      parentNote: emptyNote,
      notes: [
        {
          ...emptyNote,
          text: 'dummyText',
        },
      ],
    });
  };
