import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import InputComponent from '@/app/component/InputComponent';
import Button from '@dotpict-lib/component/dotpict/Button';
import TranslationKeys from '@/app/translation/TranslationKeys';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';
import useTranslator from '@/app/hook/useTranslator';
import Text from '@dotpict-lib/foundation/typography/Text';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import useLogin from '@/app/component/page/me/login/useLogin';
import Column from '@dotpict-lib/component/core/layout/Column';
import { HorizontalGravity } from '@dotpict-lib/component/core/layout/HorizontalGravity';

export type LoginState = {
  mailAddress: string;
  password: string;
  errorMessage: string;
};

export type LoginProps = LoginState & {
  handleMailAddress: (newMailAddress: string) => void;
  handlePassword: (newPassword: string) => void;
  handleLoginButtonClick: () => void;
};

const Login = () => {
  const translator = useTranslator();

  const {
    mailAddress,
    password,
    errorMessage,
    handleMailAddress,
    handlePassword,
    handleLoginButtonClick,
  } = useLogin();

  return (
    <Column horizontalGravity={HorizontalGravity.center}>
      <Spacer height={40} />
      <Column
        horizontalGravity={HorizontalGravity.center}
        width={400}
        background={ColorStyle.WHITE}
      >
        <Spacer height={32} />
        <InputComponent
          width={320}
          height={48}
          placeHolder={translator(TranslationKeys.Email)}
          isHidden={false}
          value={mailAddress}
          onTextChange={(mailAddress: string) => handleMailAddress(mailAddress)}
        />
        <Spacer height={20} />
        <InputComponent
          width={320}
          height={48}
          placeHolder={translator(TranslationKeys.Password)}
          isHidden
          value={password}
          onTextChange={(password: string) => handlePassword(password)}
        />
        <Spacer height={32} />
        <Button
          width={320}
          height={48}
          text={translator(TranslationKeys.Login)}
          isFilled
          handleClick={handleLoginButtonClick}
        />
        {errorMessage !== '' && (
          <>
            <Spacer height={8} />
            <Text text={errorMessage} textStyle={TextStyle.BOLD12} colorStyle={ColorStyle.ERROR} />
          </>
        )}
        <Spacer height={32} />
      </Column>
    </Column>
  );
};

export default Login;
