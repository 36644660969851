export type SpacerProps = {
  width?: number;
  height?: number;
};

const Spacer = ({ width, height }: SpacerProps) => {
  return <div style={{ width: width ?? 1, height: height ?? 1 }} />;
};

export default Spacer;
