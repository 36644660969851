import { MakingContentType } from '@/app/making/MakingContentType';
import { MakingLinkType } from '@/app/making/MakingLinkType';
import TranslationKeys from '@/app/translation/TranslationKeys';

export type MakingType = {
  key: string;
  userId: number;
  mainImage: string;
  mainImageSize: number;
  ogpImage: string;
  authorName: string;
  profileImage: string;
  profileTexts: string[];
  links: MakingLinkType[];
  theme: string;
  tableOfContents: string[];
  contents: MakingContentType[];
};

export const defaultMaking: MakingType = {
  key: '',
  userId: 0,
  mainImage: '',
  mainImageSize: 320,
  ogpImage: '',
  authorName: TranslationKeys.Empty,
  profileImage: '',
  profileTexts: [],
  links: [],
  theme: TranslationKeys.Empty,
  tableOfContents: [],
  contents: [],
};
