import { UserSummaryType } from '@/app/model/UserSummaryType';

export interface IFollowedUsersState {
  userId: number;
  userSummaries: UserSummaryType[];
  nextUrl: string | null;
  requestedUserId: number;
}

export const defaultFollowedUsersState: IFollowedUsersState = {
  userId: 0,
  userSummaries: [],
  nextUrl: null,
  requestedUserId: 0,
};
