import TranslationKeys from '@/app/translation/TranslationKeys';
import { useContext } from 'react';
import { APIContext, GlobalContext, MeContext } from '@/Routing';
import { DotpictAction } from '@/app/common/reducer';
import useTranslator from '@/app/hook/useTranslator';
import { PrimaryButton, PrimaryButtonSizeS } from '@dotpict-lib/component/core/PrimaryButton';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';

export type FollowButtonProps = {
  userId: number;
  isFollowed: boolean;
};

export const FollowButton = ({ userId, isFollowed }: FollowButtonProps) => {
  const { dispatch } = useContext(GlobalContext);
  const meState = useContext(MeContext);
  const translator = useTranslator();
  const { client } = useContext(APIContext);

  const handleClick = () => {
    if (meState.isLoggedIn) {
      const request = async () => {
        const user = isFollowed
          ? await client.unfollow(meState.token, `${userId}`)
          : await client.follow(meState.token, `${userId}`);
        const action: DotpictAction = {
          type: 'UPDATE_FOLLOW_STATE',
          payload: { user },
        };
        dispatch(action);
      };
      request();
    } else {
      // eslint-disable-next-line no-alert
      window.alert('ログインが必要です');
    }
  };

  return (
    <PrimaryButton
      width={128}
      size={PrimaryButtonSizeS}
      text={translator(isFollowed ? TranslationKeys.Followed : TranslationKeys.Follow)}
      icon={
        isFollowed
          ? 'https://storage.googleapis.com/dotpict-images/web/ic_followed.svg'
          : 'https://storage.googleapis.com/dotpict-images/web/ic_follow.svg'
      }
      iconWidth={30}
      backgroundColor={isFollowed ? ColorStyle.MONO50 : undefined}
      onClick={handleClick}
    />
  );
};
