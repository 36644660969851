import Spacer from '@dotpict-lib/component/core/layout/Spacer';
import { Row } from '@dotpict-lib/component/core/layout/Row';

export type PageControlProps = {
  selectedIndex: number;
  numberOfItems: number;
};

const selectedImageSrc =
  'https://storage.googleapis.com/dotpict-images/web/page_control_selected.svg';
const unSelectedImageSrc =
  'https://storage.googleapis.com/dotpict-images/web/page_control_unselected.svg';

const PageControl = (props: PageControlProps) => {
  const { selectedIndex, numberOfItems } = props;

  return (
    <Row>
      {[...new Array(numberOfItems)].map((_, index) => (
        <>
          <Spacer width={4} />
          <img
            src={index === selectedIndex ? selectedImageSrc : unSelectedImageSrc}
            alt="page_control_icon"
          />
          <Spacer width={4} />
        </>
      ))}
    </Row>
  );
};

export default PageControl;
