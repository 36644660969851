import TwitterShareComponent from '@/app/component/TwitterShareComponent';
import FacebookShareComponent from '@/app/component/FacebookShareComponent';
import { Row } from '@dotpict-lib/component/core/layout/Row';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';

const itemWidth = 102;
const itemHeight = 28;

type Props = {
  url: string;
  title: string;
};

const ShareComponent = ({ url, title }: Props) => (
  <Row>
    <TwitterShareComponent url={url} title={title} width={itemWidth} height={itemHeight} />
    <Spacer width={12} />
    <FacebookShareComponent url={url} title={title} width={itemWidth} height={itemHeight} />
  </Row>
);

export default ShareComponent;
