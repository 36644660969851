import { UserSummaryType } from '@/app/model/UserSummaryType';

export interface ILikeUsersState {
  workId: number;
  userSummaries: UserSummaryType[];
  nextUrl: string | null;
  requestedUserId: number;
}

export const defaultLikeUsersState: ILikeUsersState = {
  workId: 0,
  userSummaries: [],
  nextUrl: null,
  requestedUserId: 0,
};
