import { WorkType } from '../model/WorkType';

export type WorksStateType = {
  works: WorkType[];
  nextUrl: string | null;
};

export const defaultWorksState: WorksStateType = {
  works: [],
  nextUrl: null,
};
