import { OdaiType } from '@dotpict-sns-lib/model/Odai/OdaiType';
import { convertArrayToColorCode } from 'dotpict-lib/src/model/ColorCode/convertArrayToColorCode';

export const convertArrayToOdai = (json: any): OdaiType => ({
  id: json.id,
  title: json.title,
  tag: json.tag,
  colorCodes: json.color_codes.map((colorCode: any) => convertArrayToColorCode(colorCode)),
  heldDate: json.held_date,
});
