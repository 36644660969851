import { Link } from 'react-router-dom';
import Text from '@dotpict-lib/foundation/typography/Text';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import TranslationKeys from '@/app/translation/TranslationKeys';
import { Row } from '@dotpict-lib/component/core/layout/Row';
import useTranslator from '@/app/hook/useTranslator';
import Spacer from '@dotpict-lib/component/core/layout/Spacer';

type Props = {
  type: string | undefined;
  query: string | undefined;
};

const SearchHeaderComponent = ({ type, query }: Props) => {
  const translator = useTranslator();

  return (
    <div>
      <Text
        text={translator(TranslationKeys.SearchResult, { value: query })}
        textStyle={TextStyle.BOLD16}
        colorStyle={ColorStyle.TEXT_PRIMARY}
      />
      <Spacer height={16} />
      <Row>
        <Link to={`/search/works/title/${query}`}>
          <Text
            text={translator(TranslationKeys.Title)}
            textStyle={TextStyle.BOLD20}
            colorStyle={type === 'title' ? ColorStyle.PRIMARY : ColorStyle.TEXT_PRIMARY}
          />
        </Link>
        <Spacer width={24} />
        <Link to={`/search/works/tag/${query}`}>
          <Text
            text={translator(TranslationKeys.Tag)}
            textStyle={TextStyle.BOLD20}
            colorStyle={type === 'tag' ? ColorStyle.PRIMARY : ColorStyle.TEXT_PRIMARY}
          />
        </Link>
        <Spacer width={24} />
        <Link to={`/search/users/${query}`}>
          <Text
            text={translator(TranslationKeys.User)}
            textStyle={TextStyle.BOLD20}
            colorStyle={type === 'user' ? ColorStyle.PRIMARY : ColorStyle.TEXT_PRIMARY}
          />
        </Link>
      </Row>
    </div>
  );
};

export default SearchHeaderComponent;
