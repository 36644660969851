import { useContext, useMemo } from 'react';
import { APIContext } from '@/Routing';
import { useParams } from 'react-router-dom';
import {
  WorkRepliesContext,
  WorkRepliesDependencies,
} from '@/app/component/page/work/thread/replies/useWorkReplies';
import { WorkReplies } from '@/app/component/page/work/thread/replies/WorkReplies';

export const WorkRepliesPage = () => {
  const { client } = useContext(APIContext);
  const { workId, threadId } = useParams();

  const dependencies = useMemo((): WorkRepliesDependencies => {
    return {
      threadId: Number(threadId),
      getWorkRepliesService: () =>
        workId && threadId ? client.fetchWorkReplies(workId, threadId) : Promise.reject(),
    };
  }, [client]);

  return (
    <WorkRepliesContext.Provider value={dependencies}>
      <WorkReplies />
    </WorkRepliesContext.Provider>
  );
};
