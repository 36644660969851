import Text from '@dotpict-lib/foundation/typography/Text';
import TextStyle from '@dotpict-lib/foundation/typography/TextStyle';
import ColorStyle from '@dotpict-lib/foundation/typography/ColorStyle';
import TranslationKeys from '@/app/translation/TranslationKeys';
import { css } from '@emotion/react';
import useTranslator from '@/app/hook/useTranslator';
import Column from '@dotpict-lib/component/core/layout/Column';
import { HorizontalGravity } from '@dotpict-lib/component/core/layout/HorizontalGravity';
import { VerticalGravity } from '@dotpict-lib/component/core/layout/VerticalGravity';

export type FileSelectButtonProps = {
  handleFileSelect: (file: File | undefined) => void;
};

const styledLabel = css`
  width: 320px;
  height: 48px;
`;

const styledInput = css`
  display: none;
`;

const FileSelectButton = ({ handleFileSelect }: FileSelectButtonProps) => {
  const translator = useTranslator();

  return (
    <label css={styledLabel} htmlFor="upload-file">
      <Column
        width={320}
        height={48}
        background={ColorStyle.PRIMARY}
        horizontalGravity={HorizontalGravity.center}
        verticalGravity={VerticalGravity.center}
      >
        <Text
          text={translator(TranslationKeys.AddFile)}
          textStyle={TextStyle.BOLD14}
          colorStyle={ColorStyle.WHITE}
        />
      </Column>
      <input
        id="upload-file"
        accept="image/png,image/gif"
        css={styledInput}
        type="file"
        onChange={(event) => {
          const file = event.target.files?.item(0);
          if (!file) return;
          handleFileSelect(file);
        }}
      />
    </label>
  );
};

export default FileSelectButton;
